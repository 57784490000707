import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api, { isHTTPError } from 'containers/api'

const useGetCompetitorsReport = () => {
  const token = useSelector(selectToken)

  const [report, setReport] = useState()
  const [isLoadingReport, setIsLoadingReport] = useState(false)
  const [reportError, setReportError] = useState('')

  const getCompetitorsReport = async ({ reportId, organization }) => {
    setIsLoadingReport(true)
    try {
      const responseReport = await api.getCompetitorsReport({
        reportId,
        organizationId: organization.id,
        token,
      })

      setReport(responseReport)
      setIsLoadingReport(false)
      return responseReport
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 422) {
        setReportError('Failed loading competitors report: Validation error')
      } else if (!isHTTPError(error) || error.response.status !== 404) {
        setReportError('Failed loading competitors report')
      }
      setIsLoadingReport(false)
      return false
    }
  }

  return { report, isLoadingReport, reportError, getCompetitorsReport }
}

export default useGetCompetitorsReport
