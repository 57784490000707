import { useState } from 'react'

import api, { isHTTPError } from '../api'
import { Country } from 'types'

const useGetCountries = () => {
  const [countries, setCountries] = useState<Country[]>([])
  const [loadingCountries, setLoadingCountries] = useState(true)
  const [countriesError, setCountriesError] = useState('')

  const getCountries = async () => {
    try {
      const responseCountries = await api.getCountries()

      setCountries(responseCountries)
      setLoadingCountries(false)
      return responseCountries
    } catch (error) {
      if (isHTTPError(error)) {
        setCountriesError(`Could not get available countries`)
      } else {
        setCountriesError("Can't connect to the server")
      }
    }
  }

  return { countries, loadingCountries, countriesError, getCountries }
}

export default useGetCountries
