import { useSelector } from 'react-redux'
import { selectToken } from './authenticationSlice'
import api, { isHTTPError } from '../api'
import { MicrosoftRedirectParams } from 'types'
import { useState } from 'react'

const useMicrosoft = () => {
  const [authURIError, setAuthURIError] = useState<string | null>(null)

  const getAuthUri = async (params: MicrosoftRedirectParams | null) => {
    try {
        if (!params) {
          params = {isMona: false}
        }
        return await api.getMicrosoftAuthUri({ params })
      } catch (error) {
        if (isHTTPError(error) && error.response.status === 404) {
          setAuthURIError('Microsoft account not connected')
        } else if (isHTTPError(error) && 'detail' in error.response.data) {
          setAuthURIError(
            'Error getting Microsoft auth URI: ' + error.response.data.detail.type
          )
      }
      return null
    }
  }

  const createMonaSubscription = async (subscription: string, microsoft_code: string) => {
    try {
      const response = await api.createMonaSubscription({ microsoft_code, subscription})
      return response
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Something went wrong while creating subscription')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error while creating subscription: ' + error.response.data.detail.type
        )
      }
      return null
    }
  }

  return {
    authURIError,
    getAuthUri,
    createMonaSubscription,
  }
}

export default useMicrosoft
