import ScreenLoading from 'components/ScreenLoading'
import { FetchStatus } from 'containers/dataLoader'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetSharedUsers from 'containers/sharedDataset/useGetSharedUsers'
import useShareDataset from 'containers/sharedDataset/useShareDataset'
import React, { useContext, useEffect, useState } from 'react'
import UnshareDatasetTable from './UnshareDatasetTable'

const ShareDatasetForm = () => {
  const [email, setEmail] = useState<string>('')
  const { currentOrganization } = useContext(OrganizationsContext)
  const [users, getSharedUsers] = useGetSharedUsers(currentOrganization)
  const [sharedStatus, shareDataset, loading] =
    useShareDataset(currentOrganization)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await shareDataset(email)
    await getSharedUsers()
  }

  useEffect(() => {
    getSharedUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="share-dataset-container">
      <p>
        Fill your email in the form bellow to get access to your shared dataset.
        <br /> Click on the following link for instructions on how to{' '}
        <a href="https://docs.mayainsights.com/docs/db/" target="_blank" rel="noreferrer">
          access your dataset
        </a>
        .
      </p>
      <form
        style={{ marginTop: '1rem', width: '50%', minWidth: '300px' }}
        onSubmit={onSubmit}
      >
        <div className="input-container">
          <input
            className="share-input"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Google email"
          />
          <button className="share-button" type="submit">
            {loading === FetchStatus.Pending ? (
              <ScreenLoading spinnerColor="white" />
            ) : (
              'Share'
            )}
          </button>
        </div>

        <div className="status">
          {sharedStatus?.hasOwnProperty('success') ? (
            <div className="share-status">
              {!sharedStatus.success ? (
                <span className="material-icons arrow non-selectable fail">
                  close
                </span>
              ) : (
                <span className="material-icons arrow non-selectable success">
                  check
                </span>
              )}
              <p className={sharedStatus.success ? 'success' : 'fail'}>
                {sharedStatus?.message}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
      <UnshareDatasetTable users={users} getUsers={getSharedUsers} />
    </div>
  )
}

export default ShareDatasetForm
