import { useHistory } from 'react-router-dom'

const Breadcrumb = ({ reportPages, clusterBreadcrumb, page, report }) => {
  const DashboardBreadcrumb = ({ reportName, pages, isdemo }) => {
    return (
      <>
        <button
          className="report-name"
          onClick={() => pages && pages.length > 0 && pages[0].setActive()}
        >
          {reportName}{' '}
          {isdemo && <span style={{ color: '#739570' }}>(Demo)</span>}
        </button>
        {pages.find((page) => page.isActive) && (
          <button className="report-page">
            {'/ ' + pages.find((page) => page.isActive)?.displayName}
          </button>
        )}
      </>
    )
  }

  const ClustersBreadcrumb = ({ clusterBreadcrumb }) => {
    const history = useHistory()

    return (
      <>
        <button
          className="report-name"
          onClick={() => history.push(clusterBreadcrumb.url)}
        >
          {clusterBreadcrumb.category}
        </button>
        {clusterBreadcrumb.name && (
          <button className="report-page">
            {'/ ' + clusterBreadcrumb.name}
          </button>
        )}
      </>
    )
  }

  if (page === 'dashboard') {
    return (
      report &&
      reportPages && (
        <DashboardBreadcrumb
          reportName={report.name}
          pages={reportPages}
          isdemo={report.demo}
        />
      )
    )
  } else if (page === 'clusters') {
    return (
      clusterBreadcrumb.category !== null && (
        <ClustersBreadcrumb clusterBreadcrumb={clusterBreadcrumb} />
      )
    )
  } else {
    return <></>
  }
}

export default Breadcrumb
