import { useReducer, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import { IBillingDetails } from 'types'
import api from '../api'

type BillingDetailsAction =
  | { type: 'detailsLoaded'; payload: IBillingDetails }
  | { type: 'companyNameChanged'; payload: string }
  | { type: 'countryCodeChanged'; payload: string }
  | { type: 'addressLine1Changed'; payload: string }
  | { type: 'addressLine2Changed'; payload: string }
  | { type: 'stateChanged'; payload: string }
  | { type: 'postalCodeChanged'; payload: string }
  | { type: 'taxIdChanged'; payload: string }
  | { type: 'cityChanged'; payload: string }
  | { type: 'taxOfficeChanged'; payload: string }

const useBillingDetails = (organizationId: number) => {
  const token = useSelector(selectToken)

  const [isSaved, setIsSaved] = useState(false)

  const detailsReducer = (
    details: IBillingDetails,
    action: BillingDetailsAction
  ): IBillingDetails => {
    setIsSaved(false)
    switch (action.type) {
      case 'detailsLoaded':
        if (!action.payload.countryCode) {
          action.payload.countryCode = 'us'
        }
        return action.payload
      case 'companyNameChanged':
        return { ...details, companyName: action.payload, taxOffice: '' }
      case 'countryCodeChanged':
        return { ...details, countryCode: action.payload }
      case 'addressLine1Changed':
        return { ...details, addressLine1: action.payload }
      case 'addressLine2Changed':
        return { ...details, addressLine2: action.payload }
      case 'stateChanged':
        return { ...details, state: action.payload }
      case 'postalCodeChanged':
        return { ...details, postalCode: action.payload }
      case 'taxIdChanged':
        return { ...details, taxId: action.payload }
      case 'cityChanged':
        return { ...details, city: action.payload }
      case 'taxOfficeChanged':
        return { ...details, taxOffice: action.payload }
    }
  }

  const [billingDetails, dispatch] = useReducer(detailsReducer, {
    companyName: '',
    countryCode: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    postalCode: '',
    taxId: '',
    city: '',
    taxOffice: '',
  })
  const [loadingBillingDetails, setLoadingBillingDetails] = useState(true)
  const [billingDetailsError, setBillingDetailsError] = useState('')

  const [savingBillingDetails, setSavingBillingDetails] = useState(false)
  const [savingBillingDetailsError, setSavingBillingDetailsError] = useState('')

  const saveBillingDetails = async () => {
    setIsSaved(false)
    setSavingBillingDetails(true)
    try {
      await api.saveBillingDetails({
        organizationId,
        token,
        billingDetails,
      })
      setIsSaved(true)
    } catch (err) {
      if (err instanceof Error) {
        setSavingBillingDetailsError(err.message)
      }
    }
    setSavingBillingDetails(false)
  }

  const getBillingDetails = async () => {
    setLoadingBillingDetails(true)
    try {
      const details = await api.getBillingDetails({ token, organizationId })
      dispatch({ type: 'detailsLoaded', payload: details })
      setLoadingBillingDetails(false)
      return details
    } catch (e) {
      if (e instanceof Error) {
        setBillingDetailsError(e.message)
      }
    }
    setLoadingBillingDetails(false)
  }

  return {
    billingDetails,
    dispatch,
    isSaved,
    loadingBillingDetails,
    getBillingDetails,
    billingDetailsError,
    saveBillingDetails,
    savingBillingDetails,
    savingBillingDetailsError,
  }
}

export default useBillingDetails
