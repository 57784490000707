import { useEffect, useRef, useState } from 'react'

interface IObserverOptions {
  root: HTMLElement | null
  rootMargin: string
  threshold: number
}

const useElementOnScreen = (options: IObserverOptions) => {
  const [isVisible, setIsVisible] = useState(false)
  const elementRef = useRef<HTMLElement>(null)

  const callbackFn = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  // the reason for this is to reset the isVisible state. by doing that everytime the components in which the ref is in rerenders
  // fires a new observation by the observer reseting the isVisible state
  // the result of this is to keep firing of a useEffect and keep on doing an action as long as the ref is in frame
  // TODO: this shouldn't be the default and should be specified by an option
  useEffect(() => {
    if (isVisible) {
      const resetIsVisible = setTimeout(() => {
        setIsVisible(false)
      }, 1000)
      return () => {
        clearTimeout(resetIsVisible)
      }
    }
  }, [isVisible])

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFn, options)
    if (elementRef.current) observer.observe(elementRef.current)

    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current)
    }
  }, [options, elementRef])

  return {
    isVisible,
    elementRef,
  }
}

export default useElementOnScreen
