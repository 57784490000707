import { useReportsCtx } from 'containers/reports/ReportsContext'
import 'css/Toolbar.scss'
import { models, Page, Report } from 'powerbi-client'
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  memo,
  useCallback,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import Options from './OptionsMenu/Options'
import useGetFilterOptions from 'containers/filterOptions/useGetFiltersOptions'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import { FetchStatus } from 'containers/dataLoader'
import ScreenLoading from './ScreenLoading'
import _ from 'lodash'
import { IEmbedReport } from 'types'

type Submenu = {
  title: string
  content: React.JSX.Element | null
}

interface IReportBookmarkExt extends models.IReportBookmark {
  hasOptions: boolean
  canRename: boolean
  canDelete: boolean
}

enum AdvancedOperatorEnum {
  none = 'None',
  isBefore = 'LessThan',
  isAfter = 'GreaterThan',
  is = 'Is',
  isNot = 'IsNot',
  contains = 'Contains',
}

const PagesSubmenu = () => {
  const { reportPages } = useReportsCtx()

  return (
    <ul className="submenu-pages">
      {reportPages !== null && reportPages !== undefined ? (
        reportPages.map((page: Page) => {
          return (
            <li
              className={page.isActive ? 'active' : ''}
              key={page.name}
              onClick={() => page.setActive()}
            >
              <div>
                <span className="material-icons-outlined non-selectable">
                  insert_drive_file
                </span>
                {page.displayName}
              </div>
              {page.isActive && (
                <span className="material-icons-outlined non-selectable">
                  check
                </span>
              )}
            </li>
          )
        })
      ) : (
        <ScreenLoading />
      )}
    </ul>
  )
}

interface IBookmarksSubmenuProps {
  embeddedReport: Report | null
}

const BookmarksSubmenu: React.FC<IBookmarksSubmenuProps> = ({
  embeddedReport,
}) => {
  const [selectedBookmark, setSelectedBookmark] = useState('')
  const [capturedBookmarks, setCapturedBookmarks] = useState<
    IReportBookmarkExt[] | []
  >([])
  const [bookmarks, setBookmarks] = useState<models.IReportBookmark[] | []>([])
  const [once, setOnce] = useState(false)
  const [isReportBookmarksOpen, setIsReportBookmarksOpen] = useState(true)
  const [isCapturedBookmarksOpen, setIsCapturedBookmarksOpen] = useState(false)

  const fetchBookmarks = useCallback(async () => {
    if (embeddedReport === null) return
    // @ts-ignore
    embeddedReport.bookmarksManager.config = embeddedReport.config
    const bookmarks = await embeddedReport.bookmarksManager.getBookmarks()
    setBookmarks(bookmarks)
    setOnce(true)
  }, [embeddedReport])

  useEffect(() => {
    if (embeddedReport === null) return
    embeddedReport.on('rendered', () => {
      fetchBookmarks()
    })
  }, [embeddedReport, fetchBookmarks])

  useEffect(() => {
    if (once) return
    fetchBookmarks()
  }, [fetchBookmarks, once])

  const captureBookmark = async () => {
    const capturedBookmark = await embeddedReport!.bookmarksManager.capture()
    setCapturedBookmarks((prev) => [
      ...prev,
      {
        ...capturedBookmark,
        hasOptions: true,
        canRename: true,
        canDelete: true,
      },
    ])
  }

  const handleApplyBookmark = async (
    e: React.MouseEvent,
    bookmark: models.IReportBookmark
  ) => {
    e.stopPropagation()
    await embeddedReport!.bookmarksManager.applyState(bookmark.state!)
    setSelectedBookmark(bookmark.name)
  }

  const BookmarkDropdown = ({ bookmark }) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    return (
      <>
        <li
          key={bookmark.name}
          className={`${isCollapsed && 'collapsed'}`}
          onClick={() => setIsCollapsed((prev) => !prev)}
        >
          <div>
            <span className="material-icons-outlined non-selectable">
              bookmarks
            </span>
            {bookmark.displayName}
          </div>
          <span className="material-icons-outlined non-selectable">
            {isCollapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
          </span>
        </li>
        <ul className="bookmarks-submenu">
          {generateBookmarks(bookmark.children)}
        </ul>
      </>
    )
  }

  const BookmarkCategory = ({
    categoryName,
    bookmarks,
    open = false,
    onClose,
  }) => {
    return (
      <>
        <div className="bookmark-category" onClick={() => onClose(!open)}>
          <p>{categoryName}</p>
          <span className="material-icons-outlined non-selectable">
            {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </span>
        </div>
        {open && (
          <ul className="submenu-bookmarks">
            {bookmarks?.length > 0 ? (
              generateBookmarks(bookmarks)
            ) : (
              <p style={{ paddingLeft: '10px', color: '#c1c1c1' }}>
                No bookmarks
              </p>
            )}
          </ul>
        )}
      </>
    )
  }

  const generateBookmarks = (
    bookmarks: models.IReportBookmark[] | IReportBookmarkExt[]
  ) => {
    const deleteBookmark = (bookmark: models.IReportBookmark) => {
      setCapturedBookmarks((prev) =>
        prev.filter((bm) => bm.name !== bookmark.name)
      )
    }

    const renameBookmark = (bookmark: models.IReportBookmark) => {
      setCapturedBookmarks((prev) =>
        prev.map((bm) => {
          if (bm.name === bookmark.name) {
            return {
              ...bm,
              displayName: 'renamed',
            }
          }
          return bm
        })
      )
    }
    return bookmarks.map((bookmark) => {
      if (Object.hasOwnProperty.call(bookmark, 'children')) {
        return <BookmarkDropdown bookmark={bookmark} />
      } else {
        return (
          <li
            key={bookmark.name}
            onClick={(e) => handleApplyBookmark(e, bookmark)}
          >
            <div>
              <span className="material-icons-outlined non-selectable">
                {selectedBookmark === bookmark.name
                  ? 'bookmark'
                  : 'bookmark_outline'}
              </span>
              {bookmark.displayName}
            </div>
            {bookmark.hasOptions && (
              <Options
                options={[
                  bookmark.canRename && {
                    uuid: uuidv4(),
                    icon: 'drive_file_rename_outline',
                    title: 'Rename',
                    onClick: () => renameBookmark(bookmark),
                  },
                  bookmark.canDelete && {
                    uuid: uuidv4(),
                    icon: 'delete',
                    title: 'Delete',
                    onClick: () => deleteBookmark(bookmark),
                  },
                ]}
              />
            )}
          </li>
        )
      }
    })
  }

  return (
    <>
      <div className="capture-btn">
        <button className="pb-btn" onClick={captureBookmark}>
          Capture
          <span
            style={{ scale: '.8' }}
            className="material-icons-outlined non-selectable"
          >
            camera_alt
          </span>
        </button>
      </div>
      <BookmarkCategory
        open={isReportBookmarksOpen}
        onClose={(open: boolean) => setIsReportBookmarksOpen(open)}
        categoryName="Report bookmarks"
        bookmarks={bookmarks}
      />
      <BookmarkCategory
        open={isCapturedBookmarksOpen}
        onClose={(open: boolean) => setIsCapturedBookmarksOpen(open)}
        categoryName="Captured bookmarks"
        bookmarks={capturedBookmarks}
      />
    </>
  )
}

interface IFiltersSubmenuProps {
  reportPage: Page | null
  report: IEmbedReport
}
const FiltersSubmenu: React.FC<IFiltersSubmenuProps> = ({
  reportPage,
  report,
}) => {
  const [filters, setFilters] = useState<models.IFilter[]>([])
  const getFilters = useCallback(async () => {
    const fltrs = await reportPage!.getFilters()
    setFilters(
      fltrs.filter((filter) => !filter?.displaySettings!?.isHiddenInViewMode)
    )
  }, [reportPage])

  const updateFilters = async (filter: models.IFilter) => {
    await reportPage?.updateFilters(models.FiltersOperations.Replace, [filter])
    getFilters()
  }

  useEffect(() => {
    if (reportPage) {
      getFilters()
    }
  }, [reportPage, getFilters])

  return (
    <div className="filters-submenu">
      {filters && filters.length > 0 ? (
        filters.map((filter, idx) => (
          <Filter
            key={idx}
            report={report}
            filter={filter}
            updateFilters={updateFilters}
          />
        ))
      ) : (
        <p style={{ textAlign: 'center', color: 'rgb(62, 62, 62)' }}>
          There aren't any filters to display.
        </p>
      )}
    </div>
  )
}

const isBasicFilter = (filter: models.IFilter): filter is models.IBasicFilter =>
  models.getFilterType(filter) === models.FilterType.Basic
const isAdvancedFilter = (
  filter: models.IFilter
): filter is models.IAdvancedFilter =>
  models.getFilterType(filter) === models.FilterType.Advanced
const isRelativeDate = (
  filter: models.IFilter
): filter is models.IRelativeDateFilter =>
  models.getFilterType(filter) === models.FilterType.RelativeDate

const matchFilterType = (filter: models.IFilter): string => {
  switch (models.getFilterType(filter)) {
    case models.FilterType.Basic:
      return 'Basic'
    case models.FilterType.Advanced:
      return 'Advanced'
    case models.FilterType.RelativeDate:
      return 'Relative Date'
    default:
      return ''
  }
}

const matchTimeUnitType = (
  period: string
): models.RelativeDateFilterTimeUnit => {
  period = period.replace(/s$/gi, '')
  switch (period) {
    case 'day':
      return models.RelativeDateFilterTimeUnit.Days
    case 'week':
      return models.RelativeDateFilterTimeUnit.Weeks
    case 'month':
      return models.RelativeDateFilterTimeUnit.Months
    case 'year':
      return models.RelativeDateFilterTimeUnit.Years
    default:
      return 0
  }
}

const reverseMatchTimeUnitType = (
  period: models.RelativeDateFilterTimeUnit
): string => {
  switch (period) {
    case models.RelativeDateFilterTimeUnit.Days:
      return 'days'
    case models.RelativeDateFilterTimeUnit.Weeks:
      return 'weeks'
    case models.RelativeDateFilterTimeUnit.Months:
      return 'months'
    case models.RelativeDateFilterTimeUnit.Years:
      return 'years'
    default:
      return 'days'
  }
}

const matchOperator = (operator: string): models.RelativeDateOperators => {
  switch (operator) {
    case 'is in the last':
      return models.RelativeDateOperators.InLast
    case 'is in the next':
      return models.RelativeDateOperators.InNext
    case 'is in this':
      return models.RelativeDateOperators.InThis
    default:
      return models.RelativeDateOperators.InLast
  }
}

const matchAdvancedOperator = (operator: string): AdvancedOperatorEnum => {
  switch (operator) {
    case 'is':
      return AdvancedOperatorEnum.is
    case 'is not':
      return AdvancedOperatorEnum.isNot
    case 'is after':
      return AdvancedOperatorEnum.isAfter
    case 'is before':
      return AdvancedOperatorEnum.isBefore
    case 'contains':
      return AdvancedOperatorEnum.contains
    default:
      return AdvancedOperatorEnum.none
  }
}

const reverseMatchAdvancedOperator = (
  operator: AdvancedOperatorEnum
): string => {
  switch (operator) {
    case AdvancedOperatorEnum.is:
      return 'is'
    case AdvancedOperatorEnum.isNot:
      return 'is not'
    case AdvancedOperatorEnum.isAfter:
      return 'is after'
    case AdvancedOperatorEnum.isBefore:
      return 'is before'
    case AdvancedOperatorEnum.contains:
      return 'contains'
    default:
      return 'is'
  }
}

const pad2Digits = (num: number) => num.toString().padStart(2, '0')

const formatDate = (date: Date, join = '/'): string => {
  return [
    date.getFullYear(),
    pad2Digits(date.getMonth() + 1),
    pad2Digits(date.getDate()),
  ].join(join)
}

const calculateDate = (
  operator: models.RelativeDateOperators,
  period: models.RelativeDateFilterTimeUnit,
  input: number
) => {
  const today = new Date()
  const date = new Date()
  switch (operator) {
    case models.RelativeDateOperators.InLast:
      switch (period) {
        case models.RelativeDateFilterTimeUnit.Days:
          date.setDate(today.getDate() - input)
          break
        case models.RelativeDateFilterTimeUnit.Weeks:
          date.setDate(today.getDate() - input * 7)
          break
        case models.RelativeDateFilterTimeUnit.Months:
          date.setMonth(today.getMonth() - input)
          break
        case models.RelativeDateFilterTimeUnit.Years:
          date.setFullYear(today.getFullYear() - input)
          break
      }
      break
    case models.RelativeDateOperators.InThis:
      switch (period) {
        case models.RelativeDateFilterTimeUnit.Days:
          date.setDate(today.getDate())
          break
        case models.RelativeDateFilterTimeUnit.Weeks:
          date.setDate(today.getDate() - today.getDay())
          break
        case models.RelativeDateFilterTimeUnit.Months:
          date.setMonth(today.getMonth())
          date.setDate(1)
          break
        case models.RelativeDateFilterTimeUnit.Years:
          date.setDate(1)
          date.setMonth(0)
          date.setFullYear(today.getFullYear())
          break
      }
      break
    case models.RelativeDateOperators.InNext:
      switch (period) {
        case models.RelativeDateFilterTimeUnit.Days:
          date.setDate(today.getDate() + input)
          break
        case models.RelativeDateFilterTimeUnit.Weeks:
          date.setDate(today.getDate() + input * 7)
          break
        case models.RelativeDateFilterTimeUnit.Months:
          date.setMonth(today.getMonth() + input)
          break
        case models.RelativeDateFilterTimeUnit.Years:
          date.setFullYear(today.getFullYear() + input)
          break
      }
      break
  }
  return date
}

const getRelativeDatePreviewValue = (
  date: string,
  operator: models.RelativeDateOperators
) => {
  switch (operator) {
    case models.RelativeDateOperators.InLast:
      return `${date} - ${formatDate(new Date())}`
    case models.RelativeDateOperators.InThis:
      return `${date}`
    case models.RelativeDateOperators.InNext:
      return `${formatDate(new Date())} - ${date}`
    default:
      return `${date}`
  }
}

interface IFilterProps {
  report: IEmbedReport
  filter: models.IFilter
  updateFilters: (filter: models.IFilter) => void
}

const areEqual = (prevProps, nextProps) =>
  _.isEqual(prevProps.filter, nextProps.filter)

const Filter: React.FC<IFilterProps> = memo(
  ({ report, filter, updateFilters }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [hasFilterSet, setHasFilterSet] = useState(false)
    const [previewValue, setPreviewValue] = useState<string | null>(null)
    const [filterName, setFilterName] = useState<string | null>(null)

    const handleClearFilter = (e) => {
      e.stopPropagation()
      setHasFilterSet(false)
      setPreviewValue('is (all)')
      const newFilter = { ...filter }
      if (isBasicFilter(newFilter)) {
        newFilter.values = []
        newFilter.requireSingleSelection = false
        newFilter.operator = 'All'
      } else if (isAdvancedFilter(newFilter)) {
        newFilter.conditions = []
      } else if (isRelativeDate(newFilter)) {
        newFilter.operator = models.RelativeDateOperators.InLast
        newFilter.timeUnitType = models.RelativeDateFilterTimeUnit.Days
        newFilter.timeUnitsCount = undefined
      }

      updateFilters(newFilter)
    }

    const handleSetPreviewValue = (filter: models.IFilter) => {
      if (isBasicFilter(filter)) {
        if (filter.values!.length > 0) {
          let values: string
          if (filter.values.length > 5) {
            values = `${filter.values!.slice(0, 5).join(' or ')} or ...`
          } else {
            values = filter.values!.join(' or ')
          }
          setPreviewValue(`is ${values}`)
          setHasFilterSet(true)
        } else {
          setPreviewValue('is (all)')
        }
      } else if (isAdvancedFilter(filter)) {
        if (filter.conditions?.length > 0) {
          const parseValueForDate = (value: any) =>
            isNaN(value) && Date.parse(value)
              ? new Date(value).toLocaleDateString()
              : value
          const conditions = filter
            .conditions!.map((condition) => {
              return `${reverseMatchAdvancedOperator(
                condition.operator as AdvancedOperatorEnum
              )} '${parseValueForDate(condition.value)}'`
            })
            .join(` ${filter.logicalOperator} `)
          setPreviewValue(conditions)
          setHasFilterSet(true)
        } else {
          setPreviewValue('is (all)')
        }
      } else if (isRelativeDate(filter)) {
        if (filter.timeUnitsCount !== undefined) {
          const date = formatDate(
            calculateDate(
              filter.operator,
              filter.timeUnitType,
              filter.timeUnitsCount || 0
            )
          )
          const relativeDatePreviewValue = getRelativeDatePreviewValue(
            date,
            filter.operator!
          )
          setPreviewValue(relativeDatePreviewValue)
          setHasFilterSet(true)
        } else {
          setPreviewValue('is (all)')
        }
      }
    }

    useEffect(() => {
      handleSetPreviewValue(filter)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (models.isColumn(filter.target)) {
        setFilterName(filter.target.column)
      } else if (models.isHierarchyLevel(filter.target)) {
        setFilterName(filter.target.hierarchy)
      }
      handleSetPreviewValue(filter)
    }, [filter])

    if (filter === null || filter === undefined) return <></>

    return (
      <div className={`filter ${hasFilterSet ? 'has-filter' : ''}`}>
        <div>
          <div
            className="filter-header"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <p className="filter-title">{filterName}</p>
            <div>
              {hasFilterSet && (
                <span
                  onClick={handleClearFilter}
                  className="material-icons-outlined"
                >
                  delete_forever
                </span>
              )}
              <span className="material-icons-outlined">
                {isExpanded ? 'expand_less' : 'chevron_right'}
              </span>
            </div>
          </div>
          {previewValue && <p>{previewValue}</p>}
        </div>
        {isExpanded &&
          (models.isHierarchyLevel(filter.target) ||
          (models.isColumn(filter.target) &&
            filter.target.table === '__Calendar') ? (
            <DateFilter
              setHasFilterSet={setHasFilterSet}
              filter={filter}
              updateFilters={(filter) => updateFilters(filter)}
            />
          ) : (
            <CategoricalFilter
              report={report}
              filter={filter}
              updateFilters={(filter) => updateFilters(filter)}
            />
          ))}
      </div>
    )
  },
  areEqual
)

interface IDateFilterProps {
  filter: models.IFilter
  updateFilters: (filter: models.IFilter) => void
  setHasFilterSet: (value: boolean) => void
}

const DateFilter: React.FC<IDateFilterProps> = ({
  filter,
  updateFilters,
  setHasFilterSet,
}) => {
  const FilterTypes = ['Relative Date', 'Advanced']
  const [localFilterType, setLocalFilterType] = useState(filter.filterType)

  const handleApplyFilter = () => {
    setHasFilterSet(true)
  }

  const RelativeDateFilter = ({ filter }) => {
    const OperatorOptions = useMemo(() => ({
      'is in the last': {
        period: ['days', 'weeks', 'months', 'years'],
        hasInput: true,
      },
      'is in this': {
        period: ['day', 'week', 'month', 'year'],
        hasInput: false,
      },
      'is in the next': {
        period: ['days', 'weeks', 'months', 'years'],
        hasInput: true,
      },
    }), [])
    const [operator, setOperator] = useState(
      Object.keys(OperatorOptions)[filter?.operator || 0]
    )
    const [timeUnitType, setTimeUnitType] = useState<string | null>(null)
    const [timeUnitsCount, setTimeUnitsCount] = useState<string>('')
    const [canApplyFilter, setCanApplyFilter] = useState(false)

    useEffect(() => {
      const newFilter = composeRelativeFilter(
        operator,
        timeUnitType || '',
        parseInt(timeUnitsCount)
      )
      if (_.isEqual(newFilter, filter)) {
        setCanApplyFilter(false)
      } else {
        setCanApplyFilter(true)
      }
    }, [operator, timeUnitType, timeUnitsCount, filter])

    useEffect(() => {
      setOperator(Object.keys(OperatorOptions)[filter?.operator || 0])
      setTimeUnitType(reverseMatchTimeUnitType(filter?.timeUnitType || 0))
      setTimeUnitsCount(filter?.timeUnitsCount?.toString() || '')
    }, [filter, OperatorOptions])

    const composeRelativeFilter = (
      operator: models.RelativeDateOperators,
      period: string,
      input: number
    ) => {
      const relativePeriodUnit = matchTimeUnitType(period)

      const newFilter = {
        $schema: 'http://powerbi.com/product/schema#relativeDate',
        target: {
          hierarchy: 'Calendar Hierarchy',
          hierarchyLevel: 'Date',
          table: '__Calendar',
        },
        filterType: models.FilterType.RelativeDate,
        operator: operator,
        includeToday: false,
        timeUnitType: relativePeriodUnit,
        timeUnitsCount:
          operator === models.RelativeDateOperators.InThis ? 1 : input,
      }

      return newFilter
    }

    const updateRelativeFilter = (
      operator: models.RelativeDateOperators,
      period: string,
      input: number
    ) => {
      ;(async () => {
        const newFilter = composeRelativeFilter(operator, period, input)
        updateFilters(newFilter)
      })()

      handleApplyFilter()
    }

    return (
      <>
        <PBDropdown
          content={Object.keys(OperatorOptions)}
          select={(option) => setOperator(option)}
          selected={operator}
        />
        {OperatorOptions[operator].hasInput && (
          <input
            value={timeUnitsCount?.toString()}
            onChange={(e) => setTimeUnitsCount(e.target.value)}
            type="text"
            placeholder="1-10000"
            className="pb-input-field"
          />
        )}
        <PBDropdown
          content={OperatorOptions[operator].period}
          select={(option) => setTimeUnitType(option)}
          selected={timeUnitType}
        />
        <button
          disabled={operator !== 'is in this' && timeUnitsCount === null}
          className={`pb-apply-button ${!canApplyFilter ? 'disabled' : ''}`}
          style={{ alignSelf: 'flex-end', margin: '6px 0' }}
          onClick={() =>
            updateRelativeFilter(
              matchOperator(operator),
              timeUnitType!,
              parseInt(timeUnitsCount?.toString()!)
            )
          }
        >
          Apply filter
        </button>
      </>
    )
  }

  const AdvancedDateFilter = ({ filter }) => {
    const OperatorOptions = ['is', 'is not', 'is before', 'is after']
    const [operator, setOperator] = useState<string | null>(null)
    const [value, setValue] = useState<{ date: string; time: string }>({
      date: '',
      time: '',
    })
    const [canApplyFilter, setCanApplyFilter] = useState<Boolean | null>(null)

    const composeAdvancedFilter = useCallback((operator: string) => {
      const date = formatDate(new Date(value.date), '-')
      const conditionOperator = matchAdvancedOperator(operator)

      return {
        $schema: 'http://powerbi.com/product/schema#advanced',
        target: {
          hierarchy: 'Calendar Hierarchy',
          hierarchyLevel: 'Date',
          table: '__Calendar',
        },
        conditions: [
          {
            operator: conditionOperator,
            value:
              date +
              'T' +
              (value.time.length <= 5 ? value.time + ':00' : value.time),
          },
        ],
        filterType: models.FilterType.Advanced,
        logicalOperator: 'And',
      }
    }, [value.date, value.time])

    const updateAdvancedFilter = (operator: string) => {
      ;(async () => {
        const newFilter = composeAdvancedFilter(operator)
        updateFilters(newFilter)
      })()

      handleApplyFilter()
    }

    useEffect(() => {
      const newFilter = composeAdvancedFilter(operator)
      if (_.isEqual(newFilter, filter)) {
        setCanApplyFilter(false)
      } else {
        setCanApplyFilter(true)
      }
    }, [value, operator, composeAdvancedFilter, filter])

    useEffect(() => {
      setOperator(
        filter?.conditions?.length > 0
          ? reverseMatchAdvancedOperator(
              filter.conditions[0].operator as AdvancedOperatorEnum
            )
          : 'is'
      )
      const dateValue =
        filter?.conditions?.length > 0
          ? new Date(Date.parse(filter.conditions[0]?.value as string))
          : ''
      setValue({
        date: dateValue ? formatDate(dateValue, '-') : '',
        time: dateValue ? dateValue.toLocaleTimeString() : '12:00',
      })
    }, [filter])

    return (
      <>
        <PBDropdown
          content={OperatorOptions}
          select={(option) => setOperator(option)}
          selected={operator}
        />
        <input
          type="date"
          className="pb-input-field"
          value={value.date}
          onChange={(e) =>
            setValue((prev) => ({ ...prev, date: e.target.value as string }))
          }
        />
        <input
          type="time"
          className="pb-input-field"
          value={value.time}
          onChange={(e) =>
            setValue((prev) => ({ ...prev, time: e.target.value as string }))
          }
        />
        <button
          disabled={value === null || operator === AdvancedOperatorEnum.none}
          className={`pb-apply-button ${!canApplyFilter ? 'disabled' : ''}`}
          style={{ alignSelf: 'flex-end', margin: '6px 0' }}
          onClick={() => updateAdvancedFilter(operator!)}
        >
          Apply filter
        </button>
      </>
    )
  }

  const FilterViews = useMemo(
    () => ({
      'Relative Date': <RelativeDateFilter filter={filter} />,
      Advanced: <AdvancedDateFilter filter={filter} />,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localFilterType, filter]
  )

  return (
    <div className="date-filter">
      <div className="date-filter-expanded">
        <div>
          <p>Filter type</p>
          <PBDropdown
            content={FilterTypes}
            select={(option) =>
              setLocalFilterType(
                option === 'Relative Date'
                  ? models.FilterType.RelativeDate
                  : models.FilterType.Advanced
              )
            }
            selected={
              localFilterType === models.FilterType.RelativeDate
                ? 'Relative Date'
                : 'Advanced'
            }
          />
        </div>
        <div className="date-filter-view">
          <p style={{ margin: '12px 0 6px 0' }}>Show items when the value</p>
          <div>
            {
              FilterViews[
                localFilterType === models.FilterType.RelativeDate
                  ? 'Relative Date'
                  : 'Advanced'
              ]
            }
          </div>
        </div>
      </div>
    </div>
  )
}

interface ICategoricalFilterProps {
  report: IEmbedReport
  filter: models.IFilter
  updateFilters: (filter: models.IBasicFilter | models.IAdvancedFilter) => void
}

const CategoricalFilter: React.FC<ICategoricalFilterProps> = ({
  report,
  filter,
  updateFilters,
}) => {
  const FILTER_TYPES = ['Advanced', 'Basic']
  const [filterType, setFilterType] = useState(
    matchFilterType(filter) || FILTER_TYPES[0]
  )
  const { currentOrganization } = useContext(OrganizationsContext)

  const BasicFilter = () => {
    const [tempCategoryOptions, setTempCategoryOptions] = useState<string[]>([])
    const [categoryOptions, setCategoryOptions] = useState<string[]>([])
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])
    const [cursor, setCursor] = useState(0)
    const [, getOptions, loadingOptions] =
      useGetFilterOptions(currentOrganization)

    const handleSetSelectedOptions = (options: string[] | null) => {
      if (options === null) {
        setSelectedOptions([])
      } else {
        setSelectedOptions((prev) => {
          const newSelectedOptions = _.xor(prev, options)
          handleApplyBasicFilter(newSelectedOptions)
          return newSelectedOptions
        })
      }
    }

    const handleApplyBasicFilter = (options: string[]) => {
      if (!models.isColumn(filter.target)) return
      const newFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: filter.target.table,
          column: filter.target.column,
        },
        operator: options.length > 0 ? 'In' : 'All',
        values: options,
      }
      updateFilters(newFilter as models.IBasicFilter)
    }

    const fetchData = async () => {
      if (models.isColumn(filter.target)) {
        const column = filter.target.column
        const table = filter.target.table
        const res = await getOptions(
          report.dataset_id,
          table,
          column,
          cursor?.toString()
        )
        if (res !== null && res?.data !== undefined) {
          setCursor(res.nextCursor)
          setCategoryOptions(res.data)
        }
      }
    }

    const searchOptions = (e) => {
      const search = e.target.value
      const filteredOptions = categoryOptions.filter((option) =>
        option.toLowerCase().includes(search.toLowerCase())
      )
      setTempCategoryOptions(filteredOptions)

      if (e.target.value === '') {
        setTempCategoryOptions([])
      }
    }

    useEffect(() => {
      fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (isBasicFilter(filter) && filter.values?.length > 0) {
        setSelectedOptions(filter.values as string[])
      }
    }, [])

    return (
      <>
        <div className="pb-search-field">
          <span className="material-icons-outlined">search</span>
          <input type="text" placeholder="Search" onChange={searchOptions} />
        </div>
        <PBMultiSelect
          content={
            tempCategoryOptions.length > 0
              ? tempCategoryOptions
              : categoryOptions
          }
          selected={selectedOptions}
          select={handleSetSelectedOptions}
          loadingOptions={loadingOptions === FetchStatus.Pending}
        />
      </>
    )
  }

  const AdvancedFilter = () => {
    const [containsValue, setContainsValue] = useState('')

    useEffect(() => {
      if (isAdvancedFilter(filter) && filter.conditions?.length > 0) {
        setContainsValue(filter.conditions[0].value as string)
      }
    }, [])

    const updateAdvancedFilter = () => {
      const advancedFilter: models.IAdvancedFilter = {
        $schema: 'http://powerbi.com/product/schema#advanced',
        target: filter.target,
        conditions: [
          {
            operator: AdvancedOperatorEnum.contains,
            value: containsValue,
          },
        ],
        filterType: models.FilterType.Advanced,
        logicalOperator: 'And',
      }
      updateFilters(advancedFilter)
    }
    return (
      <>
        <p style={{ margin: '12px 0 6px 0' }}>Show items when the value</p>
        <PBDropdown
          content={['contains']}
          select={() => {}}
          selected={'contains'}
        />
        <input
          value={containsValue}
          onChange={(e) => setContainsValue(e.target.value)}
          type="text"
          placeholder="(Blank)"
          className="pb-input-field"
          style={{ marginTop: '2px' }}
        />
        <button
          disabled={!containsValue}
          className={`pb-apply-button ${!containsValue ? 'disabled' : ''}`}
          style={{ alignSelf: 'flex-end', margin: '6px 0' }}
          onClick={updateAdvancedFilter}
        >
          Apply filter
        </button>
      </>
    )
  }

  const FilterViews = {
    Basic: <BasicFilter />,
    Advanced: <AdvancedFilter />,
  }

  return (
    <div className="categorical-filter">
      <div className="categorical-filter-expanded">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>Filter type</p>
          <PBDropdown
            content={FILTER_TYPES}
            select={(option) => setFilterType(option)}
            selected={filterType}
          />
          {FilterViews[filterType]}
        </div>
      </div>
    </div>
  )
}

interface IToolbarProps {
  embeddedReport: Report
}

const Toolbar: React.FC<IToolbarProps> = ({ embeddedReport }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const [openSubmenu, setOpenSubmenu] = useState<Submenu | null>(null)
  const { report, reportPages } = useReportsCtx()

  const SubmenuOptions = useMemo(
    () => ({
      Pages: {
        title: 'Pages',
        content: <PagesSubmenu />,
      },
      Bookmarks: {
        title: 'Bookmarks',
        content: <BookmarksSubmenu embeddedReport={embeddedReport} />,
      },
      Filters: {
        title: 'Filters',
        content: (
          <FiltersSubmenu
            report={report!}
            reportPage={
              (reportPages && reportPages.find((page) => page.isActive)) || null
            }
          />
        ),
      },
    }),
    [embeddedReport, reportPages, report]
  )

  const handleReset = () => {
    embeddedReport.reload()
  }

  const handleOpenSubmenu = (menuName) => {
    setIsSubmenuOpen(true)
    const submenu = SubmenuOptions[menuName]
    setOpenSubmenu(submenu)
  }

  return (
    <div className="toolbar">
      {!isSubmenuOpen ? (
        <>
          <div className="toolbar-option" onClick={handleReset}>
            <span className="material-icons-outlined non-selectable">
              restart_alt
            </span>
            <div>Reset</div>
          </div>
          <div
            className="toolbar-option"
            onClick={() => handleOpenSubmenu('Filters')}
          >
            <span className="material-icons-outlined non-selectable">
              filter_list
            </span>
            <div>Filters</div>
          </div>
          <div
            className="toolbar-option"
            onClick={() => handleOpenSubmenu('Pages')}
          >
            <span
              className="material-icons-outlined non-selectable"
              style={{ scale: '.8' }}
            >
              filter_none
            </span>
            <div>Pages</div>
          </div>
          <div
            className="toolbar-option"
            onClick={() => handleOpenSubmenu('Bookmarks')}
          >
            <span className="material-icons-outlined non-selectable">
              bookmark_outline
            </span>
            <div>Bookmarks</div>
          </div>
        </>
      ) : (
        <div className="submenu">
          {openSubmenu !== null && (
            <>
              <div
                onClick={() => {
                  setIsSubmenuOpen(false)
                  setOpenSubmenu(null)
                }}
                className="submenu-back"
              >
                <span className="material-icons-outlined non-selectable">
                  keyboard_arrow_down
                </span>
                {openSubmenu.title}
              </div>
              {openSubmenu.content}
            </>
          )}
        </div>
      )}
    </div>
  )
}

interface IPBDropdownProps {
  content: string[]
  select: (option) => void
  selected: string | number | null
}

const PBDropdown: React.FC<IPBDropdownProps> = ({
  content,
  select,
  selected,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClickItem = (item) => {
    select(item)
    setIsExpanded(false)
  }

  return (
    <div className="pb-dropdown">
      <div
        className="pb-dropdown-title"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <p>{selected ? selected : 'Select'}</p>
        <span className="material-icons-outlined">
          {isExpanded ? 'expand_less' : 'chevron_right'}
        </span>
      </div>
      {isExpanded && (
        <ul className="pb-dropdown-content">
          {content &&
            content.map((item) => (
              <li
                key={item}
                className={item === selected ? 'selected' : ''}
                onClick={() => handleClickItem(item)}
              >
                {item}
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}

interface IPBMultiSelectProps {
  content: string[]
  select: (options: string[] | null) => void
  selected: string[]
  loadingOptions: boolean
}

const PBMultiSelect: React.FC<IPBMultiSelectProps> = ({
  content,
  select,
  selected,
  loadingOptions,
}) => {
  const handleClickItem = (item: string) => {
    select([item])
  }

  const handleSelectAll = () => {
    if (selected.length > 0 && selected.length !== content.length) {
      select(null)
    } else {
      select(content)
    }
  }

  return (
    <ul className="pb-multi-select">
      {content && content.length > 0 && (
        <li className="pb-multi-select-option" onClick={handleSelectAll}>
          <PBCheckbox
            checked={content.length === selected.length}
            partial={selected.length > 0 && selected.length !== content.length}
          />
          <span>Select all</span>
        </li>
      )}
      {content && content.length > 0 ? (
        content.map((name, idx) => (
          <li
            key={idx}
            className="pb-multi-select-option"
            onClick={() => handleClickItem(name)}
          >
            <PBCheckbox
              checked={selected.find((item) => item === name) !== undefined}
            />
            <span className="truncate">{name}</span>
          </li>
        ))
      ) : (
        <p>No data to display</p>
      )}
      {loadingOptions && (
        <div>
          <ScreenLoading />
        </div>
      )}
    </ul>
  )
}

interface IPBCheckboxProps {
  checked: boolean
  partial?: boolean
}

const PBCheckbox: React.FC<IPBCheckboxProps> = ({
  checked,
  partial = false,
}) => {
  return (
    <div className="pb-checkbox">
      <input
        type="checkbox"
        defaultChecked={checked && !partial}
        onChange={(e) => (e.target.checked = !e.target.checked)}
      />
      <span
        className={`checkmark ${partial ? 'partial-check' : ''} ${
          checked ? 'check' : ''
        }`}
      ></span>
    </div>
  )
}

export default Toolbar
