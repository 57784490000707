import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'

import { selectOrganizations, setOrganizations } from './organizationsSlice'

import api, { isHTTPError } from '../api'

const useGetOrganizations = () => {
  const dispatch = useDispatch()
  const token = useSelector(selectToken)
  const currentOrganizations = useSelector(selectOrganizations)
  const [organizations, setNewOrganizations] = useState()
  const [loadingOrganizations, setLoadingOrganizations] = useState(true)
  const [organizationsError, setOrganizationsError] = useState('')

  const clearOrganizationsCache = async () => {
    dispatch(setOrganizations(null))
  }

  const getOrganizations = async () => {
    if (currentOrganizations) {
      setNewOrganizations(currentOrganizations)
      setLoadingOrganizations(false)
      return currentOrganizations
    } else {
      return await fetchOrganizations()
    }
  }

  const fetchOrganizations = async () => {
    try {
      const responseOrganizations = await api.getOrganizations({ token })

      dispatch(setOrganizations(responseOrganizations))
      setNewOrganizations(responseOrganizations)
      setLoadingOrganizations(false)
      return responseOrganizations
    } catch (error) {
      if (
        error.hasOwnProperty('response') &&
        isHTTPError(error) &&
        'detail' in error.response.data
      ) {
        setOrganizationsError(
          `Error ${error.response.status}: ${error.response.data.detail}`
        )
      } else {
        setOrganizationsError("Can't connect to the server")
      }
    }
  }

  return {
    getOrganizations,
    fetchOrganizations,
    clearOrganizationsCache,
    organizations,
    loadingOrganizations,
    organizationsError,
  }
}

export default useGetOrganizations
