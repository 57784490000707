import useLogout from 'containers/authentication/useLogout'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import slugify from 'utils/slugify'

type MenuOption = {
  name: string
  isLink: boolean
  submenu?: MenuOption[]
  link?: string
}

const MENU_OPTIONS: MenuOption[] = [
  {
    name: 'Documentation',
    isLink: true,
    link: 'https://docs.mayainsights.com/docs/sources/',
  },
  {
    name: 'Legal',
    isLink: false,
    submenu: [
      {
        name: 'Privacy Policy',
        isLink: true,
        link: 'https://www.mayainsights.com/privacy-policy/',
      },
      {
        name: 'Terms of Service',
        isLink: true,
        link: 'https://www.mayainsights.com/terms-and-conditions/',
      },
    ],
  },
]

const AccountMenu = ({ currentOrganization }) => {
  const logOut = useLogout()
  const history = useHistory()

  const Submenu = ({ option, submenu }) => {
    const [hoveringMenu, setHoveringMenu] = useState(false)

    return (
      <li
        className="sub-menu"
        onClick={(e) => {
          e.stopPropagation()
        }}
        onMouseOver={() => setHoveringMenu(true)}
        onMouseLeave={() => setHoveringMenu(false)}
      >
        {option.name}
        <span className="material-icons-outlined">keyboard_arrow_right</span>
        {hoveringMenu && <div>{submenu}</div>}
      </li>
    )
  }

  const generateMenu = (options: MenuOption[]) => {
    return options.map((option) => {
      if (option.isLink) {
        return (
          <li>
            <a target="_blank" rel="noreferrer" href={option.link!}>
              {option.name}
            </a>
          </li>
        )
      } else {
        const submenu = generateMenu(option.submenu!)

        return <Submenu option={option} submenu={submenu}></Submenu>
      }
    })
  }

  return (
    <ul className="menu-options">
      <li
        onClick={() =>
          history.push(
            `/organization/${slugify(
              currentOrganization.name
            )}/account-settings/`
          )
        }
        className="custom"
      >
        <p>Account</p>
        <span className="material-icons-outlined">manage_accounts</span>
      </li>
      {generateMenu(MENU_OPTIONS)}
      <li onClick={logOut} className="log-out-button custom">
        <p>Logout</p>
        <span className="material-icons-outlined">logout</span>
      </li>
    </ul>
  )
}

export default AccountMenu
