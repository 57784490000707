import api from 'containers/api'
import useDataLoader from 'containers/dataLoader'

import { selectToken } from 'containers/authentication/authenticationSlice'
import { useSelector } from 'react-redux'

import { IOrganization } from 'types'

const useGetFeatures = (org: IOrganization) => {
  const token = useSelector(selectToken)

  const getFeatures = async () => {
    const res = await api.getFeatures(token, org.id)
    return res
  }

  return useDataLoader(getFeatures)
}

export default useGetFeatures
