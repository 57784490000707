import './Switch.scss'

const Switch = (props) => {
  return (
    <div class="switch-container">
      <p class={`bill-choice ${props.checked ? '' : 'active'}`}>
        Monthly Billing
      </p>
      <div>
        <input type="checkbox" hidden id="plan-switch" {...props} />
        <label for="plan-switch">Toggle</label>
      </div>
      <p class={`bill-choice ${props.checked ? 'active' : ''}`}>
        Annual billing
      </p>
    </div>
  )
}

export default Switch
