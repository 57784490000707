import ReportList from 'components/Navigation/ReportList'
import ScreenLoading from 'components/ScreenLoading'
import useListAccountsWithWarnings from 'containers/services/useGetAccountsWithWarnings'

import React, { useEffect, useRef, useState } from 'react'
import { PopupButton } from 'react-calendly'
import TagManager from 'react-gtm-module'
import * as rudderanalytics from 'rudder-sdk-js'

import { useHistory } from 'react-router-dom'
import { useSidebarCtx } from 'containers/SidebarContext'
import { useReportsCtx } from 'containers/reports/ReportsContext'
import ActiveReport from './ActiveReport'
import Divider from 'components/Divider'
import SetupGuide from 'components/SetupGuide'

import mayaLogo from "images/maya-logo.png"

const SideBar = ({
  embedReports,
  embedReportsFetchStatus,
  organizationSlug,
  page,
  currentOrganization,
  user,
  embedReportSlug,
  embedViewMode,
  subscription,
}) => {
  const refIsMounted = useRef(false)
  const history = useHistory()
  const [setupCollapsed, setSetupCollapsed] = useState(true)
  const [isActiveReportExpanded, setIsActiveReportExpanded] = useState(false)
  const [isReportsExpanded, setIsReportsExpanded] = useState(false)
  const [isCustomReportsExpanded, setIsCustomReportsExpanded] = useState(false)
  const [isDemoExpanded, setIsDemoExpanded] = useState(false)
  const [showGuide, setShowGuide] = useState(false)
  const [sourceWarnings, fetchSourceWarnings] = useListAccountsWithWarnings()
  const { isSidebarCollapsed, isEmbedEnabled, collapseSidebar } =
    useSidebarCtx()
  const { embedReport, reportPages, report, setReport, clearReport } =
    useReportsCtx()
    const [activeDemoReport, setActiveDemoReport] = useState('')

  const handleReportClick = (reportName) => {
    setReport(() => ({ name: reportName }))
    setIsActiveReportExpanded(true)
    setIsReportsExpanded(false)
    setIsCustomReportsExpanded(false)
    setSetupCollapsed(true)
    if (window.innerWidth < 767) {
      collapseSidebar(true)
    }
  }

  useEffect(() => {
    clearReport()
    fetchSourceWarnings(currentOrganization.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization.id])

  useEffect(() => {
    const collapsed =
      window.innerWidth < 767
        ? 'true'
        : localStorage.getItem('isSideBarCollapsed')
    if (collapsed === null) {
      localStorage.setItem('isSideBarCollapsed', false)
      collapseSidebar(false)
    } else {
      if (collapsed === 'true') {
        collapseSidebar(true)
      } else {
        collapseSidebar(false)
      }
    }

    refIsMounted.current = true
    return () => {
      refIsMounted.current = false
    }
  }, [collapseSidebar])

  const redirectTo = (url) => {
    history.push(url)
  }

  const handleRedirectToTab = (tabName) => {
    TagManager.dataLayer({ dataLayer: { event: `clicked_sidebar_tab_${tabName}`, is_custom_track: true } })
    // rudderanalytics.track(`clicked_sidebar_tab_${tabName}`)
    if (tabName === 'billingDetails') {
      if (subscription) {
        redirectTo(`/organization/${organizationSlug}/billing/details`)
      } else {
        redirectTo(
          `/organization/${organizationSlug}/billing/details?checkout=1`
        )
      }
    } else {
      redirectTo(`/organization/${organizationSlug}/${tabName}`)
    }
    if (window.innerWidth < 550) {
      collapseSidebar(true)
    }
  }

  if (isSidebarCollapsed === null) {
    return null
  }

  const mayaReports =
    embedReportsFetchStatus !== 'pending'
      ? embedReports.filter((r) => !r.is_custom)
      : []
  const customReports =
    embedReportsFetchStatus !== 'pending'
      ? embedReports.filter((r) => r.is_custom)
      : []

  const handleOpenSection = (section) => {
    switch (section) {
      case 'setup':
        setSetupCollapsed((prev) => !prev)
        setIsReportsExpanded(false)
        setIsCustomReportsExpanded(false)
        setIsActiveReportExpanded(false)
        setIsDemoExpanded(false)
        break
      case 'reports':
        setSetupCollapsed(true)
        setIsReportsExpanded((prev) => !prev)
        setIsCustomReportsExpanded(false)
        setIsActiveReportExpanded(false)
        setIsDemoExpanded(false)
        break
      case 'demo':
        setSetupCollapsed(true)
        setIsReportsExpanded(false)
        setIsCustomReportsExpanded(false)
        setIsActiveReportExpanded(false)
        setIsDemoExpanded((prev) => !prev)
        break
      case 'customReports':
        setSetupCollapsed(true)
        setIsReportsExpanded(false)
        setIsCustomReportsExpanded((prev) => !prev)
        setIsActiveReportExpanded(false)
        setIsDemoExpanded(false)
        break
      case 'activeReport':
        setSetupCollapsed(true)
        setIsReportsExpanded(false)
        setIsCustomReportsExpanded(false)
        setIsActiveReportExpanded((prev) => !prev)
        setIsDemoExpanded(false)
        break
      case 'marketplace':
        setSetupCollapsed(true)
        setIsReportsExpanded(false)
        setIsCustomReportsExpanded(false)
        setIsActiveReportExpanded(false)
        setIsDemoExpanded(false)
        handleRedirectToTab('billingDetails')
        break
      default:
        break
    }
  }

   const handleOpenDemoReport = (reportName) => {
    // redirectTo(`/organization/${organizationSlug}/demo/${reportName}`)
    setActiveDemoReport(reportName)
    history.push(`/organization/${organizationSlug}/demo/${reportName}`)

    if (window.innerWidth < 767) {
      collapseSidebar(true)
    }
   }

  const handleToggleActiveReport = (v) => {
    setIsActiveReportExpanded(v)
    setIsCustomReportsExpanded(false)
    setIsReportsExpanded(false)
    setSetupCollapsed(true)
  }

  const handleClickLogo = () => {
    history.push('/')
  }

  const CollapsedSidebar = ({
    currentOrganization,
    hasCustomReports,
    hasActiveReport,
    handleOpenSection,
    isActiveReportExpanded,
    isCustomReportsExpanded,
    isReportsExpanded,
    setupCollapsed,
  }) => {
    const handleClick = (section) => {
      handleOpenSection(section)
      collapseSidebar(false)
    }

    return (
      <div className="collapsed-sidebar">
        {!currentOrganization.is_demo && (
          <div
            className="icon-container"
            onClick={() =>
              setupCollapsed ? handleClick('setup') : collapseSidebar(false)
            }
          >
            <span className="material-icons-outlined non-selectable">tune</span>
          </div>
        )}
        {isEmbedEnabled ? <div
          className="icon-container"
          onClick={() =>
            !isReportsExpanded ? handleClick('reports') : collapseSidebar(false)
          }
        >
          <span className="material-icons-outlined non-selectable">
            dashboard
          </span>
        </div> :
        <div
          className="icon-container"
          onClick={() =>
            !isDemoExpanded ? handleClick('demo') : collapseSidebar(false)
          }
        >
          <span className="material-icons-outlined non-selectable">
            data_usage
          </span>
        </div> }
        {hasCustomReports && (
          <div
            className="icon-container"
            onClick={() =>
              !isCustomReportsExpanded
                ? handleClick('customReports')
                : collapseSidebar(false)
            }
          >
            <span className="material-icons-outlined non-selectable">
              dashboard_customize
            </span>
          </div>
        )}
        <div
          className="icon-container"
          onClick={() =>
            handleClick('marketplace')
          }
        >
          <span className="material-icons-outlined non-selectable">
            storefront
          </span>
        </div>
        {hasActiveReport && (
          <>
            <Divider></Divider>
            <div
              className="icon-container"
              onClick={() =>
                !isActiveReportExpanded
                  ? handleClick('activeReport')
                  : collapseSidebar(false)
              }
            >
              <span className="material-icons-outlined">insert_chart</span>
            </div>
          </>
        )}
      </div>
    )
  }


  return (
    <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : 'open'}`}>
      <div onClick={handleClickLogo} className="logo">
        <img src={mayaLogo} alt="Maya" />
      </div>
      {isSidebarCollapsed ? (
        <CollapsedSidebar
          currentOrganization={currentOrganization}
          hasCustomReports={customReports.length > 0}
          hasActiveReport={embedReport}
          handleOpenSection={(section) => handleOpenSection(section)}
          isActiveReportExpanded={isActiveReportExpanded}
          isCustomReportsExpanded={isCustomReportsExpanded}
          isReportsExpanded={isReportsExpanded}
          setupCollapsed={setupCollapsed}
        />
      ) : (
        <>
          {currentOrganization.is_demo === null ? (
            <div style={{ display: 'flex', marginTop: '100px' }}>
              <ScreenLoading />
            </div>
          ) : (
            <>
              <ul className="navigation">
                {!currentOrganization.is_demo && (
                  <>
                    <li
                      className={`main-navigation setup-${
                        setupCollapsed ? 'collapsed' : 'expanded'
                      }`}
                      onClick={() => handleOpenSection('setup')}
                    >
                      <div
                        className="setup-header"
                      >
                        <div>
                          <span className="material-icons-outlined non-selectable">
                            tune
                          </span>
                          Setup
                        </div>
                        <div>
                          {setupCollapsed &&
                            sourceWarnings &&
                            sourceWarnings.length > 0 && (
                              <span className="material-icons-outlined warning-icon">
                                warning
                              </span>
                            )}
                          <span
                            className={`material-icons-outlined ${
                              !setupCollapsed && 'rotate'
                            }`}
                          >
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>
                      <ul className="sub-navigation">
                        <li
                          onClick={() => handleRedirectToTab('sources')}
                          className={page === 'sources' ? 'active' : ''}
                        >
                          <span className="material-icons-outlined">
                            account_tree
                          </span>
                          Sources
                          {sourceWarnings.length > 0 && (
                            <span className="material-icons-outlined warning-icon">
                              warning
                            </span>
                          )}
                        </li>
                        <li
                          onClick={() => handleRedirectToTab('clusters')}
                          className={page === 'clusters' ? 'active' : ''}
                        >
                          <span className="material-icons-outlined">code</span>
                          Clusters
                        </li>
                        <li
                          onClick={() => handleRedirectToTab('competitors')}
                          className={page === 'competitors' ? 'active' : ''}
                        >
                          <span className="material-icons-outlined">
                            trending_up
                          </span>
                          Competitors
                        </li>
                        <li
                          onClick={() => handleRedirectToTab('pagespeed')}
                          className={page === 'pagespeed' ? 'active' : ''}
                        >
                          <span className="material-icons-outlined">speed</span>
                          PageSpeed
                        </li>
                        <li
                          onClick={() => handleRedirectToTab('expenses')}
                          className={page === 'expenses' ? 'active' : ''}
                        >
                          <span className="material-icons-outlined">
                            payments
                          </span>
                          Manual Expenses
                        </li>
                        <li
                          onClick={() => handleRedirectToTab('settings')}
                          className={page === 'settings' ? 'active' : ''}
                        >
                          <span className="material-icons-outlined">
                            manage_accounts
                          </span>
                          Administration
                        </li>
                        {currentOrganization.is_etl_enabled && (
                          <li
                            onClick={() => handleRedirectToTab('dataload')}
                            className={page === 'dataload' ? 'active' : ''}
                          >
                            <span className="material-icons-outlined">
                              cached
                            </span>
                            Data Load
                          </li>
                        )}
                      </ul>
                    </li>
                  </>
                )}
                {isEmbedEnabled ? (
                  <>
                    <li
                      className={`main-navigation reports-${
                        isReportsExpanded ? 'expanded' : 'collapsed'
                      }`}
                      onClick={() => handleOpenSection('reports')}
                    >
                      <div
                        className="reports-header"
                      >
                        <div>
                          <span className="material-icons-outlined non-selectable">
                            dashboard
                          </span>
                          {customReports.length > 0 ? 'Maya' : ''} Reports
                        </div>
                        <span
                          className={`material-icons-outlined ${
                            isReportsExpanded && 'rotate'
                          }`}
                        >
                          keyboard_arrow_right
                        </span>
                      </div>
                      <ReportList
                        embedReportsFetchStatus={embedReportsFetchStatus}
                        embedReports={mayaReports}
                        currentOrganization={currentOrganization}
                        currentReportSlug={embedReportSlug}
                        onReportOpen={handleReportClick}
                      />
                    </li>
                    {embedReportsFetchStatus !== 'pending' &&
                      customReports.length > 0 && (
                        <li
                          className={`main-navigation custom-reports-${
                            isCustomReportsExpanded ? 'expanded' : 'collapsed'
                          }`}
                          onClick={() => handleOpenSection('customReports')}
                        >
                          <div
                            className="custom-reports-header"
                          >
                            <div>
                              <span className="material-icons-outlined">
                                dashboard_customize
                              </span>
                              Custom Reports
                            </div>
                            <span
                              className={`material-icons-outlined ${
                                isCustomReportsExpanded && 'rotate'
                              }`}
                            >
                              keyboard_arrow_right
                            </span>
                          </div>
                          <ReportList
                            embedReportsFetchStatus={embedReportsFetchStatus}
                            embedReports={customReports}
                            currentOrganization={currentOrganization}
                            currentReportSlug={embedReportSlug}
                            onReportOpen={handleReportClick}
                          />
                        </li>
                      )}
                    {currentOrganization.is_demo && (
                      <>
                        <li style={{ padding: '0 10px' }}>
                          <PopupButton
                            url="https://calendly.com/mayainsights/book-a-demo"
                            text="Book a Demo"
                            onClick={() => {
                              TagManager.dataLayer({ dataLayer: { event: `demo_report_visited`, is_custom_track: true } })
                              // rudderanalytics.track('demo_report_visited')
                            }}
                            className="submit-button"
                            styles={{
                              left: 0,
                              width: '100%',
                            }}
                            prefill={{
                              email: user ? user.email : '',
                              firstName:
                                user && user.profile
                                  ? user.profile.first_name
                                  : '',
                              lastName:
                                user && user.profile
                                  ? user.profile.last_name
                                  : '',
                              name:
                                user && user.profile
                                  ? user.profile.first_name +
                                    ' ' +
                                    user.profile.last_name
                                  : '',
                            }}
                          />
                        </li>
                      </>
                    )}
                    {report && (
                      <>
                        <Divider />
                        <ActiveReport
                          reportPages={reportPages}
                          isActiveReportExpanded={isActiveReportExpanded}
                          setIsActiveReportExpanded={(v) =>
                            handleToggleActiveReport(v)
                          }
                          embedViewMode={embedViewMode}
                          embedReport={embedReport}
                          report={report}
                          currentOrganization={currentOrganization}
                          organizationSlug={organizationSlug}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                  <li
                    className={`main-navigation demo-${
                      isDemoExpanded ? 'expanded' : 'collapsed'
                    }`}
                    onClick={() => handleOpenSection('demo')}
                  >
                    <div
                        className="demo-header"
                        >
                        <div>
                          <span className="material-icons-outlined non-selectable">
                            data_usage
                          </span>
                          Demo
                        </div>
                          <span
                          className={`material-icons-outlined ${
                            isDemoExpanded && 'rotate'
                          }`}
                        >
                          keyboard_arrow_right
                        </span>
                    </div>
                    <ul className="sub-navigation"> 
                      <li className={activeDemoReport === "Spend" ? "active" : ""} onClick={() => handleOpenDemoReport('Spend')}>Spend<span className="demo-tag">DEMO</span></li>
                      <li className={activeDemoReport === "Seo" ? "active" : ""} onClick={() => handleOpenDemoReport('Seo')}>SEO<span className="demo-tag">DEMO</span></li>
                      <li className={activeDemoReport === "Sem" ? "active" : ""} onClick={() => handleOpenDemoReport('Sem')}>SEM<span className="demo-tag">DEMO</span></li>
                      <li className={activeDemoReport === "Conversions" ? "active" : ""} onClick={() => handleOpenDemoReport('Conversions')}>Conversions<span className="demo-tag">DEMO</span></li>
                      <li className={activeDemoReport === "Competition" ? "active" : ""} onClick={() => handleOpenDemoReport('Competition')}>Competition<span className="demo-tag">DEMO</span></li>
                    </ul>
                  </li>
                  </>
                )}
                <li
                  onClick={() => handleRedirectToTab('billingDetails')}
                  className={`main-navigation static ${page === 'billingDetails' || page === 'billingProducts' || page === 'billingCheckout' ? 'active' : ''}`}
                >
                  <div>
                    <span className="material-icons-outlined">
                      storefront
                    </span>
                    Marketplace
                  </div>
                </li>
              </ul>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(SideBar)
