import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import InputField from 'components/InputField'

import 'css/PopUp.scss'

const ReportSaveDialog = ({
  reportNameValidation,
  onSave,
  onCancel,
  visible,
}) => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  const handleSave = async () => {
    const n = name.trim()
    const err = await reportNameValidation(n)
    if (err) {
      setNameError(err)
    } else {
      setName('')
      setNameError('')
      onSave(n)
    }
  }

  const handleCancel = async () => {
    setName('')
    setNameError('')
    onCancel()
  }

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          exit={{ opacity: 0 }}
          className={`pop-up confirmation-pop-up`}
          id="pop-up"
        >
          <div className="text-container">
            <span className="text">Enter a name for your report</span>
          </div>
          <div className="report-name-input-field-container">
            <InputField
              label="Report Name"
              type="text"
              initialValue={name}
              setValue={(v) => setName(v)}
              error={nameError}
              removeError={() => setNameError('')}
            />
          </div>
          <div
            className="confirmation-actions"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <button onClick={handleCancel} className="cancel-button">
              Cancel
            </button>{' '}
            <button className="submit-button" onClick={handleSave}>
              Confirm
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default React.memo(ReportSaveDialog)
