import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api, { isAPIError } from 'containers/api'

const useGetColumnByCategory = () => {
  const token = useSelector(selectToken)

  const [column, setColumn] = useState()
  const [isLoadingColumn, setIsLoadingColumn] = useState(true)
  const [columnError, setColumnError] = useState('')

  const fetchColumnByCategory = async ({
    category,
    columnId,
    organization,
  }) => {
    try {
      const responseColumn = await api.getColumnByCategory({
        category,
        organizationId: organization.id,
        columnId,
        token,
      })
      setColumn(responseColumn)
      setIsLoadingColumn(false)
      return responseColumn
    } catch (error) {
      if (isAPIError(error)) {
        setColumnError(error.response.data.detail.type)
      } else {
        setColumnError('Failed loading clustering column')
      }
      return false
    }
  }

  return { column, isLoadingColumn, columnError, fetchColumnByCategory }
}

export default useGetColumnByCategory
