import Feature from 'components/Feature'
import ScreenLoading from 'components/ScreenLoading'
import UpgradeButton from 'components/UpgradeButton'
import useCreateInvite from 'containers/invites/useCreateInvite'
import useDeleteInvite from 'containers/invites/useDeleteInvite'
import useGetPendingInvites from 'containers/invites/useGetPendingInvites'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetOrganizationUsers from 'containers/organizations/useGetOrganizationUsers'
import produce from 'immer'

import { useContext, useEffect, useState } from 'react'

const Member = ({
  member,
  status,
  deleteInviteWithOrganization,
  handleDeleteInviteFromList,
}) => {
  const [hovered, setHovered] = useState(false)
  const [deleteError, setDeleteError] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteInvite = async () => {
    setIsDeleting(true)
    if (!(await deleteInviteWithOrganization({ invite: member }))) {
      setDeleteError(true)
      setIsDeleting(false)
      return
    }
    setIsDeleting(false)
    handleDeleteInviteFromList(member.uuid)
  }

  return (
    <tr
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="grid member"
    >
      <td className="grid-column-10 members-column">{member.email}</td>
      <td className="grid-column-1 status-column">
        <Status status={status} />
      </td>
      <td className="grid-column-1 bin-column">
        {status === 'pending' && (
          <Bin
            deleteError={deleteError}
            handleDeleteInvite={handleDeleteInvite}
            hovered={hovered}
            isDeleting={isDeleting}
          />
        )}
      </td>
    </tr>
  )
}

const Status = ({ status }) => {
  if (status === 'loading') {
    return <ScreenLoading />
  }
  if (status === 'pending') {
    return (
      <>
        <div className="status-circle pending"></div>
        <span className="status-text">Pending</span>
      </>
    )
  }
  if (status === 'member') {
    return (
      <>
        <div className="status-circle success"></div>
        <span className="status-text">Member</span>
      </>
    )
  }

  return null
}

const Bin = ({ handleDeleteInvite, deleteError, hovered, isDeleting }) => {
  return (
    <>
      {isDeleting ? (
        <ScreenLoading />
      ) : (
        <>
          {hovered && (
            <span
              onClick={handleDeleteInvite}
              className="material-icons-outlined bin"
            >
              delete_outline
            </span>
          )}
        </>
      )}
      {deleteError && <Error />}
    </>
  )
}

const Error = () => {
  return <span className="material-icons-outlined error">error</span>
}

const InvitesTable = ({
  pendingInvites,
  organizationUsers,
  deleteInviteWithOrganization,
  handleDeleteInviteFromList,
}) => {
  return (
    <table className="invites">
      <thead>
        <tr className="grid">
          <th className="grid-column-10 members-column">Members</th>
          <th className="grid-column-1 status-column">Status</th>
        </tr>
      </thead>
      <tbody>
        {pendingInvites.map((invite) => (
          <Member
            key={invite.uuid}
            member={invite}
            status="pending"
            deleteInviteWithOrganization={deleteInviteWithOrganization}
            handleDeleteInviteFromList={handleDeleteInviteFromList}
          />
        ))}
        {organizationUsers.map((member) => (
          <Member key={member.id} member={member} status="member" />
        ))}
        {pendingInvites.length === 0 && organizationUsers.length === 0 && (
          <tr className="no-members">
            <td>No members</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

const InviteNewMember = ({ handleCreateInvite }) => {
  const [newMember, setNewMember] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    await handleCreateInvite({ email: newMember })
    setLoading(false)
    setNewMember('')
  }

  const handleKeyDown = async (event) => {
    switch (event.key) {
      case 'Enter':
        if (loading) {
          return
        }
        handleSubmit()
        break
      default:
        break
    }
  }

  const handleChange = (e) => {
    if (loading) {
      return
    }

    setNewMember(e.target.value)
  }

  return (
    <div className="invite-member">
      <input
        onKeyDown={handleKeyDown}
        value={newMember}
        onChange={handleChange}
        placeholder="Add a new member..."
      />
      <div className="actions">
        {loading ? (
          <ScreenLoading />
        ) : (
          <button onClick={handleSubmit} className="submit-button">
            Add
          </button>
        )}
      </div>
    </div>
  )
}

export default function Invites() {
  const {
    pendingInvites,
    setPendingInvites,
    loadingPendingInvites,
    pendingInvitesError,
    getPendingInvites,
    handleDeleteInviteFromList,
  } = useGetPendingInvites()
  const {
    organizationUsers,
    loadingOrganizationUsers,
    organizationUsersError,
    getOrganizationUsers,
  } = useGetOrganizationUsers()
  const { createInvite } = useCreateInvite()
  const { deleteInvite } = useDeleteInvite()

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  const getData = async () => {
    if (!isLoadingOrganization) {
      getPendingInvites({ organization: currentOrganization })
      getOrganizationUsers({ organization: currentOrganization })
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [isLoadingOrganization, currentOrganization])

  if (pendingInvitesError || organizationUsersError) {
    return <>getting invites error</>
  }

  const deleteInviteWithOrganization = async ({ invite }) => {
    return await deleteInvite({ organization: currentOrganization, invite })
  }

  const handleCreateInvite = async ({ email }) => {
    const newInvite = await createInvite({
      email,
      organization: currentOrganization,
    })
    if (!newInvite) {
      console.log('error inviting')
      return
    }

    setPendingInvites(
      produce(pendingInvites, (draft) => {
        draft.unshift({
          ...newInvite,
          status: 'pending',
        })
      })
    )
  }
  return (
    <>
      {isLoadingOrganization ||
      loadingPendingInvites ||
      loadingOrganizationUsers ? (
        <ScreenLoading />
      ) : (
        <>
          <Feature
            feature="can_add_users"
            switchComponent={
              <UpgradeButton style={{ marginBottom: 20 }}>
                Upgrade plan to add more users
              </UpgradeButton>
            }
          >
            <InviteNewMember handleCreateInvite={handleCreateInvite} />
          </Feature>
          <InvitesTable
            pendingInvites={pendingInvites}
            organizationUsers={organizationUsers}
            deleteInviteWithOrganization={deleteInviteWithOrganization}
            handleDeleteInviteFromList={handleDeleteInviteFromList}
          />
        </>
      )}
    </>
  )
}
