import React, { useEffect } from 'react'
import _ from 'lodash'

import ScreenLoading from 'components/ScreenLoading'
import { Redirect } from 'components/Redirect'

import useGetOrganizations from 'containers/organizations/useGetOrganizations'
import usePageTitle from 'containers/pageTitle/usePageTitle'
import {useLocation, useHistory} from "react-router"

const Home = () => {
  const {
    organizationsError,
    getOrganizations,
    organizations,
    loadingOrganizations,
  } = useGetOrganizations()
  
  const history = useHistory()
  const location = useLocation()

  //Page title
  usePageTitle('Maya')


  const getData = async () => {
    getOrganizations()
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  if (loadingOrganizations) {
    return <ScreenLoading />
  }

  if (!organizations || organizationsError) {
    return <>There was an issue loading your organizations</>
  }

  if (organizations && organizations.length === 0) {
    return <Redirect to={'/create-organization'} />
  }

  let organization = null
  if (localStorage.getItem('currentOrganization')) {
    organization = _.find(organizations, [
      'slug',
      localStorage.getItem('currentOrganization'),
    ])
  }
  if (!organization) {
    organization = organizations[0]
  }

  if (location.state?.destinations) {
    history.push({pathname: `/organization/${organization.slug}/settings/destinations`, 
    state: {
      refresh_destinations: true
    }})
    return <></>

  }

  if (organization.has_dashboard) {
    history.push({pathname: `/organization/${organization.slug}/dashboard`})
    return <></>
  }

  history.push({pathname: `/organization/${organization.slug}/sources`})
  return <></>
}

export default React.memo(Home)
