import React, { useEffect, useContext, useState, useCallback } from 'react'
import { useParams } from 'react-router'

import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetAuthUrl from 'containers/services/useGetAuthUrl'

import Page404 from 'pages/ErrorPages/404'
import ScreenLoading from 'components/ScreenLoading'

const RedirectSource = () => {
  const { serviceUuid } = useParams()
  const { getAuthUrl } = useGetAuthUrl()
  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)
  const [status, setStatus] = useState('pending')

  const getData = useCallback(async () => {
    if (currentOrganization.id) {
      try {
        const url = await getAuthUrl(serviceUuid, currentOrganization.id)
        if (url) {
          window.location.replace(url)
        } else {
          throw new Error('no url')
        }
      } catch (e) {
        setStatus('failed')
      }
    }
  }, [currentOrganization.id, getAuthUrl, serviceUuid])

  useEffect(() => {
    getData()
  }, [serviceUuid, isLoadingOrganization, currentOrganization.id, getData])

  if (status === 'failed') {
    return <Page404 />
  } else {
    return <ScreenLoading />
  }
}

export default React.memo(RedirectSource)
