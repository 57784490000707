import React, { useContext, useEffect, useState, useReducer } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import { IManualExpense, IOrganization } from 'types'
import ScreenLoading from 'components/ScreenLoading'
import InputField from 'components/InputField'
import DropDownField from 'components/DropDownField'
import AutoCompleteField from 'components/AutoCompleteField'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetCurrencies from 'containers/currencies/useGetCurrencies'
import useGetManualExpense from 'containers/expenses/useGetManualExpense'
import useSaveManualExpense from 'containers/expenses/useSaveManualExpense'
import useListManualExpenseCategories from 'containers/expenses/useListManualExpenseCategories'
import useListManualExpenseSubcategories from 'containers/expenses/useListManualExpenseSubcategories'

import {
  useValidator,
  isRequired,
  maxLength,
  isValidNumber,
} from 'containers/formValidator'

import './ManualExpensesList.scss'

type ExpenseAction =
  | { type: 'organizationLoaded'; payload: IOrganization }
  | { type: 'expenseLoaded'; payload: IManualExpense }
  | { type: 'paymentDateChanged'; payload: string }
  | { type: 'amountChanged'; payload: number }
  | { type: 'categoryChanged'; payload: string }
  | { type: 'currencyCodeChanged'; payload: string }
  | { type: 'subcategoryChanged'; payload: string }
  | { type: 'startDateChanged'; payload: string }
  | { type: 'endDateChanged'; payload: string }
  | { type: 'isMarketingRelatedChanged'; payload: boolean }
  | { type: 'descriptionChanged'; payload: string }

const expenseReducer = (
  expense: IManualExpense,
  action: ExpenseAction
): IManualExpense => {
  switch (action.type) {
    case 'organizationLoaded':
      if (action.payload.currency_id) {
        return { ...expense, currencyCode: action.payload.currency_id }
      } else {
        return expense
      }
    case 'expenseLoaded':
      return action.payload
    case 'paymentDateChanged':
      return { ...expense, paymentDate: action.payload }
    case 'amountChanged':
      return { ...expense, amount: action.payload }
    case 'categoryChanged':
      return { ...expense, category: action.payload }
    case 'subcategoryChanged':
      return { ...expense, subcategory: action.payload }
    case 'currencyCodeChanged':
      return { ...expense, currencyCode: action.payload }
    case 'startDateChanged':
      return { ...expense, startDate: action.payload }
    case 'endDateChanged':
      return { ...expense, endDate: action.payload }
    case 'isMarketingRelatedChanged':
      return { ...expense, isMarketingRelated: action.payload }
    case 'descriptionChanged':
      return { ...expense, description: action.payload }
  }
}

const ManualExpenseView = () => {
  const { expenseId } = useParams<{ expenseId: string }>()
  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)
  const currentOrganizationId = currentOrganization.id

  const history = useHistory()

  const [expense, dispatch] = useReducer(expenseReducer, {
    paymentDate: '',
    uuid: expenseId,
    description: '',
    amount: 0,
    currencyCode: currentOrganization?.currency_id || 'USD',
    category: '',
    subcategory: '',
    startDate: '',
    endDate: '',
    isMarketingRelated: true,
  })

  const [
    currencies,
    fetchCurrencies,
    fetchCurrenciesStatus,
    fetchCurrenciesError,
  ] = useGetCurrencies()
  const [_expense, fetchExpense, fetchExpenseStatus, fetchExpenseError] =
    useGetManualExpense(currentOrganization.id, expenseId)
  const [saveExpense, saveExpenseStatus, saveExpenseError] =
    useSaveManualExpense(currentOrganizationId)
  const [manualExpenseCategories, fetchManualExpenseCategories] =
    useListManualExpenseCategories(currentOrganization.id)
  const [manualExpenseSubcategories, fetchManualExpenseSubcategories] =
    useListManualExpenseSubcategories(currentOrganization.id)

  const [advancedOpen, setAdvancedOpen] = useState(false)

  const [validateForm, getFormFieldError] = useValidator({
    amount: [isValidNumber('Invalid amount'), isRequired()],
    category: [isRequired()],
    subcategory: [isRequired()],
    description: [maxLength(50, 'Description too long')],
  })

  const getData = async () => {
    if (currentOrganization.id && expenseId) {
      fetchManualExpenseCategories()
      fetchManualExpenseSubcategories()
      const expense = await fetchExpense()
      if (!expense) {
        return
      }
      dispatch({ type: 'expenseLoaded', payload: expense })
    }
  }

  useEffect(() => {
    if (!isLoadingOrganization) {
      dispatch({ type: 'organizationLoaded', payload: currentOrganization })
    }
    // eslint-disable-next-line
  }, [isLoadingOrganization])

  useEffect(() => {
    fetchCurrencies()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [currentOrganizationId, expenseId])

  const handleSave = async () => {
    if (!validateForm(expense)) {
      return
    }
    if (currentOrganizationId) {
      await saveExpense(expense)
      localStorage.setItem('manualExpenseSaved', '1')
      history.push(`/organization/${currentOrganization.slug}/expenses`)
    }
  }

  const loadError = [fetchExpenseError, fetchCurrenciesError].find((e) => e)

  return (
    <div className="clusters-campaigns container-column manual-expenses">
      {loadError && (
        <div className="alert alert-danger" role="alert">
          {loadError}
        </div>
      )}
      <NavLink to={`/organization/${currentOrganization.slug}/expenses`}>
        <span className="material-icons-outlined back-arrow non-selectable">
          arrow_back
        </span>
      </NavLink>
      <div className="head" style={{ marginBottom: '30px' }}>
        <div className="page-name">
          <h2>Manual Expenses</h2>
          <span className="subtitle-text">
            Record expenses that you want included in your reports, such as
            non-digital marketing expenses.
          </span>
        </div>
      </div>
      <div
        className={`grid manual-expenses-form ${
          fetchExpenseStatus === 'pending' ? 'form-loading' : ''
        }`}
      >
        <div className="form-container grid-column-12 g-col-xxl-6">
          <div className="loading-indicator">
            <ScreenLoading />
          </div>
          <div className="loading-shade"></div>
          <div className="form-body">
            <div className="grid">
              <div className="grid-column-12">
                <InputField
                  type="date"
                  label="Payment Date"
                  value={expense.paymentDate}
                  setValue={(v) =>
                    dispatch({ type: 'paymentDateChanged', payload: v })
                  }
                  required
                />
              </div>
            </div>
            <div className="grid">
              <div className="grid-column-12 g-col-lg-8">
                <InputField
                  label="Amount"
                  type="text"
                  value={
                    expense.amount ? (expense.amount / 100).toString() : ''
                  }
                  setValue={(v) =>
                    dispatch({
                      type: 'amountChanged',
                      payload: Number(v) * 100,
                    })
                  }
                  error={getFormFieldError('amount')}
                />
              </div>
              <div className="grid-column-12 g-col-lg-4">
                {!isLoadingOrganization ? (
                  <DropDownField
                    label="currency"
                    options={currencies.map((c) => {
                      return { label: `${c.name} (${c.symbol})`, value: c.code }
                    })}
                    value={expense.currencyCode}
                    onChange={(v) =>
                      dispatch({ type: 'currencyCodeChanged', payload: v })
                    }
                    loadingOptions={fetchCurrenciesStatus === 'pending'}
                    disabled={false}
                    inputRef={() => {}}
                  />
                ) : (
                  <ScreenLoading />
                )}
              </div>
            </div>
            <div className="grid">
              <div className="grid-column-12 g-col-lg-4">
                <AutoCompleteField
                  label="Category"
                  options={manualExpenseCategories}
                  value={expense.category}
                  onChange={(v) =>
                    dispatch({ type: 'categoryChanged', payload: v })
                  }
                  error={getFormFieldError('category')}
                />
              </div>
              <div className="grid-column-12 g-col-lg-4">
                <AutoCompleteField
                  label="Subcategory"
                  options={manualExpenseSubcategories}
                  value={expense.subcategory}
                  onChange={(v) =>
                    dispatch({ type: 'subcategoryChanged', payload: v })
                  }
                  error={getFormFieldError('subcategory')}
                />
              </div>
              <div className="grid-column-12 g-col-lg-4">
                <DropDownField
                  label="Is Marketing Related"
                  value={expense.isMarketingRelated ? 'yes' : 'no'}
                  onChange={(v) =>
                    dispatch({
                      type: 'isMarketingRelatedChanged',
                      payload: v === 'yes',
                    })
                  }
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                />
              </div>
            </div>
            <div className="grid">
              <div className="grid-column-12">
                <InputField
                  type="text"
                  label="Description"
                  value={expense.description}
                  setValue={(v) =>
                    dispatch({ type: 'descriptionChanged', payload: v })
                  }
                  error={getFormFieldError('description')}
                />
              </div>
            </div>
            <div className="section">
              <div className="advanced">
                <button
                  className="advanced-title link-button"
                  onClick={() => setAdvancedOpen(!advancedOpen)}
                >
                  Advanced
                  <span className="material-icons-outlined">
                    {advancedOpen ? 'expand_less' : 'expand_more'}
                  </span>
                </button>
                <div
                  className={advancedOpen ? 'collapse-open' : 'collapse-closed'}
                >
                  <div className="section-header">
                    <span className="section-title">Expense period</span>
                    <span className="subtitle-text">
                      The period over which the expense will be reported to have
                      effect. For example, the period that a billboard
                      advertisement is in display.
                    </span>
                  </div>
                  <div className="grid">
                    <div className="grid-column-12 g-col-lg-6">
                      <InputField
                        type="date"
                        label="Period Start Date"
                        value={expense.startDate ? expense.startDate : ''}
                        setValue={(v) =>
                          dispatch({ type: 'startDateChanged', payload: v })
                        }
                      />
                    </div>
                    <div className="grid-column-12 g-col-lg-6">
                      <InputField
                        type="date"
                        label="Period End Date"
                        value={expense.endDate ? expense.endDate : ''}
                        setValue={(v) =>
                          dispatch({ type: 'endDateChanged', payload: v })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-column-12">
          <div className="section actions">
            <div className="grid">
              <div className="grid-column-2">
                <div className="save-button">
                  {saveExpenseStatus === 'pending' ? (
                    <ScreenLoading />
                  ) : (
                    <>
                      <button onClick={handleSave} className="submit-button">
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="grid-column-10" style={{ zIndex: -1 }}>
                {saveExpenseStatus === 'fulfilled' && (
                  <AnimatePresence>
                    <motion.span
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="material-icons-outlined saved-icon non-selectable"
                    >
                      task_alt
                    </motion.span>
                  </AnimatePresence>
                )}
                {saveExpenseError && (
                  <div className="saving-error">
                    {saveExpenseError}. Please try again later.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ManualExpenseView)
