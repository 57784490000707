import React, { useMemo, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ChangePasswordTab, InvitesTab } from 'components/SettingsTabs'
import 'pages/Settings/Settings.scss'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import DevelopersTab from 'components/SettingsTabs/DevelopersTab'
import slugify from 'utils/slugify'
import Destinations from 'components/SettingsTabs/Destinations/Destinations'

const TABS = [
  {
    name: 'Change Password',
    slug: slugify('Change Password'),
    settingType: 'account',
  },
  { name: 'Members', slug: slugify('Members'), settingType: 'administration' },
  {
    name: 'Developers',
    slug: slugify('Developers'),
    settingType: 'administration',
  },
  {
    name: 'Destinations',
    slug: slugify('Destinations'),
    settingType: 'administration',
  },
]

const Settings = ({ settingType }) => {
  const { currentOrganization } = useContext(OrganizationsContext)

  const tab =
    useParams<{ tab: string }>().tab ||
    TABS.find((tab) => tab.settingType === settingType)?.slug ||
    ''
  const CurrentTab = useMemo(() => {
    switch (tab) {
      case 'members':
        return <InvitesTab />
      case 'change-password':
        return <ChangePasswordTab />
      case 'developers':
        return (
          <DevelopersTab
            organizationSlug={currentOrganization.slug}
          />
        )
      case 'destinations':
        return (
          <Destinations currentOrganization={currentOrganization}/>
        )
      default:
        return settingType === 'account' ? ChangePasswordTab : InvitesTab
    }
  }, [tab, currentOrganization.slug, settingType])

  return (
    <div className="organization-settings-page">
      <div className="head">
        <div className="page-name">
          <h2>
            {settingType.slice(0, 1).toUpperCase() + settingType.slice(1)}
          </h2>
          <span className="subtitle-text">
            {settingType === 'account'
              ? 'Configure account settings.'
              : 'Invite new members, share your database or use the API key.'}
          </span>
        </div>
      </div>
      <TabNavigationMenu
        tabs={TABS}
        currentTab={tab}
        organizationSlug={currentOrganization.slug}
        settingType={settingType}
      />
      {CurrentTab && CurrentTab}
    </div>
  )
}

export default React.memo(Settings)

function TabNavigationMenu({
  tabs,
  currentTab,
  organizationSlug,
  settingType,
}) {
  const history = useHistory()

  if (currentTab === '') {
    history.push(
      `/organization/${organizationSlug}/${
        settingType === 'account' ? 'account-' : ''
      }settings/`
    )
  }

  function handleTabChange(e) {
    history.push(
      `/organization/${organizationSlug}/${
        settingType === 'account' ? 'account-' : ''
      }settings/${slugify(e.target.textContent)}`
    )
  }

  return (
    <nav className="page-navigation-menu">
      <ul>
        {tabs?.map(
          (tab) =>
            tab.settingType === settingType && (
              <li
                key={tab.slug}
                onClick={handleTabChange}
                className={tab.slug === currentTab ? 'selected' : ''}
              >
                {tab.name}
              </li>
            )
        )}
      </ul>
    </nav>
  )
}
