import api from '../api'
import useDataLoader from '../dataLoader'

const useGetInviteAddress = (inviteId: string) => {
  const fetchInviteAddress = async () => {
    try {
      const data = (await api.getPendingInvite(inviteId)).email
      return data
    } catch (error) {
      throw Error('Failed to load invitation.')
    }
  }

  return useDataLoader(fetchInviteAddress, [], [])
}

export default useGetInviteAddress
