import { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { selectToken } from '../authentication/authenticationSlice'

import api, { isHTTPError } from '../api'
import produce from 'immer'

import { IServiceAccount } from 'types'

const useGetConnectedAccounts = () => {
  const [serviceAccounts, setServiceAccounts] = useState<IServiceAccount[]>([])
  const [loadingServicesAccounts, setLoadingServicesAccounts] = useState(true)
  const [serviceAccountsError, setServiceAccountsError] = useState('')
  const token = useSelector(selectToken)

  const removeServiceAccount = (uuid) => {
    setServiceAccounts(
      produce(serviceAccounts, (draftServiceAccounts) => {
        _.remove(draftServiceAccounts, (account) => account.uuid === uuid)
      })
    )
  }

  const getServiceAccounts = async (organizationId) => {
    try {
      const responseServiceAccounts = await api.getConnectedServiceAccounts({
        token,
        organizationId,
      })
      setServiceAccounts(responseServiceAccounts)
      setLoadingServicesAccounts(false)
      return responseServiceAccounts
    } catch (error) {
      if (isHTTPError(error) && 'detail' in error.response.data) {
        setServiceAccountsError(
          `Error ${error.response.status}: ${error.response.data.detail}`
        )
      } else {
        setServiceAccountsError('Failed loading service accounts')
      }
    }
  }

  return {
    serviceAccounts,
    loadingServicesAccounts,
    serviceAccountsError,
    getServiceAccounts,
    removeServiceAccount,
  }
}

export default useGetConnectedAccounts
