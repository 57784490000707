import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  MouseEventHandler,
} from 'react'
import _ from 'lodash'
import stringSimilarity from 'string-similarity'
import ScreenLoading from 'components/ScreenLoading'

interface IOption {
  label: string
  value: any
}

export const makeOption = (value: string): IOption => {
  return { label: value, value }
}

interface IDropDownOption {
  value: any
  selected: boolean
  onClick: MouseEventHandler<HTMLLIElement>
  inputValue: any
}

const DropDownOption = ({
  value,
  selected,
  onClick,
  inputValue,
}: IDropDownOption) => {
  const elementRef = useRef<HTMLLIElement | null>(null)
  useEffect(() => {
    if (selected && elementRef?.current?.parentElement) {
      // elementRef.current.parentNode.scrollTop = elementRef.current.offsetTop;
      const miss =
        elementRef.current.offsetTop -
        elementRef.current.parentElement.scrollTop
      if (miss > 140) {
        elementRef.current.parentElement.scrollTop =
          elementRef.current.offsetTop - 130
      } else if (miss < 0) {
        elementRef.current.parentElement.scrollTop =
          elementRef.current.offsetTop
      }
    }
  }, [selected])
  return (
    <li
      style={{ fontWeight: value === inputValue ? 'bold' : '' } as any}
      onClick={onClick}
      ref={elementRef}
    >
      <span>{value}</span>
    </li>
  )
}

interface IDropDownField {
  label: string
  options: IOption[]
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  inputRef?: any
  hasError?: boolean
  loadingOptions?: boolean
  filterWithSimilarity?: boolean
  style: any
}

function DropDownField({
  label,
  options,
  value,
  onChange,
  disabled = false,
  inputRef,
  hasError = false,
  loadingOptions = false,
  filterWithSimilarity = false,
  style = {}
}: IDropDownField) {
  const selectedOption = _.find(options, ['value', value])
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState(
    selectedOption ? selectedOption.label : ''
  )
  const [filteredOptions, setFilteredOptions] = useState(options)

  const fieldRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event) => {
      if (disabled) {
        return
      }

      if (fieldRef.current && !fieldRef.current.contains(event.target)) {
        setOpen(false)
      }
      document.removeEventListener('mousedown', handleClickOutside)
    },
    [disabled, setOpen]
  )

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [disabled, handleClickOutside])

  useEffect(() => {
    setFilteredOptions(options)
    // eslint-disable-next-line
  }, [JSON.stringify(options)])

  const handleClickDropDown = () => {
    if (disabled) {
      return
    }

    setFilteredOptions(options)

    setOpen(!open)
    if (!open) {
      document.addEventListener('mousedown', handleClickOutside)
    }
  }

  const handleChooseOption = (optionIndex: number) => {
    if (disabled) {
      return
    }

    const option = filteredOptions[optionIndex]
    setInputValue(option.label)
    onChange(option.value)
  }

  const handleTextChange = (inputValue: any) => {
    setFilteredOptions(
      options.filter((option) => {
        const containsSubString = option.label
          .toLowerCase()
          .includes(inputValue.toLowerCase())

        if (filterWithSimilarity) {
          return (
            containsSubString ||
            stringSimilarity.compareTwoStrings(option.label, inputValue) > 0.4
          )
        } else {
          return containsSubString
        }
      })
    )
    setInputValue(inputValue)
  }

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 40) {
      const filteredIndex = _.findIndex(filteredOptions, ['label', value])
      const nextFilteredIndex = (filteredIndex + 1) % filteredOptions.length
      const nextValue = filteredOptions[nextFilteredIndex]
      handleChooseOption(options.indexOf(nextValue))
    } else if (event.keyCode === 38) {
      const filteredIndex = _.findIndex(filteredOptions, ['label', value])
      if (filteredIndex > 0) {
        const nextFilteredIndex = (filteredIndex - 1) % filteredOptions.length
        const nextValue = filteredOptions[nextFilteredIndex]
        handleChooseOption(options.indexOf(nextValue))
      }
    } else if (event.keyCode === 13) {
      setOpen(false)
    }
  }

  const isValueInOptions = _.find(options, ['value', value])
  hasError = hasError || !isValueInOptions

  return (
    <div
      ref={fieldRef}
      onClick={handleClickDropDown}
      className={`drop-down-input-field ${open ? 'open' : ''} ${
        disabled ? 'disabled' : ''
      } ${hasError && !open ? 'error' : ''}`}
      style={style}
      >
      <div 
        className="input-header"
      >
        <label>{label}</label>
      </div>
      {open ? (
        <input
          autoFocus
          value={inputValue}
          onChange={(e) => handleTextChange(e.target.value)}
          onKeyDown={handleKeyDown}
          className="value"
          type="text"
          ref={inputRef}
        />
      ) : (
        <div className="value">{selectedOption?.label || ''}</div>
      )}
      <span className="material-icons arrow non-selectable">
        keyboard_arrow_down
      </span>
      {open && loadingOptions && (
        <ul className="drop-down-options">
          <li style={{ padding: 40 }}>
            <ScreenLoading />
          </li>
        </ul>
      )}
      {open && !loadingOptions && filteredOptions && (
        <ul className="drop-down-options">
          {filteredOptions.map((option, optionIndex) => {
            return (
              <DropDownOption
                key={optionIndex}
                value={option.label}
                selected={option.value === value}
                onClick={() => handleChooseOption(optionIndex)}
                inputValue={inputValue}
              />
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default React.memo(DropDownField)
