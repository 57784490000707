import { useCallback, useContext, useEffect } from 'react'

import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetApiKeys from 'containers/apikeys/useGetApiKeys'
import InputField from 'components/InputField'

const ApiKeyView = () => {
  const { currentOrganization } = useContext(OrganizationsContext)
  const [apiKeys, fetchApiKeys] = useGetApiKeys(currentOrganization.id)

  const getData = useCallback(async () => {
    if (currentOrganization.id) {
      fetchApiKeys()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization.id])

  const handleCopyAPIKey = (e) => {
    e.preventDefault()
    if (apiKeys !== null && apiKeys !== undefined && apiKeys[0] !== undefined) {
      navigator.clipboard.writeText(apiKeys[0].key)
    }
  }

  useEffect(() => {
    getData()
  }, [currentOrganization.id, getData])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <InputField
          label="API Key"
          value={apiKeys && apiKeys[0] ? apiKeys[0].key : ''}
          style={{
            width:
              apiKeys && apiKeys[0]
                ? apiKeys[0].key.length * 9 + 'px'
                : '200px',
            maxWidth: '400px',
          }}
          disabled={true}
        />
        <button
          onClick={handleCopyAPIKey}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <span className="material-icons-outlined saved-icon non-selectable">
            copy
          </span>
        </button>
      </div>
    </div>
  )
}

export default ApiKeyView
