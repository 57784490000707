import Divider from './Divider'

const SetupGuide = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Setup Guide</h1>
      <Divider spaceY={10} />
      <div className="step-heading">
        <p>STEP 1</p>
        <h2>Communicate with your PowerBI Team</h2>
      </div>
      <ol>
        <li>
          Ask the Power BI Admin Team to create a workspace for Maya Usage on
          your tenant url.
        </li>
        <li>
          The name of the workspace will be <b>###.Maya [Client Name]</b>. The ###
          Number will be provided by the Maya Team.{' '}
        </li>
        <li>
          Depending on the dataset size, you may need to create a workspace with
          Premium capabilities (i.e run it under premium per user). To do this
          navigate to Settings &gt; Premium &gt; Choose Premium per User.{' '}
        </li>
      </ol>
      <div className="step-heading">
        <p>STEP 2</p>
        <h2>Configure Microsoft Admin</h2>
      </div>
      <ol>
        <li>
          On the{' '}
          <a
            href="https://admin.microsoft.com/Adminportal/Home#/GuestUsers/"
            target="_blank "
          >
            guest users page
          </a>{' '}
          on Microsoft’s Admin Portal, add guest user emails{' '}
          <a href="mailto:yanakeas@mayainsights.com">
            yanakeas@mayainsights.com
          </a>
          ,{' '}
          <a href="mailto:angelos@mayainsights.com">angelos@mayainsights.com</a> 
          , <a href="mailto:ioanik@mayainsights.com">ioanik@mayainsights.com</a>{' '}
          and{' '}
          <a href="mailto:kalipeti@mayainsights.com">
            kalipeti@mayainsights.com
          </a>
          . You will need an admin user for this.
        </li>
        <li>
          After that, create a security group on the group setup page called
          Maya Security Group and add the users as <b>admins</b>.
        </li>
      </ol>
      <div className="step-heading">
        <p>STEP 3</p>
        <h2>Create Security Group</h2>
      </div>
      <ol className="no-number"> 
        <li>
        Navigate to the Tenant Settings on the Admin  Portal page. Make sure that the following page settings are enabled for the entire organization or only for the Maya Security Group (depends on your policy):
        </li>
        <div className="img-item">
            <img src="https://old.mayainsights.com/static/img/icons/guide-01.png" alt="" width="500"/>
        </div>
      </ol>
        <div className="step-heading">
        <p>STEP 4</p>
        <h2>Provide Permissions</h2>
        </div>
        <ol className="no-number">
        <li>
        Add the security group to the created Workspace mentioned earlier to enable maya users to collaborate and share content inside the workspace. Provide the security group with Admin access inside the workspace settings. 
        </li>
        <div className="img-item">
            <img className="small" src="https://old.mayainsights.com/static/img/icons/guide-02.png" alt="" width="500"/>
        </div>
        </ol>
        <div className="step-heading">
        <p>STEP 5</p>
        <h2>Share the Workspace</h2>
        </div>
        <ol className="no-number">
            <li>
            For Maya to view this Workspace, you need to navigate to <b>Help and Support</b> Or the ? icon on the top right corner of the workspace, press <b>About Power BI</b> , copy and send us the <b>Tenant URL</b>.
            </li>
        </ol>
        <div className="step-heading">
        <p>STEP 6</p>
        <h2>Notes</h2>
        </div>
        <ol>
            <li>Once we receive the url, a Maya member will upload the appropriate datasets and reports, set the refresh policy (by default daily) and provide credentials to login (Google Big Query or other file connections). Do not take over the datasets, otherwise database connections and the refresh policy will break.</li>
            <li>From then on, you may create your own custom reports on top of the existing data model, or create composite models to run on top of Maya’s data models.</li>
        </ol>
    </div>
  )
}

export default SetupGuide
