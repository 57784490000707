import api, { isHTTPError } from '../api'
import useDataLoader from '../dataLoader'

const useMergeAccount = () => {
  const mergeAccount = async (requestId: string, email: string, password: string | null) => {
    try {
        return await api.mergeAccounts(requestId, email, password)
      } catch (error) {
        if (isHTTPError(error) && error.response.status === 404) {
          return error.response.data.detail.type
        } else if (isHTTPError(error) && 'detail' in error.response.data) {
            return error.response.data.detail.type
      }
    }
  }

  return useDataLoader(mergeAccount, [], [])
}

export default useMergeAccount
