import { useState } from 'react'

export enum MutationStatus {
  NotStarted = 'not_started',
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

function useMutator<P, T>(
  mutationFn: (payload: P) => Promise<T | null>
): [(payload: P) => Promise<T | null>, string, string, T | null] {
  const [response, setResponse] = useState<T | null>(null)
  const [mutationStatus, setMutationStatus] = useState<MutationStatus>(
    MutationStatus.NotStarted
  )
  const [mutationError, setMutationError] = useState<string>('')

  const mutate = async (payload: P) => {
    setMutationError('')
    setMutationStatus(MutationStatus.Pending)
    try {
      const response = await mutationFn(payload)
      setResponse(response)
      setMutationStatus(MutationStatus.Fulfilled)
      setMutationError('')
      return response
    } catch (error) {
      setMutationError('Something went wrong')
      setMutationStatus(MutationStatus.Rejected)
      setResponse(error.response)
      return null
    }
  }

  return [mutate, mutationStatus, mutationError, response]
}

export default useMutator
