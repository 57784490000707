import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api, { isHTTPError } from '../api'

import { IServiceAccount } from 'types'

// used when adding source
const useGetUniqueAccount = () => {
  const [uniqueAccount, setUniqueAccounts] = useState<IServiceAccount[]>([])
  const [loadingUniqueAccount, setLoadingUniqueAccount] = useState(false)
  const [uniqueAccountError, setUniqueAccountError] = useState('')
  const token = useSelector(selectToken)

  const getUniqueAccount = async (organizationId: number, projectId: string, datasetId: string) => {
    const serviceUuid = localStorage.getItem('redirectServiceUuid')!

    try {
      setLoadingUniqueAccount(true)
      setUniqueAccountError('')
      const responseAccounts = await api.verifyUniqueAccount({
        token,
        serviceUuid,
        projectId,
        datasetId,
        organizationId,
      })
      setUniqueAccounts(responseAccounts)
      setLoadingUniqueAccount(false)
      return responseAccounts
    } catch (error) {
      if (isHTTPError(error) && 'detail' in error.response.data) {
        setUniqueAccountError(
          `Error ${error.response.status}: ${error.response.data.detail}`
        )
      } else {
        setUniqueAccountError('Failed loading unique service account')
      }
    }
  }

  return { uniqueAccount, loadingUniqueAccount, getUniqueAccount, uniqueAccountError }
}

export default useGetUniqueAccount
