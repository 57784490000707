import { useSelector, useDispatch } from 'react-redux'
import { selectUser, setUser } from '../authentication/authenticationSlice'

import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

const useUser = () => {
  const token = useSelector(selectToken)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()

  const updateUser = async (
    companyDetails: { companyName: string; position: string },
    useThisToken = null
  ) => {
    const request = {
      company_name: companyDetails.companyName,
      company_position: companyDetails.position,
    }

    const headers = {
      Authorization: `Bearer ${
        useThisToken ? useThisToken : token.accessToken
      }`,
    }

    try {
      const response = await api.patch('/user', request, headers)
      const data = response.data

      dispatch(
        setUser({
          id: data.id,
          email: data.email,
          profile: data.profile,
        })
      )
    } catch (error) {}
  }

  const getUser = async () => {
    return api.getUser({ token })
  }

  return { user, updateUser, getUser }
}

export default useUser
