import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import ScreenLoading from '../../components/ScreenLoading'
import useGetServices from 'containers/services/useGetServices'
import useQuery from 'containers/useQuery'

const LandingAddAccount = () => {
  const query = useQuery()
  const { serviceId } = useParams()

  let history = useHistory()
  const { getServices } = useGetServices()

  useEffect(() => {
    let serviceUuidsById = {}
    const getData = async () => {
      const allServices = await getServices()

      allServices.forEach((service) => {
        serviceUuidsById[service.id] = service.uuid
      })
    }

    const redirect = async () => {
      await getData()
      if (query.get('error')) {
        let error = query.get('error')
        if (query.get('error_description')) {
          error = query.get('error_description')
        }
        history.push({
          pathname: `/organization/${localStorage.getItem(
            'currentOrganization'
          )}/sources/add`,
          state: {
            error: error,
          },
        })
      } else {
        localStorage.setItem('redirectState', query.get('state'))
        localStorage.setItem('redirectCode', query.get('code'))
        let serviceUuid = serviceUuidsById[serviceId]
        if (serviceId === 'search_console') {
          localStorage.setItem('redirectServiceId', 'google_search_console')
          serviceUuid = serviceUuidsById['google_search_console']
        } else {
          localStorage.setItem('redirectServiceId', serviceId)
        }
        localStorage.setItem('redirectServiceUuid', serviceUuid)
        history.push(
          `/organization/${localStorage.getItem(
            'currentOrganization'
          )}/integrations/add`
        )
      }
      // eslint-disable-next-line
    }

    redirect()
  }, [getServices, history, query, serviceId])

  return <ScreenLoading />
}

export default React.memo(LandingAddAccount)
