import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import './CustomTable.scss'
import DropDownField from './DropDownField'
import InputField from './InputField'
import { Column } from 'types'
import useGetCategoryColumns from 'containers/customTable/useGetCategoryColumns'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import Modal from './Modal'
import { getCSVColumns } from './FileUpload/utils'
import UploadZone from './UploadZone'
import useUploadCustomTable from 'containers/customTable/useUploadCustomTable'
import ScreenLoading from './ScreenLoading'
import { FetchStatus } from 'containers/dataLoader'
import useGetCustomTableColumns from 'containers/customTable/useGetCustomTableColumns'
import useUpdateCustomTableColumns from 'containers/customTable/useUpdateCustomTableColumns'
import ProgressBar from './ProgressBar'

const TYPE_OPTIONS = {
  string: 'String',
  numeric: 'Numeric',
  date: 'Date',
}

const UploadCSV = ({
  setShowPopup,
  columns,
  setColumns,
  setCSV,
  mayaColumns,
  category,
}) => {
  const [fileTypeError, setFileTypeError] = useState(false)
  const [emptyFileError, setEmptyFileError] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [clearFiles, setClearFiles] = useState(false)

  const setColumnsOnCSV = (foundColumns: string[]) => {
    if (foundColumns) {
      foundColumns.forEach((column: string) => {
        columns.find((col: Column) => col.columnName === column) ||
          setColumns((prev: Column[]) => [
            ...prev,
            {
              columnName: column,
              alias: column,
              type:
                mayaColumns.find((mayaColumn) => mayaColumn.alias === column)
                  ?.type || TYPE_OPTIONS.string.toLocaleLowerCase(),
              isRequired: false,
              isPrimaryKey: false,
            },
          ])
      })
      setShowPopup(false)
    }
  }

  const handleClearFiles = () => {
    setClearFiles(true)
    setFile(null)
    setColumns([])
    setTimeout(() => {
      setClearFiles(false)
    }, 100)
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (fileTypeError) setFileTypeError(false)
    const csv = e.target.files![0]

    if (csv?.type !== 'text/csv') {
      setFileTypeError(true)
      return
    }

    if (csv) {
      const { columns: columnsOnFile } = await getCSVColumns(csv)
      if (columnsOnFile && columnsOnFile?.length <= 0) {
        setEmptyFileError(true)
        return
      }
      setColumnsOnCSV(columnsOnFile!)
    }

    setShowPopup(false)
    setCSV(csv)
    setFile(csv)
  }

  const renderUploadFile = () => {
    return (
      <div className="upload-file">
        <div className="upload-file__header">
          <h2>Upload a CSV file</h2>
          <p>
            Upload a CSV file to create a new table. The first row of the CSV
            file must contain the column names seperated by commas
            <br /> eg.{' '}
            <span
              style={{
                color: '#739570',
                backgroundColor: '#dddcd755',
                padding: '2px 7px',
                borderRadius: '3px',
              }}
            >
              first_column, second_column, third_column
            </span>
            .
            <br />
            <br />
            <span style={{ fontSize: '14px' }}>
              * A schema will be generated automatically from the columns of the
              CSV
            </span>
            .
            <br />
            <br />
            <span style={{ fontSize: '14px' }}>
              Download this{' '}
              <a
                href={`https://old.mayainsights.com/static/files/${category}.csv`}
                target="_blank"
                download
              >
                example csv file
              </a>{' '}
              for reference
            </span>
          </p>
        </div>
        <div className="upload-file__body">
        {!file && (
            <div className="upload-file__body__upload">
              <UploadZone
                fileType="csv"
                uploadFile={handleFileUpload}
                uploadingFile={false}
                clear={clearFiles}
              />
            </div>
        )}
          {file && (
            <>
              <h4>Files</h4>
              <div className="uploaded-file" onClick={handleClearFiles}>
                <div className="upload-file__body__upload__file__name">
                  {file.name}
                </div>
                <button className="clear-btn">&times;</button>
              </div>
            </>
          )}
        </div>
        <div className="upload-file__footer">
          {fileTypeError && (
            <div className="upload-file__body__upload__error">
              Please upload a CSV file
            </div>
          )}
          {emptyFileError && (
            <div className="upload-file__body__upload__error">
              Please upload a CSV file with columns
            </div>
          )}
        </div>
      </div>
    )
  }

  return renderUploadFile()
}

const CustomTable = ({ edit }: { edit: boolean }) => {
  const categoryId = useParams<{ id: string }>()?.id

  const [category, setCategory] = useState<string | null>(null)
  const [csvColumns, setCSVColumns] = useState<Column[]>([])
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const [mayaColumns, setMayaColumns] = useState<Column[]>([])
  const [canSetPrimaryKey, setCanSetPrimaryKey] = useState(true)
  const [isAPIExpanded, setIsAPIExpanded] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [uploadStatus, setUploadStatus] = useState<number | null>(null)

  const [hasDuplicateColumns, setHasDuplicateColumns] = useState(false)
  const [unmappedColumns, setUnmappedColumns] = useState<string[] | null>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const history = useHistory()
  const { currentOrganization } = useContext(OrganizationsContext)
  const [uploadResponse, uploadTable, uploadingTable] =
    useUploadCustomTable(currentOrganization)
  const [, getColumns] = useGetCategoryColumns(currentOrganization)
  const [, getCustomTableColumns, fetchingCustomTableColumns] =
    useGetCustomTableColumns(currentOrganization)
  const [, updateCustomTableColumns] =
    useUpdateCustomTableColumns(currentOrganization)

  const addColumn = () => {
    setCSVColumns([
      ...csvColumns,
      {
        columnName: '',
        alias: '',
        type: TYPE_OPTIONS.string,
        isRequired: false,
        isPrimaryKey: false,
      },
    ])
  }

  const handleChangePrimaryKey = (index: number) => {
    const newColumns = csvColumns.map((column, i) => {
      if (i === index) {
        return { ...column, isPrimaryKey: true }
      }
      return { ...column, isPrimaryKey: false }
    })

    setCSVColumns(newColumns)
  }

  const updateColumns = (
    index: number,
    columnKey: string,
    columnValue: any
  ) => {
    const newColumns = [...csvColumns]

    if (columnKey == 'alias') {
      const typeValue =
        mayaColumns.find((mayaColumn) => mayaColumn.alias === columnValue)
          ?.type || TYPE_OPTIONS.string.toLocaleLowerCase()
      newColumns[index]['type'] = typeValue
    }
    newColumns[index][columnKey] = columnValue
    setCSVColumns(newColumns)
  }

  const handleDeleteColumn = (index: number) => {
    const newColumns = csvColumns.filter((_column, i) => i !== index)
    setCSVColumns(newColumns)
  }

  const checkIsDuplicateColumn = (column: Column) => {
    return csvColumns
      .filter(
        (filteredColumn) => filteredColumn.columnName !== column.columnName
      )
      .some((filteredColumn) => filteredColumn.alias === column.alias)
  }

  const checkUnmappedColumns = () => {
    const unmapped = mayaColumns
      .filter(
        (mayaColumn) =>
          !csvColumns.some((csvColumn) => csvColumn.alias === mayaColumn.alias)
      )
      .map((mayaColumn) => mayaColumn.alias)
    return unmapped
  }

  const testAPIConnection = () => {
    console.log('test connection')
  }

  const saveCustomTable = () => {
    if (csvColumns?.length <= 0) return

    if (uploadError) setUploadError(null)

    const makeChecks = () => {
      let pass = true

      const isDuplicate = csvColumns.some(checkIsDuplicateColumn)
      if (isDuplicate) {
        setHasDuplicateColumns(true)
        pass = false
      } else {
        setHasDuplicateColumns(false)
      }

      const unmapped = checkUnmappedColumns()
      if (unmapped.length > 0) {
        setUnmappedColumns(unmapped)
        pass = false
      } else {
        setUnmappedColumns(null)
      }

      return pass
    }

    const upload = async () => {
      await uploadTable(csvColumns, categoryId, csvFile, setUploadStatus)
    }

    const update = async () => {
      await updateCustomTableColumns(csvColumns, categoryId)
      history.push(`/organization/${currentOrganization?.slug}/sources`)
    }

    if (makeChecks()) {
      if (edit) {
        update()
        return
      } else {
        upload()
      }
    }
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    const fetchMayaColumns = async () => {
      const res = await getColumns(categoryId)
      setMayaColumns(res[categoryId])

      if (edit) {
        const res = await getCustomTableColumns(categoryId)
        setCSVColumns(res[categoryId])
      }
    }
    if (categoryId) {
      fetchMayaColumns()
    }
  }, [categoryId])

  useEffect(() => {
    if (uploadResponse?.success) {
      history.push(`/organization/${currentOrganization?.slug}/sources`)
    } else if (uploadResponse?.success === false) {
      setUploadError(uploadResponse?.detail || 'Error uploading csv')
    }
  }, [uploadResponse])

  useEffect(() => {
    switch (categoryId) {
      case 'manual_orders':
        setCategory('Manual Orders')
        break
      case 'manual_users':
        setCategory('Manual Users')
        break
    }

    if (!mayaColumns) {
      return
    }

    const idx = mayaColumns.findIndex((column: Column) => column.isPrimaryKey)
    setCanSetPrimaryKey(idx === -1 ? true : false)
  }, [categoryId, mayaColumns])

  return (
    <>
      <div className="container">
        <div className="main-header">
          <button onClick={goBack}>
            <span className="material-icons-outlined">arrow_back</span>
          </button>
          <h2>{category}</h2>
        </div>
        <div className="custom-columns">
          <div className="header">
            <div className="table-name">
              <InputField
                label="Table Name"
                value={categoryId}
                placeholder="custom_table_name"
                disabled
              />
            </div>
          </div>
          <div className="main">
            <h3>Columns</h3>
            <div className="column-header">
              <div>
                <p>Column Name</p>
              </div>
              <div>
                <p>Type</p>
              </div>
              <div>
                <p>Final Name</p>
              </div>
              <div>
                <p>Primary Key</p>
              </div>
              <div>
                <p></p>
              </div>
            </div>
            {csvColumns?.length > 0 ? (
              csvColumns.map((column, index) => (
                <Row
                  key={column.columnName}
                  columnName={column.columnName}
                  setColumnName={(columnValue) =>
                    updateColumns(index, 'columnName', columnValue)
                  }
                  finalName={column.alias}
                  setFinalName={(columnValue) =>
                    updateColumns(index, 'alias', columnValue)
                  }
                  type={column.type}
                  setType={(type: string) => updateColumns(index, 'type', type)}
                  columns={mayaColumns}
                  index={index}
                  isChecked={
                    column.isPrimaryKey ||
                    mayaColumns.find(
                      (mayaColumn) => mayaColumn.alias === column.alias
                    )?.isPrimaryKey
                  }
                  check={handleChangePrimaryKey}
                  isRequired={column.isRequired}
                  canSetPrimaryKey={canSetPrimaryKey}
                  canEdit={edit}
                  isDuplicate={checkIsDuplicateColumn(column)}
                  deleteColumn={handleDeleteColumn}
                />
              ))
            ) : (
              <p style={{ textAlign: 'center' }}>No columns</p>
            )}
            {fetchingCustomTableColumns === FetchStatus.Pending && (
              <ScreenLoading />
            )}
            {!edit && (
              <div className="btn-container">
                <button
                  className={`submit-button ${
                    csvColumns?.length <= 0 ? 'disabled' : ''
                  }`}
                  onClick={addColumn}
                  disabled={csvColumns?.length <= 0}
                >
                  Add column
                </button>
                <button
                  className="submit-button upload-csv"
                  onClick={() => setShowPopup(true)}
                >
                  Upload CSV
                </button>
                <Modal show={showPopup} onHide={() => setShowPopup(false)}>
                  <UploadCSV
                    setCSV={setCsvFile}
                    setShowPopup={setShowPopup}
                    columns={csvColumns}
                    setColumns={setCSVColumns}
                    mayaColumns={mayaColumns}
                    category={categoryId}
                  />
                </Modal>
              </div>
            )}
          </div>
          {false && (
            <div className="api">
              <div
                className="api-header"
                onClick={() => setIsAPIExpanded((prev) => !prev)}
              >
                <h3>Advanced</h3>
                <span
                  className={`material-icons-outlined arrow ${
                    isAPIExpanded ? 'rotate' : ''
                  }`}
                >
                  arrow_drop_down
                </span>
              </div>
              {isAPIExpanded && (
                <>
                  <h4>API Connection</h4>
                  <div className="api-fields">
                    <div className="field">
                      <InputField
                        label="API URL"
                        placeholder="https://api.com"
                        type="text"
                      />
                    </div>
                    <div className="field">
                      <InputField
                        label="HTTP Method"
                        placeholder="GET"
                        type="text"
                      />
                    </div>
                    <div className="field">
                      <InputField
                        label="Start Date Parameter"
                        placeholder="start_date"
                        type="text"
                      />
                    </div>
                    <div className="field">
                      <InputField
                        label="End Date Parameter"
                        placeholder="end_date"
                        type="text"
                      />
                    </div>
                    <button
                      className="submit-button"
                      onClick={testAPIConnection}
                    >
                      Test connection
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="save-bar">
        {(hasDuplicateColumns || unmappedColumns !== null) && (
          <p className="error-message">
            {hasDuplicateColumns
              ? 'Please remove duplicate column names' +
                (unmappedColumns !== null
                  ? ` and map column${
                      unmappedColumns.length > 1 ? 's' : ''
                    } ${unmappedColumns!.join(', ')}`
                  : '')
              : `Map column${
                  unmappedColumns!.length > 1 ? 's' : ''
                } ${unmappedColumns!.join(', ')}`}
          </p>
        )}
        {uploadStatus !== null && (
          <ProgressBar loadValue={uploadStatus} progressAlignment="center" showProgress switchWhenDone>
            {uploadError ? (
              <p className="error-message">{uploadError}</p>
            ) : (
              <p style={{color: "#739570"}}>Success</p>
            )}
          </ProgressBar>
        )}
        <button
          className={`submit-button ${
            csvColumns?.length <= 0 ? 'disabled' : ''
          }`}
          onClick={saveCustomTable}
          disabled={csvColumns?.length <= 0}
        >
          {uploadingTable === FetchStatus.Pending ? (
            <ScreenLoading spinnerColor="white" />
          ) : (
            'Save'
          )}
        </button>
      </div>
    </>
  )
}

const Row = ({
  columnName,
  setColumnName,
  finalName,
  setFinalName,
  type,
  setType,
  columns,
  index,
  check,
  isRequired,
  isChecked,
  deleteColumn,
  canSetPrimaryKey,
  canEdit,
  isDuplicate,
}) => {
  const [draftColumnName, setDraftColumnName] = useState(columnName)
  const handleDeleteRow = () => {
    deleteColumn(index)
  }

  return (
    <div className="row" key={index}>
      <div className="field">
        <input
          className="input-field"
          type="text"
          placeholder={columnName || 'column_name'}
          value={draftColumnName}
          disabled
          // TODO: check if is edit. If it is then we can decide if we want to allow editing else we can't (if canEdit)
          // onChange={(e) => isRequired || setDraftColumnName(e.target.value)}
          onBlur={() => {
            if (isRequired) {
              return
            }
            setColumnName(draftColumnName)
            setFinalName(draftColumnName)
          }}
        />
      </div>
      <div className="field">
        <DropDownField
          label=""
          value={type}
          disabled={columns.find((column) => column.alias === finalName && column.isRequired)}
          options={Object.values(TYPE_OPTIONS).map((type) => ({
            value: type.toLocaleLowerCase(),
            label: type,
          }))}
          onChange={(v) => setType(v)}
        />
      </div>
      <div className="field">
        <DropDownField
          label=""
          value={finalName}
          hasError={isDuplicate}
          options={[
            {
              value: columnName,
              label: 'Keep same name',
            },
            ...columns
              .filter((column) => column.columnName !== columnName)
              .map((column: Column) => {
                return {
                  value: column.columnName,
                  label: column.columnName,
                }
              }),
          ]}
          onChange={(v) => setFinalName(v)}
        />
      </div>
      <div className="field">
        <input
          className="checkbox"
          type="checkbox"
          checked={isChecked}
          disabled={!canSetPrimaryKey}
          onChange={() => check(index)}
        />
      </div>
      <div onClick={handleDeleteRow} className="delete-row">
        <span className="material-icons-outlined delete">delete</span>
      </div>
    </div>
  )
}

export default CustomTable
