import React, { useEffect, useRef } from 'react'
import ScreenLoading from './ScreenLoading'
import "./UploadZone.scss"

interface IUploadZoneProps {
    fileType: string
    uploadingFile: boolean
    uploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void
    clear: boolean
}


const UploadZone: React.FC<IUploadZoneProps> = ({fileType, uploadingFile, uploadFile, clear}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (clear && fileInputRef.current !== null) {
      fileInputRef.current.value = null
    }
  }, [clear])

  return (
    <div className="upload-zone">
      {uploadingFile ? (
        <div style={{ height: '30px' }}>
          <ScreenLoading />{' '}
        </div>
      ) : (
        <div id="dropzone">
          <div className="dropzone__title">
            <span className="material-icons arrow non-selectable">
              cloud_upload
            </span>
            Drop file or click to upload
          </div>
          <input
            type="file"
            accept={"." + fileType}
            name="file"
            onChange={uploadFile}
            ref={fileInputRef}
          />
        </div>
      )}
    </div>
  )
}

export default UploadZone