import React, { useContext, useEffect } from 'react'

import ScreenLoading from '../../components/ScreenLoading'

import useCheckout from 'containers/billing/useCheckout'
import useGetSubscription from 'containers/billing/useGetSubscription'
import OrganizationsContext from 'containers/organizations/organizationsContext'

const BillingCheckout = () => {
  const { subscription, getSubscription } = useGetSubscription()

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  const { redirectUrl, getRedirectUrl, checkoutFailed } =
    useCheckout(currentOrganization)

  useEffect(() => {
    const getData = async () => {
      await getSubscription(currentOrganization.id)
    }

    if (!isLoadingOrganization) {
      getRedirectUrl()
      getData()
    }
    // eslint-disable-next-line
  }, [isLoadingOrganization])

  if (checkoutFailed) {
    return (
      <p>
        Starting an order checkout session failed. Please contact support or try
        again later.
      </p>
    )
  }

  if (subscription && subscription.status !== 'canceled') {
    // return <p>Your organization already has a subscription. Use the <a href={`/organization/${currentOrganization.slug}/billing/portal`}>Billing portal</a> instead.</p>
  }

  if (redirectUrl) {
    window.location.href = redirectUrl
  }

  return <ScreenLoading />
}

export default React.memo(BillingCheckout)
