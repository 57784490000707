import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import ScreenLoading from 'components/ScreenLoading'
import useConfirmation from 'containers/confirmation/useConfirmation'
import useDeleteReport from 'containers/embed/useDeleteReport'
import { useHistory } from 'react-router-dom'

const Page = ({ isDuplicateName, reportPage, embedViewMode }) => {
  const ACTIONS = {
    SET_DRAFT_NAME: 'set-draft-name',
    RENAME: 'rename',
    REMOVE: 'remove',
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const setDraftName = (name) =>
    dispatch({ type: ACTIONS.SET_DRAFT_NAME, payload: { draftName: name } })

  const renamePage = useCallback((draftName) =>
    dispatch({ type: ACTIONS.RENAME, payload: { draftName } }), [ACTIONS.RENAME])

  const removePage = () => dispatch({ type: ACTIONS.REMOVE })

  useEffect(() => {
    if (inputRef.current === null) return
    inputRef.current.onblur = (e) => renamePage(e.target!.value)
    inputRef.current.onkeydown = (e) => {
      if (e.key === 'Enter') {
        renamePage(e.target!.value)
      }
    }
  }, [renamePage])

  const reducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.SET_DRAFT_NAME:
        return {
          ...state,
          draftName: action.payload.draftName,
          isEditing: true,
        }
      case ACTIONS.RENAME:
        if (action.payload?.completed) {
          return {
            ...state,
            name: state.draftName,
            isEditing: false,
            isDuplicateName: false,
          }
        }

        if (isDuplicateName(state.name, state.draftName)) {
          return { ...state, isDuplicateName: true }
        }

        ;(async () => {
          await state.page.setDisplayName(state.draftName)
          dispatch({ type: ACTIONS.RENAME, payload: { completed: true } })
        })()

        return { ...state, name: state.draftName }
      case ACTIONS.REMOVE:
        if (action.payload !== null && action.payload?.completed) {
          return { ...state, page: null, isDeleting: false }
        }

        ;(async () => {
          await state.page.delete()
          dispatch({ type: ACTIONS.REMOVE, payload: { completed: true } })
        })()

        return { ...state, isDeleting: true }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    page: reportPage,
    isEditing: false,
    isDeleting: false,
    isDuplicateName: false,
    name: reportPage.displayName,
    draftName: null,
  })

  const PageOptions = ({ setEditing, removePage }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpenOptions = (e) => {
      e.stopPropagation()
      if (e.target === e.currentTarget) {
        if (!isOpen) {
          document.addEventListener('click', handleCloseAll)
        }
        setIsOpen((prev) => !prev)
      }
    }

    const handleCloseAll = () => {
      document.removeEventListener('click', handleCloseAll)
      setIsOpen(false)
    }

    return (
      <div className="options-container">
        <div className="report-controls">
          <span
            className="report-delete material-icons-outlined"
            onClick={handleOpenOptions}
          >
            more_vert
          </span>
          {isOpen && (
            <div className="controls-menu">
              {
                <div className="option" onClick={setEditing}>
                  <span className="viewmode-switch material-icons-outlined rename">
                    drive_file_rename_outline
                  </span>{' '}
                  Rename
                </div>
              }
              <div className="option" onClick={removePage}>
                <span className="report-delete material-icons-outlined">
                  delete
                </span>
                Delete
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  if (state.page === null) return <></>

  return (
    <li
      key={reportPage.name}
      onClick={() => {
        reportPage.setActive()
      }}
      className={`report-page ${reportPage.isActive ? 'active' : ''}`}
    >
      {state.isDeleting ? (
        <ScreenLoading />
      ) : state.isEditing ? (
        <input
          autoFocus
          className={`${state.isDuplicateName && 'error'}`}
          type="text"
          value={state.draftName}
          ref={inputRef}
          onChange={(e) => setDraftName(e.target.value)}
        />
      ) : (
        <p>{state.name}</p>
      )}
      {embedViewMode === 'edit' && (
        <PageOptions
          setEditing={() => setDraftName(state.name)}
          removePage={removePage}
        />
      )}
    </li>
  )
}

const ActiveReport = ({
  reportPages,
  isActiveReportExpanded,
  setIsActiveReportExpanded,
  embedViewMode,
  onReportDelete,
  embedReport,
  report,
  currentOrganization,
  organizationSlug,
}) => {
  const { confirm } = useConfirmation()
  const [deleteReport] = useDeleteReport()
  const history = useHistory()

  const handleDeleteReport = async (e, report) => {
    e.stopPropagation()
    if (
      await confirm(
        `Are you sure you want to permanently delete report ${report.name}? This action cannot be reversed.`
      )
    ) {
      await deleteReport({
        organizationId: currentOrganization.id,
        reportId: report.id,
      })
      onReportDelete()
    }
  }

  const handleSwitchEmbedViewMode = (reportSlug, viewMode) => {
    if (viewMode === 'edit') {
      history.push(
        `/organization/${organizationSlug}/dashboard/${reportSlug}/edit`
      )
    } else {
      history.push(`/organization/${organizationSlug}/dashboard/${reportSlug}/`)
    }
  }

  const handleExpandReport = (e) => {
    e.stopPropagation()
    setIsActiveReportExpanded((prev) => !prev)
  }

  const handleAddPage = async () => {
    if (embedReport !== null) {
      await embedReport.addPage()
    }
  }

  const OptionsMenu = ({ report }) => {
    const [optionsOpen, setOptionsOpen] = useState(false)

    const handleOpenOptions = (e) => {
      e.stopPropagation()
      if (e.target === e.currentTarget) {
        if (!optionsOpen) {
          document.addEventListener('click', handleCloseAll)
        }
        setOptionsOpen((prev) => !prev)
      }
    }

    const handleCloseAll = () => {
      document.removeEventListener('click', handleCloseAll)
      setOptionsOpen(false)
    }

    return (
      <div className="options-container">
        {report &&
          (report.access_level === 'Edit' ||
            report.permission_level === 'All') && (
            <div className="report-controls">
              <span
                className="report-delete material-icons-outlined"
                onClick={handleOpenOptions}
              >
                more_vert
              </span>
              {optionsOpen && (
                <div className="controls-menu">
                  {report.access_level === 'Edit' && (
                    <div
                      className="option"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleSwitchEmbedViewMode(
                          report.slug,
                          embedViewMode === 'edit' ? 'view' : 'edit'
                        )
                      }}
                    >
                      <span className="viewmode-switch material-icons-outlined edit">
                        {embedViewMode === 'edit' ? 'visibility' : 'edit'}
                      </span>{' '}
                      {embedViewMode === 'edit' ? 'View' : 'Edit'}
                    </div>
                  )}
                  {report.permission_level === 'All' && (
                    <div
                      className="option"
                      onClick={(e) => handleDeleteReport(e, report)}
                    >
                      <span className="report-delete material-icons-outlined">
                        delete
                      </span>
                      Delete
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        <span
          onClick={handleExpandReport}
          className={`material-icons-outlined ${
            isActiveReportExpanded && 'rotate'
          }`}
        >
          keyboard_arrow_right
        </span>
      </div>
    )
  }

  const isDuplicateName = (currentName, draftName) => {
    return reportPages.some(
      (page) => currentName !== draftName && page.displayName === draftName
    )
  }

  return (
    <div className="report-container">
      <li
        className={`active-report ${
          isActiveReportExpanded ? 'open' : 'collapsed'
        }`}
        onClick={handleExpandReport}
      >
        <span className="material-icons-outlined">insert_chart</span>
        {report && report.name}
        <OptionsMenu report={report} />
      </li>
      {reportPages.length > 0 ? (
        <ul>
          {reportPages.map((page) => (
            <Page
              key={page.name}
              isDuplicateName={isDuplicateName}
              reportPage={page}
              embedViewMode={embedViewMode}
            />
          ))}
        </ul>
      ) : (
        <ScreenLoading />
      )}
      {embedViewMode === 'edit' && (
        <div className="btn-container">
          <button className="pb-btn" onClick={handleAddPage}>
            Add page<span className="material-icons-outlined">add</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default ActiveReport
