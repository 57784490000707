import { createContext, useContext } from 'react'
import { SubscriptionResponse } from 'types'

const SubscriptionCtx = createContext<SubscriptionResponse>({ features: null })

export function SubscriptionProvider({ value, children }) {
  return <SubscriptionCtx.Provider value={value}>{children}</SubscriptionCtx.Provider>
}

export const useSubscriptionCtx = () => {
  return { ...useContext(SubscriptionCtx) }
}
