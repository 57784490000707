import useStorage from 'containers/useStorage'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import api from '../api'
import { selectToken } from '../authentication/authenticationSlice'

const useCreateOrganization = () => {
  const storage = useStorage()
  const token = useSelector(selectToken)
  const [createOrganizationError, setCreateOrganizationError] = useState('')
  const [isCreatingOrganization, setIsCreatingOrganization] = useState(false)

  const removeCreateOrganizationError = () => {
    setCreateOrganizationError('')
  }

  const createOrganization = async (name: string) => {
    if (!token) {
      return
    }

    if (!name) {
      setCreateOrganizationError('Please enter a valid organization name')
      return
    }

    const request = {
      name: name,
    }

    setIsCreatingOrganization(true)

    const headers = {
      Authorization: `Bearer ${token.accessToken}`,
    }
    try {
      const response = await api.post('/organizations', request, headers)
      const organizationSlug = response.data.slug
      storage.setCurrentOrganization({ organizationSlug })
      setIsCreatingOrganization(false)
      return response
    } catch (error) {
      setCreateOrganizationError('Failed creating organization')
      setIsCreatingOrganization(false)
    }
    return false
  }

  return {
    createOrganization,
    createOrganizationError,
    removeCreateOrganizationError,
    isCreatingOrganization,
  }
}

export default useCreateOrganization
