import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import './CheckoutSuccess.scss'
import mayaLogo from 'images/maya-logo.png'
import usePageTitle from 'containers/pageTitle/usePageTitle'

const CheckoutSuccess = () => {
  //Page title
  usePageTitle('Checkout Success - Maya')

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: 'checkout_success', is_custom_track: true } })
  }, [])

  return (
    <div className="page-column">
      <header>
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
      </header>
      <div className="container-grow">
        <div className="thank-you-box" style={{ textAlign: 'center' }}>
          <h1 style={{ textAlign: 'center' }}>Thank you for your order!</h1>
          <div>
            <a href="https://app.mayainsights.com">Back to dashboard</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CheckoutSuccess)
