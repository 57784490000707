import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { IOrganization } from 'types'

const useDeleteCustomTable = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const deleteTable = async (category: string) => {
    const res = await api.deleteCustomTable(
            token,
            organization.id,
            category,
    )
    return res
  }

  return useDataLoader(deleteTable)
}

export default useDeleteCustomTable
