import React from 'react'
import { Link } from 'react-router-dom'

interface IUpgradeProps {
  style?: React.CSSProperties
  children: any
}

const UpgradeButton: React.FC<IUpgradeProps> = (props) => {
  return (
    <Link to="./billing/products" className="submit-button" style={props.style}>
      {props.children}
    </Link>
  )
}

export default UpgradeButton
