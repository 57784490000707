import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'
import useDataLoader from '../dataLoader'

const useListManualExpenseCategories = (organizationId: number | null) => {
  const token = useSelector(selectToken)
  const fetchManualExpenseCategories = async () => {
    if (organizationId) {
      return await api.listManualExpenseCategories({ token, organizationId })
    }
  }

  return useDataLoader(fetchManualExpenseCategories, [], [])
}

export default useListManualExpenseCategories
