import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'

import { setUser, setToken } from '../authentication/authenticationSlice'

import useValidator from '../authentication/useValidator'

import api, { isHTTPError } from '../api'

import produce from 'immer'
import { setOrganizations } from '../organizations/organizationsSlice'
import useStorage from 'containers/useStorage'

const useSignUp = () => {
  const dispatch = useDispatch()
  const [, setCookie] = useCookies(['user_id'])
  const [signUpError, setSignUpError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyName: '',
    position: '',
    acceptTerms: '',
    server: '',
  })
  const { validateEmail } = useValidator()
  const storage = useStorage()

  const removeError = (errorName) => {
    setSignUpError(
      produce(signUpError, (draftTempSignUpError) => {
        draftTempSignUpError[errorName] = ''
      })
    )
  }

  const removeAllErrors = () => {
    setSignUpError(
      produce(signUpError, (draftTempSignUpError) => {
        Object.keys(signUpError).forEach((key) => {
          draftTempSignUpError[key] = ''
        })
      })
    )
  }

  const validateCredentials = (
    credentials,
    isCorrect,
    step,
    signingUpWithGoogle = false,
    signingUpWithMicrosoft = false
  ) => {
    let hasError = false

    if (step === 3) {
      setSignUpError(
        produce(signUpError, (draftTempSignUpError) => {
          if (credentials.acceptTerms === false) {
            draftTempSignUpError.acceptTerms =
              'You have to accept the Legal Terms & Conditions to continue'
            hasError = true
          }
        })
      )
    }

    if (step === 2) {
      setSignUpError(
        produce(signUpError, (draftTempSignUpError) => {
          const coPattern = /.*\S.*\S.*/gi

          if (!credentials.companyName.match(coPattern)) {
            draftTempSignUpError.companyName =
              'Please enter a valid company name'
            hasError = true
          }

          if (!credentials.position.match(coPattern)) {
            draftTempSignUpError.position = 'Please enter a valid position'
            hasError = true
          }
        })
      )
    }

    if (step === 1 && (!signingUpWithGoogle || !signingUpWithMicrosoft)) {
      setSignUpError(
        produce(signUpError, (draftTempSignUpError) => {
          const namePattern = /^[a-z]{2,}(\s)*/gi

          if (!credentials.firstName.match(namePattern)) {
            draftTempSignUpError.firstName = 'Please enter your Firstname'
            hasError = true
          }

          if (!credentials.lastName.match(namePattern)) {
            draftTempSignUpError.lastName = 'Please enter your Last name'
            hasError = true
          }

          if (!validateEmail(credentials.email)) {
            draftTempSignUpError.email = 'Please enter a valid email address'
            hasError = true
          }

          if (credentials.password === '') {
            draftTempSignUpError.password = 'Please enter a valid password'
            hasError = true
          }
        })
      )
    }

    if (!hasError) {
      isCorrect()
    }
  }

  const signUpWithMicrosoft = async (credentials: any, microsoftAccessToken: string) => {
    try {
      const responseCredentials = await api.signUpWithMicrosoft({
        microsoftAccessToken,
        companyName: credentials.companyName,
        companyPosition: credentials.position,
      })
      storage.clearNewSession()
      setCookie('user_id', responseCredentials.id, { path: '/' })
      dispatch(setOrganizations(null))
      dispatch(
        setUser({
          id: responseCredentials.id,
          email: responseCredentials.email,
          profile: responseCredentials.profile,
        })
      )
      dispatch(
        setToken({
          accessToken: responseCredentials.access_token,
          accessTokenExpiresAt: responseCredentials.access_token_expires_at,
          userId: responseCredentials.id,
        })
      )
    } catch (error) {
      console.log(`Sign Up with Microsoft Error`, error)
    }
  }
  const signUpWithGoogle = async (credentials: any, googleIdToken: string) => {
    try {
      const responseCredentials = await api.signUpWithGoogle({
        googleIdToken,
        companyName: credentials.companyName,
        companyPosition: credentials.position,
      })
      storage.clearNewSession()
      setCookie('user_id', responseCredentials.id, { path: '/' })
      dispatch(setOrganizations(null))
      dispatch(
        setUser({
          id: responseCredentials.id,
          email: responseCredentials.email,
          profile: responseCredentials.profile,
        })
      )
      dispatch(
        setToken({
          accessToken: responseCredentials.access_token,
          accessTokenExpiresAt: responseCredentials.access_token_expires_at,
          userId: responseCredentials.id,
        })
      )
    } catch (error) {
      console.log(`Sign Up with Google Error`, error)
    }
  }

  const signUp = async (credentials: any, handleDuplicateEmail: () => void) => {
    const request = {
      email: credentials.email,
      password: credentials.password,
      first_name: credentials.firstName,
      last_name: credentials.lastName,
      company_name: credentials.companyName,
      company_position: credentials.position,
    }

    try {
      const response = await api.post('/user', request)
      const data = response.data

      storage.clearNewSession()
      setCookie('user_id', data.id, { path: '/' })
      dispatch(setOrganizations(null))
      dispatch(
        setUser({
          id: data.id,
          email: data.email,
          profile: data.profile,
        })
      )
      dispatch(
        setToken({
          accessToken: data.access_token,
          accessTokenExpiresAt: data.access_token_expires_at,
          userId: data.id,
        })
      )
      return response
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 409) {
        setSignUpError(
          produce(signUpError, (draftTempSignUpError) => {
            draftTempSignUpError.email = 'This email address is already in use'
          })
        )
        handleDuplicateEmail()
      }
      return null
    }
  }

  return {
    signUpError,
    signUpWithGoogle,
    signUpWithMicrosoft,
    removeError,
    removeAllErrors,
    validateCredentials,
    signUp,
  }
}

export default useSignUp
