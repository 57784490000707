import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import OrganizationsContext from 'containers/organizations/organizationsContext'
import ScreenLoading from 'components/ScreenLoading'
import Item from 'components/Item'
import PopUpMessage from 'components/PopUpMessage'
import useListManualExpenses from 'containers/expenses/useListManualExpenses'
import useDeleteManualExpense from 'containers/expenses/useDeleteManualExpense'

import './ManualExpensesList.scss'

function shorten(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + '...'
  } else {
    return text
  }
}

const ManualExpensesList = () => {
  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)
  const currentOrganizationId = currentOrganization.id
  const deleteExpenseError = null
  const [expenses, fetchExpenses, fetchExpensesStatus, fetchExpensesError] =
    useListManualExpenses(currentOrganizationId)
  const [deleteManualExpense] = useDeleteManualExpense(currentOrganizationId)
  const history = useHistory()

  useEffect(() => {
    fetchExpenses()
    // eslint-disable-next-line
  }, [currentOrganizationId])

  const handleDeleteExpense = async (uuid: string) => {
    await deleteManualExpense(uuid)
    fetchExpenses()
  }

  const handleAddExpense = () => {
    const newUuid = uuidv4()
    history.push(
      `/organization/${currentOrganization.slug}/expenses/${newUuid}`
    )
  }

  return (
    <div className="clusters-campaigns container-column manual-expenses">
      {deleteExpenseError && (
        <div className="alert alert-danger" role="alert">
          {deleteExpenseError}
        </div>
      )}
      {fetchExpensesError && (
        <div className="alert alert-danger" role="alert">
          {fetchExpensesError}
        </div>
      )}
      <div className="head" style={{ marginBottom: '30px' }}>
        <div className="page-name">
          <h2>Manual Expenses</h2>
          <span className="subtitle-text">
            Record expenses that you want included in your reports, such as
            non-digital marketing expenses.
          </span>
        </div>
        <button onClick={handleAddExpense} className="submit-button">
          <span className="enlarge">+</span>Add expense
        </button>
      </div>
      <>
        {isLoadingOrganization || fetchExpensesStatus === 'pending' ? (
          <ScreenLoading />
        ) : (
          <div id="expenses-table">
            <div className="column is-not-draggable table-title">
              <div className="flex-grow grid">
                <div className="grid-column-1 subtitle-text">Payment Date</div>
                <div
                  className="grid-column-2 subtitle-text"
                  style={{ textAlign: 'right', paddingRight: 80 }}
                >
                  Amount
                </div>
                <div className="grid-column-2 subtitle-text">Category</div>
                <div className="grid-column-2 subtitle-text">
                  Is Marketing Related
                </div>
                <div className="grid-column-2 subtitle-text">Description</div>
              </div>
            </div>
            {expenses.map((expense: any, expenseIndex: number) => {
              return (
                <Item
                  key={expense.uuid}
                  columns={[
                    {
                      value: expense.payment_date,
                      size: 1,
                    },
                    {
                      value: (expense.amount / 100).toLocaleString(undefined, {
                        style: 'currency',
                        currency: expense.currency.code,
                      }),
                      size: 2,
                      valueStyle: { textAlign: 'right', paddingRight: 80 },
                    },
                    {
                      value:
                        expense.category +
                        (expense.subcategory
                          ? ' 〉 ' + expense.subcategory
                          : ''),
                      size: 2,
                    },
                    {
                      value: expense.is_marketing_related ? 'Yes' : 'No',
                      size: 2,
                    },
                    {
                      value: shorten(expense.description, 20),
                      size: 2,
                    },
                    {
                      value: '',
                      size: 1,
                    },
                  ]}
                  linkText="Edit Expense"
                  id={expense.uuid}
                  isDraggable={false}
                  editUrl={`/organization/${currentOrganization.slug}/expenses/${expense.uuid}`}
                  orderIndex={expenseIndex}
                  handleDeleteItem={() => handleDeleteExpense(expense.uuid)}
                  subtitle=""
                />
              )
            })}
          </div>
        )}
      </>
      {localStorage.getItem('manualExpenseSaved') && (
        <PopUpMessage
          onClose={() => localStorage.removeItem('manualExpenseSaved')}
          message={`Manual expense was saved successfully.`}
          time={3000}
        />
      )}
    </div>
  )
}

export default React.memo(ManualExpensesList)
