import React from 'react'
import { INotification, NotificationStatus, NotificationType } from 'types'
import {
  placeNotificationTypeIcon,
  timeElapsedSinceReceived,
} from 'utils/notificationUtils'

export interface INotificationItemProps {
  notification: INotification
  handleClickNotification: (notification: INotification) => void
  handleArchive: (archive: boolean, notification: INotification) => void
  handlePinNotification: (pin: boolean, notification: INotification) => void
}

export const NotificationItem: React.FC<INotificationItemProps> = ({
  notification,
  handleClickNotification,
  handleArchive,
  handlePinNotification,
}) => {
  return (
    <li className="notification" data-unread={notification.status === NotificationStatus.Unread}> 
      <div
        className="item"
        data-status={notification.status}
        data-id={notification.id}
        onClick={() => handleClickNotification(notification)}
      >
        <div className="notification-item__header">
          <div className="notification-item__info">
            <span
              className="material-icons
            "
              data-status={notification.type}
            >
              {placeNotificationTypeIcon(notification.type)}
            </span>
            <p className="notification-item__date">
              {timeElapsedSinceReceived(notification.created_at)}
            </p>
          </div>
          <p className="notification-item__title">{notification.title}</p>
        </div>
        {notification.type !== NotificationType.Persistent ? (
          <button
            onClick={(e) => {
              e.stopPropagation()
              handleArchive(
                notification.status !== NotificationStatus.Archived,
                notification
              )
            }}
            data-tooltip={notification.status !== NotificationStatus.Archived ? "Archive" : "Unarchive"}
            className="action-btn tooltip top" 
          >
            <span className="material-icons-outlined non-selectable">
              {notification.status !== NotificationStatus.Archived
                ? 'archive'
                : 'unarchive'}
            </span>
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.stopPropagation()
              handlePinNotification(!notification.pinned, notification)
            }}
            data-tooltip={notification.pinned ? "Unpin" : "Pin"}
            className="action-btn tooltip top"
          >
            <span
              className={`material-icons${
                notification.pinned ? '' : '-outlined'
              } non-selectable`}
            >
              push_pin{' '}
            </span>
          </button>
        )}
      </div>
    </li>
  )
}
