import api, { isHTTPError } from '../api'
import useDataLoader from '../dataLoader'

const useRequestMergeAccount = () => {
  const requestMergeAccount = async (provider: string, email: string) => {
    try {
        return await api.requestMergeAccountsEmail(provider, email)
      } catch (error) {
        if (isHTTPError(error) && error.response.status === 404) {
          return error.response.data.detail.type
        } else if (isHTTPError(error) && 'detail' in error.response.data) {
            return error.response.data.detail.type
      }
    }
  }

  return useDataLoader(requestMergeAccount, [], [])
}

export default useRequestMergeAccount
