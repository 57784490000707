import { useState } from 'react'

import moment from 'moment'
import Moment from 'react-moment'

import ScreenLoading from 'components/ScreenLoading'
import { IETLlogs, ETLlog } from 'types'

import './LogTable.scss'

type LogTableProps = {
  allLogs: IETLlogs
}

type LogStepProps = {
  log: ETLlog
}

type LogProps = {
  etlDate: Date
  logs: ETLlog[]
}

function Log({ etlDate, logs }: LogProps) {
  const [expanded, setExpanded] = useState(false)

  const minsToString = (minutes) => {
    const seconds = minutes * 60
    const numyears = Math.floor(seconds / 31536000)
    const numDays = Math.floor((seconds % 31536000) / 86400)
    const numHours = Math.floor(((seconds % 31536000) % 86400) / 3600)
    const numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
    return (
      (numyears > 0 ? numyears + (numyears === 1 ? ' year ' : ' years ') : '') +
      (numDays > 0 ? numDays + (numDays === 1 ? ' day ' : ' days ') : '') +
      (numHours > 0 ? numHours + (numHours === 1 ? ' hour ' : ' hours') : '') +
      (numHours > 0 && numMinutes ? ' and ' : '') +
      (numMinutes > 0
        ? numMinutes + (numMinutes === 1 ? ' minute ' : ' minutes ')
        : '')
    )
  }

  let totalRuntimeInMinutes = minsToString(
    logs.reduce((acc, log) => {
      return { ...acc, run_time: acc.run_time + log.run_time }
    }).run_time
  )

  return (
    <>
      <div
        className={`log-head ${expanded ? 'expanded' : ''}`}
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        <span
          className={`material-icons arrow non-selectable ${
            expanded ? 'rotate' : ''
          }`}
        >
          keyboard_arrow_right
        </span>
        <p>Data Load</p>
        <Moment
          local
          fromNow={true}
          title={moment(etlDate).format('YYYY-MM-DD HH:mm:ss')}
        >
          {etlDate}
        </Moment>
        <p className="total-runtime">
          {totalRuntimeInMinutes < 1 ? '< 1' : totalRuntimeInMinutes}{' '}
        </p>
      </div>
      {expanded ? (
        <div className="log-body">
          {logs
            .sort((a, b) => (a.sync_started_at > b.sync_started_at ? 1 : -1))
            .map((log) => {
              return <LogStep log={log}></LogStep>
            })}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

function LogStep({ log }: LogStepProps) {
  return (
    <div className="log-step">
      <div className="log-head">
        <div className="step-container">
          <p>{log.step}</p>
        </div>
        <Moment
          local
          fromNow={true}
          title={moment(log.sync_started_at).format('YYYY-MM-DD HH:mm:ss')}
        >
          {log.sync_started_at}
        </Moment>
        <p className="total-runtime">
          {log.run_time < 1 ? '< 1' : log.run_time}{' '}
          {log.run_time > 1 ? 'minutes' : 'minute'}
        </p>
      </div>
    </div>
  )
}

export default function LogTable({ allLogs }: LogTableProps) {
  if (allLogs === undefined) {
    return <ScreenLoading />
  }

  return (
    <div className="log-table">
      <div className="table-header">
        <p>Step</p>
        <p>Started</p>
        <p>Finished in</p>
      </div>
      {allLogs &&
        Object.entries(allLogs).map((logs) => {
          return <Log etlDate={logs[0]} logs={logs[1]}></Log>
        })}
    </div>
  )
}
