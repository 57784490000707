import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

const useGetOrganizationUsers = () => {
  const token = useSelector(selectToken)

  const [organizationUsers, setOrganizationUsers] = useState()
  const [loadingOrganizationUsers, setLoadingOrganizationUsers] = useState(true)
  const [organizationUsersError, setOrganizationUsersError] = useState(false)

  const getOrganizationUsers = async ({ organization }) => {
    const organizationId = organization.id

    try {
      const responseOrganizationUsers = await api.getOrganizationUsers({
        organizationId,
        token,
      })

      setOrganizationUsers(responseOrganizationUsers)
      setLoadingOrganizationUsers(false)
    } catch {
      setOrganizationUsersError(true)
    }
    setLoadingOrganizationUsers(false)
  }

  return {
    organizationUsers,
    loadingOrganizationUsers,
    organizationUsersError,
    getOrganizationUsers,
  }
}

export default useGetOrganizationUsers
