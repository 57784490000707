import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { IOrganization } from 'types'

const useUnshareDataset = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const unshareDataset = (email: string) => {
    return api.unshareDataset(email, token, organization.id)
  }

  return useDataLoader(unshareDataset)
}

export default useUnshareDataset
