import { ProcessFunc, ProcessResult } from "./FileUploadTypes"

export const getCSVColumns = async (
    file: File,
  ): Promise<ProcessResult> => {
    const streamBlob = file.stream() as unknown as ReadableStream
    const reader = streamBlob.getReader()
    const decoder = new TextDecoder('utf-8')
    let data = ''
  
    const res = await reader
      .read()
      .then<ProcessFunc<any>, any>(function process({ value, done }) {
        if (done) {
         // we return that because if we got to done it means that we searched the whole file and didn't find any columns
          return { success: false, type: 'File read successfully but had no column data', columns: []}
        }
  
        const readableData = decoder.decode(value)
        data += readableData
  
        if (data.includes('\n')) {
          reader.cancel('Columns found on file')
          reader.releaseLock()
  
          return {
            success: true,
            type: `Successfuly found columns ${data.split('\n')[0]}`,
            columns: data.split('\n')[0].trimEnd().split(','),
          }
        }
  
        return reader.read().then(process)
      })
  
    return res
  }