import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'
import { useCookies } from 'react-cookie'
import { useGoogleLogin } from 'react-google-login'
import TagManager from 'react-gtm-module'

import StepsProgress from './StepsProgress'
import Credential from '../../components/Credential'
import { selectToken } from '../../containers/authentication/authenticationSlice'
import useSignUp from '../../containers/user/useSignUp'
import TermsAndConditions from './TermsAndConditions'
import useStorage from 'containers/useStorage'
import { Redirect } from 'components/Redirect'
import DropDownField from 'components/DropDownField'

import mayaLogo from 'images/maya-logo.png'

import './SignUp.scss'
import produce from 'immer'
import useSignIn from 'containers/authentication/useSignIn'
import useQuery from 'containers/useQuery'

import * as rudderanalytics from 'rudder-sdk-js'
import usePageTitle from 'containers/pageTitle/usePageTitle'
import useCheckEmailDomain from './useCheckEmailDomain'
import ScreenLoading from 'components/ScreenLoading'

import companyPositions from './company-positions.json'
import useMicrosoft from 'containers/authentication/useMicrosoft'

const variants = {
  initial: (animationDirection) => {
    return {
      x: animationDirection === -1 ? 70 : -70,
      opacity: 0,
    }
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: (animationDirection) => {
    return {
      x: animationDirection === -1 ? -70 : 70,
      opacity: 0,
    }
  },
}

const inputFieldsStep1 = [
  {
    key: 'firstName',
    label: 'Firstname',
    type: 'text',
    spellcheck: 'false',
  },
  {
    key: 'lastName',
    label: 'Last name',
    type: 'text',
    spellcheck: 'false',
  },
  {
    key: 'email',
    label: 'Email',
    type: 'email',
    spellcheck: 'false',
  },
  {
    key: 'password',
    label: 'Password',
    type: 'password',
    spellcheck: 'false',
  },
]

const inputFieldsStep2 = [
  {
    key: 'companyName',
    label: 'Company Name',
    type: 'text',
    spellcheck: 'false',
  },
]

const whereDidYouFoundUsOptions = [
  'Search engine (Google, Bing, etc.)',
  'Recommended by friend or colleague',
  'Social media',
  'Blog or publication',
  'Other',
]

const SignUpSteps = ({
  step,
  signUpError,
  removeError,
  credentials,
  setCredential,
  foundUsOpen,
  setFoundUsOpen,
  foundUsSelected,
  setFoundUsSelected,
  responseGoogle,
}) => {
  const { signIn } = useGoogleLogin({
    clientId:
      '1017969023466-rndtdtnqk73rujtlk24r1ntbmer4rsjt.apps.googleusercontent.com',
    onSuccess: responseGoogle,
  })

  const { getAuthUri } = useMicrosoft()

  const handleSignUpWithGoogle = () => {
    TagManager.dataLayer({ dataLayer: { event: 'sign_up_step1_google_completed', is_custom_track: true }})
    // rudderanalytics.track('sign_up_step1_google_completed')

    signIn()
  }

  const handleSignUpWithMicrosoft = async () => {
    TagManager.dataLayer({ dataLayer: { event: 'sign_up_step1_google_completed', is_custom_track: true }})
    // rudderanalytics.track('sign_up_step1_google_completed')

    const response = await getAuthUri()
    if (response) {
      window.location.href = response.auth_uri
    }
  }

  const transformPositions = (positions) => {
    return positions.map((position) => {
      return {
        value: position,
        label: position,
      }
    })
  }

  const transformedPositions = useMemo(() => {
    return transformPositions(companyPositions)
  }, [])

  if (step === 1) {
    return (
      <>
        <div>
          <h2 className="text-align-center">Fill your Account info</h2>
          <div className="secondary-text text-align-center"></div>
        </div>
        <div className="container-center container-column sign-up">
          <div className="sign-up-method-container">
            <div onClick={handleSignUpWithGoogle} className="with-google sign-up">
              Sign up with Google
            </div>
            <div onClick={handleSignUpWithMicrosoft} className="with-microsoft sign-up">
              Sign up with Microsoft
            </div>
          </div>
          <div className="container-column width-100">
            {inputFieldsStep1.map((inputField) => (
              <Credential
                key={inputField.key}
                value={credentials[inputField.key]}
                setValue={(value) => setCredential(inputField.key, value)}
                label={inputField.label}
                type={inputField.type}
                spellcheck={inputField.spellcheck}
                error={signUpError[inputField.key]}
                removeError={() => removeError(inputField.key)}
              />
            ))}
          </div>
        </div>
      </>
    )
  }

  const handleCloseDropDown = () => {
    setFoundUsOpen(false)
    document.removeEventListener('click', handleCloseDropDown)
  }

  const handleClickDropDown = (e) => {
    e.stopPropagation()
    if (!foundUsOpen) {
      document.addEventListener('click', handleCloseDropDown)
    }
    setFoundUsOpen(!foundUsOpen)
  }

  const handleChooseOption = (optionIndex) => {
    setFoundUsSelected(optionIndex)
    setFoundUsOpen(false)
  }

  if (step === 2) {
    return (
      <>
        <div>
          <h2 className="text-align-center">Fill your company info</h2>
          <div className="secondary-text text-align-center">
            Let us know some information about your company
          </div>
        </div>
        <div className="container-center container-column sign-up">
          <div className="container-column width-100">
            {inputFieldsStep2.map((inputField) => (
              <Credential
                key={inputField.key}
                value={credentials[inputField.key]}
                setValue={(value) => setCredential(inputField.key, value)}
                label={inputField.label}
                type={inputField.type}
                spellcheck={inputField.spellcheck}
                error={signUpError[inputField.key]}
                removeError={() => removeError(inputField.key)}
              />
            ))}
            <DropDownField
              label="Position"
              options={transformedPositions}
              value={credentials['position'] || ''}
              onChange={(value) => {
                if (value.length > 0) {
                  removeError('position')
                }
                setCredential('position', value)
              }}
              hasError={signUpError['position'].length > 0}
            />
            <div className="drop-down-select">
              <label>Where did you find about us?</label>
              <div onClick={handleClickDropDown} className="select">
                <div>
                  {foundUsSelected !== null
                    ? whereDidYouFoundUsOptions[foundUsSelected]
                    : 'Select the answer'}
                </div>
                <span className="material-icons arrow non-selectable">
                  keyboard_arrow_down
                </span>
                {foundUsOpen && (
                  <ul className="drop-down-options">
                    {whereDidYouFoundUsOptions.map((option, optionIndex) => (
                      <li
                        className={`${
                          optionIndex === foundUsSelected ? 'selected' : ''
                        }`}
                        key={optionIndex}
                        onClick={() => handleChooseOption(optionIndex)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const handleClickCheck = () => {
    if (signUpError.acceptTerms) {
      removeError('acceptTerms')
    }
    setCredential('acceptTerms', !credentials.acceptTerms)
  }

  return (
    <>
      <div>
        <h2 className="text-align-center"> Legal Terms & Conditions</h2>
      </div>
      <div className="container-center container-column terms">
        <div className="terms-box">
          <TermsAndConditions />
        </div>
        <div
          onClick={handleClickCheck}
          className={`checkbox-input ${signUpError.acceptTerms ? 'error' : ''}`}
        >
          <input
            type="checkbox"
            checked={credentials.acceptTerms}
            readOnly
            data-lpignore="true"
          />
          <label>I agree to the terms and conditions</label>
        </div>
        <a href="/" className="no-accept">
          I do not accept
        </a>
      </div>
    </>
  )
}

const SignUp = () => {
  const token = useSelector(selectToken)
  const [cookies] = useCookies(['user_id'])
  const [step, setStep] = useState(1)
  const query = useQuery()
  const [credentials, setCredentials] = useState({
    firstName: '',
    lastName: '',
    email: query.get('email') || '',
    password: '',
    companyName: '',
    position: '',
    acceptTerms: false,
  })
  let location = useLocation()

  const [foundUsSelected, setFoundUsSelected] = useState(null)
  const [foundUsOpen, setFoundUsOpen] = useState(false)

  const [signingUpWithGoogle, setSigningUpWithGoogle] = useState(false)
  const [signingUpWithMicrosoft, setSigningUpWithMicrosoft] = useState(false)
  const [googleIdToken, setGoogleIdToken] = useState()
  const [microsoftAccessToken, setMicrosoftAccessToken] = useState()

  const setCredential = (key, value) => {
    setCredentials(
      produce(credentials, (draftCredentials) => {
        draftCredentials[key] = value
      })
    )
  }

  const [animationDirection, setAnimationDirection] = useState(1)
  const [redirectToSignIn, setRedirectToSignIn] = useState(false)
  const [loadingNextStep, setLoadingNextStep] = useState(false)

  const {
    signUpError,
    signUpWithGoogle,
    signUpWithMicrosoft,
    removeError,
    removeAllErrors,
    validateCredentials,
    signUp,
  } = useSignUp()
  const { signInWithGoogle, signInWithMicrosoft } = useSignIn()
  const [checkEmailDomain] = useCheckEmailDomain()
  const storage = useStorage()

  //Page title
  usePageTitle('Sign Up - Maya')

  useEffect(() => {
    if (location.state) {
      if (location.state.googleIdToken) {
      setGoogleIdToken(location.state.googleIdToken)
      setSigningUpWithGoogle(true)
      removeAllErrors()
      setAnimationDirection(-1)
      setStep(2)
    }
    else if (location.state.microsoftAccessToken) {
      setMicrosoftAccessToken(location.state.microsoftAccessToken)
      setSigningUpWithMicrosoft(true)
      removeAllErrors()
      setAnimationDirection(-1)
      setStep(2)
    }
  }
    // eslint-disable-next-line
  }, [location])

  const checkEmailContinue = async () => {
    const emailDomainExists = await checkEmailDomain(credentials.email)

    if (emailDomainExists === null) {
      setStep(2)
    }

    if (!emailDomainExists) {
      let companyName = credentials.email.split('.')[0].split('@')[1]
      companyName = companyName.slice(0, 1).toUpperCase() + companyName.slice(1)
      setCredential('companyName', companyName)
    }

    setStep(2)
  }

  const handleDuplicateEmail = () => {
    setAnimationDirection(1)
    setStep(1)
  }

  const handleContinue = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (step === 1) {
      const traits = {
        name: credentials.firstName + ' ' + credentials.lastName,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        email: credentials.email,
      }
      TagManager.dataLayer({ dataLayer: { 
        event: 'sign_up_step1_email_completed',
        is_custom_track: true,
        event_params: {
          name: credentials.firstName + ' ' + credentials.lastName,
          firstName: credentials.firstName,
          lastName: credentials.lastName,
          email: credentials.email,
        }
      }})
      // rudderanalytics.track('sign_up_step1_email_completed', traits)

      setAnimationDirection(-1)
      validateCredentials(credentials, checkEmailContinue, 1)
      setLoadingNextStep(false)
    }
    if (step === 2) {
      const traits = {
        position: credentials.position,
        company: credentials.companyName,
      }
      TagManager.dataLayer({ dataLayer: { 
        event: 'sign_up_step2_completed',
        is_custom_track: true,
        event_params: {
          position: credentials.position,
          company: credentials.companyName,
        }
      }})
      // rudderanalytics.track('sign_up_step2_completed', traits)

      setAnimationDirection(-1)
      validateCredentials(credentials, () => setStep(3), 2)
      setLoadingNextStep(false)
    }
    if (step === 3) {
      const appropriateSignUp = async () => {
        let res = null
        if (signingUpWithGoogle) {
          res = await signUpWithGoogle(credentials, googleIdToken)
        } else if (signingUpWithMicrosoft) {
          res = await signUpWithMicrosoft(credentials, microsoftAccessToken)
        } else {
          res = await signUp(credentials, handleDuplicateEmail)
        }
        if (res) {
          TagManager.dataLayer({ dataLayer: { event: 'sign_up_completed', is_custom_track: true } })
          // rudderanalytics.track('sign_up_completed')
        }
      }
      validateCredentials(credentials, appropriateSignUp, 3)
    }
  }
  

  const responseGoogle = async (response) => {
    if (await signInWithGoogle({ google_id_token: response.tokenId })) {
      return
    }

    setGoogleIdToken(response.tokenId)
    setSigningUpWithGoogle(true)
    removeAllErrors()
    setAnimationDirection(-1)
    setStep(2)
  }

  const responseMicrosoft = async (response) => {
    if (await signInWithMicrosoft({ code: response.code })) {
      return
    }

    setMicrosoftAccessToken(response.code)
    setSigningUpWithMicrosoft(true)
    removeAllErrors()
    setAnimationDirection(-1)
    setStep(2)
  }

  const handleClickStep = (goToStep) => {
    if (goToStep === step) {
      return
    }
    if (goToStep === 1) {
      if (step === 2) {
        setSigningUpWithGoogle(false)
        setSigningUpWithMicrosoft(false)
      }
      setAnimationDirection(1)
      setStep(1)
    }
    if (goToStep === 2) {
      const direction = step === 1 ? -1 : 1
      setAnimationDirection(direction)
      validateCredentials(credentials, () => setStep(2), 1, signingUpWithGoogle, signingUpWithMicrosoft)
    }
    if (goToStep === 3) {
      setAnimationDirection(-1)
      validateCredentials(
        credentials,
        () => {
          setStep(2)
          validateCredentials(credentials, () => setStep(3), 2)
        },
        1,
        signingUpWithGoogle,
        signingUpWithMicrosoft
      )
    }
  }

  const handleBack = () => {
    setAnimationDirection(1)
    if (step === 2) {
      setSigningUpWithGoogle(false)
      setSigningUpWithMicrosoft(false)
    }
    setStep(step - 1)
  }

  if (token && cookies.user_id && token.userId === parseInt(cookies.user_id)) {
    const url = storage.getUrlWhenLoggedIn()
    storage.removeUrlWhenLoggedIn()
    if (url) {
      return <Redirect to={url}></Redirect>
    }

    return <Redirect to={'/'}></Redirect>
  }

  if (redirectToSignIn) {
    return <Redirect to={'/sign-in'}></Redirect>
  }

  return (
    <div className="sign-up">
      <header className="header-container">
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
        <div className="step-progress-bar hide-mobile">
          <StepsProgress currentStep={step} handleClickStep={handleClickStep} />
        </div>
      </header>
      <form onSubmit={handleContinue} className="sign-up-container" noValidate>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="form-wrapper"
        >
          <AnimatePresence>
            {step !== 1 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.1,
                }}
              >
                <span
                  onClick={handleBack}
                  className="material-icons back-arrow sign-up non-selectable"
                >
                  arrow_back
                </span>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence
            initial={false}
            custom={animationDirection}
            exitBeforeEnter
          >
            <motion.div
              key={step}
              custom={animationDirection}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{
                damping: 10,
                stiffness: 100,
                duration: 0.1,
              }}
            >
              <SignUpSteps
                step={step}
                signUpError={signUpError}
                removeError={removeError}
                credentials={credentials}
                setCredential={setCredential}
                foundUsOpen={foundUsOpen}
                setFoundUsOpen={setFoundUsOpen}
                foundUsSelected={foundUsSelected}
                setFoundUsSelected={setFoundUsSelected}
                responseGoogle={responseGoogle}
              />
            </motion.div>
          </AnimatePresence>
          <div className="container-center container-grow">
            <button type="submit" className="submit-button" onClick={() => setLoadingNextStep(true)} >
              {loadingNextStep ? (
                <ScreenLoading spinnerColor="white" spinnerWidth='lg'/>
              ) : (
                'Continue'
              )}
            </button>
          </div>
          <div className="text-align-center secondary-text sign-up-footer">
            Already have an account? Then please{' '}
            <button className="link" onClick={() => setRedirectToSignIn(true)}>
              sign in.
            </button>
          </div>
        </motion.div>
      </form>
    </div>
  )
}

export default React.memo(SignUp)
