import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useStorage from 'containers/useStorage'
import { useCookies } from 'react-cookie'

import { selectToken } from 'containers/authentication/authenticationSlice'
import useToken from 'containers/authentication/useToken'
import ScreenLoading from 'components/ScreenLoading'

import { Redirect } from 'components/Redirect'
import useLogout from 'containers/authentication/useLogout'
import useAcceptInvite from 'containers/invites/useAcceptInvite'
import useGetInviteAddress from 'containers/invites/useGetInviteAddress'
import useGetOrganizations from 'containers/organizations/useGetOrganizations'

import 'pages/Invites/AcceptInvite.scss'

const AcceptInvite = (props) => {
  const [cookies] = useCookies(['user_id'])
  const userId = parseInt(cookies.user_id)
  const { organizationSlug, inviteId } = useParams()
  const { clearOrganizationsCache } = useGetOrganizations()
  let history = useHistory()
  const logOut = useLogout()
  const { isLoadingToken } = useToken()
  const token = useSelector(selectToken)
  const storage = useStorage()

  const { acceptInvite, inviteError, inviteFailed } = useAcceptInvite()
  const [inviteAddress, getInviteAddress, loadingInviteAddressStatus] =
    useGetInviteAddress(inviteId)

  const verifyInvite = async () => {
    if (await acceptInvite({ organizationSlug, inviteId })) {
      clearOrganizationsCache()
      history.push(`/organization/${organizationSlug}/sources`)
    }
  }

  useEffect(() => {
    try {
      getInviteAddress()
    } catch {
      // ignore errors here, its only for email address indication to signup
      // errors during acceptance are handled further down
    }
    // eslint-disable-next-line
  }, [inviteId])

  useEffect(() => {
    if (!isLoadingToken && token) {
      verifyInvite()
    }
    // eslint-disable-next-line
  }, [isLoadingToken, token])

  if (isLoadingToken || loadingInviteAddressStatus === 'pending') {
    return <ScreenLoading />
  }

  if (!token || !userId || userId !== token.userId) {
    storage.setUrlWhenLoggedIn({ url: props.location.pathname })

    return (
      <Redirect
        to={{
          pathname: '/sign-up',
          search:
            '?email=' +
            (inviteAddress ? encodeURIComponent(inviteAddress) : ''),
          state: {
            from: props.location,
          },
        }}
      />
    )
  }

  let errorMessage = 'There was an issue with this invite'

  if (inviteFailed && inviteError) {
    switch (inviteError.type) {
      case 'invitation_expired':
        errorMessage = 'Invitation expired'
        break
      case 'invitation_forbidden':
        errorMessage =
          'This invitation is for a different user. Please login or signup with the correct email address (' +
          inviteError.invitation_email +
          ').'
        break
      case 'invitation_does_not_exist':
        errorMessage =
          'Invitation not found, please check you are using the correct link.'
        break
      default:
        break
    }
  }
  if (inviteFailed) {
    return (
      <div className="invite-page">
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
        <div className="actions">
          {inviteFailed &&
            inviteError &&
            inviteError.type === 'invitation_forbidden' && (
              <button className="submit-button" onClick={logOut}>
                Login with different account
              </button>
            )}
        </div>
      </div>
    )
  }

  return <ScreenLoading />
}

export default React.memo(AcceptInvite)
