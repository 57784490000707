import React, { useEffect, useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { useCookies } from 'react-cookie'
import { useGoogleLogin } from 'react-google-login'
import useStorage from 'containers/useStorage'

import Credential from '../../components/Credential'
import { selectToken } from '../../containers/authentication/authenticationSlice'
import useSignIn from '../../containers/authentication/useSignIn'
import { Redirect } from 'components/Redirect'

import * as rudderanalytics from 'rudder-sdk-js'

import mayaLogo from 'images/maya-logo.png'

import './SignIn.scss'
import usePageTitle from 'containers/pageTitle/usePageTitle'
import ScreenLoading from 'components/ScreenLoading'
import useMicrosoft from 'containers/authentication/useMicrosoft'
import PopUpMessage from 'components/PopUpMessage'
import TagManager from 'react-gtm-module'

const SignIn = () => {
  const token = useSelector(selectToken)
  const [cookies] = useCookies(['user_id'])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loadingSignIn, setLoadingSignIn] = useState(false)
  let history = useHistory()
  const storage = useStorage()

  const {
    signInError,
    removeError,
    signInWithGoogle,
    signInWithEmailPassword,
  } = useSignIn()

  //Page title
  usePageTitle('Sign In - Maya'); 

  useEffect(() => {
    if (signInError?.details?.type === "merge_required" || signInError?.details?.type === "no_password_set") {
      history.push({
        pathname: `merge-account`,
        search: `?email=${signInError.details.email}&provider=${signInError.details.provider}&is_google_signin=${signInError.details.is_google_signin}&is_microsoft_signin=${signInError.details.is_microsoft_signin}&is_password_signin=${signInError.details.is_password_signin}`,
        state: {
          googleIdToken: signInError.details.google_id_token,
        }
      })
    }
  }, [signInError])

  const responseGoogle = async (response) => {
    if (!(await signInWithGoogle({ google_id_token: response.tokenId }))) {
      history.push({
        pathname: '/sign-up',
        state: {
          googleIdToken: response.tokenId,
        },
      })
    }
  }

  const { signIn } = useGoogleLogin({
    clientId:
      '1017969023466-rndtdtnqk73rujtlk24r1ntbmer4rsjt.apps.googleusercontent.com',
    onSuccess: responseGoogle,
  })

  const {getAuthUri} = useMicrosoft()

  const handleSignInWithMicrosoft = async () => {
    const response = await getAuthUri()
    if (response) {
      window.location.href = response.auth_uri
    }
    // handle error
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setLoadingSignIn(true)
    await signInWithEmailPassword({ email, password })
    TagManager.dataLayer({ dataLayer: { event: 'sign_in_completed', is_custom_track: true }})
    // rudderanalytics.track('sign_in_completed')
  }

  if (token && cookies.user_id && token.userId === parseInt(cookies.user_id)) {
    const url = storage.getUrlWhenLoggedIn()
    storage.removeUrlWhenLoggedIn()
    if (url) {
      return <Redirect to={url}></Redirect>
    }

    return <Redirect to={'/'}></Redirect>
  }

  const redirectToSignUp = () => {
    history.push('/sign-up')
  }

  return (
    <div className="page-column">
      <header>
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
      </header>
      <div className="container-grow">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="container-column sign-in-box"
        >
          <div className="title-container">
            <h1 className="text-align-center">Sign In</h1>
            <div className="secondary-text text-align-center">
              If you have not created an account yet, then please{' '}
              <button className="link" onClick={redirectToSignUp}>
                sign up.
              </button>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="container-grow container-column sign-in"
            noValidate
          >
            <div onClick={signIn} className="with-google" style={{marginBottom: "10px"}}>
              Sign in with Google
            </div>
            <div onClick={handleSignInWithMicrosoft} className="with-microsoft ">
              Sign in with Microsoft
            </div>
            <div className="or">OR</div>
            <div className="container-column width-100 credentials">
              <Credential
                value={email}
                setValue={setEmail}
                error={signInError.email}
                removeError={() => removeError('email')}
                label="Email"
                type="email"
                spellcheck="false"
              />
              <Credential
                value={password}
                setValue={setPassword}
                error={signInError.password}
                removeError={() => removeError('password')}
                label="Password"
                type="password"
              />
              {
                <NavLink to="/forgot-password" className="form-link">
                  Forgot Password?
                </NavLink>
              }
            </div>
            {signInError.server && (
              <span className="server-error">{signInError.server}</span>
            )}
            <button type="submit" className="submit-button">
              {loadingSignIn ? (
                <ScreenLoading spinnerColor="white" />
              ) : (
                'Sign In'
              )}
            </button>
          </form>
        </motion.div>
      </div>
    </div>
  )
}

export default React.memo(SignIn)
