import OrganizationsContext from 'containers/organizations/organizationsContext'
import useUnshareDataset from 'containers/sharedDataset/useUnshareDataset'
import { useContext } from 'react'
import './Dataset.scss'

const UnshareDatasetTable = ({ users, getUsers }) => {
  const { currentOrganization } = useContext(OrganizationsContext)
  const [, unshareDataset] = useUnshareDataset(currentOrganization)

  const unshareEmail = async (email) => {
    await unshareDataset(email)
    await getUsers()
  }

  return (
    <div>
      <table className="unshare-dataset-table">
        <thead>
          <tr>
            <th>Members</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users !== null && users.length > 0
            ? users.map((user) => {
                return (
                  <tr>
                    <td>{user.email}</td>
                    <td onClick={() => unshareEmail(user.email)}>
                      <span className="material-icons-outlined non-selectable">
                        delete
                      </span>
                    </td>
                  </tr>
                )
              })
            : <tr><td>No data</td></tr>}
        </tbody>
      </table>
    </div>
  )
}

export default UnshareDatasetTable
