import { Redirect as ReactRedirect } from 'react-router-dom'

export const Redirect = ({ to, ...rest }) => {
  const loc = window.location.toString().split('?')[1]

  to = redirectWithParams(to, loc)

  return (
    <>
      <ReactRedirect to={to} {...rest} />
    </>
  )
}

function redirectWithParams(to, windowLoc) {
  const windowLocParams = new URLSearchParams(windowLoc);
  let pathname, params;

  if (typeof to === 'string') {
    pathname = to.split('?')[0];
    params = new URLSearchParams(to.split('?')[1]);
  } else if (typeof to === 'object') {
    pathname = to.pathname;
    if ('search' in to && to.search.length > 0) {
      params = new URLSearchParams(to.search);
    } else {
      params = new URLSearchParams();
    }
  } else {
    throw new Error('Unexpected to argument type, should be string or object');
  }

  // Iterate over all parameters in windowLocParams and set them in params
  for (const [key, value] of windowLocParams.entries()) {
    params.set(key, value);
  }

  pathname += params.toString().length > 0 ? '?' + params.toString() : '';

  return pathname || '/';
}
