import { useEffect, useState } from 'react'
import './NotificationBell.scss'
import { useNotificationContext } from 'containers/notifications/NotificationContext'
import {
  ActionStatus,
  INotification,
  INotificationFilter,
  IOrganization,
  NotificationStatus,
  NotificationType,
} from 'types'
import { NavLink, useHistory } from 'react-router-dom'
import NotificationList from 'pages/Notifications/NotificationList'
import useElementOnScreen from 'containers/useElementOnScreen'

interface INotificationBellProps {
  currentOrganization: IOrganization
}

const NotificationBell: React.FC<INotificationBellProps> = ({
  currentOrganization,
}) => {
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false)
  // const [newNotifications, setNewNotifications] = useState(false)
  const [latestNotificationCount, setLatestNotificationCount] = useState(0)
  const {
    notifications,
    notificationCursor,
    updateNotification,
    getNotificationCount,
    nextNotifications, 
  } = useNotificationContext()
  const { elementRef, isVisible } = useElementOnScreen({
    root: null,
    rootMargin: '10px',
    threshold: 1.0,
  })
  const history = useHistory()

  // update newNotifications when new notifications arrive
  const findUnreadNotifications = (notifications: INotification[] | null) => {
    if (notifications === null) {
      return []
    }
    return notifications.filter(
      (notification) =>
        ![NotificationStatus.Read, NotificationStatus.Archived].includes(
          notification.status
        )
    )
  }

  const handleClickNotification = (notification: INotification) => {
    // redirect to notifications and open preview notification
    history.push({
      pathname: `/organization/${currentOrganization.slug}/notifications`,
      state: {
        selectedPreview: notification,
      },
    })
    if (
      notification.type === NotificationType.Persistent &&
      notification.action?.status !== ActionStatus.FULFILLED
    ) {
      return
    } else {
      handleMarkAsReadNotification(notification)
    }
  }

  const handleClickArchive = (archive, notification: INotification) => {
    // handle archive notification from notification panel
    handleArchiveNotification(archive, notification)
  }

  const handleMarkAsReadNotification = (notification: INotification) => {
    if (notification.status !== NotificationStatus.Unread) {
      return
    }
    const updateParams: INotificationFilter = {
      notificationId: notification.id,
      status: NotificationStatus.Read,
    }
    if (updateNotification) updateNotification(updateParams)
  }

  const handleArchiveNotification = (
    archive: boolean = true,
    notification: INotification
  ) => {
    const updateParams: INotificationFilter = {
      notificationId: notification.id,
      status: archive ? NotificationStatus.Archived : NotificationStatus.Read,
    }
    if (updateNotification) updateNotification(updateParams)
  }

  const handlePinNotification = (pin: boolean, notification: INotification) => {
    const updateParams: INotificationFilter = {
      notificationId: notification.id,
      pinned: pin,
    }
    if (updateNotification) updateNotification(updateParams)
  }

  const handleClickBell = (e) => {
    e.stopPropagation()

    if (window.innerWidth <= 500) {
      history.push({
        pathname: `/organization/${currentOrganization.slug}/notifications`,
      })
      return
    }

    if (!openNotificationMenu) {
      document.addEventListener('click', handleCloseAll)
    }
    setOpenNotificationMenu((prev) => !prev)
    // setNewNotifications(false)
  }

  const handleCloseAll = () => {
    document.removeEventListener('click', handleCloseAll)
    setOpenNotificationMenu(false)
  }

  useEffect(() => {
    const fecthNotificationCount = async () => {
      if (getNotificationCount) {
        const notificationCount = await getNotificationCount()
        setLatestNotificationCount(notificationCount || 0)
      }
    }
    fecthNotificationCount()
  }, [notifications, getNotificationCount])

  useEffect(() => {
    if (isVisible) {
      if (nextNotifications) nextNotifications()
    }
}, [isVisible])

  return (
    <div className="notification-bell">
      <button
        className="bell"
        data-new-notifications={latestNotificationCount || 0}
        onClick={handleClickBell}
      >
        {latestNotificationCount && latestNotificationCount > 0 ? (
          <span className="material-icons">notifications</span>
        ) : (
          <span className="material-icons-outlined">notifications_none</span>
        )}
      </button>
      {openNotificationMenu && (
        <div className="notification-menu">
          <div className="notification-container">
            <h2>Latest Unread</h2>
            <NotificationList
              handleArchiveNotification={handleClickArchive}
              handleClickNotification={handleClickNotification}
              handlePinNotification={handlePinNotification}
              hasMoreNotifications={notificationCursor !== null}
              triggerElementRef={elementRef}
              notifications={
                notifications &&
                notifications?.filter(
                  (notification) =>
                    notification.status === NotificationStatus.Unread
                )
              }
              selectedFilter={NotificationStatus.Unread}
            />

            <h2>Previous</h2>
            <NotificationList
              handleArchiveNotification={handleClickArchive}
              handleClickNotification={handleClickNotification}
              handlePinNotification={handlePinNotification}
              hasMoreNotifications={false}
              triggerElementRef={null}
              notifications={
                notifications &&
                notifications.filter(
                  (notification) =>
                    notification.status === NotificationStatus.Read
                )
              }
              selectedFilter={NotificationStatus.Read}
            />
          </div>
          <NavLink
            to={`/organization/${currentOrganization.slug}/notifications`}
          >
            See all
          </NavLink>
        </div>
      )}
    </div>
  )
}

export default NotificationBell
