import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { useCookies } from 'react-cookie'

import useStorage from 'containers/useStorage'
import Credential from '../../components/Credential'
import { selectToken } from '../../containers/authentication/authenticationSlice'
import useForgotPassword from 'containers/authentication/useForgotPassword'
import { Redirect } from 'components/Redirect'

import mayaLogo from 'images/maya-logo.png'

import './ForgotPassword.scss'
import ScreenLoading from 'components/ScreenLoading'
import usePageTitle from 'containers/pageTitle/usePageTitle'

const ForgotPassword = () => {
  const token = useSelector(selectToken)
  const [cookies] = useCookies(['user_id'])
  const [email, setEmail] = useState('')
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const storage = useStorage()

  const {
    forgotPasswordError,
    loadingForgotPassword,
    removeForgotPasswordError,
    requestPasswordReset,
  } = useForgotPassword()

  //Page title
  usePageTitle('Forgot Password - Maya')

  const handleSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHasSubmitted(await requestPasswordReset({ email }))
  }

  if (token && cookies.user_id && token.userId === parseInt(cookies.user_id)) {
    const url = storage.getUrlWhenLoggedIn()
    storage.removeUrlWhenLoggedIn()
    if (url) {
      return <Redirect to={url}></Redirect>
    }

    return <Redirect to={'/'}></Redirect>
  }

  return (
    <div className="page-column">
      <header>
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
      </header>
      <div className="container-grow">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="container-column forgot-password-box"
        >
          <div>
            <h1 className="text-align-center">Forgot Password</h1>
            <div className="secondary-text text-align-center">
              We will send you an email with a link to reset your password
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="container-grow container-column sign-in"
            noValidate
          >
            <div className="container-column width-100 credentials">
              <Credential
                value={email}
                setValue={setEmail}
                error={forgotPasswordError.email}
                removeError={() => removeForgotPasswordError('email')}
                label="Email"
                type="email"
                spellcheck="false"
              />
            </div>
            {forgotPasswordError.server && (
              <span className="server-error">{forgotPasswordError.server}</span>
            )}
            <div className="submit-forgot-password">
              {hasSubmitted ? (
                <span className="material-icons-outlined green has-submitted">
                  check_circle
                </span>
              ) : (
                <>
                  {loadingForgotPassword ? (
                    <ScreenLoading />
                  ) : (
                    <button type="submit" className="submit-button">
                      Send email
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
          <div className="text-align-center secondary-text sign-up-footer">
            If you remember your password then please{' '}
            <NavLink className="link" to="/sign-in">
              sign in.
            </NavLink>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default React.memo(ForgotPassword)
