import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api from 'containers/api'

const useSaveWordGroups = () => {
  const token = useSelector(selectToken)

  const [isSavingWordGroups, setIsSavingWordGroups] = useState(false)
  const [saveWordGroupsError, setSaveWordGroupsError] = useState(false)

  const saveWordGroups = async ({ organization, state }) => {
    setIsSavingWordGroups(true)
    try {
      await api.saveWordGroups({
        organizationId: organization.id,
        token,
        state,
      })

      setIsSavingWordGroups(false)
      return true
    } catch (error) {
      setSaveWordGroupsError(true)
      setIsSavingWordGroups(false)
      return false
    }
  }

  return { isSavingWordGroups, saveWordGroupsError, saveWordGroups }
}

export default useSaveWordGroups
