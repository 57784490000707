import React from 'react'
import { INotification, NotificationStatus } from 'types'
import { NotificationItem } from './NotificationItem'
import ScreenLoading from 'components/ScreenLoading'

interface INotificationListProps {
  notifications: INotification[] | null
  selectedFilter: NotificationStatus
  triggerElementRef: React.RefObject<HTMLElement> | null
  hasMoreNotifications: boolean
  handleClickNotification: (notification: INotification) => void
  handleArchiveNotification: (
    archive: boolean | undefined,
    notification: INotification
  ) => void
  handlePinNotification: (pin: boolean, notification: INotification) => void
}

const NotificationList: React.FC<INotificationListProps> = ({
  notifications,
  selectedFilter,
  triggerElementRef,
  hasMoreNotifications,
  handleArchiveNotification,
  handleClickNotification,
  handlePinNotification,
}) => {
  return (
    <ul className="notification-list">
      {notifications !== null && notifications?.length > 0 ? (
        notifications.map((notification: INotification) => {
          if (
            notification.status === selectedFilter ||
            (selectedFilter === NotificationStatus.All &&
              notification.status !== NotificationStatus.Archived)
          ) {
            return (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleClickNotification={handleClickNotification}
                handleArchive={handleArchiveNotification}
                handlePinNotification={handlePinNotification}
              />
            )
          }
        })
      ) : (
        <li>No notifications</li>
      )}
      {hasMoreNotifications && (
        <div className="next-notification-trigger" ref={triggerElementRef}>
          <ScreenLoading spinnerWidth="sm" />
        </div>
      )}
    </ul>
  )
}

export default NotificationList
