import React, { useEffect, useRef, useState } from 'react'
import './NotificationSummary.scss'
import {
  ActionStatus,
  INotification,
  INotificationFilter,
  NotificationStatus,
  NotificationType,
} from 'types'
import { beautifyNotificationDate } from 'utils/notificationUtils'
import { useNotificationContext } from 'containers/notifications/NotificationContext'
import { useLocation } from 'react-router-dom'
import useElementOnScreen from 'containers/useElementOnScreen'
import NotificationList from './NotificationList'

const NotificationSummary = ({ currentOrganization }) => {
  const [selectedFilter, setSelectedFilter] = useState<NotificationStatus>(
    NotificationStatus.All
  )
  const [selectedPreview, setSelectedPreview] = useState<INotification>()
  const {
    notifications,
    notificationCursor,
    updateNotification,
    nextNotifications,
  } = useNotificationContext()
  const { elementRef, isVisible } = useElementOnScreen({
    root: null,
    rootMargin: '10px',
    threshold: 1.0,
  })
  const location = useLocation()

  const handleClickFilter = (e) => {
    setSelectedFilter(Number(e.target.dataset.filterId))
  }

  const handleClickNotification = (notification: INotification) => {
    const targetNotification = notifications?.find(
      (notificationInstance) => notificationInstance.id === notification.id
    )
    setSelectedPreview(targetNotification)
    if (
      notification.type === NotificationType.Persistent &&
      notification.action?.status !== ActionStatus.FULFILLED
    ) {
      return
    } else {
      handleMarkAsReadNotification(notification)
    }
  }

  const handleMarkAsReadNotification = (notification: INotification) => {
    if (notification.status !== NotificationStatus.Unread) {
      return
    }
    const updateParams: INotificationFilter = {
      notificationId: notification.id,
      status: NotificationStatus.Read,
    }
    if (updateNotification) updateNotification(updateParams)
  }

  const handleArchiveNotification = (
    archive: boolean = true,
    notification: INotification
  ) => {
    const updateParams: INotificationFilter = {
      notificationId: notification.id,
      status: archive ? NotificationStatus.Archived : NotificationStatus.Read,
    }
    if (updateNotification) updateNotification(updateParams)
  }

  const handlePinNotification = (pin: boolean, notification: INotification) => {
    const updateParams: INotificationFilter = {
      notificationId: notification.id,
      pinned: pin,
    }
    if (updateNotification) updateNotification(updateParams)
  }

  useEffect(() => {
    // in case we switch organization we want to remove the previous preview
    setSelectedPreview(undefined)
  }, [currentOrganization])

  // check if there's state for selectedPreview
  useEffect(() => {
    const statePreview = location.state?.selectedPreview
    if (statePreview !== undefined) {
      setSelectedPreview(statePreview)
    }
  }, [location])

  useEffect(() => {
      if (isVisible) {
        console.log('visible')
        if (nextNotifications) nextNotifications()
      }
  }, [isVisible])

  return (
    <div className="notification-page">
      <h1>All notifications</h1>
      <div className="notification-preview">
        {selectedPreview !== null && selectedPreview !== undefined && (
          <>
            <div className="notification-preview__header">
              <p className="notification-preview__title">
                {selectedPreview.title}
              </p>
              <p className="notification-preview__date">
                Sent on {beautifyNotificationDate(selectedPreview.created_at)}
              </p>
            </div>
            <div className="notification-preview__body">
              {selectedPreview.body}
            </div>
          </>
        )}
      </div>
      <div className="notification-wrapper">
        <div className="notification-filter-container">
          <ul className="notification-filter-list">
            <li
              className="notification-filter"
              data-filter-id={NotificationStatus.All}
              data-selected={selectedFilter === NotificationStatus.All}
              onClick={handleClickFilter}
            >
              all
            </li>
            <li
              className="notification-filter"
              data-filter-id={NotificationStatus.Unread}
              data-selected={selectedFilter === NotificationStatus.Unread}
              onClick={handleClickFilter}
            >
              unread
            </li>
            <li
              className="notification-filter"
              data-filter-id={NotificationStatus.Read}
              data-selected={selectedFilter === NotificationStatus.Read}
              onClick={handleClickFilter}
            >
              read
            </li>
            <li
              className="notification-filter"
              data-filter-id={NotificationStatus.Archived}
              data-selected={selectedFilter === NotificationStatus.Archived}
              onClick={handleClickFilter}
            >
              archived
            </li>
          </ul>
        </div>
        <div className="notification-container">
          <NotificationList
            handleArchiveNotification={handleArchiveNotification}
            handleClickNotification={handleClickNotification}
            handlePinNotification={handlePinNotification}
            notifications={notifications}
            selectedFilter={selectedFilter}
            triggerElementRef={elementRef}
            hasMoreNotifications={notificationCursor !== null}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(NotificationSummary)
