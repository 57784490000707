import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api, { isHTTPError } from '../api'

import { IServiceAccount } from 'types'

// used when adding source
const useGetAccounts = () => {
  const [accounts, setAccounts] = useState<IServiceAccount[]>([])
  const [loadingAccounts, setLoadingAccounts] = useState(false)
  const [accountsError, setAccountsError] = useState('')
  const token = useSelector(selectToken)

  const getAccounts = async (organizationId: number, meta: any) => {
    const state = localStorage.getItem('redirectState')
    const code = localStorage.getItem('redirectCode')
    const metadata =  meta || JSON.parse(localStorage.getItem('google_play_bucket') || '{}')
    const serviceUuid = localStorage.getItem('redirectServiceUuid')

    if (!organizationId || !state || !code || !serviceUuid) {
      setAccountsError('Redirection error')
      return
    }

    try {
      setLoadingAccounts(true)
      setAccountsError('')
      const responseAccounts = await api.verifyServiceAccounts({
        token,
        serviceUuid,
        code,
        state,
        organizationId,
        metadata,
      })
      setAccounts(responseAccounts)
      setLoadingAccounts(false)
      return responseAccounts
    } catch (error) {
      if (isHTTPError(error) && 'detail' in error.response.data) {
        setAccountsError(
          `Error ${error.response.status}: ${error.response.data.detail}`
        )
      } else {
        setAccountsError('Failed loading service accounts')
      }
    }
  }

  return { accounts, loadingAccounts, getAccounts, accountsError }
}

export default useGetAccounts
