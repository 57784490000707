import { useFeatureCtx } from 'containers/features/FeatureContext'
import React from 'react'
import ScreenLoading from './ScreenLoading'

type FeatureOpt = 
  "can_add_accounts" |
  "can_add_users" |
  "has_database_access"  |
  "integrations"

interface IFeatureProps {
  children: any
  switchComponent: React.ReactElement
  feature: FeatureOpt | ((features: any) => boolean)
}

const Feature: React.FC<IFeatureProps> = ({
  feature,
  switchComponent,
  children,
}) => {
  const { features } = useFeatureCtx()

  if (features === null) {
    return <ScreenLoading />
  }

  if (typeof feature === 'function') {
    return feature(features) ? <>{children}</> : switchComponent
  }

  if (features instanceof Object && features[feature]) {
    return <>{children}</>
  } else {
    return switchComponent
  }
}

export default Feature
