import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import * as rudderanalytics from 'rudder-sdk-js'

import ScreenLoading from 'components/ScreenLoading'
import './CustomBootstrap.scss'
import './css/Tooltip.scss'

import useToken from './containers/authentication/useToken'

import './css/Forms.scss'
import './css/Header.scss'
import './css/Layout.scss'
import './css/Common.scss'
import './css/Sidebar.scss'
import './css/Typography.scss'

import ForgotPassword from 'pages/ForgotPassword/ForgotPassword'
import ResetPassword from 'pages/ForgotPassword/ResetPassword'
import SignIn from './pages/SignIn/SignIn'
import CreateOrganization from './pages/SignUp/CreateOrganization'
import SignUp from './pages/SignUp/SignUp'
import MergeAccount from './pages/SignIn/MergeAccount'
import VerifyEmail from 'pages/SignIn/VerifyEmail'

import Home from './pages/Home/Home'
import LandingAddAccounts from './pages/Sources/LandingAddAccounts'

import ProtectedRoute from './components/ProtectedRoute'
import {
  selectToken,
  setToken,
} from './containers/authentication/authenticationSlice'

import AcceptInvite from 'pages/Invites/AcceptInvite'

import OrganizationRoute from 'components/OrganizationRoute'
import Page404 from 'pages/ErrorPages/404'

import CheckoutCancel from './pages/Billing/CheckoutCancel'
import CheckoutSuccess from './pages/Billing/CheckoutSuccess'

import ConfirmationMessage from 'components/ConfirmationMessage'
import ConfirmationContextProvider from 'containers/confirmation/confirmationContextProvider'
import LatestOrganizationRoute from 'components/LatestOrganizationRoute'

import api from './containers/api'
import GooglePlayOauth from 'components/Sources/GooglePlayOauth'
import MicrosoftRedirect from 'pages/SignIn/MicrosoftRedirect'
import MonaStart from 'pages/Mona/MonaStart'
import MonaRedirect from 'pages/Mona/MonaRedirect'
import PopUpMessage from 'components/PopUpMessage'

if (window.location.hostname === 'app.mayainsights.com') {
  Sentry.init({
    dsn: 'https://4583be6c8d584e848ec9684dc93865c0@o475428.ingest.sentry.io/5718408',
  })
}
const App = () => {
  const dispatch = useDispatch()
  const { isLoadingToken } = useToken()
  const token = useSelector(selectToken)
  const [cookies] = useCookies(['user_id'])
  const [redirectError, setRedirectError] = useState((new URLSearchParams(window.location.search)).get('redirect_error'))

  useEffect(() => {
    if (
      token &&
      (!cookies.user_id || parseInt(cookies.user_id) !== token.userId)
    ) {
      dispatch(setToken(null))
    }
    // eslint-disable-next-line
  }, [cookies])

  const loadGtm = async () => {
    const gtmId = await api.getGoogleTagManagerId()
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }

  const loadRudderStack = async () => {
    const rudderConf = await api.getRudderstackConfiguration()

    rudderanalytics.ready(() => console.log('rudderstack ready'))

    if (rudderConf) {
      rudderanalytics.load(rudderConf.write_key, rudderConf.data_plane_url)
    } else {
      console.log('no rudderstack configuration found')
    }
  }

  const handleRedirectErrorMessage = () => {
    switch (redirectError) {
      case 'no_such_user':
        return "We couldn't find a user with that email address. Please create an account."
      case 'subscription_does_not_exist':
        return 'This subscription does not exist. Please contact support.'
      default:
        return 'An unknown error occurred. Please try again later.'
    }
  }

  useEffect(() => {
    loadGtm()
    loadRudderStack()
  }, [])

  if (isLoadingToken) {
    return <ScreenLoading />
  }

  return (
    <Sentry.ErrorBoundary fallback={'An error has occured'}>
      <ConfirmationContextProvider>
      {redirectError && 
        <PopUpMessage
          time={5000}
          onClose={() => setRedirectError(null)}
          type="error"
          message={handleRedirectErrorMessage()}
          styles={{top: "10%"}}
        />}
        <ConfirmationMessage />
        <BrowserRouter>
          <Switch>
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/merge-account" component={MergeAccount} />
            <Route exact path="/verify-email" component={VerifyEmail} />
            <Route path="/sign-up" component={SignUp} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route exact path="/microsoft/redirect" component={MicrosoftRedirect} />
            <Route exact path="/microsoft/mona/start" component={MonaStart} />
            <Route exact path="/microsoft/mona/redirect" component={MonaRedirect} />
            <Route
              exact
              path="/invitation/:organizationSlug/:inviteId"
              component={AcceptInvite}
            />

            <ProtectedRoute
              exact
              path="/create-organization"
              userId={parseInt(cookies.user_id)}
              token={token}
              component={CreateOrganization}
            />

            <ProtectedRoute
              exact
              path="/"
              userId={parseInt(cookies.user_id)}
              token={token}
              component={Home}
            />
            <ProtectedRoute
              path="/integrations/:serviceId/oauth/redirect"
              userId={parseInt(cookies.user_id)}
              token={token}
              component={LandingAddAccounts}
            />

            <Route
              path="/sources/setup/google_play/oauth"
              component={GooglePlayOauth}
            />

            <ProtectedRoute
              path="/organization/0"
              userId={parseInt(cookies.user_id)}
              token={token}
              component={LatestOrganizationRoute}
            />

            <ProtectedRoute
              path="/organization/:organizationSlug"
              userId={parseInt(cookies.user_id)}
              token={token}
              component={OrganizationRoute}
            />

            <ProtectedRoute
              exact
              path={`/checkout-success`}
              userId={parseInt(cookies.user_id)}
              token={token}
              component={CheckoutSuccess}
            />
            <ProtectedRoute
              exact
              path={`/checkout-cancel`}
              userId={parseInt(cookies.user_id)}
              token={token}
              component={CheckoutCancel}
            />
            <Route component={() => <Page404 />} />
          </Switch>
        </BrowserRouter>
      </ConfirmationContextProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
