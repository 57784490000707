import api, { isHTTPError } from '../api'
import useDataLoader from '../dataLoader'

const useCheckMergeAccount = () => {
  const checkMergeAccount = async (requestId: string, email: string) => {
    try {
        return await api.checkMergeAccounts(requestId, email)
      } catch (error) {
        if (isHTTPError(error) && error.response.status === 404) {
          return error.response.data.detail.type
        } else if (isHTTPError(error) && 'detail' in error.response.data) {
            return error.response.data.detail.type
      }
    }
  }

  return useDataLoader(checkMergeAccount, [], [])
}

export default useCheckMergeAccount
