import { useState } from 'react'
import { useSelector } from 'react-redux'

import produce from 'immer'
import _ from 'lodash'

import { selectToken } from 'containers/authentication/authenticationSlice'
import api, { isHTTPError } from 'containers/api'

const useGetCompetitorsReports = () => {
  const token = useSelector(selectToken)

  const [reports, setReports] = useState([])
  const [isLoadingReports, setIsLoadingReports] = useState(true)
  const [reportsError, setReportsError] = useState('')

  const removeReport = (reportId: number) => {
    const reportIndex = _.findIndex(reports, ['uuid', reportId])

    setReports(
      produce(reports, (draft) => {
        draft.splice(reportIndex, 1)
      })
    )
  }

  const getCompetitorsReports = async ({
    organization,
    filterColumn = null,
  }) => {
    try {
      setIsLoadingReports(true)
      setReportsError('')
      const responseReports = await api.getCompetitorsReports({
        organizationId: organization.id,
        filterColumn,
        token,
      })

      setReports(responseReports)
      setIsLoadingReports(false)
      return responseReports
    } catch (error) {
      setIsLoadingReports(false)
      if (isHTTPError(error) && error.response.status === 422) {
        setReportsError('Failed loading competitors reports: Validation error')
      } else {
        setReportsError('Failed loading competitors reports')
      }
      return false
    }
  }

  return {
    reports,
    removeReport,
    isLoadingReports,
    reportsError,
    getCompetitorsReports,
  }
}

export default useGetCompetitorsReports
