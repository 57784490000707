import { useState } from 'react'
import { useSelector } from 'react-redux'

import { IToken } from 'types'

import api, { isAPIError } from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useQuery from 'containers/useQuery'

const useCheckout = (organization) => {
  const [redirectUrl, setRedirectUrl] = useState<string>('')
  const [checkoutFailed, setCheckoutFailed] = useState<boolean>(false)

  const token: IToken = useSelector(selectToken)

  const query = useQuery()

  const getRedirectUrl = async () => {
    try {
      const details = {
        product_id: query.get('product'),
        annual: JSON.parse(query.get('annual')!),
      }

      const url = await api.getBillingCheckoutUrl({
        organizationId: organization.id,
        token: token,
        details: details,
      })
      setRedirectUrl(url)
    } catch (e) {
      if (isAPIError(e)) {
        setRedirectUrl(
          `/organization/${organization.slug}/billing/details?checkout=1&checkout_error=${e.response.data.detail.type}`
        )
      } else {
        setCheckoutFailed(true)
      }
    }
  }

  return { getRedirectUrl, redirectUrl, checkoutFailed }
}

export default useCheckout
