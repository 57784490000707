import { useContext, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import WaveLoading from 'components/WaveLoading'
import Divider from '../Divider'
import InputField from '../InputField'

import AuthorizeButton from './AuthorizeButton'
import './GooglePlayBucket.scss'
import useGetUniqueAccount from 'containers/services/useGetUniqueAccount'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import useApiCreds from 'containers/services/useApiCreds'

const Guide = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Setup Guide</h1>
      <Divider spaceY={10} />
      <div className="step-heading">
        <p>Step 1</p>
        <h2>Get your API Key</h2>
      </div>
      <ol>
        <li>Log in to <a href="https://identity.moosend.com/" target="_blank">your account</a>.</li>
        <li>On the menu bar, click <b>More &gt; Settings.</b></li>
        <li>Click <b>API key</b>.</li>
        <li>To copy your API key, click Copy.</li>
      </ol>
    </div>
  )
}

const Moosend = () => {
  const [showGuide, setShowGuide] = useState(true)
  const [apiKey, setApiKey] = useState("")
  const { currentOrganization } = useContext(OrganizationsContext)
  const history = useHistory()
  const serviceUUID = useParams<{id: string}>().id
  const {saveApiCreds} = useApiCreds(serviceUUID)

  const saveData = async () => {
    if (apiKey) {
        localStorage.setItem('redirectServiceUuid', serviceUUID)
        await saveApiCreds(currentOrganization.id, {apiKey: apiKey})
          history.push(`/organization/${currentOrganization.slug}/sources`)
        
      }
  }

  return (
    <div className="guide-container">
      <Guide onHide={() => setShowGuide(false)} show={showGuide} />

      <img
        src="https://old.mayainsights.com/static/img/logos/moosend.svg"
        width="200"
        alt=""
      />
      <p>
        Press the{' '}
        <button style={{verticalAlign: "initial"}} className="button-tip" onClick={() => setShowGuide(true)}>
          Guide
        </button>{' '}
        button to help you through the configuration proccess.
      </p>
      <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', gap: '1rem', width: "50%"}}>
      <Divider spaceY={10}></Divider>
      <h2 style={{fontWeight: "normal"}}>Configure Moosend</h2>
      <p style={{ color: "#9b9b9b", fontSize: "14px", margin: "0 "}}>Provide the API Key that you took note of from your Moosend Dashboard</p>
      <div style={{display: "flex", alignItems: 'center', gap: "2rem"}}>
        <div style={{display: "flex", flexDirection: "column", width: "150%"}}>
          <InputField
            label="API Key"
            initialValue=''
            type='string'
            value={apiKey}
            setValue={setApiKey}
          />
        </div>
        <button style={{margin: 0 }} className="submit-button" disabled={!apiKey} onClick={saveData}>Save</button>
      </div>
      </div>

      <div className="guide-bar">
        <button
          className="submit-button guide-button"
          onClick={() => setShowGuide(true)}
        >
          <span>Guide</span>
          <span className="material-icons-outlined saved-icon non-selectable">
            menu_book
          </span>
        </button>
      </div>
    </div>
  )
}

export default Moosend
