import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'react-moment'
import moment from 'moment'
import { motion } from 'framer-motion'
import TagManager from 'react-gtm-module'

import ScreenLoading from 'components/ScreenLoading'
import PopUpMessage from 'components/PopUpMessage'

import useGetAuthUrl from 'containers/services/useGetAuthUrl'
import useGetConnectedAccounts from 'containers/services/useGetConnectedAccounts'
import useDeleteServiceAccount from 'containers/services/deleteServiceAccount'
import useGetServices from 'containers/services/useGetServices'
import OrganizationsContext from 'containers/organizations/organizationsContext'

import './Sources.scss'
import Feature from 'components/Feature'
import UpgradeButton from 'components/UpgradeButton'
import useGetCustomTables from 'containers/customTable/useGetCustomTables'
import { FetchStatus } from 'containers/dataLoader'
import Modal from 'components/Modal'
import UploadZone from 'components/UploadZone'
import { getCSVColumns } from 'components/FileUpload/utils'
import useGetCustomTableColumns from 'containers/customTable/useGetCustomTableColumns'
import useUploadCustomTable from 'containers/customTable/useUploadCustomTable'
import useDeleteCustomTable from 'containers/customTable/useDeleteCustomTable'
import ProgressBar from 'components/ProgressBar'
import useConfirmation from 'containers/confirmation/useConfirmation'

const SyncStatus = ({ syncStatus, syncError }) => {
  const SyncDetails = () => {
    if (syncStatus === 'failed') {
      return <span className="status-info">{syncError}</span>
    }

    if (syncStatus === 'pending') {
      return <span className="status-info">Pending</span>
    }

    if (syncStatus === 'running') {
      return (
        <>
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <span className="status-info">Running</span>
        </>
      )
    }

    return <></>
  }

  return (
    <td className="status grid grid-column-3">
      <div className={`status-circle grid-column-1 ${syncStatus}`}></div>
      <SyncDetails />
    </td>
  )
}

const Account = ({
  isSourceOpen,
  account,
  deleteServiceAccount,
  removeServiceAccount,
  handleReconnect,
}) => {
  const [actionsOpen, setActionsOpen] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const { currentOrganization } = useContext(OrganizationsContext)

  if (!isSourceOpen) {
    return <></>
  }

  const handleCloseAll = () => {
    setActionsOpen(false)
    setConfirmDeleteOpen(false)
    document.removeEventListener('click', handleCloseAll)
  }

  const handleClickActions = (e) => {
    e.stopPropagation()
    if (!actionsOpen && !confirmDeleteOpen) {
      document.addEventListener('click', handleCloseAll)
    }
    if (confirmDeleteOpen) {
      setConfirmDeleteOpen(false)
      return
    }

    setActionsOpen(!actionsOpen)
  }

  const handleClickDelete = () => {
    TagManager.dataLayer({
      dataLayer: { event: 'clicked_delete_source_account_button', is_custom_track: true },
    })
    setActionsOpen(false)
    setConfirmDeleteOpen(true)
    document.addEventListener('click', handleCloseAll)
  }

  const handleConfirmDelete = async () => {
    const deletedAccount = await deleteServiceAccount(
      account.uuid,
      currentOrganization.id
    )

    if (deletedAccount) {
      TagManager.dataLayer({ dataLayer: { event: 'source_account_deleted', is_custom_track: true } })
      removeServiceAccount(deletedAccount.uuid)
    }
  }

  return (
    <>
      <tr className="account grid">
        <td className="integration grid-column-5">
          <div className="information">
            <span className="name">{account.name}</span>
            <span className="uuid">{account.id_label}</span>
          </div>
        </td>
        <SyncStatus
          syncStatus={account.sync_status}
          syncError={account.sync_error}
        />
        <td className="date grid-column-3">
          <span className="created">
            Created: <Moment format="DD/MM/YYYY">{account.connected_at}</Moment>
          </span>
        </td>
        <td className="actions grid-column-1">
          <div
            onClick={handleClickActions}
            className={`actions-drop-down ${actionsOpen ? 'open' : ''}`}
          >
            Actions
            <span className="material-icons">keyboard_arrow_down</span>
            {actionsOpen && !currentOrganization.id && <ScreenLoading />}
            {actionsOpen && currentOrganization.id && (
              <ul className="actions-drop-down-options">
                <li onClick={() => handleReconnect()} className="reconnect">
                  <span className="material-icons-outlined">link</span>
                  Reconnect
                </li>
                <li onClick={handleClickDelete} className="delete">
                  <span className="material-icons-outlined">delete</span>
                  Delete
                </li>
              </ul>
            )}
            {confirmDeleteOpen && (
              <div className="delete-confirmation">
                <span className="message">Are you sure?</span>
                <span onClick={handleConfirmDelete} className="delete">
                  Delete
                </span>
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  )
}

const AccountsHeader = ({ isSourceOpen }) => {
  if (!isSourceOpen) {
    return <></>
  }

  return (
    <tr className="accounts-header grid">
      <th className="integration grid-column-5">Source Account</th>
      <th className="status grid-column-3">Status</th>
      <th className="date grid-column-3">Date</th>
      <th className="actions grid-column-1"></th>
    </tr>
  )
}

const Service = ({
  service,
  deleteServiceAccount,
  removeServiceAccount,
  handleReconnect,
}) => {
  const [isSourceOpen, setIsSourceOpen] = useState(false)
  const { currentOrganization } = useContext(OrganizationsContext)
  const history = useHistory()

  const totalSyncStatus = () => {
    const totalFailed = _.filter(
      service.accounts,
      (acc) => acc.sync_status === 'failed'
    ).length

    if (totalFailed > 0) {
      return (
        <SyncStatus
          syncStatus="failed"
          syncError={`${totalFailed} Sync Problem${totalFailed > 1 ? 's' : ''}`}
        />
      )
    }

    const totalPending = _.filter(
      service.accounts,
      (acc) => acc.sync_status === 'pending'
    ).length

    if (totalPending > 0) {
      return <SyncStatus syncStatus="pending" />
    }

    const totalRunning = _.filter(
      service.accounts,
      (acc) => acc.sync_status === 'running'
    ).length

    if (totalRunning > 0) {
      return <SyncStatus syncStatus="running" />
    }

    return <SyncStatus syncStatus="finished" />
  }

  const _getEarliestSyncTime = () => {
    let moments = []
    service.accounts.forEach((acc) => {
      if (acc.last_synced_at) {
        moments.push(moment(acc.last_synced_at))
      }
    })
    const minDate = moment.min(moments)

    if (moments.length === 0) {
      return null
    }

    return minDate
  }

  const getEarliestCreatedTime = () => {
    let moments = []
    service.accounts.forEach((acc) => {
      if (acc.connected_at) {
        moments.push(moment(acc.connected_at))
      }
    })
    const minDate = moment.min(moments)

    if (moments.length === 0) {
      return null
    }

    return minDate
  }

  const handleReconnectService = (service) => {
    if (service.details.id === 'google_analytics_4_events') {
      history.push(
        `/organization/${currentOrganization.slug}/sources/setup/google_analytics_4_events/${service.details.uuid}`
      )
      return
    }
    handleReconnect(service.details.uuid)
  }

  return (
    <tbody key={service.details.id}>
      <tr
        className="service grid"
        onClick={() => setIsSourceOpen(!isSourceOpen)}
      >
        <td className="integration grid-column-5">
          <img src={service.details.logo} width="35" alt="service_icon" />
          <div className="information">
            <span className="name">{service.details.name}</span>
            <span className="connections">
              {service.accounts.length} connection
              {service.accounts.length > 1 ? 's' : ''}
            </span>
          </div>
        </td>
        {totalSyncStatus()}
        <td className="date grid-column-3">
          <span className="created">
            Created:{' '}
            <Moment format="DD/MM/YYYY">{getEarliestCreatedTime()}</Moment>
          </span>
        </td>
        <td className="actions grid-column-1">
          <span className="material-icons">keyboard_arrow_down</span>
        </td>
      </tr>
      <AccountsHeader isSourceOpen={isSourceOpen} />
      {service.accounts.map((account) => (
        <Account
          key={account.uuid}
          isSourceOpen={isSourceOpen}
          account={account}
          deleteServiceAccount={deleteServiceAccount}
          removeServiceAccount={removeServiceAccount}
          handleReconnect={() => handleReconnectService(service)}
        />
      ))}
    </tbody>
  )
}

const IntegrationsCategory = ({
  category,
  deleteServiceAccount,
  removeServiceAccount,
  handleReconnect,
}) => {
  return (
    <div className="integrations-category">
      <span className="title">{category.name}</span>
      <span className="subtitle">
        {category.services.length} integration
        {category.services.length > 1 ? 's' : ''}
      </span>

      <table>
        <thead>
          <tr className="grid">
            <th className="integration grid-column-5">Integrations</th>
            <th className="status grid-column-3">Status</th>
            <th className="date grid-column-3">Date</th>
            <th className="actions grid-column-1"></th>
          </tr>
        </thead>
        {category.services.map((service) => (
          <Service
            key={service.details.id}
            service={service}
            deleteServiceAccount={deleteServiceAccount}
            removeServiceAccount={removeServiceAccount}
            handleReconnect={handleReconnect}
          />
        ))}
      </table>
    </div>
  )
}

const NoSourcesConnected = ({ handleAddSources }) => {
  return (
    <div className="no-sources">
      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} className="box">
        <h1>Connect sources</h1>
        <div className="subtitle-text">
          No integrations connected. Add an account to get started with Maya
          Insights.
        </div>
        <Feature
          feature="can_add_accounts"
          switchComponent={
            <UpgradeButton style={{ marginTop: 20 }}>
              Upgrade plan for more accounts
            </UpgradeButton>
          }
        >
          <button onClick={handleAddSources} className="submit-button">
            <span className="enlarge">+</span>Add source
          </button>
        </Feature>
      </motion.div>
    </div>
  )
}

const Sources = () => {
  let history = useHistory()
  const { services, servicesError, getServices, loadingServices } =
    useGetServices()
  const {
    serviceAccounts,
    loadingServicesAccounts,
    serviceAccountsError,
    getServiceAccounts,
    removeServiceAccount,
  } = useGetConnectedAccounts()

  const { deleteServiceAccount } = useDeleteServiceAccount()
  const { getAuthUrl } = useGetAuthUrl()
  const { currentOrganization } = useContext(OrganizationsContext)
  const [customTables, getCustomTables, fetchingCustomTables] =
    useGetCustomTables(currentOrganization)
  const [, deleteTable] = useDeleteCustomTable(currentOrganization)
  const { confirm } = useConfirmation()

  useEffect(() => {
    getServices()
    getCustomTables()
  }, [getServices])

  useEffect(() => {
    if (currentOrganization.id) {
      getServiceAccounts(currentOrganization.id)
      getCustomTables()
    }
    // eslint-disable-next-line
  }, [currentOrganization.id])

  if (servicesError) {
    return <>{servicesError}</>
  }

  if (serviceAccountsError) {
    return <>{serviceAccountsError}</>
  }

  if (!currentOrganization.id || loadingServices || loadingServicesAccounts) {
    return <ScreenLoading />
  }

  const getOrganizedCategories = () => {
    let organizedServices = []
    let organizedCategories = []

    serviceAccounts.forEach((account) => {
      const service = _.find(services, ['id', account.service])

      const serviceIndex = _.findIndex(
        organizedServices,
        (srv) => srv.details.id === service.id
      )
      if (serviceIndex !== -1) {
        organizedServices[serviceIndex].accounts.push(account)
      } else {
        organizedServices.push({
          details: service,
          accounts: [account],
        })
      }
    })

    organizedServices.forEach((service) => {
      const category = service.details.category
      const categoryIndex = _.findIndex(
        organizedCategories,
        (ctg) => ctg.name === category
      )

      if (categoryIndex !== -1) {
        organizedCategories[categoryIndex].services.push(service)
      } else {
        organizedCategories.push({
          name: category,
          services: [service],
        })
      }
    })

    return organizedCategories
  }

  const handleAddSources = () => {
    TagManager.dataLayer({ dataLayer: { event: 'clicked_add_sources', is_custom_track: true } })
    history.push(`/organization/${currentOrganization.slug}/sources/add`)
  }

  const handleReconnect = async (serviceUuid) => {
    const url = await getAuthUrl(serviceUuid, currentOrganization.id)
    if (url) {
      TagManager.dataLayer({
        dataLayer: { event: 'clicked_reconnect_source_account', is_custom_track: true },
      })
      window.location.replace(url)
    }
  }

  const handleDeleteTable = async (tableName) => {
    if (await confirm('Are you sure you want to delete this table?')) {
      await deleteTable(tableName)
      getCustomTables()
    }
  }

  return (
    <div className="sources">
      {localStorage.getItem('checkoutCompleted') && (
        <PopUpMessage
          onClose={() => localStorage.removeItem('checkoutCompleted')}
          message="Payment completed successfully."
          time={3000}
        />
      )}
      {serviceAccounts.length === 0 && customTables?.length === 0 ? (
        <NoSourcesConnected handleAddSources={handleAddSources} />
      ) : (
        <>
          <div className="head">
            <div className="page-name">
              <h2>Sources</h2>
              <span className="subtitle-text">
                You need to add sources at your project so we can process your
                data{' '}
              </span>
            </div>
            <Feature
              feature="can_add_accounts"
              switchComponent={
                <UpgradeButton style={{ marginLeft: 'auto' }}>
                  Upgrade plan for more accounts
                </UpgradeButton>
              }
            >
              <button onClick={handleAddSources} className="submit-button">
                <span className="enlarge">+</span>
                <span>Add source</span>
              </button>
            </Feature>
          </div>
          <nav>
            <ul>
              <li className="selected">Integrations</li>
            </ul>
          </nav>
          <div className="content">
            {getOrganizedCategories().map((category) => (
              <IntegrationsCategory
                key={category.name}
                category={category}
                deleteServiceAccount={deleteServiceAccount}
                removeServiceAccount={removeServiceAccount}
                handleReconnect={handleReconnect}
              />
            ))}
            {fetchingCustomTables === FetchStatus.Pending && <ScreenLoading />}
            {customTables?.length > 0 &&
              customTables.map((table) => (
                <CustomTableIntegration
                  table={table}
                  currentOrganization={currentOrganization}
                  handleDeleteTable={handleDeleteTable}
                />
              ))}
          </div>
          {localStorage.getItem('serviceConnected') && (
            <PopUpMessage
              onClose={() => localStorage.removeItem('serviceConnected')}
              message={`${localStorage.getItem(
                'serviceConnected'
              )} connected succesfully.`}
              time={3000}
            />
          )}
        </>
      )}
    </div>
  )
}

const CustomTableIntegration = ({
  table,
  currentOrganization,
  handleDeleteTable,
}) => {
  const [isActionsOpen, setIsActionsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [csvFile, setCSVFile] = useState(null)
  const [csvColumns, setCsvColumns] = useState([])
  const [uploadStatus, setUploadStatus] = useState(null)
  const [clearFiles, setClearFiles] = useState(false)

  const [fileTypeError, setFileTypeError] = useState(false)
  const [invalidColumnsError, setInvalidColumnsError] = useState(false)
  const [uploadError, setUploadError] = useState(null)

  const [customTableColumns, getCustomTableColumns] =
    useGetCustomTableColumns(currentOrganization)
  const [uploadResult, uploadFile, uploadingFile] =
    useUploadCustomTable(currentOrganization)

  const history = useHistory()
  const handleEditSchema = () => {
    history.push(
      `/organization/${currentOrganization.slug}/sources/setup/custom-table/${table.name}/edit`
    )
  }

  const checkColumnExists = (columnName) => {
    if (csvColumns?.length <= 0) return ''
    const columnExistsInCsv = csvColumns.includes(columnName)
    const columnExistsInCustomTable = customTableColumns[table.name]?.find(
      (column) => column.columnName === columnName
    )
    if (columnExistsInCsv && columnExistsInCustomTable) {
      return 'found'
    }
    if (columnExistsInCustomTable) {
      return 'missing'
    }
  }

  const handleUploadFile = async (e) => {
    const files = e.target.files

    if (files[0].type !== 'text/csv') {
      setFileTypeError(true)
      return
    } else if (fileTypeError) {
      setFileTypeError(false)
    }

    if (files) {
      const { columns: columnsOnFile } = await getCSVColumns(files[0])
      setCsvColumns(columnsOnFile)
      setCSVFile(files[0])
      await getCustomTableColumns(table.name)

      const columnsAreTheSame = customTableColumns[table.name].every(
        (column) =>
          columnsOnFile.find((csvColumn) => csvColumn === column.columnName) !==
          undefined
      )
      if (!columnsAreTheSame) {
        setInvalidColumnsError(true)
        return
      }

      const uploadStatus = await uploadFile(
        [],
        table.name,
        files[0],
        setUploadStatus
      )
      if (uploadStatus?.success === false) {
        setUploadError(uploadStatus.detail)
        if (!uploadError) setUploadError(uploadStatus.detail)
        return
      } else if (uploadError) {
        setUploadError('')
      }
    }
  }

  const handleClearFiles = () => {
    setClearFiles(true)
    setCSVFile(null)
    setCsvColumns([])
    setUploadStatus(null)
    setTimeout(() => {
      setClearFiles(false)
    }, 100)

    if (fileTypeError) setFileTypeError(false)
    if (invalidColumnsError) setInvalidColumnsError(false)
    if (uploadError) setUploadError(null)
  }

  const unslugify = (str) => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  useEffect(() => {
    if (isModalOpen && !customTableColumns) {
      getCustomTableColumns(table.name)
    }
  }, [isModalOpen])

  useEffect(() => {
    if (uploadResult?.success) {
      setIsModalOpen(false)
    }
  }, [uploadResult])

  return (
    <div className="integrations-category">
      <span className="title">{unslugify(table.name)}</span>
      <span className="subtitle"></span>
      <table>
        <thead>
          <tr className="grid">
            <th className="integration grid-column-5">Table Name</th>
            <th className="status grid-column-3">Status</th>
            <th className="uuid grid-column-3">UUID</th>
            <th className="actions grid-column-1"></th>
          </tr>
        </thead>
        <tbody key={table.uuid}>
          <tr className="service grid">
            <td className="integration grid-column-5">
              <img
                src="https://old.mayainsights.com/static/img/logos/manual_ads.svg"
                width="35"
                alt="service_icon"
              />
              <div className="information">
                <span className="name">{unslugify(table.name)}</span>
                <span className="connections"></span>
              </div>
            </td>
            <td className="status grid-column-3"></td>
            <td className="uuid grid-column-3">
              <span className="created">{table.uuid}</span>
            </td>
            <td className="actions grid-column-1">
              <div
                onClick={() => setIsActionsOpen((prev) => !prev)}
                className={`actions-drop-down open`}
              >
                Actions
                <span className="material-icons">keyboard_arrow_down</span>
                {isActionsOpen && !currentOrganization.id && <ScreenLoading />}
                {isActionsOpen && currentOrganization.id && (
                  <ul className="actions-drop-down-options">
                    <li onClick={handleEditSchema} className="reconnect">
                      <span className="material-icons-outlined">link</span>
                      Edit Schema
                    </li>
                    <li
                      onClick={() => setIsModalOpen(true)}
                      className="reconnect"
                    >
                      <span className="material-icons-outlined">link</span>
                      Upload new file
                    </li>
                    <li
                      onClick={() => handleDeleteTable(table.name)}
                      className="delete"
                    >
                      <span className="material-icons-outlined">delete</span>
                      Delete
                    </li>
                  </ul>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Modal onHide={() => setIsModalOpen(false)} show={isModalOpen}>
        <div className="upload-file">
          <div className="upload-file__header">
            <h2>Upload a CSV file</h2>
            <p>
              Replace the currently stored CSV file with one that has the same
              schema.
            </p>
            <div className="upload-file__column-schema">
              {customTableColumns &&
                customTableColumns[table.name].map((column) => (
                  <span
                    className={`upload-file__header__column ${checkColumnExists(
                      column.columnName
                    )}`}
                  >
                    {column.columnName}
                  </span>
                ))}
            </div>
          </div>
          <div className="upload-file__body">
            {!csvFile && (
              <div className="upload-file__body__upload">
                <UploadZone
                  fileType="csv"
                  uploadFile={handleUploadFile}
                  uploadingFile={uploadingFile === FetchStatus.Pending}
                  clear={clearFiles}
                />
              </div>
            )}
            {csvFile && (
              <>
                <h4>Files</h4>
                <div className="uploaded-file" onClick={handleClearFiles}>
                  <div className="upload-file__body__upload__file__name">
                    {csvFile.name}
                  </div>
                  <button className="clear-btn">&times;</button>
                </div>
              </>
            )}
          </div>
          <div className="upload-file__footer">
            {uploadStatus !== null && (
              <ProgressBar
                loadValue={uploadStatus}
                showProgress
                progressAlignment="center"
                switchWhenDone
              >
                {uploadError && (
                  <div className="upload-file__body__upload__error error">
                    {uploadError}
                  </div>
                )}
              </ProgressBar>
            )}
            {fileTypeError && (
              <div className="upload-file__body__upload__error error">
                Please upload a CSV file
              </div>
            )}
            {invalidColumnsError && (
              <div className="upload-file__body__upload__error error">
                Current schema and CSV columns do not match
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default React.memo(Sources)
