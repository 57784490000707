import React, { useState, useContext, useEffect } from 'react'
import api from 'containers/api'
import { useSelector } from 'react-redux'

import { selectToken } from 'containers/authentication/authenticationSlice'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import ScreenLoading from '../../components/ScreenLoading'

const BillingPortal = () => {
  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  const token = useSelector(selectToken)
  const [portalUrl, setPortalUrl] = useState(null)

  const getData = async () => {
    const url = await api.getBillingPortalUrl({
      organizationId: currentOrganization.id,
      token: token,
    })
    setPortalUrl(url)
  }

  useEffect(() => {
    if (!isLoadingOrganization) {
      getData()
    }
    // eslint-disable-next-line
  }, [isLoadingOrganization])

  if (portalUrl !== null) {
    window.location.href = portalUrl
  }

  return <ScreenLoading />
}

export default React.memo(BillingPortal)
