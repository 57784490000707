import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import produce from 'immer'
import _ from 'lodash'

import { selectToken } from 'containers/authentication/authenticationSlice'
import api, { isAPIError } from 'containers/api'

import { IClusteringColumn } from 'types'

const useGetColumns = () => {
  const token = useSelector(selectToken)

  const [columns, setColumns] = useState<{ columns: IClusteringColumn[] }>({
    columns: [],
  })
  const [loadingColumns, setLoadingColumns] = useState(true)
  const [columnsError, setColumnsError] = useState('')

  const loadingCount = useRef(0)

  const reorderColumns = ({ columnId, endIndex }) => {
    const newColumns = _.clone(columns.columns)
    const startingColumns = _.clone(columns.columns)
    const movedElement = _.find(newColumns, ['id', columnId])

    newColumns.splice(_.findIndex(newColumns, ['id', columnId]), 1)
    if (movedElement) {
      newColumns.splice(endIndex, 0, movedElement)
    }

    const reorder = () => {
      setColumns(
        produce(columns, (draftColumns) => {
          draftColumns.columns = newColumns
        })
      )
    }

    const undoReorder = () => {
      setColumns(
        produce(columns, (draftColumns) => {
          draftColumns.columns = startingColumns
        })
      )
    }

    return { newColumns, reorder, undoReorder }
  }

  const deleteColumn = (columnId) => {
    const newColumns = _.clone(columns.columns)

    _.remove(newColumns, ['id', columnId])

    const remove = () => {
      setColumns(
        produce(columns, (draftColumns) => {
          draftColumns.columns = newColumns
        })
      )
    }

    return { newColumns, remove }
  }

  const stopLoading = () => {
    loadingCount.current = loadingCount.current - 1

    if (loadingCount.current === 0) {
      setLoadingColumns(false)
    }
  }

  const fetchColumnsByCategory = async ({ category, organization }) => {
    loadingCount.current = loadingCount.current + 1
    setLoadingColumns(true)
    try {
      const responseColumns = await api.getColumnsByCategory({
        category,
        organizationId: organization.id,
        token,
      })
      setColumns(responseColumns)
      stopLoading()
      return responseColumns
    } catch (error) {
      if (isAPIError(error)) {
        setColumnsError(error.response.data.detail.type)
      } else {
        setColumnsError('Server error')
      }
      stopLoading()
      return false
    }
  }

  return {
    columns,
    loadingColumns,
    columnsError,
    fetchColumnsByCategory,
    deleteColumn,
    reorderColumns,
  }
}

export default useGetColumns
