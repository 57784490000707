import _ from 'lodash'

const useStorage = () => {
  const setCurrentOrganization = ({ organizationSlug }) => {
    localStorage.setItem('currentOrganization', organizationSlug)
  }

  const isClusterColumnCollapsed = (id: string) => {
    let clusterCollapsedColumns = localStorage.getItem(
      'clusterCollapsedColumns'
    )

    if (!clusterCollapsedColumns) {
      return false
    }

    clusterCollapsedColumns = JSON.parse(clusterCollapsedColumns)

    if (
      !Array.isArray(clusterCollapsedColumns) ||
      !clusterCollapsedColumns.includes(id)
    ) {
      return false
    }

    return true
  }

  const toggleClusterColumnCollapse = (id: string) => {
    let clusterCollapsedColumnsStr = localStorage.getItem(
      'clusterCollapsedColumns'
    )

    let clusterCollapsedColumns: Array<any> = []
    if (clusterCollapsedColumnsStr) {
      clusterCollapsedColumns = JSON.parse(clusterCollapsedColumnsStr)
    }

    if (!clusterCollapsedColumns.includes(id)) {
      clusterCollapsedColumns.push(id)
    } else {
      _.remove(clusterCollapsedColumns, (columnId) => columnId === id)
    }

    localStorage.setItem(
      'clusterCollapsedColumns',
      JSON.stringify(clusterCollapsedColumns)
    )
  }
  const clearNewSession = () => {
    localStorage.removeItem('currentOrganization')
    localStorage.removeItem('isSideBarCollapsed')
  }

  const setUrlWhenLoggedIn = ({ url }) => {
    localStorage.setItem('urlWhenLoggedIn', url)
  }

  const removeUrlWhenLoggedIn = () => {
    localStorage.removeItem('urlWhenLoggedIn')
  }

  const getUrlWhenLoggedIn = () => {
    return localStorage.getItem('urlWhenLoggedIn')
  }

  return {
    setCurrentOrganization,
    setUrlWhenLoggedIn,
    removeUrlWhenLoggedIn,
    getUrlWhenLoggedIn,
    clearNewSession,
    isClusterColumnCollapsed,
    toggleClusterColumnCollapse,
  }
}

export default useStorage
