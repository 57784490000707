import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const useLogout = () => {
  const history = useHistory()
  const [, , removeCookie] = useCookies(['user_id'])

  const logOut = async () => {
    localStorage.removeItem('currentOrganization')
    removeCookie('user_id', { path: '/' })
    history.push('/')
  }
  return logOut
}

export default useLogout
