import DropDownField from 'components/DropDownField'
import Modal from 'components/Modal'
import OrganizationPicker from 'components/Navigation/OrganizationPicker'
import ScreenLoading from 'components/ScreenLoading'
import useGetOrganizations from 'containers/organizations/useGetOrganizations'
import useSubscriptions from 'containers/useSubscriptions'
import React, { useEffect, useState } from 'react'
import { IOrganization, Product } from 'types'
import "./LinkSubscriptions.scss"

interface ILinkSubscriptionsProps {
  subscriptions: Product[]
  onClose: () => void
  show: boolean
}

const LinkSubscriptions: React.FC<ILinkSubscriptionsProps> = ({
  subscriptions,
  show,
  onClose = () => {},
}) => {
  const [organizations, setOrganizations] = useState<IOrganization[] | null>(
    null
  )
  const [currentOrganization, setCurrentOrganization] =
    useState<IOrganization | null>(null)
  const { getOrganizations } = useGetOrganizations()
  const { linkSubscription } = useSubscriptions()

  useEffect(() => {
    const getOrgs = async () => {
      const orgs: IOrganization[] = (await getOrganizations()).filter(
        (org: IOrganization) => !org.is_demo && !org.has_subscription
      )
      setOrganizations(orgs)
      setCurrentOrganization(orgs[0])
    }
    getOrgs()
  }, [])

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          onClose()
        }}
        children={
          <Link
            currentOrganization={currentOrganization}
            subscriptions={subscriptions}
            organizations={organizations}
            onChange={setCurrentOrganization}
            linkSubscription={linkSubscription}
          />
        }
        className="half-screen"
      />
    </div>
  )
}

interface ILinkProps {
  subscriptions: Product[]
  currentOrganization: IOrganization | null
  organizations: IOrganization[] | null
  onChange: (org: IOrganization) => void
  linkSubscription: (
    subscriptionId: string,
    organizationId: string
  ) => Promise<any>
}

const Link: React.FC<ILinkProps> = ({
  subscriptions,
  currentOrganization,
  organizations,
  onChange,
  linkSubscription,
}) => {
  const [pickedOrganization, setPickedOrganization] =
    useState(currentOrganization)
  const [pickedSubscription, setPickedSubscription] = useState(
    subscriptions ? subscriptions[0] : null
  )
  const [loadingLink, setLoadingLink] = useState<boolean | null>(null)

  const handleLinkSubscription = async () => {
    if (subscriptions && pickedOrganization) {
      setLoadingLink(true)
      const response = await linkSubscription(
        pickedSubscription?.id!,
        pickedOrganization.id.toString()
      )
      setLoadingLink(false)
      if (response?.success) {
        window.location.href = '/'
      } else {
        alert(`${response.type}: ${response.detail || "No details"}`)
      }
    }
  }

  const handlePickOrganization = (org) => {
    onChange(org)
    setPickedOrganization(org)
  }

  const handlePickSubscription = (subscription: Product) => {
    setPickedSubscription(subscription)
  }

  useEffect(() => {
    setPickedOrganization(currentOrganization)
  }, [currentOrganization])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        padding: '70px 0 140px 0',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '.5rem',
          alignItems: 'center',
        }}
      >
        <h1>Link subscriptions</h1>
        <span className="subtitle-text" style={{ textAlign: 'center' }}>
          Select an organization to link this subscription to
        </span>
        <div className="picker-wrapper">
         {organizations && organizations?.length > 0 ? <OrganizationPicker
            currentOrganization={currentOrganization}
            isLoadingOrganization={false}
            organizations={organizations!}
            organizationSlug={currentOrganization?.slug!}
            page={'link-subscriptions'}
            onPick={(organization) => handlePickOrganization(organization)}
            style={{ width: '100%' }}
            showLabel={true}
          /> : <span className="subtitle-text" style={{ textAlign: 'center' }}>No available organizations</span>}
          <span className="material-icons-outlined">{organizations && organizations?.length > 0 ? "link" : "link_off"}</span>
          {subscriptions?.length > 0 ? (
            <DropDownField
              label="Subscription"
              value={pickedSubscription?.id!}
              options={subscriptions?.map((sub) => ({
                label: sub.name,
                value: sub.id,
              }))}
              disabled={loadingLink || false}
              onChange={(subscriptionId) =>
                handlePickSubscription(
                  subscriptions?.find((sub) => sub.id === subscriptionId)!
                )
              }
              inputRef={null}
              hasError={false}
              loadingOptions={false}
              filterWithSimilarity={false}
              style={{ margin: 0 }}
            />
          ) : (
            <ScreenLoading spinnerColor="maya-green" />
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '2rem',
          width: '100%',
        }}
      >
        <div className="form-choice">
          <button className={`submit-button ${organizations !== null && subscriptions && (organizations?.length <= 0 || subscriptions?.length <= 0) ? "disabled" : ""}`} onClick={handleLinkSubscription} disabled={organizations !== null && subscriptions && (organizations?.length <= 0 || subscriptions?.length <= 0)}>
            {loadingLink ? (
              <ScreenLoading spinnerColor="white" spinnerWidth="lg" />
            ) : (
              'Link'
            )}
          </button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      ></div>
    </div>
  )
}

export default React.memo(LinkSubscriptions)
