import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from 'containers/authentication/authenticationSlice'

import api, { isAPIError } from 'containers/api'

const useGetPreviewByColumn = () => {
  const token = useSelector(selectToken)
  const [preview, setPreview] = useState()
  const [loadingPreview, setloadingPreview] = useState(true)
  const [previewError, setPreviewError] = useState('')

  const getPreviewByColumn = async ({
    category,
    columnId,
    organization,
    column,
    orderBy,
    orderDirection,
  }) => {
    setloadingPreview(true)
    setPreviewError('')
    try {
      const responsePreview = await api.getPreviewByColumn({
        category,
        columnId,
        organizationId: organization.id,
        token,
        column,
        orderBy,
        orderDirection,
      })
      setPreview(responsePreview)
      setloadingPreview(false)
      return responsePreview
    } catch (error) {
      if (isAPIError(error)) {
        setPreviewError(error.response.data.detail.type)
      } else {
        setPreviewError('Server error')
      }
    }
    setloadingPreview(false)
  }

  return { preview, loadingPreview, previewError, getPreviewByColumn }
}

export default useGetPreviewByColumn
