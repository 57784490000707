import { createSlice } from '@reduxjs/toolkit'

export const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    services: null,
  },
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload
    },
  },
})

export const { setServices } = servicesSlice.actions

export default servicesSlice.reducer

export const selectServices = (state) => state.services.services
