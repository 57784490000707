import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { IOrganization } from 'types'

const useGetCustomTableColumns = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const getTableColumns = (category: string) => {
    return api.getCustomTableColumns(token, organization.id, category)
  }

  return useDataLoader(getTableColumns)
}

export default useGetCustomTableColumns
