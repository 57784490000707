import { useSelector } from 'react-redux'
import { IManualExpense } from 'types'

import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useMutator from 'containers/mutator'

const useSaveManualExpense = (organizationId: number | null) => {
  const token = useSelector(selectToken)
  const saveExpense = async (expense: IManualExpense) => {
    if (organizationId) {
      return api.saveManualExpense({
        organizationId: organizationId,
        token: token,
        expense: expense,
      })
    } else {
      throw new Error('organization not loaded yet')
    }
  }
  return useMutator(saveExpense)
}

export default useSaveManualExpense
