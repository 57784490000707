import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import { selectToken, setToken } from './authenticationSlice'

import api from '../api'

const useToken = () => {
  const [isLoadingToken, setIsLoadingToken] = useState(true)
  const token = useSelector(selectToken)

  const dispatch = useDispatch()

  const refreshToken = async () => {
    try {
      const response = await api.post('/auth/refresh_token')
      const data = response.data

      dispatch(
        setToken({
          accessToken: data.access_token,
          accessTokenExpiresAt: data.access_token_expires_at,
          userId: data.user_id,
        })
      )
    } catch (error) {
      Sentry.captureException(error)
    }
    setIsLoadingToken(false)
  }

  useEffect(() => {
    refreshToken()

    setInterval(() => refreshToken(), 300000)

    return () => {
      clearInterval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoadingToken, refreshToken }
}

export default useToken
