import React from 'react'

import { IOrganization } from 'types'

interface IOrganizationContext {
  currentOrganization: IOrganization
  isLoadingOrganization: boolean
}

const OrganizationsContext = React.createContext<IOrganizationContext>({
  currentOrganization: { id: 0, name: '', slug: '', currency_id: 'USD' },
  isLoadingOrganization: false,
})

export default OrganizationsContext
