import { useState } from 'react'

export enum FetchStatus {
  NotFetched = 'not_fetched',
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

function useDataLoader<T>(
  loadFn: (...args: any[]) => Promise<T>,
  extraArgs = [],
  initialValue: any = null
): [T, Function, string, string] {
  const [data, setData] = useState(initialValue)
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(
    FetchStatus.NotFetched
  )
  const [fetchError, setFetchError] = useState<string>('')

  const fetch = async (...args: any[]) => {
    setFetchError('')
    setFetchStatus(FetchStatus.Pending)
    args.push(...extraArgs)
    try {
      const data = await loadFn(...args)
      setData(data)
      setFetchStatus(FetchStatus.Fulfilled)
      return data
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'Something went wrong'
      setFetchError(message)
      setFetchStatus(FetchStatus.Rejected)
      return null
    }
  }

  return [data, fetch, fetchStatus, fetchError]
}

export default useDataLoader
