import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api from 'containers/api'
import useMutator from 'containers/mutator'

const useTriggerETL = () => {
  const token = useSelector(selectToken)
  const triggerETL = async (organizationId: number, args: string | null = null) => {
    return await api.triggerETL({ args, organizationId, token })
  }
  return useMutator(triggerETL)
}

export default useTriggerETL
