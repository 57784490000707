import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api, { isAPIError } from '../api'
import useDataLoader from '../dataLoader'

const useListEmbedReports = () => {
  const token = useSelector(selectToken)
  const fetchEmbedReports = async (organizationId: number) => {
    try {
      const data = (await api.listEmbedReports({ token, organizationId }))
        .reports
      return data
    } catch (error) {
      if (
        isAPIError(error) &&
        error.response.data.detail.type === 'workspace_not_setup'
      ) {
        throw Error('Data not yet available. Please try again later.')
      } else {
        console.error('error loading embed reports', error)
        throw Error('Failed to load dashboard. Please try again later.')
      }
    }
  }

  return useDataLoader(fetchEmbedReports, [], [])
}

export default useListEmbedReports
