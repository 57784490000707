import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { Column, IOrganization } from 'types'

const useUploadCustomTable = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const uploadTable = async (columns: Column[], category: string, file: File, setUploadStatus = (status: any) => {}) => {
    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent
      const percentCompleted = Math.floor((loaded * 100) / total)
      setUploadStatus(percentCompleted)
    }
    const res = await api.uploadTable(
            columns,
            category,
            file,
            onUploadProgress,
            token,
            organization.id,
    )
    return res
  }

  return useDataLoader(uploadTable)
}

export default useUploadCustomTable
