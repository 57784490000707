import useMicrosoft from 'containers/authentication/useMicrosoft'
import useSignIn from 'containers/authentication/useSignIn'
import useQuery from 'containers/useQuery'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const MonaRedirect = () => {
  const code = useQuery().get('code')
  const subUri = useQuery().get('state')
  const [subscriptionId, setSubscriptionId] = useState<{ sub_id: string } | null>(
    null
  )

  const history = useHistory()
  const { createMonaSubscription } = useMicrosoft()
  const { signInWithMicrosoft, signInError } = useSignIn()

  useEffect(() => {
    const createSubscription = async () => {
      if (code && subUri) {
        const response = await createMonaSubscription(subUri, code)
        if (response.success) {
          setSubscriptionId(response.data.sub_id)
          if (
            await signInWithMicrosoft({
              microsoft_access_token: response.data.access_token,
              is_mona: true,
            })
          ) {
            if (response?.data?.sub_id) {
              history.push('/')
            }
          }
        }
      }
    }
    createSubscription()
  }, [code, subUri])

  useEffect(() => {
    if (!signInError.details) return
    if (signInError.details.type === 'merge_required') {
      history.push({
        pathname: `/merge-account`,
        search: `?email=${signInError.details.email}&provider=${signInError.details.provider}&is_google_signin=${signInError.details.is_google_signin}&is_microsoft_signin=${signInError.details.is_microsoft_signin}&is_password_signin=${signInError.details.is_password_signin}&is_mona=1`,
        state: {
          microsoftAccessToken: signInError.details.microsoft_access_token,
        },
      })
    } else if (signInError.details.type === 'no_such_user') {
      history.push({
        pathname: '/sign-up',
        search: `?redirect_error=no_such_user`,
      })
    }
  }, [signInError, subscriptionId])

  return <></>
}

export default React.memo(MonaRedirect)
