import { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import produce from 'immer'

import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

import { IInvite } from 'types'

const useGetPendingInvites = () => {
  const token = useSelector(selectToken)
  const [pendingInvites, setPendingInvites] = useState<IInvite[]>([])
  const [loadingPendingInvites, setLoadingPendingInvites] = useState(true)
  const [pendingInvitesError, setPendingInvitesError] = useState(false)

  const handleDeleteInviteFromList = (uuid: string) => {
    setPendingInvites(
      produce(pendingInvites, (draft) => {
        const deleteIndex = _.findIndex(pendingInvites, ['uuid', uuid])

        draft.splice(deleteIndex, 1)
      })
    )
  }

  const getPendingInvites = async ({ organization }) => {
    const organizationId = organization.id
    try {
      let responsePendingInvites = await api.getPendingInvites({
        organizationId,
        token,
      })

      responsePendingInvites = responsePendingInvites.map((invite) => ({
        ...invite,
        status: 'pending',
      }))
      setPendingInvites(responsePendingInvites)
      setLoadingPendingInvites(false)
    } catch {
      setPendingInvitesError(true)
    }
    setLoadingPendingInvites(false)
  }

  return {
    pendingInvites,
    setPendingInvites,
    loadingPendingInvites,
    pendingInvitesError,
    getPendingInvites,
    handleDeleteInviteFromList,
  }
}

export default useGetPendingInvites
