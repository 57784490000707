import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api, { isAPIError } from '../api'
import useDataLoader from '../dataLoader'

const useAccessEmbedReport = (organizationId: number, reportSlug: string) => {
  const token = useSelector(selectToken)
  const accessEmbedReport = async () => {
    try {
      const data = (
        await api.accessEmbedReport({ token, organizationId, reportSlug })
      ).data
      return data
    } catch (error) {
      if (
        isAPIError(error) &&
        error.response.data.detail.type === 'workspace_not_setup'
      ) {
        throw Error('Data not yet available. Please try again later.')
      } else {
        console.error('error loading embed report', error)
        throw Error('Failed to load dashboard. Please try again later.')
      }
    }
  }

  return useDataLoader(accessEmbedReport)
}

export default useAccessEmbedReport
