import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { IPageSpeedLink } from 'types'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import usePageSpeedLinks from 'containers/pagespeed/usePageSpeedLinks'
import AutoCompleteField from 'components/AutoCompleteField'
import ScreenLoading from 'components/ScreenLoading'

import './PageSpeed.scss'

// from  https://www.webmasterworld.com/devshed/javascript-development-115/regexp-to-match-url-pattern-493764.html
function isValidURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  const r = !!pattern.test(str)
  return r
}

const PageSpeed = () => {
  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)
  const [newUrlIndex, setNewUrlIndex] = useState(0)
  const {
    urls,
    loadingUrls,
    topUrls,
    loadingTopUrls,
    changeUrl,
    deleteUrl,
    addEmptyUrl,
    loadUrls,
    loadTopUrls,
    saveUrls,
    isSaved,
    loadingUrlsError,
    isSaving,
    savingError,
  } = usePageSpeedLinks(currentOrganization.id)
  const ALLOWED_URLS = 100

  useEffect(() => {
    if (!isLoadingOrganization) {
      Promise.all([loadUrls(), loadTopUrls()])
    }
  }, [isLoadingOrganization, currentOrganization.id, loadUrls, loadTopUrls])

  const handleUrlChange = (index: number, value: string) => {
    changeUrl(index, value)
  }

  const handleAddUrl = () => {
    if (urls.length >= ALLOWED_URLS) {
      return
    }
    addEmptyUrl(urls.length)
    setNewUrlIndex(urls.length)
  }

  const handleDeleteUrl = (index: number) => {
    deleteUrl(index)
  }

  const handleSave = async () => {
    await saveUrls()
  }

  const errors = useMemo(() => {
    return urls.map((u, i) => {
      if (!u.urlLink) {
        return 'Please specify URL'
      } else if (!isValidURL(u.urlLink)) {
        return 'Not a valid URL'
      } else if (urls.map((u) => u.urlLink).indexOf(u.urlLink) < i) {
        return 'Duplicate URL'
      }
      return ''
    })
  }, [urls])

  const hasErrors = useMemo(() => {
    return errors.some((e) => !!e)
  }, [errors])

  const isDuplicate = useCallback(
    (i, url) => {
      const index = urls.map((u) => u.urlLink).indexOf(url)
      return index >= 0 && index < i
    },
    [urls]
  )

  const addDisabled =
    isLoadingOrganization || loadingUrls || urls.length >= ALLOWED_URLS
  const saveDisabled =
    isLoadingOrganization || loadingUrls || hasErrors || isSaved

  return (
    <div className="clusters-campaigns container-column pagespeed">
      {loadingUrlsError ? (
        <>
          Failed to load saved PagedSpeed links ({loadingUrlsError}). Please try
          again later.
        </>
      ) : (
        <div className="head">
          <div className="page-name">
            <h2>PageSpeed Links</h2>
            <span className="subtitle-text">
              Select which pages of your website should be analyzed for
              performance metrics.
            </span>
          </div>
          {isLoadingOrganization || loadingUrls ? (
            <ScreenLoading />
          ) : (
            <>
              <div className="urls">
                {urls.map((url, i) => {
                  return (
                    <PageSpeedLink
                      key={url.uuid}
                      url={url}
                      onChange={(value: string) => handleUrlChange(i, value)}
                      isDuplicate={(u: string) => isDuplicate(i, u)}
                      error={errors[i]}
                      onDelete={() => handleDeleteUrl(i)}
                      options={topUrls}
                      isNewUrl={i === newUrlIndex}
                      loadingOptions={loadingTopUrls}
                    />
                  )
                })}
              </div>
              {!urls.length && (
                <div className="urls">You haven't specified any links.</div>
              )}
            </>
          )}
          <div className="actions">
            <button
              title={
                urls.length >= ALLOWED_URLS
                  ? `Maximum of ${ALLOWED_URLS} pages`
                  : ''
              }
              onClick={handleAddUrl}
              disabled={addDisabled}
              className={`submit-button ${
                addDisabled ? 'button-disabled' : ''
              }`}
            >
              <span className="enlarge plus-icon">+</span> Add
            </button>
            <div className="save-button">
              {!isSaving ? (
                <button
                  onClick={handleSave}
                  disabled={saveDisabled}
                  className={`submit-button ${
                    saveDisabled ? 'button-disabled' : ''
                  }`}
                >
                  Save
                </button>
              ) : (
                <ScreenLoading />
              )}
            </div>
            {isSaved && (
              <AnimatePresence>
                <motion.span
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="material-icons-outlined saved-icon non-selectable"
                >
                  task_alt
                </motion.span>
              </AnimatePresence>
            )}
            {savingError && (
              <div className="saving-error">
                Something went wrong ({savingError}). Please try again later.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

interface IPageSpeedLinkConfig {
  url: IPageSpeedLink
  options: string[]
  onChange: (v: string) => void
  onDelete: () => void
  error: string
  isDuplicate: (v: string) => boolean
  isNewUrl: boolean
  loadingOptions: boolean
}

const PageSpeedLink = ({
  url,
  options,
  onChange,
  onDelete,
  error,
  isDuplicate,
  isNewUrl,
  loadingOptions,
}: IPageSpeedLinkConfig) => {
  const unusedOptions = useMemo(
    () => options.filter((t) => !isDuplicate(t)),
    [options, isDuplicate]
  )
  return (
    <div className="url" key={url.uuid}>
      <div className="url-field">
        <AutoCompleteField
          value={url.urlLink}
          label="url"
          onChange={(value: string) => onChange(value)}
          options={unusedOptions}
          hasError={error !== ''}
          autoFocus={isNewUrl}
          loadingOptions={loadingOptions}
          filterWithSimilarity={false}
        />
        <span className="error-message"> {error} </span>
      </div>
      <span onClick={onDelete} className="material-icons-outlined delete">
        delete
      </span>
    </div>
  )
}

export default React.memo(PageSpeed)
