const TermsAndConditions = () => {
  return (
    <article className="terms-and-conditions">
      <p dir="ltr">
        <span>
          BY USING THE SERVICE, CUSTOMER AGREES THAT CUSTOMER HAS READ AND
          UNDERSTOOD, AND AS A CONDITION TO CUSTOMER’S USE OF THE SERVICE,
          CUSTOMER AGREES TO BE BOUND BY THESE GENERAL TERMS AND CONDITIONS
          (“GENERAL TERMS). THE PERSON WHO ENTERS INTO THE ORDER ON CUSTOMER’S
          BEHALF REPRESENTS THAT SUCH PERSON HAS THE AUTHORITY TO AND DOES BIND
          CUSTOMER TO THESE GENERAL TERMS. For good and valuable consideration,
          the receipt and sufficiency of which is hereby acknowledged,
          MayaInsights and Customer agree as follows:
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>1. General</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                1.1 These general terms and conditions (the “General Terms”)
                shall apply to the delivery of a Service to the Customer.
                Details of the delivery are provided in the applicable purchase
                order (“Order”).
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                1.2 The following order of precedence shall be applied in the
                event of conflict or inconsistency between the Order and these
                GeneralTerms: (i) the Order; (ii) schedules included with or
                referenced to in the Order or General Terms; (iii) and these
                General Terms.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>2. Definitions</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                2.1 Capitalized terms used in these General Terms shall have the
                following meaning:
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Advertising Spend” means Customer’s monthly cost for the online
                advertising for which the Service will be used.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Agreement” means these General Terms and any Order referencing
                these General Terms, and any other schedules, supplements,
                statements of work, exhibits or appendices thereto, whether
                attached or incorporated by reference.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Confidential Information” means, with respect to Customer, the
                Customer Data, marketing and business plans and/or Customer
                financial information, and with respect to MayaInsights: (a) the
                Service and service offering terms, including, without
                limitation, all (i) computer software (both object and source
                codes) and related Documentation or specifications; (ii)
                techniques, concepts, methods, processes and designs embodied in
                or relating to the Service; and (iii) all application program
                interfaces, system security and system architecture design
                relating to the Service; and (b) MayaInsights research and
                development, service offerings, pricing and availability. In
                addition to the foregoing, Confidential Information of either
                MayaInsights or Customer (the Party disclosing such information
                being the “Disclosing Party” and the Party receiving such
                information being the “Receiving Party”) may also include
                information which the Disclosing Party protects against
                unrestricted disclosure to others that (i) the Disclosing Party
                or its representatives designates as confidential at the time of
                disclosure; or (ii) should reasonably be understood by the
                Receiving Party to be confidential given the nature of the
                information and the circumstances surrounding its disclosure.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Customer” means the entity that has consented to the Agreement:
                (i) by execution of an Order that references these General
                Terms; (ii) by having started using the Service after signing up
                for it at MayaInsights’s website; or (iii) by any other legally
                binding method of acceptance of the Agreement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Customer Data” means any data that Customer imports to the
                Service from a Data Source or that is generated from such data
                as a result of Customer’s use of the Service.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Effective Date” means the earlier of (i) the start date for the
                Service set out in the Order; or (ii) date of signing of the
                Order.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Data Destination” means a destination to which Customer Data is
                exported from the Service.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Data Source” means a digital source from which Customer Data is
                imported to be used in the Service.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Digital Advertising Data Source” means a Data Source whose data
                derives from digital advertising on the Internet.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Documentation” means MayaInsights’s technical and functional
                documentation for the Service, prevailing at the time, which is
                made available to Customer.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Media Agency” a company that is directly or indirectly using
                the Service to provide media services or products to its
                customers.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Order” means the written order form provided by MayaInsights
                (including, if Customer is ordering the Service online, a
                registration website) entered into by the MayaInsights entity
                noted on the order and Customer containing the pricing,
                subscription period, Data Sources and Data Destinations, and
                other specific terms and conditions applicable to the Service.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Other Data Sources” means another Data Source than a Digital
                Advertising Data Source.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Party” or “Parties” shall mean each of MayaInsights or Customer
                or MayaInsights and Customer together.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Service” means the MayaInsights software as a service,
                described in the Order and Documentation or as provided by
                MayaInsights when the Customer has signed up for using it at
                MayaInsights’s website (subject to payment or for use during a
                free trial), including upgrades and updates thereto made
                available to the Customer pursuant to the Agreement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>3. Use of the Service</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.1&nbsp;Provision of the Service. MayaInsights shall make the
                Service available to Customer in accordance with the Order and
                during the term and hereby grants to Customer a non-exclusive,
                non-transferable, non-sublicensable right to remotely access and
                use the Service solely for Customer’s own internal business
                purposes as permitted by and subject to the terms of the
                Agreement and the Documentation. If Customer is a Media Agency,
                Customer may use the Service to provide services to its
                designated clients, subject to the restrictions in Section 3.3,
                and, if available, permit its customers to use the MayaInsights
                Reports Center functionality. Any charges from a Data Source
                owner, shall be the responsibility of the Customer.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.2&nbsp;Additional Data Source. Upon Customer’s request, and
                subject to MayaInsights’s consent, additional Data Sources may
                added during the term.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.3&nbsp;Restrictions. Customer may not: (i) sublicense,
                license, sell, lease rent or otherwise make the Service
                available to a third party; (ii) circumvent or disclose the user
                authentication or security of the Service or any host, network,
                or account related thereto; (iii) share non-public Service
                features or content with any third party; (iv) if the Customer
                is a Media Agency, directly or indirectly, export data to a
                customer’s Data Destination; (v) copy any ideas, features,
                functions or graphics of the Service or translate, disassemble,
                decompile, reverse-engineer or otherwise modify any parts of the
                Service; (vi) infringe the intellectual property rights of any
                entity or person; (vii) interfere with or disrupt the
                MayaInsights software or MayaInsights systems used to provide or
                host the Service, or other equipment or networks connected to
                the Service; (iix) access the Service in order to build a
                competitive product or service, to build a product using similar
                ideas, features, functions or graphics of the Service, or to
                copy any ideas, features, functions or graphics of the Service;
                or (ix) use the Service in a way that does not comply with
                applicable law.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.4&nbsp;Change or modification of the Service. MayaInsights may
                change or modify the Service at any time, including restricting
                the number of subaccounts. During the term, MayaInsights shall
                not materially diminish, reduce or eliminate any of the core
                functionalities of the Service. Customer shall be automatically
                entitled (as a part of and limited to its existing Agreement) to
                any functionality that is (as determined by MayaInsights, acting
                reasonably) a direct replacement or succession of any
                functionality removed from or replaced in the Service without
                any payment of additional fees. For the avoidance of any doubt,
                Customer shall not be entitled to any functionality that is
                beyond the scope of an Order. Where MayaInsights has materially
                diminished, reduced or eliminated any core functionality in the
                Service and no equivalent functionality is otherwise made
                available to the Customer, then Customer may terminate the
                Agreement in accordance with Section 11.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.5&nbsp;Setup Services and Support, Service Quality.
                MayaInsights shall free of charge provide the setup services and
                support, which may reasonably be required by the Customer to
                understand and use the functionality of the Service. Setup
                services and support and the service quality shall comply with
                reasonable market practice.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.6&nbsp;Information Security. MayaInsights undertakes to use
                good industry practices for information security (such as
                password protection, encryption, and firewall protection,
                logging and monitoring) when providing the Service. Further
                details about MayaInsights’s information security work are
                available at MayaInsights Security Policy.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>4. Customer’s responsibilities and obligations</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.1&nbsp;Customer Data. Customer is solely responsible for the
                accuracy, quality and integrity of the Customer Data that
                Customer enters into the Service or provides for input into the
                Service. Customer represents and warrants that it has collected
                and shall maintain and process all Customer Data in compliance
                with all applicable privacy and data protection laws and
                regulations. Customer is solely responsible for determining the
                suitability of the Service for Customer's business and complying
                with any laws and regulations applicable to the Customer Data
                and Customer’s use of the Service.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
              Use of Data. Customer hereby grants to MayaInsights a
              non-exclusive right to collect and analyse data and other 
              information relating to the provision, use and performance of 
              various aspects of the Service and related systems and 
              technologies (including, without limitation, information 
              concerning Customer Data and data derived therefrom), and 
              MayaInsights shall be free (during and after the term hereof) to 
              (i) subject to Section 8, use such information and data to 
              improve and enhance the Service and for development, diagnostic 
              and corrective purposes in connection with the Service and other 
              offerings, and (ii) disclose such data solely in aggregated or 
              other de-identified form in connection with its business. 
              Receiving parties may be third-party cloud computing and AI services, 
              which receive data strictly for processing, analysis, and operational 
              enhancements critical to our service delivery and improvement, 
              always within the framework of strict confidentiality and data 
              protection compliance. The underlying data includes, but is 
              not limited to marketing, CRM and/or behavioral information in 
              an anonymized, aggregated, sampled, or customized manner to uphold 
              privacy standards.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.3&nbsp;Customer Account. Customer shall designate one of its
                employees to be the point of contact with MayaInsights for the
                management and support of the Service, and who will be
                responsible for establishing and managing Customer’s use of the
                Service, including the creation of usernames and passwords to
                access Customer’s account. Customer is solely responsible for
                maintaining the status of its user base. Customer will safeguard
                all user authentication credentials in its possession or under
                its control. Customer is responsible for all activities that
                occur under its account, including without limitation
                unauthorised access. Customer will notify MayaInsights
                immediately if Customer believes an unauthorised third party may
                be using Customer’s account or if Customer’s account information
                is lost or stolen.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.4&nbsp;User Data. When fulfilling its obligations under the
                Agreement, MayaInsights will collect and process such
                information, which is necessary to administrate Customer’s
                access and use of the Service and may constitute personal data,
                e.g. email addresses, authentication credentials and other data
                related to the use of the Service. MayaInsights will be the
                controller and responsible for the processing activities
                mentioned in this Section 4.4 and Customer shall ensure that its
                users, which may be subject to such processing, are duly
                informed about it and consents to the processing.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.5&nbsp;Suspension. MayaInsights may, in addition to such other
                remedies as MayaInsights may have, suspend Customer’s right to
                access or use any portion of the Service immediately without
                advance notice to Customer if MayaInsights determines that
                Customer’s or its users’ use of the Service (i) do not comply
                with the prohibitions described in Section 3.3; (ii) poses a
                security risk to the Service or any third party; (iii) may
                adversely impact the Service, or the networks or data of any
                other MayaInsights service provider, customer or business
                partner; (iv) does not comply with applicable law; or (v) may
                subject MayaInsights or any third party to liability; or (vi) is
                a violation of the infrastructure provider’s acceptable use or
                similar policy. MayaInsights may terminate the Agreement if
                Customer fails to rectify such use within thirty (30) days from
                notification by MayaInsights.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>5. Personal Data</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                MayaInsights may process personal data when providing the
                Service. The Customer is, or shall be regarded as a controller
                of the processing of such personal data and MayaInsights is, or
                shall be regarded as, a processor of such personal data.
                MayaInsights will process such personal data in accordance with
                the terms set forth in Data Processing Appendix.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>6. Ownership of Intellectual Property Rights</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                6.1&nbsp;MayaInsights IP. MayaInsights, or its licensors, own
                all right, title and interest in and to any and all copyrights,
                trademark rights, patent rights, database rights and other
                intellectual property or other rights in and to the Service,
                including without limitation all software, integrations,
                integrations with Data Sources and Data Destinations, technology
                and other rights used to provide the Service, and all graphics,
                user interfaces and any documentation, any improvements, design
                contributions or derivative works thereto, and any knowledge or
                processes related thereto and/or provided hereunder. Except for
                the limited rights expressly granted herein, the Agreement does
                not transfer from MayaInsights any proprietary right or interest
                in the Service. All rights not expressly granted to Customer in
                the Agreement are reserved to MayaInsights and its licensors.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                6.2&nbsp;Customer IP. Customer shall own all right, title and
                interest in and to any copyrights, trademark rights, patent
                rights, database rights and other intellectual property or other
                rights in and to the Customer Data. Except for the limited
                rights expressly granted herein, the Agreement does not transfer
                from Customer any proprietary right or interest in the Customer
                Data. All rights regarding Customer Data not expressly granted
                to MayaInsights in the Agreement are reserved to Customer.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>7. Fees and Payment</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.1&nbsp;Fees. Customer shall pay to MayaInsights the fees for
                the Service and any charges from Data Source owners. Except as
                expressly set forth in the Order, the Service is non-cancellable
                and all fees are non-refundable. Customer shall have no right to
                withhold or reduce fees under the Agreement or set off any
                amount against fees owed for alleged defects in the Service.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.2&nbsp;Payment. Customer shall pay to MayaInsights the fees
                for the Service provided hereunder, in the amount set forth in
                the Order, by credit card or by invoice. Payment shall always be
                made prior to the start date of the Service. Without limiting
                any other rights or remedies MayaInsights may have, any amount
                not paid when due will be subject to interest equal to the
                lesser of: (i) 1.5% per month of the overdue amount; or (ii) the
                highest lawful rate allowed by applicable law. Such interest
                shall accrue on a daily basis from the due date until actual
                payment of the overdue amount, whether before or after judgment.
                In addition to any interest due under this Section 7.2 Customer
                shall reimburse any costs or expenses (including, but not
                limited to, any penalties, charges and legal and other
                reasonable professional costs and expenses) incurred by
                MayaInsights to collect any amount that is not paid when due.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.3&nbsp;Taxes. All fees are exclusive of taxes, levies, and
                duties, and Customer shall be responsible for payment of all
                such taxes, levies, and duties, including value-added tax (VAT),
                withholding, or similar taxes. MayaInsights may calculate taxes
                based on the billing information Customer provides.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.4&nbsp;Fee Increase. MayaInsights may increase fees for the
                Service, which will be effective at the beginning of the next
                subscription period. MayaInsights will notify Customer of any
                increase prior to it becoming effective; notice may be in the
                form of an invoice. Customer acknowledges that expiration of any
                discount or incentive programs to which Customer was previously
                entitled do not constitute a fee increase.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.5&nbsp;Effects on non-payment. MayaInsights may suspend
                Customer’s access to the Service without advanced notice if
                Customer fails to pay in full when due.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>8. Confidentiality</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                8.1&nbsp;Restrictions on Use and Disclosure. Confidential
                Information shall not be used or reproduced in any form except
                as required to accomplish the intent of the Agreement. Any
                reproduction of any Confidential Information of the other Party
                shall remain the property of the Disclosing Party and shall
                contain any and all confidential or proprietary notices or
                legends, which appear on the original. With respect to the
                Confidential Information of the other, each Party (i) shall take
                all Reasonable Steps (defined below) to keep all Confidential
                Information strictly confidential; and (ii) shall not disclose
                any Confidential Information of the other to any person other
                than individuals whose access is necessary to enable it to
                exercise its rights and/or perform its obligations hereunder and
                who are under obligations of confidentiality substantially
                similar to those set forth herein. As used herein “Reasonable
                Steps” means those steps the Receiving Party takes to protect
                its own similar proprietary and confidential information, which
                shall not be less than a reasonable standard of care.
                Confidential Information of either Party disclosed prior to
                execution of the Agreement shall be subject to the protections
                afforded hereunder.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                8.2&nbsp;Exclusions. Confidential Information does not include
                information that the Receiving Party can establish: (i) has
                entered the public domain without the Receiving Party’s breach
                of any obligation owed to the Disclosing Party; (ii) has been
                rightfully received by the Receiving Party from a third party
                without confidentiality restrictions; (iii) is known to the
                Receiving Party without any restriction as to use or disclosure
                prior to first receipt by the Receiving Party from the
                Disclosing Party; or (iv) has been independently developed by
                the Receiving Party without use of or reference to the
                Disclosing Party’s Confidential Information.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                8.3&nbsp;Disclosure Required By Law. If the Receiving Party is
                compelled by law or legal process to disclose Confidential
                Information of the Disclosing Party, it shall provide the
                Disclosing Party with prompt prior notice of such compelled
                disclosure (to the extent legally permitted) and reasonable
                assistance, at the Disclosing Party’s expense, if the Disclosing
                Party wishes to contest the disclosure.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>9. Warranties</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                9.1&nbsp;Mutual. Each Party represents and warrants to the other
                Party: (i) it is duly organized, validly existing, and in good
                standing as a corporation or other entity under the laws of the
                jurisdiction of its incorporation or other organization; (ii) it
                has the full right, power, and authority to enter into and
                perform its obligations and grant the rights, licenses,
                consents, and authorizations it grants or is required to grant
                under this Agreement; (iii) the representative who entered into
                this Agreement on behalf of a Party has been duly authorized by
                all necessary corporate or organizational action of such Party;
                and (iv) this Agreement will constitute the legal, valid, and
                binding obligation of such Party, enforceable against such Party
                in accordance with its terms.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                9.2&nbsp;Service. MayaInsights warrants that the Service will
                substantially conform to the specifications stated in the
                Agreement and the Documentation. The foregoing warranty shall
                not apply to the extent: (i) the Service is not being used in
                accordance with the Agreement and/or any Documentation; (ii) any
                non-conformity is caused by third party products, content or
                service being accessed through the Service that are identified
                as third party products, content or service not part of the
                Service (e.g. a Data Source); or (iii) the Service being used
                was provided for free (no fee) or is a trial use of the Service.
                Subject to Section 9.5, Customer’s sole and exclusive remedy,
                and MayaInsights’s entire liability for breach of the limited
                warranty in this Section 9.2, shall be correction of the
                warranted non-conformity or, if MayaInsights fails to correct
                the warranted non-conformity after using reasonable commercial
                efforts, MayaInsights may terminate access to the non-conforming
                Service and refund the fees paid by Customer for the Service for
                the remainder of the term (starting on the date Customer
                reported the non-conformity).
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                9.3&nbsp;Insurance. MayaInsights is insured with financially
                sound and reputable insurance companies, in such amounts, with
                such deductibles and covering such risks as are customarily
                carried by companies engaged in similar businesses, providing
                similar services and in localities where MayaInsights operates.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                9.4&nbsp;Viruses. MayaInsights warrants that it shall exercise
                commercially reasonable efforts to keep the Service free of all
                computer viruses, Trojan horses, and comparable malicious code
                intended to harm the Customer’s systems (collectively, “Virus”)
                provided that MayaInsights shall not be responsible for any such
                Virus that is placed on the Service by Customer or its users or
                any third party.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                9.5&nbsp;Remedies. In case of any non-conformity described in
                this Section 9, Customer shall provide MayaInsights with prompt
                written notice for any non-conformity of the Service, within
                thirty (30) days from Customer’s discovery, or when it
                reasonably should have discovered, such non-conformity.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                9.6&nbsp;Warranty disclaimer. EXCEPT AS EXPRESSLY PROVIDED IN
                THE AGREEMENT, MayaInsights DOES NOT MAKE ANY REPRESENTATIONS,
                WARRANTIES, TERMS, CONDITIONS OR STATEMENTS, EXPRESS OR IMPLIED,
                STATUTORY OR OTHERWISE REGARDING ANY MATTER, INCLUDING THE
                MERCHANTABILITY, SUITABILITY, ORIGINALITY, OR FITNESS FOR A
                PARTICULAR USE OR PURPOSE, NON-INFRINGEMENT OR RESULTS TO BE
                DERIVED FROM THE USE OF OR INTEGRATION WITH THE SERVICE OR OTHER
                MATERIALS PROVIDED UNDER THE AGREEMENT, OR THAT THE OPERATION OF
                THE SERVICE WILL BE SECURE, UNINTERRUPTED OR ERROR FREE.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>10. Third Party Indemnification</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                10.1&nbsp;Claims Brought Against Customer. MayaInsights shall
                defend (at its sole expense) Customer against claims brought
                against Customer by any third party alleging that Customer’s use
                of the Service, in accordance with the terms and conditions of
                the Agreement, constitutes an infringement or misappropriation
                of a patent claim(s), copyright, or trade secret rights or any
                other third party intellectual property rights. MayaInsights
                will pay damages finally awarded against Customer with respect
                to such claims, and will pay reasonable attorney’s fees in
                connection with such defense. This obligation of MayaInsights
                shall not apply if the alleged infringement or misappropriation
                results from use of the Service in conjunction with any other
                software or service not provided by MayaInsights or to free (no
                fee) or trial use of the Service.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                10.2&nbsp;Intellectual Property Claims. In the event a claim
                under Section 10.1 is made or in MayaInsights’s reasonable
                opinion is likely to be made, MayaInsights may, at its sole
                option and expense: (i) procure for Customer the right to
                continue using the Service under the terms of the Agreement; or
                (ii) replace or modify the Service to be non-infringing without
                material decrease in functionality. If MayaInsights provides
                written notice to Customer that the foregoing options are not
                reasonably available, MayaInsights or Customer may terminate the
                Agreement and MayaInsights shall refund to Customer all prepaid
                fees for the remainder of its term after the date of
                termination.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                10.3&nbsp;Claims Brought Against MayaInsights. Customer shall
                defend (at its sole expense) MayaInsights and licensors against
                claims brought against MayaInsights by any third party arising
                from or related to an allegation that the Customer Data used in
                connection with the Service violates, infringes or
                misappropriates the intellectual property rights of a third
                party. Customer will pay damages finally awarded against
                MayaInsights with respect to such claims, and will pay
                reasonable attorney’s fees in connection with such defense. The
                foregoing shall apply regardless of whether such damage is
                caused by the conduct of Customer or by the conduct of a third
                party using Customer’s access credentials.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                10.4&nbsp;Conditions. The obligations under this Section 10 are
                conditioned on (i) the Party against whom a third party claim is
                brought timely notifying the other Party in writing of any such
                claim, provided however that a Party’s failure to provide or
                delay in providing such notice shall not relieve a Party of its
                obligations under this Section 10 except to the extent such
                failure or delay prejudices the defense; (ii) the Party who is
                obligated hereunder to defend a claim having the right to fully
                control the defense of such claim; and (iii) the Party against
                whom a third party claim is brought reasonably cooperating in
                the defense of such claim. Any settlement of any claim shall not
                include a financial or specific performance obligation on or
                admission of liability by the Party against whom the claim is
                brought, provided however that MayaInsights may settle any claim
                on a basis requiring MayaInsights to substitute for the Service
                any alternative substantially equivalent non-infringing service.
                The Party against whom a third party claim is brought may
                appear, at its own expense, through counsel reasonably
                acceptable to the Party obligated to defend claims hereunder.
                Neither Party shall undertake any action in response to any
                infringement or misappropriation, or alleged infringement or
                misappropriation that is prejudicial to the other Party’s
                rights.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                10.5&nbsp;Third Party Indemnification Disclaimer. THE PROVISIONS
                OF THIS SECTION 10 STATE THE SOLE, EXCLUSIVE AND ENTIRE
                LIABILITY OF A PARTY TO THE OTHER PARTY, AND IS THE OTHER
                PARTY’S SOLE REMEDY, WITH RESPECT TO THIRD PARTY CLAIMS COVERED
                HEREUNDER AND TO THE INFRINGEMENT OR MISAPPROPRIATION OF
                THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>11. Term and termination</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.1&nbsp;Term. The term of the Agreement shall begin on the
                Effective Date and shall continue for the subscription period
                designated in the Order, including any renewals, or if no
                subscription period is designated in the Order, until terminated
                by one of the Parties. If a subscription period is designated in
                the Order, the term will renew automatically on the last day of
                each subscription period for an additional time period
                corresponding to the prior subscription period.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.2&nbsp;Termination for Cause. MayaInsights may terminate the
                Agreement (including without limitation Customer’s access to the
                Service) without advance notice if Customer fails to pay
                applicable fees when due. Either Party may terminate the
                Agreement for any other material breach by the other Party via
                written notice, effective in thirty (30) days unless the other
                Party within such time period cures such breach.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.3&nbsp;Termination without Cause. Either Party may terminate
                the Agreement without cause by providing notice of termination
                at least thirty (30) days prior to the end of the then-current
                subscription period.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.4&nbsp;Effects of Termination. Upon termination of the
                Agreement, Customer shall cease all use of the Service and
                delete, destroy, or return all copies of the Documentation in
                its possession or control.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.5&nbsp;Surviving Sections. The following provisions shall
                survive termination or expiration of the Agreement: (i) Section
                3.3 Restrictions; (ii) Section 5 Ownership of Intellectual
                Property Rights; (iii) Section 7 Fees and Payment; (iv) Section
                8 Confidentiality; (v) Section 9 Warranties; (vi) Section 10
                Third Party Indemnification; (vii) Section 11.5 Surviving
                Sections; (viii) Section 12 Limitation of Liability; (ix)
                Section 14 Dispute Resolution; and (x) any other provision of
                the Agreement that must survive to fulfill its essential
                purpose.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>12. Limitation of liability</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                12.1&nbsp;Death or Personal Injury. Nothing in the Agreement
                shall limit or exclude either Party’s liability for death or
                personal injury caused by its negligence; or for fraud or
                fraudulent misrepresentation; or any other liability that may
                not be excluded or limited by law.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                12.2&nbsp;Exclusion of Liability. SUBJECT TO SECTION 12.1, AND
                EXCLUDING GROSS NEGLIGENCE, IN NO EVENT SHALL A PARTY BE LIABLE
                TO THE OTHER PARTY, OR TO ANY THIRD PARTY FOR ANY CONSEQUENTIAL,
                INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR EXEMPLARY DAMAGES,
                WHETHER FORESEEABLE OR UNFORESEEABLE (INCLUDING DAMAGES FOR LOSS
                OF DATA, GOODWILL, DIRECT OR INDIRECT PROFITS, INVESTMENTS;
                INTERRUPTION IN USE OR AVAILABILITY OF DATA; STOPPAGE OF OTHER
                WORK OR IMPAIRMENT OF OTHER ASSETS), EVEN IF SUCH PARTY HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF (I)
                THE PERFORMANCE OR NON-PERFORMANCE OF THE AGREEMENT OR THE
                SERVICE PROVIDED HEREUNDER, OR (II) ANY CLAIM, CAUSE OF ACTION,
                BREACH OF CONTRACT OR ANY EXPRESS OR IMPLIED WARRANTY, UNDER THE
                AGREEMENT OR OTHERWISE, MISREPRESENTATION, NEGLIGENCE, STRICT
                LIABILITY OR OTHER TORT. If the Order includes a free trial
                (“Trial Period”), then the following terms apply for the Trial
                Period: NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS
                AGREEMENT, DURING THE FREE TRIAL PERIOD THE SERVICE IS PROVIDED
                “AS-IS” WITHOUT ANY WARRANTY AND MayaInsights SHALL HAVE NO
                INDEMNIFICATION OBLIGATIONS NOR LIABILITY OF ANY TYPE WITH
                RESPECT TO THE SERVICE FOR THE FREE TRIAL PERIOD UNLESS SUCH
                EXCLUSION OF LIABILITY IS NOT ENFORCEABLE UNDER APPLICABLE LAW
                IN WHICH CASE MayaInsights’S LIABILITY WITH RESPECT TO THE
                SERVICE PROVIDED DURING THE FREE TRIAL PERIOD SHALL NOT EXCEED
                $100.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                12.3&nbsp;General Limitation of Liability. Subject to Section
                12.1, 12.2, and excluding Section 7 Fees and Payment, Section 8
                Confidentiality, Section 10 Third Party Indemnification or any
                other liability which cannot be excluded or limited by
                applicable law, the aggregate liability of each Party to the
                other Party, or any third party in connection with the
                Agreement, shall not exceed the annual fees payable for the
                Service under the Agreement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                12.4&nbsp;Allocation of Risks. The provisions of the Agreement
                allocate the risks between MayaInsights and Customer. The
                Service fees reflect this allocation of risk and limitations of
                liability herein. The aforementioned liability limitations shall
                include any claims against employees of, subcontractors of, or
                any other persons authorised by either Party.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>13. Miscellaneous</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.1&nbsp;No Partnership. The Parties are independent
                contractors, and no partnership, franchise, joint venture,
                agency, fiduciary or employment relationship between the Parties
                is created hereby. Notwithstanding any other provision in the
                Agreement, nothing in the Agreement shall create or confer
                (whether expressly or by implication) any rights or other
                benefits in favour of any person not a party hereto.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.2&nbsp;Publicity. Provided that a Party complies with any
                trademark usage requirements notified to it by the other Party,
                each Party may refer to the other Party as a customer (for
                MayaInsights) and a vendor (for Customer) and use the other
                Party’s logo as part of such reference. Upon execution of the
                Agreement, MayaInsights may either (i) issue a press release
                announcing the relationship between MayaInsights and Customer;
                or (ii) submit a joint press release to Customer for Customer’s
                approval, such approval not to be unreasonably withheld or
                delayed. Customer agrees to be a reference account for
                MayaInsights, provided however that MayaInsights will provide
                Customer with reasonable notice and obtain Customer’s consent
                before scheduling any reference calls.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.3&nbsp;Non-solicitation of personnel. During the term of the
                Agreement, and for a period of twelve (12) months thereafter,
                neither Party will, without the prior written consent of the
                other, directly or indirectly solicit, hire or employ any
                employee or individual independent contractor of the other Party
                who has been involved in the provision of the Service during the
                preceding year to become an employee or individual independent
                contractor of the other. Nothing in this Section 13.3 will make
                a Party liable for general solicitations in the media or on the
                Internet.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.4&nbsp;Force majeure. Any delay or non-performance of any
                provision of the Agreement caused by conditions beyond the
                reasonable control of the performing Party (force majeure) shall
                not constitute a breach of the Agreement, and the time for
                performance of such provision, if any, shall be deemed to be
                extended for a period equal to the duration of the conditions
                preventing performance.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.5&nbsp;Severability, invalidity. To the extent permitted by
                applicable law, the Parties hereby waive any provision of law
                that would render any Section of the Agreement invalid or
                otherwise unenforceable in any respect. In the event that a
                provision of the Agreement is held to be invalid or otherwise
                unenforceable, such provision will be interpreted to fulfil its
                intended purpose to the maximum extent permitted by applicable
                law, and the remaining provisions of the Agreement will continue
                in full force and effect.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.6&nbsp;Waiver.&nbsp;Neither Party will be deemed to have
                waived any of its rights under the Agreement by lapse of time or
                by any statement or representation other than by an authorized
                representative in an explicit written waiver. No waiver of a
                breach of the Agreement will constitute a waiver of any other
                breach of the Agreement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.7&nbsp;Construction. The Parties agree that the terms of the
                Agreement result from negotiations between them. The Agreement
                will not be construed in favor of or against either Party by
                reason of authorship.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.8&nbsp;Execution of the Order. The Order shall be signed in
                two counterparts, each of which shall be deemed an original and
                which shall together constitute one Order. An Order may also be
                executed electronically. Signatures sent by electronic means
                (facsimile or scanned and sent via email, or signed by
                electronic signature service where legally permitted) shall be
                deemed original signatures.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.9&nbsp;Assignment. Neither Party may assign any of its rights
                or obligations under the Agreement without the prior written
                consent of the other, which will not be unreasonably withheld,
                conditioned or delayed, however MayaInsights may assign or
                delegate some or all of its rights and obligations under the
                Agreement to any of its affiliates, or as part of a corporate
                reorganization, or upon a change of control, consolidation,
                merger, sale of all or substantially all of its business or
                assets related to the Agreement, or a similar transaction or
                series of transactions. Subject to the foregoing restriction on
                assignment by Customer, the Agreement will be binding upon,
                inure to the benefit of and be enforceable by the Parties and
                their respective successors and assigns.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.10&nbsp;Subcontractors. MayaInsights shall be entitled to
                engage subcontractors for the provision of the Service without
                Customer’s prior consent, and shall be liable for all acts and
                omissions of subcontractors, as for its own acts and omissions.
                In respect of processing of personal data, Section 5 applies.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.11&nbsp;Entire agreement. The Agreement constitutes the
                complete and exclusive statement of the agreement between
                MayaInsights and Customer in connection with the Parties’
                business relationship related to the subject matter hereof, and
                all previous representations, discussions, and writings
                (including any confidentiality agreements) are merged in, and
                superseded by the Agreement and the Parties disclaim any
                reliance on any such representations, discussions and writings.
                the Agreement shall prevail over any additional, conflicting, or
                inconsistent terms and conditions, which may appear on any
                purchase order furnished by Customer, and any additional terms
                and conditions in any such purchase order shall have no force
                and effect, notwithstanding MayaInsights’s acceptance or
                execution of such purchase order.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.12&nbsp;Amendment. MayaInsights may amend the Agreement from
                time to time by posting an amended version at its website and/or
                sending Customer an email notification or notification through
                the Service thereof. Such amendment will be deemed accepted and
                become effective as of the next renewal period after such notice
                (the “Proposed Amendment Date”) unless Customer first gives
                MayaInsights written notice of rejection of the amendment. In
                the event of such rejection, the Agreement will continue under
                its original provisions, and the amendment will become effective
                at the start of Customer’s next renewal period following the
                Proposed Amendment Date (unless Customer first terminates the
                Agreement pursuant to Section 11). Customer’s continued use of
                the Service following the effective date of an amendment will
                confirm Customer’s consent thereto. the Agreement may not be
                amended in any other way except through a written agreement by
                authorized representatives of each Party. Notwithstanding the
                foregoing provisions of this Section 13.12, MayaInsights is free
                to make minor changes to the Agreement provided that that such
                changes do not have an adverse effect for the Customer.
                MayaInsights may also revise the Agreement to the extent
                necessary to comply with mandatory law. MayaInsights shall
                notify Customer of changes made to comply with mandatory law
                without undue delay and the new version of the Agreement will
                become effective upon notification.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                13.13&nbsp;Notices. Any notice required to be given by either
                Party in writing under the Agreement shall be deemed to have
                been duly received (i) on the day of delivery, if delivered
                personally; (ii) on the date of confirmation of receipt from the
                notified Party, if sent by email (although it should be noted
                that no notice of termination may be served solely over email);
                (iii) on the second working day after sending, if sent by
                reputable overnight courier (with delivery receipt obtained); or
                (iv) on the fifth working day after sending, if sent by
                registered or certified mail, to the address or email address of
                the recipient set forth in the Order (or to such other address
                or email address of the recipient notified to the sender by the
                recipient for the purpose of the Agreement).
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>14. Dispute Resolution</strong>
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.1&nbsp;Governing law and jurisdiction. Subject to Section
                14.2, the Agreement and any dispute or claim arising out of or
                in connection with it or its subject matter or formation
                (including non-contractual disputes or claims) shall:
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.1.1 if MayaInsights is the party to the Agreement, be
                interpreted, construed and enforced in all respects in
                accordance with Greek law, without regard to its conflict of law
                principles; or
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.1.2 if MayaInsights is the party to the Agreement, be
                interpreted, construed and enforced in all respects in
                accordance with the law of Greece, without regard to its
                conflict of law principles.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.2&nbsp;Settlement by good-faith discussions. All disputes
                arising out of or in connection with the Agreement shall be
                attempted to be settled by good-faith negotiations between
                senior management of both Parties. Such negotiations shall
                commence within two weeks from the date of written request from
                a Party to the other. In the event that negotiations do not
                result in a resolution of the dispute within one (1) month from
                said written request, a Party may proceed to arbitration as set
                forth below. Both Parties agree and acknowledge that that the
                commencement of such dispute resolution process shall not
                relieve either Party from its continued duties and obligations
                under the Agreement, including but not limited to any payments
                due.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.3&nbsp;Disputes. Any dispute, controversy or claim arising
                out of or in connection with the Agreement, or the breach,
                termination or invalidity thereof, which has not been resolved
                amicably as set forth in Section 14.2, shall:
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.3.1 if MayaInsights is the party to the Agreement, be finally
                settled by arbitration in accordance with the Arbitration Rules
                of the Arbitration Institute of the Stockholm Chamber of
                Commerce. The arbitral tribunal shall be composed of three
                arbitrators. The seat of arbitration shall be Stockholm, Sweden.
                The language to be used in the proceedings shall be English. The
                arbitration award shall be final and binding upon the Parties;
                or
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.3.2 if MayaInsights is the party to the Agreement, be
                exclusively settled in the federal and state courts located in
                Greece. Each Party hereby irrevocably waives any and all right
                to trial by jury in any legal proceeding arising out of or
                related to the Agreement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                14.4&nbsp;Confidentiality. The confidentiality undertaking in
                Section 8 shall apply to any arbitration process or court
                proceedings hereunder, including any award or judgement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <h3 dir="ltr">
        <span>
          <span>
            <span>
              <span>&nbsp;</span>
            </span>
          </span>
        </span>
      </h3>
      <h3 dir="ltr">
        <span>
          <span>
            <span>
              <span>&nbsp;</span>
            </span>
          </span>
        </span>
      </h3>
      <h3 dir="ltr">
        <span>
          <span>
            <span>
              <span>
                <strong>DATA PROCESSING APPENDIX</strong>
              </span>
            </span>
          </span>
        </span>
      </h3>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>&nbsp;</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>1. Background</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                1.1 This Data Processing Appendix (“DPA”) is an appendix to the
                MayaInsights General Terms.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                1.2 The purpose of this DPAis to fulfil the requirements of a
                written agreement pursuant to Article 28 of the GDPR.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>2. Definitions</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                In this DPA the following terms shall have the following
                meanings:
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                “Data Protection Laws” refers to Regulation (EU) 2016/679 of the
                European parliament and of the council of 27 April 2016 on the
                protection of natural persons with regard to the processing of
                personal data and on the free movement of such data, and
                repealing Directive 95/46/EC (“GDPR”) including supplementing
                legislation acts and decisions.”DPA” refers to this Data
                Processing Appendix and all annexes thereto. “Personal Data”
                refers to the personal data that MayaInsights processes on
                behalf of Customer pursuant to the Agreement. “security
                incident”, “audit”, “controller”, “data subject”, “personal
                data”, “processor” and “processing” all have the meaning given
                under the GDPR.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>3. Processing instructions</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.1 In consideration of Customer making available the Personal
                Data to MayaInsights, MayaInsights agrees to process the
                Personal Data in accordance with the terms and conditions of
                this DPA.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.2 Subject to clause 3.3 in this DPA, the Parties acknowledge
                and agree that:
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                i. for the purposes of this DPA and as between them, Customer
                is, or shall be regarded as, a controller of the Personal Data
                and MayaInsights is, or shall be regarded as, a processor of the
                Personal Data; and
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                ii. Customer will comply with its obligations as a controller
                under the Data Protection Laws and MayaInsights will comply with
                its obligations as a processor under this DPA, the Data
                Protection Laws and Customer’s written instructions.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                3.3 Customer instructs MayaInsights, and MayaInsights agrees to,
                process the Personal Data in accordance with the instructions
                put forward in&nbsp;Annex 1.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>4. Confidentiality of processing</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.1 MayaInsights shall ensure that all persons it authorizes to
                process the Personal Data are subject to a duty of
                confidentiality (whether a contractual duty or a statutory duty)
                and only process the Personal Data as set out in this DPA.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.2 MayaInsights shall ensure that only persons who needs to
                process the Personal Data, in order for MayaInsights to supply
                the Service, have access to such Personal Data.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>5. Data subject rights</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                5.1 MayaInsights shall provide reasonable assistance to Customer
                to enable Customer to respond to:
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                i. any request relating to the Personal Data from a data subject
                to exercise any of its rights under Data Protection Laws;
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                ii. any other correspondence, enquiry or complaint received from
                a data subject or regulator in connection with the processing of
                the Personal Data by MayaInsights.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                5.2 If any such request, correspondence, enquiry or complaint is
                made directly to MayaInsights, MayaInsights shall without undue
                delay inform Customer of such request, correspondence, enquiry
                or complaint.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                5.3 MayaInsights shall not disclose any Personal Data in
                response to a request for access or disclosure from any third
                party without Customer’s prior written consent, unless where
                MayaInsights is compelled to do so in accordance with applicable
                law or as otherwise allowed under this DPA or the Agreement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>6. Data protection impact assessments</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                If requested by Customer, MayaInsights shall provide Customer
                with reasonable assistance in order for Customer to conduct a
                data protection impact assessment; and if necessary, consult
                with its relevant supervisory authority.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>7. Security</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.1 MayaInsights shall implement and maintain appropriate
                technical and organisational measures to protect the Personal
                Data against accidental or unlawful destruction, loss,
                alteration, unauthorized disclosure, or access.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.2 MayaInsights shall notify Customer of any security incident
                that it becomes aware of without undue delay, and in any case,
                never later than 48 hours after MayaInsights becomes aware of
                the security incident. All such notifications shall be made at
                MayaInsights’s discretion by a phone call or email to Customer
                representative that MayaInsights regularly liaises with.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.3 If the security incident may be attributed to MayaInsights’s
                processing of the Personal Data, MayaInsights shall cooperate
                with Customer and provide Customer with reasonable assistance
                and information in the investigation of a security incident.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                7.4 All costs associated with managing a security incident and
                fulfilling its obligations shall be borne by Customer where the
                security incident occurs as a result of Customer failing to
                perform its obligations under this DPA or the Data Protection
                Laws. If the security incident did not occur as a result of
                Customer failing to perform its obligations under this DPA or
                the Data Protection Laws, or if it is not possible to determine
                which Party that are responsible for the security incident, each
                Party shall bear their respective costs that are associated with
                managing such security incident and fulfilling such obligations.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>8. Sub-Processors</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                8.1 Customer gives MayaInsights a general written authorisation
                to subcontract any processing of the Personal Data to a
                third-party subcontractor.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                8.2 MayaInsights shall, upon request from Customer, provide a
                list to Customer of the third-party subcontractors MayaInsights
                engages with in its processing of the Personal Data.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                8.3 MayaInsights shall impose data protection terms to an
                equivalent standard as provided for under this DPA for all its
                subcontractors.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                8.4 MayaInsights shall remain fully liable for the processing of
                the Personal Data that its subcontractors process under this
                DPA.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>9. Audit</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                9.1 MayaInsights shall permit Customer (or its appointed
                third-party auditors) to audit MayaInsights's compliance with
                this DPA, and shall make available to Customer information,
                systems and staff necessary for Customer (or its third-party
                auditors) to conduct such audit. MayaInsights acknowledges that
                Customer (or its third-party auditors) may enter its premises
                for the purposes of conducting this audit, provided that
                Customer gives it reasonable prior notice of its intention to
                audit, conducts its audit during normal business hours, and
                takes all reasonable measures to prevent unnecessary disruption
                to MayaInsights's operations. Customer will not exercise its
                audit rights more than once in any twenty-four (24) calendar
                month period, except if, and when, required by instruction of a
                competent supervisory authority.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>10. International data transfers</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                Customer gives MayaInsights permission to transfer and process
                the Personal Data outside the European Economic Area, as long as
                MayaInsights transfers such Personal Data in accordance with one
                of the allowed mechanisms prescribed by the Data Protection
                Laws.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>11. Terms and termination</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.1 This DPA shall be in effect for as long as MayaInsights
                processes Personal Data for Customer. Upon termination of the
                Agreement, MayaInsights shall destroy or return the Personal
                Data to Customer, depending on what Customer chooses. If
                Customer has not informed MayaInsights of its choice within two
                (2) months from the termination of the Agreement, MayaInsights
                shall destroy all Personal Data.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.2 At the request of Customer, MayaInsights shall confirm the
                actions taken regarding the Personal Data after the completion
                of the process mentioned in clause 11.1 in this DPA.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.3 If Customer chooses that MayaInsights should destroy the
                Personal Data, in accordance with clause 11.1 in this DPA, it
                shall not apply to the extent that MayaInsights is required by
                any European Union, or Member State, law or other applicable law
                to retain such data.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                11.4 All clauses of this DPA which by their nature should
                survive termination will survive termination.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>&nbsp;</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>&nbsp;</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                12.&nbsp;Instruction for processing of the Personal Data
              </span>
            </span>
          </span>
        </span>
      </p>
      <h3 dir="ltr">
        <span>
          <span>
            <span>
              <span>&nbsp;</span>
            </span>
          </span>
        </span>
      </h3>
      <div dir="ltr" align="left">
        <table className="in-terms-and-conditions">
          <tbody>
            <tr>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>Purposes</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>
                          To provide the Service pursuant to the Agreement and
                          security and monitoring.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>Categories of Personal Data</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>
                          Data Sources, which the Customer imports to the
                          Service.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>Categories of data subjects</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>
                          Data subjects in the Data
                          Sources,&nbsp;e.g.&nbsp;customers’ customers and
                          employees.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>Processing activities</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>Collection;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>logging;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>organization;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>structuring;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>storage;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>adaptation or alteration;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>use;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>disclosure;</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>anonymization or aggregating; and</span>
                      </span>
                    </span>
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>erasure.</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>
                          Location for the processing of the Personal Data
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>EU</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>Retention periods</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>
                          MayaInsights will process the Personal Data during the
                          period in which the Agreement is in effect and for a
                          reasonable period of time thereafter. However,
                          MayaInsights will strive to aggregate or in other ways
                          de-identify the Personal Data so it is no longer
                          considered as personal data.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>Subcontractors per the Effective Date</span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td>
                <p dir="ltr">
                  <span>
                    <span>
                      <span>
                        <span>
                          MayaInsights uses data center services from Google
                          Cloud Platform (GCP), based in the EU and the USA.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 dir="ltr">
        <span>
          <span>
            <span>
              <span>CCPA ADDENDUM</span>
            </span>
          </span>
        </span>
      </h3>
      <h2 dir="ltr">
        <span>
          <span>
            <span>
              <span>&nbsp;</span>
            </span>
          </span>
        </span>
      </h2>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>1. Background</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                Pursuant to the MayaInsights General Terms entered into between
                Customer and MayaInsights, (the “Agreement”), and in furtherance
                of obligations under the California Consumer Privacy Act of 2018
                (California Civil Code §§ 1798.100 to 1798.199) and its
                implementing regulations, as amended or superseded from time to
                time (“CCPA”), the Parties hereby adopt this CCPA Addendum
                (“Addendum”) for so long as MayaInsights maintains Personal
                Information on behalf of Customer. This Addendum prevails over
                any conflicting terms of the Agreement, but does not otherwise
                modify the Agreement.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>2. Definitions</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                The capitalized terms used in this Addendum and not otherwise
                defined in this Addendum shall have the definitions set forth in
                the CCPA. “CCPA” means the California Consumer Privacy Act, Cal.
                Civ. Code §1798.100 et seq., and its implementing regulations.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>3. Scope</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                This Addendum applies to the collection, retention, use,
                disclosure, and sale of Personal Information provided by
                Customer or which is collected on behalf of Customer by
                MayaInsights to provide Services to Customer pursuant to the
                Agreement or to perform a Business Purpose.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>4. Processing and Restrictions</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.1 Customer may under the Agreement disclose Consumers’
                Personal Information to MayaInsights. With regard hereto, the
                Parties acknowledge and agree that MayaInsights is a Service
                Provider and received Personal Information pursuant to the
                business purpose of providing the Service to Customer as set
                forth in the Agreement and carry out such other activities as
                specified therein.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                4.2 With respect to Consumers’ Personal Information provided to
                MayaInsights by Customer, MayaInsights shall not:
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>(i). Sell the Personal Information;</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                (ii). Retain, use or disclose the Personal Information for any
                purpose other than for the specific purpose of providing the
                Service and performing the other activities specified in the
                Agreement, including retaining, using or disclosing the Personal
                Information for a commercial purpose other than providing the
                Service and performing the other activities specified in the
                Agreement; or
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                (iii). Retain, use or disclose the Personal Information outside
                of the direct business relationship between the Parties.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>5. Compliance Certification</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                5.1 The Customer hereby certifies its compliance with its
                obligations as a Business under the CCPA, including providing
                notice that the Personal Information is being used or shared
                consistent with Cal. Civ. Code 1798. 140(t)(2)(C)(i).
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                5.2 MayaInsights hereby certifies that it understands the
                restrictions on its use of Consumers’ Personal Information
                imposed by the CCPA and by this Addendum and agrees to comply
                with the restrictions herein.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>6. Consumer Rights</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                6.1 MayaInsights shall provide reasonable assistance to Customer
                in facilitating compliance with Consumer rights requests.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                6.2 Upon direction by Customer, and in any event no later than
                30 days after receipt of a request from Customer, MayaInsights
                shall promptly delete the Personal Information as directed by
                Customer.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                6.3 MayaInsights shall not be required to delete any of the
                Personal Information to comply with a Consumer’s request
                directed by Customer if it is necessary to maintain such
                information in accordance with Cal. Civ. Code 1798.105(d), in
                which case MayaInsights shall promptly inform Customer of the
                exceptions relied upon and MayaInsights shall not use the
                Personal Information retained for any other purpose than
                provided for by that exception.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>7. Deidentified Information</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                In the event that either Party shares Deidentified Information
                with the other Party, the receiving Party warrants that it: (i)
                has implemented technical safeguards that prohibit
                reidentification of the Consumer to whom the information may
                pertain; (ii) has implemented business processes that
                specifically prohibit reidentification of the information; (iii)
                has implemented business processes to prevent inadvertent
                release of Deidentified information; and (iv) will make no
                attempt to reidentify the information.
              </span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>8. As Required by Law</span>
            </span>
          </span>
        </span>
      </p>
      <p dir="ltr">
        <span>
          <span>
            <span>
              <span>
                Notwithstanding any provision to the contrary of the Agreement
                or this Addendum, MayaInsights may cooperate with law
                enforcement agencies concerning conduct or activity that it
                reasonably and in good faith believes may violate federal,
                state, or local law.
              </span>
            </span>
          </span>
        </span>
      </p>
    </article>
  )
}

export default TermsAndConditions
