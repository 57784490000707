import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import useConfirmation from 'containers/confirmation/useConfirmation'

import 'css/PopUp.scss'

const ConfirmationMessage = () => {
  const { message, visible, onConfirm, onCancel } = useConfirmation()

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          exit={{ opacity: 0 }}
          className={`pop-up confirmation-pop-up`}
          id="pop-up"
        >
          <div className="text-container">
            <span className="text">{message}</span>
          </div>
          <div
            className="confirmation-actions"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <button onClick={onCancel} className="cancel-button">
              Cancel
            </button>{' '}
            <button className="submit-button" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default React.memo(ConfirmationMessage)
