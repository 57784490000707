import Divider from 'components/Divider'
import InputField from 'components/InputField'
import ScreenLoading from 'components/ScreenLoading'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetPublicKey from 'containers/publicKey/useGetPublicKey'
import useGetAccounts from 'containers/services/useGetAccounts'
import useQuery from 'containers/useQuery'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const Guide = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Setup Guide</h1>
      <Divider spaceY={10} />
      <div className="step-heading">
        <p>STEP 1</p>
        <h2>Copy Public Key</h2>
      </div>
      <ol>
        <li>
          In Maya source configuration screen click Copy Public Key. This is Log
          in to your{' '}
          <a
            href="https://developer.apple.com/documentation/apple_search_ads/implementing_oauth_for_the_apple_search_ads_api"
            target="_blank"
          >
            automatically generated
            <span className="material-icons-outlined non-selectable">
              launch
            </span>
          </a>
          via OpenSSL Toolkit.
        </li>
      </ol>
      <div className="step-heading">
        <p>STEP 2</p>
        <h2>Get credentials</h2>
      </div>
      <ol>
        <li>Log in to your Apple Search Ads.</li>
        <li>
          In your Apple Search Ads dashboard, choose Account Settings &gt; API
          and paste the Public key.
        </li>
        <li>
          In your Apple Search Ads dashboard, click Save. A group of credentials
          displays as a code block above the Public Key field. Copy the
          clientId, teamId, and keyId values.
        </li>
      </ol>
      <div className="step-heading">
        <p>STEP 3</p>
        <h2>Finish configuration</h2>
      </div>
      <ol>
        <li>
          In Maya source configuration screen, insert clientId, teamId, and
          keyId values to connector screen.
        </li>
        <li>
          Click <b>Authorize</b> to authorize Maya to access your Apple Search
          Ads account.
        </li>
      </ol>
    </div>
  )
}

const AppleAds = () => {
  const { currentOrganization } = useContext(OrganizationsContext)
  const { loadingAccounts, getAccounts, accountsError } = useGetAccounts()
  const history = useHistory()

  const [showGuide, setShowGuide] = useState(false)
  const [clientId, setClientId] = useState<string | null>(null)
  const [teamId, setTeamId] = useState<string | null>(null)
  const [keyId, setKeyId] = useState<string | null>(null)
  const [publicKey, setPublicKey] = useState<string | null>(null)
  const serviceUUID = useParams<{uuid: string}>().uuid
  const [error, setError] = useState(null)

  const [, getPublicKey] = useGetPublicKey(currentOrganization)

  useEffect(() => {
    ;(async () => {
      const pk = await getPublicKey(serviceUUID)
      setPublicKey(pk)
    })()
  }, [])

  const handleCopyPublicKey = (e) => {
    e.preventDefault()
    if (publicKey !== null && publicKey.length > 0) {
      navigator.clipboard.writeText(publicKey)
    }
  }

  const handleSave = async () => {
    // save / create service account on backend and store clientId, teamId, keyId and publicKey
    if (clientId && teamId && keyId && publicKey) {
      await getAccounts(currentOrganization.id, {
        clientId,
        teamId,
        keyId,
        publicKey,
      })

      // redirect to sources
      if (!accountsError) {
        history.push('../..')
      }
    } else {
      setError('Please fill all fields')
    }
  }

  return (
    <div className="guide-container">
      <Guide onHide={() => setShowGuide(false)} show={showGuide} />

      <img
        src="https://www.mayainsights.com/wp-content/uploads/2023/01/apple_ads-e1673568494745.png"
        width="200"
        alt=""
      />
      <p>
        Press the{' '}
        <button className="button-tip" onClick={() => setShowGuide(true)}>
          Guide
        </button>{' '}
        button to help you through the authorization proccess.
      </p>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <InputField
            label="Public Key"
            value={accountsError ? accountsError : publicKey || ''}
            style={{
              maxWidth: '400px',
              border: accountsError && '1px solid red',
            }}
            disabled={true}
          />
          <button onClick={handleCopyPublicKey}>
            <span className="material-icons-outlined saved-icon non-selectable">
              copy
            </span>
          </button>
        </div>
        <p>Use the public key to grant Maya access to Apple Search Ads api.</p>
      </div>
      <InputField
        label="Client Id"
        value={clientId || ''}
        setValue={setClientId}
        style={{
          maxWidth: '400px',
        }}
      />
      <InputField
        label="Team Id"
        value={teamId || ''}
        setValue={setTeamId}
        style={{
          maxWidth: '400px',
        }}
      />
      <InputField
        label="Key Id"
        value={keyId || ''}
        setValue={setKeyId}
        style={{
          maxWidth: '400px',
        }}
      />
      {error && <p style={{color: 'red' }} className="error">{error}</p>}
      <div className="authorize-container">
        {loadingAccounts ? (
          <ScreenLoading />
        ) : (
          <button className="submit-button" onClick={handleSave}>
            Authorize & Save
          </button>
        )}

        {clientId ? (
          <p className="authorized">
            Saved
            <span className="material-icons-outlined saved-icon non-selectable">
              task_alt
            </span>
          </p>
        ) : (
          <p>Click to grant access to your Apple Ads account.</p>
        )}
      </div>

      <div className="guide-bar">
        <button
          className="submit-button guide-button"
          onClick={() => setShowGuide(true)}
        >
          <span>Guide</span>
          <span className="material-icons-outlined saved-icon non-selectable">
            menu_book
          </span>
        </button>
      </div>
    </div>
  )
}

export default AppleAds
