import axios, { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'

import {
  ETLReponse,
  IToken,
  ILoginCredentials,
  IWordGroup,
  IClusteringColumn,
  ICurrency,
  EmailDomainExists,
  DatasetResponse,
  FeatureResponse,
  DatasetUser,
  SearchTermsResponse,
  APIKey,
  IColumns,
  Column,
  ISubscriptionDetailsResponse,
  INotification,
  INotificationUpdateStatus,
  INotificationFilterResponse,
  IDestination,
  IOrganizationDestination,
  Product,
} from 'types'
import {
  IManualExpense,
  IInvite,
  IPageSpeedLink,
  ISubscription,
  IBillingDetails,
} from 'types'
import { IEmbedReport } from 'types'
import { Conversation } from 'types'

let API_ADDRESS = ''
let GTM_ID: string | null | undefined = undefined
let RUDDERSTACK_CONFIG:
  | { write_key: string; data_plane_url: string }
  | null
  | undefined = undefined

axios.defaults.withCredentials = true

export type ErrorDetail =
  | {
      type: 'invalid_field_reference'
      ref_column_id: string
      column_id: string
    }
  | { type: 'workspace_not_setup' }
  // billing:
  | { type: 'unrecognized_location' }
  | { type: 'location_recognition_failed' }
  | { type: 'billing_details_missing' }
  // invites:
  | { type: 'invitation_does_not_exist' }
  | { type: 'invitation_expired' }
  | {
      type: 'invitation_forbidden'
      invitation_email: string
      user_email: string
    }
  | { type: 'invitation_already_accepted' }
/* | { type: string } */

interface ErrorPayload {
  detail: ErrorDetail
}

export interface HTTPError extends AxiosError {
  response: AxiosResponse<ErrorPayload | object>
}

export function isHTTPError(error: any): error is HTTPError {
  return 'response' in error && axios.isAxiosError(error)
}

export type APIError = HTTPError & { response: AxiosResponse<ErrorPayload> }

export function isAPIError(error: any): error is APIError {
  return (
    isHTTPError(error) &&
    typeof error.response.data === 'string' &&
    error.response.data !== 'Internal Server Error' &&
    'detail' in error.response.data
  )
}

interface IClusteringPreviewColumnPayload {
  category: string
  columnId: number
  organizationId: number
  column: IClusteringColumn
  token: IToken
  orderBy: string
  orderDirection: string
}

interface IClusteringPreviewValuePayload
  extends IClusteringPreviewColumnPayload {
  value: string
}

interface IBasePayload {
  token: IToken
  organizationId: number
}

interface IUUIDPayload extends IBasePayload {
  uuid: string
}

const api = {
  getHeaders: (token: IToken) => {
    if (!token) {
      return {}
    }
    const headers = {
      Authorization: `Bearer ${token.accessToken}`,
    }

    return headers
  },
  loadConfig: async () => {
    if (!API_ADDRESS) {
      try {
        const response = await axios({
          method: 'GET',
          url: '/data/config.json',
        })
        const config = response.data
        if (config.apiAddress) {
          API_ADDRESS = config.apiAddress
        } else {
          API_ADDRESS = 'http://localhost:8000'
        }
        if (config.gtmId) {
          GTM_ID = config.gtmId
        } else {
          GTM_ID = null
        }
        if (config.rudderstack) {
          RUDDERSTACK_CONFIG = config.rudderstack
        } else {
          RUDDERSTACK_CONFIG = null
        }
      } catch (err) {
        API_ADDRESS = 'http://localhost:8000'
        GTM_ID = null
      }
    }
  },
  getCompanyPositions: async (): Promise<string[]> => {
    try {
      return (await axios.get('/data/company-positions.json')).data
    } catch (err) {
      return []
    }
  },
  getRudderstackConfiguration: async () => {
    if (RUDDERSTACK_CONFIG === undefined) {
      await api.loadConfig()
    }
    return RUDDERSTACK_CONFIG
  },
  getGoogleTagManagerId: async () => {
    if (GTM_ID === undefined) {
      await api.loadConfig()
    }
    return GTM_ID
  },
  discoverAddress: async () => {
    if (!API_ADDRESS) {
      await api.loadConfig()
    }
    return API_ADDRESS
  },
  post: async (endpoint: string, data = {}, headers = {}, params = {}, onUploadProgress = (progressEvent: any) => {}) => {
    const address = await api.discoverAddress()
    return await axios({
      method: 'POST',
      headers,
      data,
      params,
      onUploadProgress: progressEvent => onUploadProgress(progressEvent),
      // onUploadProgress: progressEvent => console.log((progressEvent.loaded * 100) / progressEvent.total),
      url: address + endpoint,
    })
  },
  get: async (endpoint: string, params = {}, headers = {}) => {
    const address = await api.discoverAddress()
    return await axios({
      method: 'GET',
      headers,
      params,
      url: address + endpoint,
    })
  },
  put: async (endpoint: string, data = {}, headers = {}, params = {}) => {
    const address = await api.discoverAddress()
    return await axios({
      method: 'PUT',
      headers,
      data,
      params,
      url: address + endpoint,
    })
  },
  delete: async (endpoint: string, headers = {}, params = {}) => {
    const address = await api.discoverAddress()
    return await axios({
      method: 'DELETE',
      headers,
      params,
      url: address + endpoint,
    })
  },
  patch: async (endpoint: string, body = {}, params = {}, headers = {}) => {
    const address = await api.discoverAddress()
    return await axios({
      url: address + endpoint,
      method: "PATCH",
      data: body,
      params, 
      headers
    })
  },
  deleteServiceAccount: async ({
    uuid,
    organizationId,
    token,
  }: IUUIDPayload) => {
    return (
      await api.delete(`/service_accounts/${uuid}`, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  getServices: async () => {
    return (await api.get('/services')).data
  },
  getUser: async ({ token }: { token: IToken }) => {
    return (await api.get('/user', {}, api.getHeaders(token))).data
  },
  getOrganizations: async ({ token }: { token: IToken }) => {
    return (await api.get('/organizations', {}, api.getHeaders(token))).data
  },
  getConnectedServiceAccounts: async ({
    token,
    organizationId,
  }: {
    token: IToken
    organizationId: number
  }) => {
    return (
      await api.get(
        '/service_accounts',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getAccountsWithWarnings: async ({
    token,
    organizationId,
  }: {
    token: IToken
    organizationId: number
  }) => {
    return (
      await api.get(
        '/service_accounts',
        { organization_id: organizationId, has_warnings: true },
        api.getHeaders(token)
      )
    ).data
  },
  verifyServiceAccounts: async ({
    token,
    serviceUuid,
    code,
    state,
    organizationId,
    metadata
  }: {
    token: IToken
    serviceUuid: string
    code: string
    state: string
    organizationId: number
    metadata: { [key: string]: string}
  }) => {
    const credentials = { code, state };
    return (
      await api.post(
        `/services/${serviceUuid}/oauth/verify`,
        {bucket: metadata.bucket},
        api.getHeaders(token),
        { credentials, organization_id: organizationId}
      )
    ).data
  },
  connectAccounts: async ({
    accounts,
    token,
    credentialsId,
    organizationId,
  }: {
    accounts: string[]
    token: IToken
    credentialsId: string
    organizationId: number
  }) => {
    return (
      await api.post(`/service_accounts`, accounts, api.getHeaders(token), {
        credentials_id: credentialsId,
        organization_id: organizationId,
      })
    ).data
  },
  getServiceAuthUrl: async ({
    token,
    serviceUuid,
    organizationId,
  }: {
    token: IToken
    serviceUuid: string
    organizationId: number
  }) => {
    return (
      await api.post(
        `/services/${serviceUuid}/oauth/start`,
        {},
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  signUpWithGoogle: async ({
    googleIdToken,
    companyName,
    companyPosition,
  }: {
    googleIdToken: string
    companyName: string
    companyPosition: string
  }) => {
    return (
      await api.post('/user', {
        google_id_token: googleIdToken,
        company_name: companyName,
        company_position: companyPosition,
      })
    ).data
  },
  signUpWithMicrosoft: async ({
    microsoftAccessToken,
    companyName,
    companyPosition,
  }: {
    microsoftAccessToken: string
    companyName: string
    companyPosition: string
  }) => {
    return (
      await api.post('/user', {
        microsoft_access_token: microsoftAccessToken,
        company_name: companyName,
        company_position: companyPosition,
      })
    ).data
  },
  signIn: async (credentials: ILoginCredentials) => {
    return (await api.post('/auth/login', credentials)).data
  },
  getColumnByCategory: async ({
    category,
    columnId,
    organizationId,
    token,
  }: {
    category: string
    columnId: string
    organizationId: number
    token: IToken
  }) => {
    return (
      await api.get(
        `/clustering/${category}/columns/${columnId}`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getColumnsByCategory: async ({
    category,
    organizationId,
    token,
  }: {
    category: string
    organizationId: number
    token: IToken
  }) => {
    return (
      await api.get(
        `/clustering/${category}`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  saveColumnByCategory: async ({
    category,
    columnId,
    organizationId,
    column,
    token,
  }: {
    category: string
    columnId: string
    organizationId: number
    column: IClusteringColumn
    token: IToken
  }) => {
    return (
      await api.put(
        `/clustering/${category}/columns/${columnId}`,
        column,
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  deleteColumnByCategory: async ({
    category,
    columnId,
    organizationId,
    token,
  }: {
    category: string
    columnId: string
    organizationId: number
    token: IToken
  }) => {
    return (
      await api.delete(
        `/clustering/${category}/columns/${columnId}`,
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  updateColumnsByCategory: async ({
    category,
    organizationId,
    columns,
    token,
  }: {
    category: string
    organizationId: number
    columns: string[]
    token: IToken
  }) => {
    return (
      await api.put(
        `/clustering/${category}/columns`,
        columns,
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  getSqlByColumn: async ({
    category,
    columnId,
    organizationId,
    column,
    token,
  }: {
    category: string
    columnId: string
    organizationId: number
    column: IClusteringColumn
    token: IToken
  }) => {
    return (
      await api.post(
        `/clustering/${category}/columns/${columnId}/sql`,
        column,
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  getPreviewByColumn: async ({
    category,
    columnId,
    organizationId,
    column,
    token,
    orderBy,
    orderDirection,
  }: IClusteringPreviewColumnPayload) => {
    let extraFields = {}
    if (orderBy && orderDirection) {
      extraFields = { order_by: orderBy, order_direction: orderDirection }
    }

    return (
      await api.post(
        `/clustering/${category}/columns/${columnId}/preview`,
        column,
        api.getHeaders(token),
        { ...extraFields, organization_id: organizationId }
      )
    ).data
  },
  getPreviewValueByColumn: async ({
    category,
    columnId,
    organizationId,
    column,
    token,
    value,
    orderBy,
    orderDirection,
  }: IClusteringPreviewValuePayload) => {
    let extraFields = {}
    if (orderBy && orderDirection) {
      extraFields = { order_by: orderBy, order_direction: orderDirection }
    }

    return (
      await api.post(
        `/clustering/${category}/columns/${columnId}/preview_value`,
        column,
        api.getHeaders(token),
        { ...extraFields, organization_id: organizationId, value }
      )
    ).data
  },
  getWordGroups: async ({ organizationId, token }: IBasePayload) => {
    return (
      await api.get(
        `/clustering/wordgroups`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  saveWordGroups: async ({
    organizationId,
    token,
    state,
  }: {
    organizationId: string
    token: IToken
    state: IWordGroup[]
  }) => {
    return (
      await api.put(`/clustering/wordgroups`, state, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  listEmbedReports: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<IEmbedReport> => {
    return (
      await api.get(
        '/embed/reports',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  accessEmbedReport: async ({
    organizationId,
    token,
    reportSlug,
  }: {
    organizationId: number
    token: IToken
    reportSlug: string
  }) => {
    return await api.post(
      `/embed/reports/${reportSlug}/access`,
      {},
      api.getHeaders(token),
      { organization_id: organizationId }
    )
  },
  getPendingInvite: async (inviteId: string): Promise<IInvite> => {
    return (await api.get(`/invitations/${inviteId}`)).data
  },
  getPendingInvites: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<IInvite[]> => {
    return (
      await api.get(
        `/invitations`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  createInvite: async ({
    organizationId,
    email,
    token,
  }: {
    organizationId: number
    email: string
    token: IToken
  }) => {
    const body = { email }
    return (
      await api.post(`/invitations`, body, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  deleteInvite: async ({
    organizationId,
    invitationId,
    token,
  }: {
    organizationId: number
    invitationId: string
    token: IToken
  }) => {
    return (
      await api.delete(`/invitations/${invitationId}`, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  acceptInvite: async ({
    organizationSlug,
    inviteId,
    token,
  }: {
    organizationSlug: string
    inviteId: number
    token: IToken
  }) => {
    const body = {
      invitation_id: inviteId,
      organization_slug: organizationSlug,
    }

    return (await api.post(`/invitations/accept`, body, api.getHeaders(token)))
      .data
  },
  requestPasswordReset: async ({ email }: { email: string }) => {
    const body = { email }
    return (await api.post(`/auth/reset_password_request`, body)).data
  },
  passwordReset: async ({
    password,
    id,
    email,
  }: {
    password: string
    id: string
    email: string
  }) => {
    const body = {
      new_password: password,
      request_id: id,
      email,
    }
    return (await api.post(`/auth/reset_password`, body)).data
  },
  changePassword: async (
    token: IToken,
    currentPassword: string,
    newPassword: string
  ) => {
    const headers = {
      ...api.getHeaders(token),
      'Content-Type': 'application/json',
    }
    const body = {
      current_password: currentPassword,
      new_password: newPassword,
    }

    return api.post('/user/change_password', body, headers)
  },
  getOrganizationUsers: async ({ organizationId, token }: IBasePayload) => {
    return (
      await api.get(
        `/organizations/${organizationId}/users`,
        {},
        api.getHeaders(token)
      )
    ).data
  },
  getCountries: async () => {
    return (await api.get(`/google_search_countries`)).data
  },
  getCompetitorsReport: async ({
    reportId,
    organizationId,
    token,
  }: {
    reportId: string
    organizationId: string
    token: IToken
  }) => {
    return (
      await api.get(
        `/competition_reports/${reportId}`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  saveReport: async ({
    organizationId,
    token,
    countryCode,
    reportId,
    accountUUID,
    uuid,
    columnId,
    value,
    orderBy,
  }: {
    organizationId: number
    token: IToken
    countryCode: string
    reportId: string
    accountUUID: string
    uuid: string
    columnId: string
    value: string
    orderBy: string
  }) => {
    const request = {
      uuid,
      account_uuid: accountUUID,
      search_terms_number: 300,
      order_by: orderBy,
      country_code: countryCode,
      filter_column: columnId,
      filter_value: value,
    }

    return (
      await api.put(
        `/competition_reports/${reportId}`,
        request,
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  getCompetitorsReports: async ({ organizationId, token, filterColumn }) => {
    let qs = { organization_id: organizationId }
    if (filterColumn !== null) {
      qs['filter_column'] = filterColumn
    }
    return (await api.get(`/competition_reports`, qs, api.getHeaders(token)))
      .data
  },
  deleteReport: async ({
    organizationId,
    reportId,
    token,
  }: {
    organizationId: number
    reportId: number
    token: IToken
  }) => {
    return (
      await api.delete(
        `/competition_reports/${reportId}`,
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  deleteEmbedReport: async ({
    organizationId,
    reportId,
    token,
  }: IBasePayload & { reportId: string }) => {
    return (
      await api.delete(`/embed/reports/${reportId}`, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  getPageSpeedUrls: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<IPageSpeedLink[]> => {
    const data = (
      await api.get(
        `/pagespeed_urls`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
    return data.map(({ uuid, url_link }) => {
      return {
        uuid: uuid,
        urlLink: url_link,
      }
    })
  },
  updatePageSpeedUrls: async ({
    organizationId,
    token,
    urls,
  }: {
    organizationId: number
    token: IToken
    urls: IPageSpeedLink[]
  }) => {
    const data = urls.map(({ uuid, urlLink }) => {
      return { uuid, url_link: urlLink }
    })
    return (
      await api.put(`/pagespeed_urls`, data, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  getPageSpeedTopUrls: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<string[]> => {
    return (
      await api.get(
        `/pagespeed_urls/top`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getProductDetails: async ({
    token,
    organizationId,
  }: IBasePayload): Promise<Product[]> => {
    return (
      await api.get(
        `/products`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getBillingDetails: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<IBillingDetails> => {
    const data = (
      await api.get(
        `/billing/details`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
    return {
      ...data,
      companyName: data.company_name,
      addressLine1: data.address_line_1,
      addressLine2: data.address_line_2,
      postalCode: data.postal_code,
      countryCode: data.country_code,
      taxId: data.tax_id,
      taxOffice: data.tax_office,
    }
  },
  saveBillingDetails: async ({
    organizationId,
    token,
    billingDetails,
  }: {
    organizationId: number
    token: IToken
    billingDetails: IBillingDetails
  }) => {
    const payload = {
      company_name: billingDetails.companyName,
      address_line_1: billingDetails.addressLine1,
      address_line_2: billingDetails.addressLine2,
      postal_code: billingDetails.postalCode,
      city: billingDetails.city,
      state: billingDetails.state,
      country_code: billingDetails.countryCode,
      tax_id: billingDetails.taxId,
      tax_office: billingDetails.taxOffice,
    }
    return (
      await api.put(`/billing/details`, payload, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  getBillingPortalUrl: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<string> => {
    return (
      await api.post(`/billing/portal_session`, {}, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data.url
  },
  getBillingCheckoutUrl: async ({
    organizationId,
    token,
    details,
  }: any): Promise<string> => {
    return (
      await api.post(
        `/billing/checkout_session`,
        details,
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data.url
  },
  getBillingCheckoutPlan: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<any> => {
    return (
      await api.get(
        `/billing/checkout_plan`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getSubscription: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<ISubscription> => {
    return (
      await api.get(
        '/billing/subscription',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getManualExpense: async ({
    organizationId,
    token,
    uuid,
  }: IUUIDPayload): Promise<IManualExpense> => {
    const res = await api.get(
      `/expenses/${uuid}`,
      { organization_id: organizationId },
      api.getHeaders(token)
    )
    const data = res.data
    return {
      uuid: data.uuid,
      currencyCode: data.currency.code,
      amount: data.amount,
      category: data.category,
      subcategory: data.subcategory,
      paymentDate: data.payment_date,
      startDate: data.start_date,
      endDate: data.end_date,
      description: data.description,
      isMarketingRelated: data.is_marketing_related,
    }
  },
  saveManualExpense: async ({
    organizationId,
    token,
    expense,
  }: {
    organizationId: number
    token: IToken
    expense: IManualExpense
  }) => {
    return (
      await api.put(
        `/expenses/${expense.uuid}`,
        {
          category: expense.category,
          subcategory: expense.subcategory,
          amount: expense.amount,
          currency_code: expense.currencyCode,
          service_id: expense.serviceId,
          payment_date: expense.paymentDate,
          start_date: expense.startDate || null,
          end_date: expense.endDate || null,
          is_marketing_related: expense.isMarketingRelated,
          description: expense.description,
          uuid: expense.uuid,
        },
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  listManualExpenses: async ({ organizationId, token }: IBasePayload) => {
    return (
      await api.get(
        '/expenses',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  listManualExpenseCategories: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<string[]> => {
    return (
      await api.get(
        '/expenses/categories',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  listManualExpenseSubcategories: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<string[]> => {
    return (
      await api.get(
        '/expenses/subcategories',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  deleteManualExpense: async ({
    organizationId,
    token,
    uuid,
  }: IUUIDPayload) => {
    return (
      await api.delete(`/expenses/${uuid}`, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  listCurrencies: async (): Promise<ICurrency[]> => {
    return (await api.get('/currencies')).data
  },
  triggerETL: async ({ args, organizationId, token }) => {
    const res = await api.post(`/etl/trigger`, {}, api.getHeaders(token), {
      organization_id: organizationId,
      args: args
    })
  },
  getNextETLTime: async ({ organizationId, token }) => {
    const res = await api.get(
      '/etl/next_times',
      { organization_id: organizationId },
      api.getHeaders(token)
    )
    if (
      res.data &&
      res.data['success'] &&
      res.data['data'] &&
      res.data['data'].length
    ) {
      const time = moment(res.data['data'][0].time)
      return time
    } else {
      throw new Error('Failed to load next ETL time')
    }
  },
  getETLProgress: async ({ organizationId, token }) => {
    const res = await api.get(
      '/etl/progress',
      { organization_id: organizationId },
      api.getHeaders(token)
    )
    return res.data
  },
  createConversation: async ({
    organizationId,
    token,
    conversation,
  }: {
    organizationId: number
    token: IToken
    conversation: Conversation
  }) => {
    return (
      await api.post('/conversations/create', {}, api.getHeaders(token), {
        organization_id: organizationId,
        message: conversation.message,
        visitor_id: conversation.visitorId,
      })
    ).data
  },
  getETLlogs: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<ETLReponse> => {
    return (
      await api.get(
        '/etl/logs',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  checkEmailDomain: async (emailDomain: string): Promise<EmailDomainExists> => {
    return (
      await api.post(
        '/auth/check_email_domain',
        {},
        {},
        { email_domain: emailDomain }
      )
    ).data
  },
  shareDataset: async (
    email: string,
    token: IToken,
    organizationId: number
  ): Promise<DatasetResponse> => {
    return (
      await api.post(
        '/shared_dataset/share',
        { email },
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  unshareDataset: async (
    email: string,
    token: IToken,
    organizationId: number
  ): Promise<DatasetResponse> => {
    return (
      await api.post(
        '/shared_dataset/unshare',
        { email },
        api.getHeaders(token),
        { organization_id: organizationId }
      )
    ).data
  },
  getSharedUsers: async (
    token: IToken,
    organizationId: number
  ): Promise<DatasetUser[]> => {
    return (
      await api.get(
        '/shared_dataset/users',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getFeatures: async (
    token: IToken,
    organizationId: number
  ): Promise<FeatureResponse> => {
    return (
      await api.post('/features', {}, api.getHeaders(token), {
        organization_id: organizationId,
      })
    ).data
  },
  manualUpload: async (
    token: IToken,
    organizationId: number,
    data: FormData,
    category: string
  ): Promise<SearchTermsResponse> => {
    return (
      await api.post(
        `/files/manual_uploads/${category}`,
        data,
        { ...api.getHeaders(token), 'Content-Type': 'multipart/form-data' },
        { organization_id: organizationId }
      )
    ).data
  },
  getApiKeys: async ({
    token,
    organizationId,
  }: IBasePayload): Promise<APIKey[]> => {
    return (
      await api.get(
        '/api_keys',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getNotificationToken: async ({
    token,
    organizationId,
  }: IBasePayload): Promise<any> => {
    return (
      await api.post(
        '/notifications/token',
        {},
        api.getHeaders(token),
        { organization_id: organizationId },
      )
    ).data
  },
  getFilterOptions: async (
    token: IToken,
    organizationId: number,
    datasetId: string,
    table: string,
    column: string,
    cursor: string
  ) => {
    return (
      await api.get(
        `/powerbi_dataset/${datasetId}`,
        { table, column, cursor, organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getCategoryColumns: async (
    category: string,
    token,
    organizationId
  ): Promise<IColumns> => {
    return (
      await api.get(
        `/custom_table/${category}/default`,
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  uploadTable: async (
    columns: Column[],
    category: string,
    file: File,
    onUploadProgress: (progressEvent: any) => void,
    token: IToken,
    organizationId: number,
  ): Promise<any> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('columns', JSON.stringify(columns))
    return (
      await api.post(
        `/custom_table/${category}/create`,
        formData,
        {...api.getHeaders(token), 'Content-Type': 'multipart/form-data'},
        { organization_id: organizationId },
        onUploadProgress
      )
    ).data
  },
  getCustomTables: async (
    token: IToken,
    organizationId: number
  ): Promise<any> => {
    return (
      await api.get(
        '/custom_table/list',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getCustomTableColumns: async (
    token: IToken,
    organizationId: number,
    category: string
  ): Promise<any> => {
    return (
      await api.get(
        `/custom_table/${category}/columns`,
        { organization_id: organizationId },
        api.getHeaders(token)
    )
    ).data
  },
  updateCustomTableColumns: async (
    token: IToken,
    organizationId: number,
    category: string,
    columns: Column[]
  ): Promise<any> => {
    return (
      await api.put(
        `/custom_table/${category}/edit`,
        columns,
        api.getHeaders(token),
        { organization_id: organizationId },
    )
    ).data
  },
  deleteCustomTable: async (
    token: IToken,
    organizationId: number,
    category: string
  ): Promise<any> => {
    return (
      await api.delete(
        `/custom_table/${category}/delete`,
        api.getHeaders(token),
        { organization_id: organizationId },
    )
    ).data
  },
  listClusteringCategories: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<string[]> => {
    return (
      await api.get(
        '/clustering/categories',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getGA4Emails: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<string[]> => {
    return (
      await api.get(
        '/google_analytics_4/emails',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  verifyUniqueAccount: async ({
    token,
    serviceUuid,
    projectId,
    datasetId,
    organizationId,
  }: {
    token: IToken
    serviceUuid: string
    projectId: string
    datasetId: string
    organizationId: number
  }) => {
    const credentials = {project_id: projectId, dataset_id: datasetId}
    return (
      await api.post(
        `/services/${serviceUuid}/oauth/verify`,
        {},
        api.getHeaders(token),
        { credentials, organization_id: organizationId }
      )
    ).data
  },
  verifyApiCreds: async ({
    token,
    serviceUuid,
    apiCreds,
    organizationId,
  }: {
    token: IToken
    serviceUuid: string
    apiCreds: any
    organizationId: number
  }) => {
    return (
      await api.post(
        `/services/${serviceUuid}/oauth/verify`,
        {},
        api.getHeaders(token),
        { credentials: apiCreds, organization_id: organizationId }
      )
    ).data
  },
  getEmbedStatus: async ({
    organizationId,
    token,
  }: IBasePayload): Promise<any> => {
    return (
      await api.get(
        '/embed/status',
        { organization_id: organizationId },
        api.getHeaders(token)
      )
    ).data
  },
  getMicrosoftAuthUri: async ({
    params
  }): Promise<any> => {
    return (
      await api.get(
        '/auth/microsoft/auth_uri', 
        {is_mona: params.isMona, state: params.monaSubscription},
        {}
      )
    ).data
  },
  authenticateWithMicrosoft: async ({
    token,
    params
  }): Promise<any> => {
    // TODO: complete this call
    return (
      await api.get(
        'auth/microsoft/authenticate',
        {is_mona: params.isMona},
        api.getHeaders(token)
      )
    ).data
  },
  createMonaSubscription: async ({
    microsoft_code,
    subscription
  }): Promise<any> => {
    return (
      await api.post(
        '/subscriptions/mona/create',
        {},
        {},
        {microsoft_code, subscription_uri: subscription},
      )
    ).data
  },
  verifyEmail: async (
    requestId,
    email,
  ): Promise<any> => {
    const verifyData = {request_id: requestId, email: email}
    return (
      await api.post(
        '/auth/verify_email',
        {},
        {},
        verifyData,
      )
    ).data
  },
  requestVerificationEmail: async (
    email,
  ): Promise<any> => {
    return (
      await api.post(
        '/auth/request_verification_email',
        {},
        {},
        {email:email},
      )
    ).data
  },
  requestMergeAccountsEmail: async (
    provider,
    email
  ): Promise<any> => {
    return (
      await api.post(
        '/auth/request_merge_accounts_email',
        {},
        {},
        {provider, email},
      )
    ).data
  },
  mergeAccounts: async (
    requestId,
    email,
    password
  ): Promise<any> => {
    return (
      await api.post(
        '/auth/merge_accounts',
        {},
        {},
        {request_id: requestId, email, password},
      )
    ).data
  },
  checkMergeAccounts: async (
    email,
    provider
  ): Promise<any> => {
    return (
      await api.get(
        '/auth/check_merge_accounts',
        {email, provider},
        {}, 
      )
    ).data
  },
  checkUserSubscriptions: async ({
    token
  }: {
    token: IToken
  }) => {
    return (
      await api.get(
        '/user/subscriptions/check',
        { },
        api.getHeaders(token), 
      )
    ).data
  },
  linkSubscription: async ({
    token,
    subscriptionId,
    organizationId
  }: {
    token: IToken,
    subscriptionId: string,
    organizationId: string
  }) => {
    return (
      await api.post(
        `/subscriptions/link/${subscriptionId}`,
        {},
        api.getHeaders(token),
        {organization_id: organizationId}
      )
    ).data
  },
  checkSubscriptionExists: async ({
    token,
    subscriptionId,
  }: {
    token: IToken,
    subscriptionId: string,
  }) => {
    return (
      await api.get(
        `/subscriptions/check/${subscriptionId}`,
        {},
        api.getHeaders(token),
      )
    )
  },
  getSubscriptionDetails: async ({
    token,
    subscriptionId,
  }: {
    token: IToken,
    subscriptionId: string,
  }): Promise<ISubscriptionDetailsResponse> => {
    return (
      await api.get(
        `/subscriptions/details/${subscriptionId}`,
        {},
        api.getHeaders(token),
      )
    ).data
  },
  getPublicKey: async ({ token, serviceUUID, organizationId }): Promise<string> => {
    return (await api.get(`/services/${serviceUUID}/public_key`, {organization_id: organizationId}, api.getHeaders(token))).data
  },
  getAllNotifications: async ({organizationId, token}): Promise<INotification[]> => {
    return (await api.get(`/notifications/all`, {organization_id: organizationId}, api.getHeaders(token))).data
  },
  refreshNotifications: async ({organizationId, params, token}): Promise<INotification[]> => {
    return (await api.post(`/notifications/refresh`, params, api.getHeaders(token), {organization_id: organizationId})).data
  },
  getFilteredNotifications: async ({organizationId, params, token}): Promise<INotificationFilterResponse> => {
    return (await api.post(`/notifications/filter`, params, api.getHeaders(token), {organization_id: organizationId})).data
  },
  updateNotification: async ({organizationId, params, token}): Promise<INotificationUpdateStatus> => {
    return (await api.post(`/notifications/update/${params.notificationId}`, params, api.getHeaders(token), {organization_id: organizationId})).data
  },
  getNotificationCount: async ({organizationId, params, token}): Promise<{count: number}> => {
    return (await api.post(`/notifications/count`, params, api.getHeaders(token), {organization_id: organizationId})).data
  },
  getAllDestinations: async ({organizationId, token}): Promise<IDestination[]> => {
    return (await api.get(`/destinations`, {organization_id: organizationId}, api.getHeaders(token))).data
  },
  getDestination: async ({destinationId, organizationId, token}): Promise<IDestination> => {
    return (await api.get(`/destination/${destinationId}`, {organization_id: organizationId}, api.getHeaders(token))).data
  },
  getOrganizationDestination: async ({organizationId, token}): Promise<IOrganizationDestination> => {
    return (await api.get(`/destinations/organizations`, {organization_id: organizationId}, api.getHeaders(token))).data
  },
  createOrUpdateOrganizationDestination: async ({destinationId, destinationName, destinationCreds, location, organizationId, token}): Promise<IDestination[]> => {
    return (await api.post(`/destinations/organizations`, {name: destinationName, destination_id: destinationId, credentials: destinationCreds, location}, api.getHeaders(token), {organization_id: organizationId})).data
  },
  removeOrganizationDestination: async ({organizationId, token}): Promise<IDestination[]> => {
    return (await api.delete(`/destinations/organizations`, api.getHeaders(token), {organization_id: organizationId})).data
  },
  checkOrganizationDestinationStatus: async ({organizationId, token}): Promise<{organization_id: string, can_configure_destination: boolean}> => {
    return (await api.get(`/destinations/organizations/status`, {organization_id: organizationId}, api.getHeaders(token))).data
  },
  checkOrganizationExternalDb: async ({organizationId, token}): Promise<{organization_id: string, can_configure_destination: boolean}> => {
    return (await api.get(`/destinations/organizations/external_db`, {organization_id: organizationId}, api.getHeaders(token))).data
  },
}

export default api
