import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useParams, useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'

import _ from 'lodash'
import isUUID from 'is-uuid'

import ScreenLoading from 'components/ScreenLoading'
import DropDownWithImage from 'components/DropDownWithImage'
import DropDownField, { makeOption } from 'components/DropDownField'
import TagManager from 'react-gtm-module'

import Page404 from 'pages/ErrorPages/404'

import useGetConnectedAccounts from 'containers/services/useGetConnectedAccounts'
import useGetServices from 'containers/services/useGetServices'
import useGetCountries from 'containers/countries/useGetCountries'
import useGetColumns from 'containers/clustering/useGetColumnsByCategory'
import useGetCompetitorsReport from 'containers/competitors/useGetCompetitorsReport'
import useSaveCompetitorsReport from 'containers/competitors/useSaveCompetitorsReport'
import OrganizationsContext from 'containers/organizations/organizationsContext'

import './CompetitorsReport.scss'

const IS_BRAND = {
  name: 'Is Brand',
  id: 'cluster_brand_auto',
  values: [
    {
      id: 'no',
      name: 'No',
    },
    {
      id: 'yes',
      name: 'Yes',
    },
  ],
}

const CompetitorsReport = () => {
  const { competitorsId } = useParams()
  const { serviceAccountsError, getServiceAccounts } = useGetConnectedAccounts()
  const { countries, loadingCountries, countriesError, getCountries } =
    useGetCountries()
  const { columns, loadingColumns, columnsError, fetchColumnsByCategory } =
    useGetColumns()
  const { isLoadingReport, reportError, getCompetitorsReport } =
    useGetCompetitorsReport()
  const { isSavingReport, saveReportError, saveReport } =
    useSaveCompetitorsReport()
  const { getServices } = useGetServices()
  let history = useHistory()

  const [selectedAccountId, setSelectedAccountId] = useState()
  const [availableAccounts, setAvailableAccounts] = useState([])
  const [loadingState, setLoadingState] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState('gr')
  const [selectedColumnId, setSelectedColumnId] = useState('cluster_brand_auto')
  const [selectedValue, setSelectedValue] = useState('No')

  const [availableAccountsError, setAvailableAccountsError] = useState(false)

  const [saveError, setSaveError] = useState()

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  const getData = async () => {
    if (isLoadingOrganization) {
      return
    }

    const [tempColumns, allAccounts, allServices, tempReport] =
      await Promise.all([
        fetchColumnsByCategory({
          category: 'searchTerms',
          organization: currentOrganization,
        }),
        getServiceAccounts(currentOrganization.id),
        getServices(),
        getCompetitorsReport({
          reportId: competitorsId,
          organization: currentOrganization,
        }),
      ])

    let serviceLogosById = {}

    allServices.forEach((service) => {
      serviceLogosById[service.id] = service.logo
    })

    const tempAvailableAccounts = []

    allAccounts.forEach((account) => {
      if (
        account.service === 'google_search_console' ||
        account.service === 'google_ads'
      ) {
        tempAvailableAccounts.push({
          ...account,
          image: serviceLogosById[account.service],
        })
      }
    })

    setAvailableAccounts(tempAvailableAccounts)

    if (!tempReport) {
      if (tempAvailableAccounts.length === 0) {
        setAvailableAccountsError(true)
      } else {
        setSelectedAccountId(tempAvailableAccounts[0].uuid)
      }

      if (tempColumns.columns.length > 0) {
        setSelectedColumnId('cluster_brand_auto')
        setSelectedValue('No')
      }
    } else {
      setSelectedAccountId(tempReport.account_uuid)
      setSelectedColumnId(tempReport.filter_column)
      if (tempReport.filter_column !== 'cluster_brand_auto') {
        setSelectedValue(tempReport.filter_value)
      } else {
        setSelectedValue(tempReport.filter_value === 'yes' ? 'Yes' : 'No')
      }
      setSelectedCountry(tempReport.country_code)
    }

    setLoadingState(false)
  }

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [isLoadingOrganization, currentOrganization])

  const handleChangeValue = (value) => {
    setSelectedValue(value)
  }

  const handleSave = async () => {
    setSaveError(null)
    if (
      !selectedCountry ||
      !selectedAccountId ||
      !selectedColumnId ||
      !selectedValue
    ) {
      setSaveError('Could not save')
      return
    }
    const value =
      selectedColumnId !== 'cluster_brand_auto'
        ? selectedValue
        : selectedValue === 'Yes'
        ? 'yes'
        : 'no'

    const saveReportStatus = await saveReport({
      organization: currentOrganization,
      countryCode: selectedCountry,
      reportId: competitorsId,
      uuid: competitorsId,
      accountUUID: selectedAccountId,
      columnId: selectedColumnId,
      value: value,
      orderBy: orderMetric.toLowerCase(),
    })
    if (saveReportStatus) {
      TagManager.dataLayer({
        dataLayer: { event: 'saved_competition_analysis_report' },
      })
      localStorage.setItem('competitorsReportSaved', true)
      history.push(`/organization/${currentOrganization.slug}/competitors`)
    }
  }

  const handleChangeColumn = (value) => {
    let newSelectedColumn
    if (value === 'Is Brand') {
      newSelectedColumn = IS_BRAND
    } else {
      newSelectedColumn = _.find(columns.columns, ['name', value])
    }

    setSelectedColumnId(newSelectedColumn.id)

    if (newSelectedColumn.values.length > 0) {
      setSelectedValue(newSelectedColumn.values[0].name)
    } else {
      setSelectedValue(null)
    }
  }

  let selectedColumn

  if (selectedColumnId) {
    if (selectedColumnId === 'cluster_brand_auto') {
      selectedColumn = IS_BRAND
    } else {
      selectedColumn = _.find(columns.columns, ['id', selectedColumnId])
    }
  }

  let availableValues = []

  if (selectedColumn) {
    availableValues = _.sortedUniq(
      selectedColumn.values.map((value) => value.name).sort()
    )
  }

  const selectedAccount = useMemo(() => {
    if (!selectedAccountId || !availableAccounts.length) {
      return null
    }
    return _.find(availableAccounts, ['uuid', selectedAccountId])
  }, [selectedAccountId, availableAccounts])

  const orderMetric = useMemo(() => {
    return selectedAccount && selectedAccount.service.endsWith('ads')
      ? 'Cost'
      : 'Clicks'
  }, [selectedAccount])

  if (!isUUID.v4(competitorsId)) {
    return <Page404 />
  }

  if (isLoadingReport || loadingColumns) {
    return <ScreenLoading />
  }

  if (serviceAccountsError || countriesError || columnsError || reportError) {
    return <>Failed loading report</>
  }

  return (
    <div className="clusters-campaigns container-column competitors">
      {saveReportError && (
        <div className="alert alert-danger" role="alert">
          Error saving the report
        </div>
      )}
      <NavLink to={`/organization/${currentOrganization.slug}/competitors`}>
        <span className="material-icons-outlined back-arrow non-selectable">
          arrow_back
        </span>
      </NavLink>
      <div className="head" style={{ marginBottom: '30px' }}>
        <div
          className="page-name"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <h2>Competition Analysis Reports</h2>
          <span className="subtitle-text" style={{ marginTop: '5px' }}>
            Set up a competition analysis report. A competition analysis report
            displays the ranking of competitors on a set of search terms. The
            report is calculated once per month starting from the day this
            report is first set up.
          </span>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="account">
            {availableAccountsError ? (
              <div className="alert alert-danger" role="alert">
                No accounts to select from
              </div>
            ) : (
              <>
                {loadingState ? (
                  <ScreenLoading />
                ) : (
                  <DropDownWithImage
                    label="Account"
                    selectedId={selectedAccountId}
                    options={availableAccounts.map((account) => ({
                      id: account.uuid,
                      value: account.name,
                      subtitle: account.uuid,
                      image: account.image,
                    }))}
                    setSelectedId={setSelectedAccountId}
                  />
                )}
              </>
            )}
          </div>
          <div className="country">
            {loadingCountries ? (
              <ScreenLoading />
            ) : (
              <DropDownWithImage
                label="Country"
                selectedId={selectedCountry}
                options={countries.map((country) => ({
                  id: country.code,
                  value: country.name,
                  image: '',
                }))}
                setSelectedId={setSelectedCountry}
              />
            )}
          </div>
        </div>
        <span className="search-terms">Search term cluster (Advanced)</span>
        <span className="subtitle-text" style={{ marginTop: '5px' }}>
          Configure the set of search terms that will be analyzed. You can
          choose a column and value defined in the “Clusters” section.
        </span>
        <span className="subtitle-text" style={{ marginTop: '5px' }}>
          Leave this to the default value if you do not know what to set.
        </span>
        <div className="row search-terms">
          <div className="competitors-column">
            {loadingState ? (
              <ScreenLoading />
            ) : (
              <DropDownField
                label={'Column'}
                value={selectedColumn?.name}
                onChange={handleChangeColumn}
                options={[makeOption('Is Brand')].concat(
                  columns?.columns.map((column) => makeOption(column.name))
                )}
              />
            )}
          </div>
          <div className="competitors-value">
            {loadingState ? (
              <ScreenLoading />
            ) : (
              <>
                {availableValues.length === 0 ? (
                  <div className="alert alert-danger" role="alert">
                    No values to select from
                  </div>
                ) : (
                  <DropDownField
                    label={'Value'}
                    value={selectedValue}
                    onChange={handleChangeValue}
                    options={availableValues.map(makeOption)}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <span className="subtitle-text" style={{ marginTop: '5px' }}>
          From these search terms, we will analyze top 300 by{' '}
          {orderMetric.toLowerCase()}. Contact us if you need to change the
          following options.
        </span>
        <div className="row search-terms">
          <div className="search-by">
            <DropDownField
              label={'Top search terms by metric'}
              value={orderMetric}
              onChange={() => true}
              options={['Clicks', 'Cost'].map(makeOption)}
              disabled={true}
            />
          </div>
          <div className="search-count">
            <DropDownField
              label={'Number of search terms'}
              value={'300'}
              onChange={() => true}
              options={[{ label: '300', value: '300' }]}
              disabled={true}
            />
          </div>
        </div>
        <div className="save-button">
          {isSavingReport ? (
            <ScreenLoading />
          ) : (
            <>
              {saveError && <span className="save-error">{saveError}</span>}
              <button onClick={handleSave} className="submit-button">
                Save
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(CompetitorsReport)
