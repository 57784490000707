import { createContext, useContext } from 'react'
import { FeatureResponse } from 'types'

const FeatureCtx = createContext<FeatureResponse>({ features: null })

export function FeatureProvider({ value, children }) {
  return <FeatureCtx.Provider value={value}>{children}</FeatureCtx.Provider>
}

export const useFeatureCtx = () => {
  return { ...useContext(FeatureCtx) }
}
