import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'

import api from '../api'

const useConnectAccounts = () => {
  const token = useSelector(selectToken)

  const [loadingConnectAccounts, setLoadingConnectAccounts] = useState(false)
  const [connectAccountsError, setConnectAccountsError] = useState('')

  const connectAccounts = async (credentialsId, organizationId, accounts) => {
    try {
      setConnectAccountsError('')
      setLoadingConnectAccounts(true)
      const connectedAccounts = await api.connectAccounts({
        accounts,
        token,
        credentialsId,
        organizationId,
      })
      setLoadingConnectAccounts(false)
      return connectedAccounts
    } catch (error) {
      setConnectAccountsError('An error occured, please try again.')
      setLoadingConnectAccounts(false)
    }
  }

  return {
    connectAccounts,
    loadingConnectAccounts,
    connectAccountsError,
    setConnectAccountsError,
  }
}

export default useConnectAccounts
