import mayaLogo from 'images/maya-logo.png'
import googleLogo from 'images/google-icon.png'
import microsoftLogo from 'images/microsoft-icon.png'

import './NotificationWindow.scss'

import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import ScreenLoading from 'components/ScreenLoading'
import InputField from 'components/InputField'

import useQuery from 'containers/useQuery'
import useLogout from 'containers/authentication/useLogout'
import useMergeAccount from 'containers/authentication/useMergeAccount'
import useRequestMergeAccount from 'containers/authentication/useRequestMergeAccount'
import useCheckMergeAccount from 'containers/authentication/useCheckMergeAccounts'
import useSignIn from 'containers/authentication/useSignIn'

const MergeAccount = () => {
  const METHODS = {
    MICROSOFT: 'microsoft',
    GOOGLE: 'google',
    MAYA: 'maya',
  }
  const logout = useLogout()
  const [, mergeAccount] = useMergeAccount()
  const [, checkMergeAccount] = useCheckMergeAccount()
  const [, requestMergeAccountsEmail] = useRequestMergeAccount()
  const { signInWithGoogle, signInWithMicrosoft, signInWithEmailPassword } =
    useSignIn()
  const location = useLocation()
  const history = useHistory()

  const email = useQuery().get('email')
  const requestId = useQuery().get('request_id')
  const provider = useQuery().get('provider')!
  const isGoogleSignIn =
    useQuery().get('is_google_signin') === 'true' ? true : false
  const isMicrosoftSignIn =
    useQuery().get('is_microsoft_signin') === 'true' ? true : false
  const isPasswordSignIn =
    useQuery().get('is_password_signin') === 'true' ? true : false
  const isMona =
    useQuery().get('is_mona') === '1' ? true : false
  const [pendingSendVerification, setPendingSendVerification] = useState(false)
  const [emailStatus, setEmailStatus] = useState<string | null>(null)
  const [mergingError, setMergingError] = useState<string | null>(null)
  const [checkMergeError, setCheckMergeError] = useState(false)
  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [isMerging, setIsMerging] = useState(false)

  const goToSignIn = () => {
    logout()
  }

  const checkMerge = async () => {
    const response = await checkMergeAccount(email!, provider)
    if (response?.is_merged === true) {
      history.push('/')
      return
    } else {
      setCheckMergeError(true)
    }
  }

  const checkMergePeriodically = async (timeout?: number) => {
    const interval = setInterval(async () => {
      const response = await checkMergeAccount(email!, provider)
      if (response?.is_merged === true) {
        if (provider === 'google') {
          const googleIdToken = location.state?.googleIdToken
          await signInWithGoogle({ google_id_token: googleIdToken })
        } else if (provider === 'microsoft') {
          const microsoftAccessToken = location.state?.microsoftAccessToken
          await signInWithMicrosoft({
            microsoft_access_token: microsoftAccessToken,
          })
        } else {
          console.error('Invalid provider')
        }
        clearInterval(interval)
        window.location.href = '/'
        return
      }
    }, timeout || 3000)
  }

  const resendMergeEmail = async () => {
    setPendingSendVerification(true)
    const response = await requestMergeAccountsEmail(provider, email!)
    if (response.success === true) {
      setEmailStatus('Merge email sent!')
      setTimeout(() => {
        setEmailStatus(null)
      }, 5000)
    }
    setPendingSendVerification(false)
  }

  const merge = async (withPassword?: boolean) => {
    if (withPassword && newPassword !== repeatNewPassword) {
      setPasswordError(true)
      return
    }
    setIsMerging(true)
    const response = await mergeAccount(requestId, email, newPassword)

    if (response.success === true) {
      if (provider === 'maya' && withPassword) {
        await signInWithEmailPassword({ email, password: newPassword })
      }
      checkMergePeriodically(5000)
    }
    if (response.success !== true) {
      const error_detail = response
      if (error_detail === 'merge_request_does_not_exist') {
        setMergingError('The merge link is invalid. Please sign in again.')
      } else if (error_detail === 'already_merged') {
        setMergingError(`Your email has already been merged with ${provider}`)
      } else {
        setMergingError(
          'An error occurred while merging accounts. Please try again later.'
        )
      }
      setIsMerging(false)
    }
  }

  useEffect(() => {
    // Runs only when requestId is present ie. when the user clicks on the merge link
    if (requestId && email && provider !== 'maya') {
      merge()
    }
  }, [])

  useEffect(() => {
    // Checks if the accounts have been merged every 3 seconds and if so, login and redirect to home
    if (!requestId && email && provider) {
      checkMergePeriodically(3000)
    }
  }, [])

  // Load this if the provider is Maya in order to set a password during merge
  if (requestId && email && provider === 'maya') {
    return (
      <>
        <header>
          <div className="logo">
            <img src={mayaLogo} alt="Maya" />
          </div>
        </header>
        <div className="alert-container">
          <div className="alert-body">
            <div className="logo-container">
              <img src={mayaLogo} alt="Maya" />
            </div>
            <div className="notification-message">
              <p>
                Your email has been successfully merged with Maya.
                <br />
                Please set a new password.
              </p>
            </div>
            <div className="password-container">
              <InputField
                value={newPassword}
                setValue={setNewPassword}
                label="New password"
                type="password"
              />
              <InputField
                value={repeatNewPassword}
                setValue={setRepeatNewPassword}
                label="Repeat password"
                type="password"
              />
            </div>
            {passwordError && (
              <p style={{ color: 'red' }}>Passwords do not match</p>
            )}
            <div className="notification-buttons">
              <button onClick={() => merge(true)} className="submit-button">
                {isMerging ? (
                  <ScreenLoading spinnerColor="white" />
                ) : (
                  'Set password'
                )}
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  // Load this if we are merging with Google or Microsoft
  if (requestId && email && provider) {
    return (
      <>
        {mergingError !== null ? (
          <>
            <p style={{ color: 'red' }}>{mergingError}</p>
            <button
              onClick={() => history.push(`/sign-in`)}
              className="submit-button"
            >
              Back
            </button>
          </>
        ) : (
          <p>Redirecting...</p>
        )}{' '}
      </>
    )
  }

  return (
    <>
      <header>
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
      </header>
      <div className="alert-container">
        <div className="alert-body">
          <div className="logo-container">
            <img src={mayaLogo} alt="Maya" />
            {(provider === METHODS.MICROSOFT ||
              provider === METHODS.GOOGLE) && (
              <img
                src={
                  provider === METHODS.MICROSOFT ? microsoftLogo : googleLogo
                }
                alt={provider}
              />
            )}
          </div>
          <h3>Looks like this account is already in use</h3>
          <div className="accounts-box">
            <div
              className={`account ${isPasswordSignIn ? 'valid' : 'invalid'}`}
            >
              <div className="head">
                <img src={mayaLogo} alt="Maya" />
                <p>Maya</p>
              </div>
              <span className="material-icons">
                {isPasswordSignIn ? 'check' : 'do_disturb'}
              </span>
            </div>
            <div
              className={`account ${isMicrosoftSignIn ? 'valid' : 'invalid'}`}
            >
              <div className="head">
                <img src={microsoftLogo} alt="Microsoft" />
                <p>Microsoft</p>
              </div>
              <span className="material-icons">
                {isMicrosoftSignIn ? 'check' : 'do_disturb'}
              </span>
            </div>
            <div className={`account ${isGoogleSignIn ? 'valid' : 'invalid'}`}>
              <div className="head">
                <img src={googleLogo} alt="Google" />
                <p>Google</p>
              </div>
              <span className="material-icons">
                {isGoogleSignIn ? 'check' : 'do_disturb'}
              </span>
            </div>
          </div>
          <h4>
            Please follow the instructions on the email we have sent to{' '}
            <span className="email">{email}</span> in order to be able to
            Sign-In using{' '}
            <span className="provider-name">
              {provider.slice(0, 1).toUpperCase() + provider.slice(1)}
            </span>
            !
          </h4>
          { isMona && <p style={{fontWeight: "bold"}}>You need to connect your Microsoft account to Maya in order to be able to link your Mona Subscription with us.</p>}
          <p>
            The link expires after 24 hours. If the link doesn't work or if you
            haven't received an email check your Spam and/or Trash folders. Alternatively, click
            the resend button below to have another email sent to you.
          </p>
          <a className="resend-link" onClick={resendMergeEmail}>
            {emailStatus || "I haven't received an email. Resend"}
          </a>
          {pendingSendVerification && <ScreenLoading />}
          <div className="button-container">
            <button className="submit-button" onClick={goToSignIn}>
              Back
            </button>
            <button className="submit-button" onClick={checkMerge}>
              I merged the accounts
            </button>
          </div>
          {checkMergeError && (
            <p className="warn">The accounts have not been merged</p>
          )}
        </div>
      </div>
    </>
  )
}

export default MergeAccount
