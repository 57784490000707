import { createContext, useContext, useState } from 'react'

interface ISidebarContext {
  isSidebarCollapsed: boolean
  isEmbedEnabled: boolean
  collapseSidebar: React.Dispatch<React.SetStateAction<boolean>> | (() => void)
}

const SidebarContext = createContext<ISidebarContext>({
  isSidebarCollapsed: false,
  isEmbedEnabled: false,
  collapseSidebar: () => {},
})
 
export function SidebarProvider({ isEmbedEnabled, children }) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  return (
    <SidebarContext.Provider
      value={{
        isSidebarCollapsed: isCollapsed,
        isEmbedEnabled,
        collapseSidebar: setIsCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebarCtx = () => {
  return { ...useContext(SidebarContext) }
}
