import React from 'react'
import './ProgressBar.scss'

interface IProgressBarProps {
  loadValue: number
  maxValue?: number
  showProgress?: boolean
  progressAlignment: 'left' | 'right' | 'center'
  switchWhenDone?: boolean
  children?: React.ReactNode
}

const ProgressBar: React.FC<IProgressBarProps> = ({
  loadValue,
  maxValue = 100,
  showProgress = false,
  progressAlignment = 'left',
  switchWhenDone = false,
  children,
}) => {
  if (loadValue === 100 && switchWhenDone) {
    return <>{children}</>
  }

  return (
    <div className={`progress-bar-container ${progressAlignment}`}>
      {showProgress && loadValue && (
        <p className="percent-value">{loadValue}%</p>
      )}
      <progress
        className="upload-progress"
        value={loadValue}
        max={`${maxValue}`}
      ></progress>
    </div>
  )
}

export default ProgressBar
