import React, { useState } from 'react'

const Credential = ({ label, type, value, setValue, error, removeError }) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (event) => {
    removeError()
    setValue(event.target.value)
  }

  return (
    <div
      className={`input-field ${isFocused ? 'focused' : ''} ${
        error ? 'error' : ''
      }`}
    >
      <div className="input-header">
        <label>{label}</label>
        {error ? <span className="error-message">{error}</span> : <></>}
      </div>
      <input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={value}
        onChange={handleChange}
        type={type}
      />
    </div>
  )
}

export default React.memo(Credential)
