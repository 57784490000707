import * as pbi from 'powerbi-client'
import React, {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { IEmbedReport } from 'types'

interface IReportsContext {
  embedReport: pbi.Report | null
  setEmbedReport: React.Dispatch<SetStateAction<pbi.Report | null>>
  report: IEmbedReport | null
  setReport: React.Dispatch<SetStateAction<IEmbedReport | null>>
  reportPages: pbi.Page[] | []
  setReportPages: React.Dispatch<SetStateAction<pbi.Page[]>>
  getReportPages: (report: any) => void
  clearReport: () => void
}

const ReportsContext = createContext<IReportsContext>({
  embedReport: null,
  setEmbedReport: () => {},
  report: null,
  setReport: () => {},
  reportPages: [],
  setReportPages: () => {},
  getReportPages: () => {},
  clearReport: () => {},
})

export function ReportsProvider({ children }) {
  const [embedReport, setEmbedReport] = useState<pbi.Report | null>(null)
  const [reportPages, setReportPages] = useState<pbi.Page[]>([])
  const [report, setReport] = useState<IEmbedReport | null>(null)

  const history = useHistory()

  const getReportPages = async (report: pbi.Report) => {
    const pages = await report.getPages()
    // filter's by page visibility
    return pages.filter((page) => page.visibility !== 1)
  }

  const clearReport = () => {
    setEmbedReport(null)
    setReportPages([])
    setReport(null)
  }

  useEffect(() => {
    history.listen((loc) => {
      if (!loc.pathname.includes('dashboard')) {
        clearReport()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearReport])

  useEffect(() => {
    setReportPages([])
  }, [report])

  useEffect(() => {
    if (report && report.name.slice(0, 1) === '-') {
      setReport((prev) => {
        if (prev !== null) {
          return {
            ...prev,
            name:
              report.name.slice(1, 2).toUpperCase() +
              report.name.slice(2, report.name.length),
          }
        } else {
          return null
        }
      })
    }
  }, [report])

  return (
    <ReportsContext.Provider
      value={{
        embedReport,
        setEmbedReport,
        report,
        setReport,
        reportPages,
        setReportPages,
        getReportPages,
        clearReport,
      }}
    >
      {children}
    </ReportsContext.Provider>
  )
}

export const useReportsCtx = () => {
  return { ...useContext(ReportsContext) }
}
