import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'

import { Draggable } from 'react-beautiful-dnd'

const ConfirmDelete = ({ handleDeleteItem }) => {
  return (
    <div className="confirm-delete">
      <span className="sure">Are you sure?</span>
      <span onClick={handleDeleteItem} className="delete">
        Delete
      </span>
      <span className="material-icons-outlined arrow">arrow_drop_down</span>
    </div>
  )
}

const Item = ({
  columns,
  id,
  editUrl,
  orderIndex,
  handleDeleteItem,
  isDraggable,
  linkText,
  subtitle,
  deletable = true,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const deleteRef = useRef()

  const handleClickOutside = (event) => {
    if (deleteRef.current && !deleteRef.current.contains(event.target)) {
      setConfirmDelete(false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }

  const handleClickBin = () => {
    setConfirmDelete(!confirmDelete)
    if (!confirmDelete) {
      document.addEventListener('mousedown', handleClickOutside)
    }
  }

  const getContent = (provided) => {
    return (
      <div
        className={`column ${!isDraggable ? 'is-not-draggable' : ''} ${
          subtitle ? 'with-subtitle' : ''
        }`}
      >
        <div className="column-main">
          {isDraggable && (
            <div className="container-row container-center drag-handle-container">
              <span
                className={`material-icons-outlined drag-handle`}
                {...provided.dragHandleProps}
              >
                drag_indicator
              </span>
            </div>
          )}
          <div className="flex-grow grid">
            {columns.map((column) => (
              <div
                key={column.value}
                className={`grid-column-${column.size} info`}
              >
                <span className="title subtitle-text">{column.label}</span>
                <NavLink to={editUrl} className="name" style={column.valueStyle}>
                  {column.value}
                </NavLink>
              </div>
            ))}
            <div className="grid-column-2 actions">
              <div ref={deleteRef} className="delete">
                {deletable && (
                  <>
                    <span
                      onClick={handleClickBin}
                      className="material-icons-outlined bin"
                    >
                      delete_outline
                    </span>
                    {confirmDelete && (
                      <ConfirmDelete handleDeleteItem={handleDeleteItem} />
                    )}
                  </>
                )}
              </div>
              <NavLink to={editUrl} className="edit-button link">
                {linkText}
              </NavLink>
            </div>
          </div>
        </div>
        {subtitle && <div className="subtitle-section">{subtitle}</div>}
      </div>
    )
  }

  if (!isDraggable) {
    return getContent()
  }

  return (
    <Draggable key={id} draggableId={id} index={orderIndex}>
      {(provided, _draggableSnapshot) => (
        <div
          ref={provided.innerRef}
          className="draggable-column"
          {...provided.draggableProps}
        >
          {getContent(provided)}
        </div>
      )}
    </Draggable>
  )
}

export default React.memo(Item)
