import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

import { IToken, Product } from 'types'

const useProductDetails = (organization) => {
  const token: IToken = useSelector(selectToken)
  const [products, setProducts] = useState<Product[]>([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [productError, setProductError] = useState('')

  const getProducts = async () => {
    setLoadingProducts(true)
    try {
      const planResponse = await api.getProductDetails({
        token: token,
        organizationId: organization.id,
      })
      setProducts(planResponse)
      setLoadingProducts(false)
      return planResponse
    } catch (e) {
      setLoadingProducts(false)
      if (e instanceof Error) setProductError(e.message)
    }
    setLoadingProducts(false)
  }

  return { products, getProducts, loadingProducts, productError }
}

export default useProductDetails
