import React, { useState, useRef, useEffect, useCallback } from 'react'
import stringSimilarity from 'string-similarity'
import ScreenLoading from 'components/ScreenLoading'

const Suggestion = ({ value, selected, onClick, inputValue }) => {
  const elementRef = useRef()
  useEffect(() => {
    if (selected && elementRef.current) {
      // elementRef.current.parentNode.scrollTop = elementRef.current.offsetTop;
      const miss =
        elementRef.current.offsetTop - elementRef.current.parentNode.scrollTop
      if (miss > 140) {
        elementRef.current.parentNode.scrollTop =
          elementRef.current.offsetTop - 130
      } else if (miss < 0) {
        elementRef.current.parentNode.scrollTop = elementRef.current.offsetTop
      }
    }
  }, [selected])
  return (
    <li
      style={{ fontWeight: value === inputValue ? 'bold' : '' }}
      onClick={onClick}
      ref={elementRef}
    >
      <span>{value}</span>
    </li>
  )
}

const AutoCompleteField = ({
  label,
  options,
  value,
  onChange,
  disabled = false,
  inputRef = null,
  hasError = false,
  error = '',
  loadingOptions = false,
  autoFocus = false,
  filterWithSimilarity = false,
}) => {
  const [open, setOpen] = useState(autoFocus)
  const [inputValue, setInputValue] = useState(value)
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [showError, setShowError] = useState(true)

  const fieldRef = useRef()

  const handleClickOutside = useCallback(
    (event) => {
      if (disabled) {
        return
      }

      if (fieldRef.current && !fieldRef.current.contains(event.target)) {
        setOpen(false)
      }
      document.removeEventListener('mousedown', handleClickOutside)
    },
    [disabled, setOpen]
  )

  useEffect(() => {
    if (autoFocus && !disabled) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [autoFocus, disabled, handleClickOutside])

  useEffect(() => {
    setFilteredOptions(options)
    // eslint-disable-next-line
  }, [JSON.stringify(options)])

  const handleClickDropDown = () => {
    if (disabled) {
      return
    }

    setFilteredOptions(options)

    setOpen(!open)
    if (!open) {
      document.addEventListener('mousedown', handleClickOutside)
    }
  }

  const handleChooseOption = (optionIndex) => {
    if (disabled) {
      return
    }

    setShowError(false)
    const value = options[optionIndex]
    setInputValue(value)
    onChange(value)
  }

  const handleTextChange = (inputValue) => {
    setFilteredOptions(
      options.filter((value) => {
        const containsSubString = value
          .toLowerCase()
          .includes(inputValue.toLowerCase())

        if (filterWithSimilarity) {
          return (
            containsSubString ||
            stringSimilarity.compareTwoStrings(value, inputValue) > 0.4
          )
        } else {
          return containsSubString
        }
      })
    )
    setShowError(false)
    setInputValue(inputValue)
    onChange(inputValue)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 40) {
      const filteredIndex = filteredOptions.indexOf(value)
      const nextFilteredIndex = (filteredIndex + 1) % filteredOptions.length
      const nextValue = filteredOptions[nextFilteredIndex]
      handleChooseOption(options.indexOf(nextValue))
    } else if (event.keyCode === 38) {
      const filteredIndex = filteredOptions.indexOf(value)
      if (filteredIndex > 0) {
        const nextFilteredIndex = (filteredIndex - 1) % filteredOptions.length
        const nextValue = filteredOptions[nextFilteredIndex]
        handleChooseOption(options.indexOf(nextValue))
      }
    } else if (event.keyCode === 13) {
      setOpen(false)
    }
  }

  return (
    <div
      ref={fieldRef}
      onClick={handleClickDropDown}
      className={`drop-down-input-field ${open ? 'open' : ''} ${
        disabled ? 'disabled' : ''
      } ${hasError && !open ? 'error' : ''}`}
    >
      <div className="input-header">
        <label>{label}</label>
        {showError && error && <span className="error-message">{error}</span>}
      </div>
      {open ? (
        <input
          autoFocus
          value={inputValue}
          onChange={(e) => handleTextChange(e.target.value)}
          onKeyDown={handleKeyDown}
          className="value"
          type="text"
          ref={inputRef}
        />
      ) : (
        <div className="value">{value}</div>
      )}
      {open && loadingOptions && (
        <ul className="drop-down-options">
          <li style={{ padding: 40 }}>
            <ScreenLoading />
          </li>
        </ul>
      )}
      {open && !loadingOptions && filteredOptions && (
        <ul className="drop-down-options">
          {filteredOptions.map((option, optionIndex) => {
            return (
              <Suggestion
                key={optionIndex}
                value={option}
                selected={option === value}
                onClick={() => handleChooseOption(options.indexOf(option))}
                inputValue={inputValue}
              />
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default React.memo(AutoCompleteField)
