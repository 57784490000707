import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'
import useDataLoader from '../dataLoader'

const useListManualExpenses = (organizationId: number | null) => {
  const token = useSelector(selectToken)
  const fetchManualExpenses = async () => {
    if (organizationId) {
      return await api.listManualExpenses({ token, organizationId })
    }
  }

  return useDataLoader(fetchManualExpenses, [], [])
}

export default useListManualExpenses
