import { useEffect, useState } from 'react'
import capitalize from 'utils/capitalize'

function usePageTitle(pathname: string) {
  const [title, setTitle] = useState(pathname)

  const changeTitle = (str: string) => {
    const splitTitle = str.split('/')

    if (splitTitle.length === 1) {
      setTitle(capitalize(splitTitle[0]))
      return capitalize(splitTitle[0])
    }

    const capitalizedWords = splitTitle.slice(3).map((word) => {
      return word
        .split('-')
        .map((w) => capitalize(w))
        .join(' ')
    })

    const newTitle = ['Maya', ...capitalizedWords].join(' - ')

    setTitle(newTitle)
    return newTitle
  }

  useEffect(() => {
    document.title = title
  }, [title])

  return { title, changeTitle }
}

export default usePageTitle
