import React from 'react'
import { NavLink } from 'react-router-dom'

import mayaLogo from 'images/maya-logo.png'

import './404.scss'

const Page404 = () => {
  return (
    <div className="page-column">
      <header>
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
      </header>
      <Error404 />
    </div>
  )
}

export const Error404 = () => {
  return (
    <div className="container-grow">
      <div className="page-404">
        <span className="title">404</span>
        <span className="subtitle">
          We could not find the page you are looking for.
        </span>
        <NavLink className="submit-button" to="/">
          Go Home
        </NavLink>
      </div>
    </div>
  )
}

export default React.memo(Page404)
