import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router'
import { useCookies } from 'react-cookie'
import TagManager from 'react-gtm-module'

import './CreateOrganization.scss'

import Credential from '../../components/Credential'

import useCreateOrganization from '../../containers/organizations/useCreateOrganization'
import useGetOrganizations from '../../containers/organizations/useGetOrganizations'
import ScreenLoading from '../../components/ScreenLoading'

import mayaLogo from 'images/maya-logo.png'
import usePageTitle from 'containers/pageTitle/usePageTitle'

const CreateOrganization = () => {
  const {
    createOrganization,
    createOrganizationError,
    removeCreateOrganizationError,
    isCreatingOrganization,
  } = useCreateOrganization()
  const [organizationName, setOrganizationName] = useState('')
  const { clearOrganizationsCache } = useGetOrganizations()
  const { organizations, getOrganizations, loadingOrganizations } =
    useGetOrganizations()
  let history = useHistory()
  const [, , removeCookie] = useCookies(['user_id'])

  //Page title
  usePageTitle('Create Organization - Maya')

  const getData = async () => {
    await getOrganizations()
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  if (loadingOrganizations) {
    return <ScreenLoading />
  }

  const handleSubmit = async () => {
    const createdOrganization = await createOrganization(organizationName)
    if (createdOrganization) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'created_organization',
          is_custom_track: true,
          event_params: {
            organizationId: createdOrganization.data.id,
            organizationSlug: createdOrganization.data.slug,
            organizationName: createdOrganization.data.name,
          }
        },
      })
      clearOrganizationsCache()
      history.push({
      pathname: '/',
      state: {
        destinations: true,
      },
    })
    }
  }

  const handleCancel = () => {
    if (organizations.length > 0) {
      history.push({
      pathname: '/',
    })
    } else {
      removeCookie('user_id', { path: '/' })
      history.push({
      pathname: '/',
      state: {
        destinations: true,
      },
    })
    }
  }

  return (
    <div className="page-column">
      <header>
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
      </header>
      <div className="container-grow">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="container-column create-organization-box"
        >
          <div>
            <h1 className="text-align-center">Create organization</h1>
            <div className="secondary-text text-align-center">
              You can rename it or join another organization later
            </div>
          </div>
          <div className="form-tip">
            <h3>Tip</h3>
            <p>
              If your company has a lot of brands or websites or in different
              countries it will be good to add every entity in a different
              organization. ex. If you are Google add as a different
              organization Gmail & Google search. In the end you want different
              products in a different organization so you can extract value.
            </p>
          </div>
          <div className="container-center container-column container-grow create-organization">
            <Credential
              value={organizationName}
              setValue={setOrganizationName}
              label="Name"
              type="text"
              spellcheck="false"
              error={createOrganizationError}
              removeError={() => removeCreateOrganizationError()}
            />
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              {!isCreatingOrganization ? (
                <button
                  disabled={isCreatingOrganization}
                  onClick={handleSubmit}
                  className="submit-button"
                >
                  Continue
                </button>
              ) : (
                <ScreenLoading />
              )}
            </div>
            <button onClick={handleCancel} className="link">
              Cancel
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default React.memo(CreateOrganization)
