import React, { useEffect, useContext, useState, useRef } from 'react'
import * as pbi from 'powerbi-client'
import * as rudderanalytics from 'rudder-sdk-js'

import ReportSaveDialog from 'components/ReportSaveDialog'

import OrganizationsContext from 'containers/organizations/organizationsContext'
import useAccessEmbedReport from 'containers/embed/useFetchEmbedReport'

import { PowerBIEmbed } from 'powerbi-client-react'
import slugify from 'utils/slugify'

import './Dashboard.scss'
import { useReportsCtx } from 'containers/reports/ReportsContext'
import Toolbar from 'components/Toolbar'
import TagManager from 'react-gtm-module'

const Dashboard = ({ match, onSaved, viewMode, reports }) => {
  const [embeddedReport, setEmbeddedReport] = useState(null)
  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const { currentOrganization } = useContext(OrganizationsContext)
  const reportIdRef = useRef(null)
  const currentOrganizationId = currentOrganization.id
  const reportSlug = match?.params?.reportSlug || '-seo'
  // eslint-disable-next-line no-unused-vars
  const [report, fetchReport,, fetchReportError] =
    useAccessEmbedReport(currentOrganizationId, reportSlug)
  const {
    setEmbedReport,
    getReportPages,
    reportPages,
    setReportPages,
    setReport,
  } = useReportsCtx()

  useEffect(() => {
    if (embeddedReport) {
      embeddedReport.on('rendered', () => {
        getReportPages(embeddedReport).then((pages) => setReportPages(pages))
      })
    }
    // eslint-disable-next-line
  }, [reportIdRef.current, embeddedReport])

  useEffect(() => {
    if (report) {
      setReport(report)
    }

    if (reportSlug === '-seo' && embeddedReport !== null) {
      getReportPages(embeddedReport).then((pages) => setReportPages(pages))
      setEmbedReport(embeddedReport)
    }
    // eslint-disable-next-line
  }, [report])

  useEffect(() => {
    if (currentOrganizationId && reportSlug) {
      fetchReport().then(() => {
        if (report) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'report_viewed',
              // TODO:
              // Specify is_custom_track = true for all custom tagmanager calls
              // For every event that has custom properties put them under the object property event_params
              // custom_property for identifying
              is_custom_track: true,
              event_params: {
                reportId: report.id,
                reportName: report.name,
                isDemo: report.demo,
                isCustom: report.is_custom,
              }
            },
          })

          // rudderanalytics.track('report_viewed', {
          //   report_id: report.id,
          //   report_name: report.name,
          //   is_demo: report.demo,
          //   is_custom: report.is_custom,
          // })
        }
      })
    }
    // eslint-disable-next-line
  }, [currentOrganizationId, reportSlug])

  useEffect(() => {
    if (embeddedReport) {
      embeddedReport.switchMode(
        viewMode === 'edit' && report.access_level === 'Edit'
          ? models.ViewMode.Edit
          : models.ViewMode.View
      )
    }
    // eslint-disable-next-line
  }, [viewMode, embeddedReport])

  const models = pbi.models

  const reportNameValidation = (name: string): string => {
    if (!name.length) {
      return 'Required'
    }
    if (name[0] === '-') {
      return 'Report name cannot start with dash (-)'
    }
    const nameSlug = slugify(name)
    const isDuplicate = reports.some((r) => slugify(r.name) === nameSlug)
    if (isDuplicate) {
      return 'Duplicate or reserved name'
    }
  }

  const handleSaveAs = (name: string) => {
    embeddedReport.saveAs({ name })
    setShowSaveDialog(false)
  }

  useEffect(() => {
    if (!embeddedReport) return

    if (window.innerWidth < 767) {
      embeddedReport.updateSettings({
        layoutType: models.LayoutType.MobilePortrait,
      })
    } else {
      embeddedReport.updateSettings({
        layoutType: models.LayoutType.Master,
      })
    }
    // eslint-disable-next-line
  }, [embeddedReport])

  return (
    <div className="dashboard">
      <ReportSaveDialog
        onCancel={() => {
          setShowSaveDialog(false)
        }}
        onSave={handleSaveAs}
        visible={showSaveDialog}
        reportNameValidation={reportNameValidation}
      />
      {fetchReportError ? (
        <div className="embed-info-error">
          <div className="box">
            <h1>Dashboard not ready</h1>
            <div>{fetchReportError}</div>
          </div>
        </div>
      ) : (
        <section id="report-container" className="">
          {!report ? (
            <PowerBIEmbed
              embedConfig={{
                type: 'report',
                id: undefined,
                embedUrl: undefined,
                accessToken: undefined,
                settings: {
                  layoutType:
                    window.innerWidth >= 767
                      ? models.LayoutType.Master
                      : models.LayoutType.MobilePortrait,
                },
                tokenType: models.TokenType.Embed,
              }}
              cssClassName={'embed-container'}
            />
          ) : (
            <PowerBIEmbed
              embedConfig={{
                type: 'report',
                id: report.id,
                embedUrl: report.embed_url,
                permissions: models.Permissions[report.permission_level],
                viewMode:
                  viewMode === 'edit' && report.access_level === 'Edit'
                    ? models.ViewMode.Edit
                    : models.ViewMode.View,
                accessToken: report.access_token,
                tokenType: models.TokenType.Embed,
                settings: {
                  useCustomSaveAsDialog: true,
                  panes: {
                    bookmarks: {
                      visible: viewMode === 'edit',
                    },
                    filters: {
                      visible: window.innerWidth >= 767,
                    },
                    fields: {
                      expanded: false,
                      visible: true,
                    },
                    pageNavigation: {
                      visible: false,
                      position: models.PageNavigationPosition.Left,
                    },
                  },
                },
              }}
              eventHandlers={
                new Map([
                  ['loaded', () => console.log('powerbi loaded')],
                  ['rendered', () => console.log('powerbi rendered')],
                  ['error', (e) => console.log('error', e.detail)],
                  [
                    'saved',
                    (e) => {
                      onSaved(e)
                    },
                  ],
                  [
                    'saveAsTriggered',
                    () => {
                      setShowSaveDialog(true)
                    },
                  ],
                ])
              }
              cssClassName={'embed-container'}
              getEmbeddedComponent={(embed) => {
                setEmbeddedReport(embed)
                setEmbedReport(embed)
                reportIdRef.current = embeddedReport?.config.id
              }}
            />
          )}
        </section>
      )}
      <Toolbar embeddedReport={embeddedReport} reportPages={reportPages} />
    </div>
  )
}

export default React.memo(Dashboard)
