import DropDownField, { makeOption } from 'components/DropDownField'
import InputField from 'components/InputField'
import PopUpMessage from 'components/PopUpMessage'
import ScreenLoading from 'components/ScreenLoading'
import useGetColumnByCategory from 'containers/clustering/useGetColumnByCategory'
import useGetColumns from 'containers/clustering/useGetColumnsByCategory'
import useGetPreviewByColumn from 'containers/clustering/useGetPreviewByColumn'
import useGetPreviewValueByColumn from 'containers/clustering/useGetPreviewValueByColumn'
import useGetSqlByColumn from 'containers/clustering/useGetSqlByColumn'
import useGetWordGroups from 'containers/clustering/useGetWordGroups'
import useSaveColumnByCategory from 'containers/clustering/useSaveColumnByCategory'
import useGetCompetitorsReports from 'containers/competitors/useGetCompetitorsReports'
import useConfirmation from 'containers/confirmation/useConfirmation'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import { useSidebarCtx } from 'containers/SidebarContext'
import useStorage from 'containers/useStorage'
import produce from 'immer'
import _ from 'lodash'
import Page404 from 'pages/ErrorPages/404'
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import TagManager from 'react-gtm-module'
import { useHistory } from 'react-router'
import { highlight } from 'sql-highlight'
import { v4 as uuidv4 } from 'uuid'
import './ClustersView.scss'
import './PreviewTable.scss'

const Rule = ({
  rule,
  column,
  setColumn,
  relation,
  setRelation,
  pattern,
  setPattern,
  fields,
  deleteRule,
  canDeleteRules,
  wordGroups,
  manageRulesErrors,
}) => {
  const [hovered, setHovered] = useState(false)
  const [patternError, setPatternError] = useState(false)
  const columnOptions = fields.map((field) => field.name)

  const currentColumn = _.find(fields, ['id', column])
  let availableRelations = []

  if (currentColumn?.operators) {
    availableRelations = currentColumn.operators
  }

  const currentRelation = _.find(availableRelations, ['value', relation])

  const onChangeRelation = (value) => {
    const tempRelation = _.find(availableRelations, ['key', value])
    setRelation(tempRelation.value)
  }

  const handleChangeColumn = (name) => {
    const pickedColumn = _.find(fields, ['name', name])
    setColumn(pickedColumn.id)
  }

  const isPatternNumeric = (str) => {
    if (typeof str != 'string') return false
    if (str === '') return true
    return !isNaN(str) && !isNaN(parseFloat(str))
  }

  useEffect(() => {
    if (
      currentColumn &&
      currentColumn.type === 'numeric' &&
      !isPatternNumeric(pattern)
    ) {
      manageRulesErrors.add(rule.uuid)
      setPatternError('Please enter a number')
    } else {
      manageRulesErrors.remove(rule.uuid)
      setPatternError(false)
    }
    // eslint-disable-next-line
  }, [pattern, currentColumn])

  return (
    <div
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="rule grid-column-10 grid"
    >
      <div className="grid-column-4 fix-overflow">
        <DropDownField
          label={'Name'}
          value={currentColumn ? currentColumn.name : column}
          onChange={handleChangeColumn}
          options={columnOptions.map(makeOption)}
        />
      </div>
      <div className="grid-column-4">
        <DropDownField
          label={'Relation'}
          value={currentRelation ? currentRelation.key : relation}
          onChange={onChangeRelation}
          options={availableRelations.map((relation) =>
            makeOption(relation.key)
          )}
        />
      </div>
      <div className={canDeleteRules ? 'grid-column-3' : 'grid-column-4'}>
        {currentRelation &&
        (currentRelation.value === 'matches_wordgroup' ||
          currentRelation.value === 'not_matches_wordgroup') ? (
          <DropDownField
            label={'Wordgroup'}
            value={pattern}
            onChange={setPattern}
            options={wordGroups.map((wordGroup) => makeOption(wordGroup.name))}
          />
        ) : (
          <InputField
            label={`${relation === 'matches' ? 'Pattern' : 'Value'} `}
            type="text"
            initialValue={pattern}
            setValue={setPattern}
            error={patternError}
            removeError={() => true}
          />
        )}
      </div>
      <div className="grid-column-1 container-center bin">
        {hovered && canDeleteRules && (
          <span onClick={deleteRule} className="material-icons-outlined">
            delete_outline
          </span>
        )}
      </div>
    </div>
  )
}

const RuleWithOperator = ({
  fields,
  rule,
  deleteRule,
  canDeleteRules,
  changePattern,
  changeField,
  changeOperator,
  changeCondition,
  wordGroups,
  manageRulesErrors,
}) => {
  const operators = [
    { key: 'and', value: 'AND' },
    { key: 'or', value: 'OR' },
  ]

  const handleChangeOperator = (value) => {
    const operator = _.find(operators, ['value', value])
    changeCondition(operator.key)
  }

  return (
    <div className="grid">
      <div className="operator grid-column-2">
        <DropDownField
          label={'Condition'}
          value={_.find(operators, ['key', rule.condition]).value}
          onChange={handleChangeOperator}
          options={operators.map((o) => makeOption(o.value))}
        />
      </div>
      <Rule
        rule={rule}
        column={rule.field}
        setColumn={(value) => changeField(value)}
        relation={rule.operator}
        setRelation={(value) => changeOperator(value)}
        pattern={rule.value}
        setPattern={(value) => changePattern(value)}
        fields={fields}
        canDeleteRules={canDeleteRules}
        deleteRule={deleteRule}
        wordGroups={wordGroups}
        manageRulesErrors={manageRulesErrors}
      />
    </div>
  )
}

const Then = ({ value, changeThenValue, collapsed, handleToggleCollapse }) => {
  return (
    <div className="grid">
      <h2
        onClick={handleToggleCollapse}
        className={`when-then ${collapsed ? 'collapsed' : ''}`}
      >
        <span className="material-icons-outlined arrow">
          {collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_up'}
        </span>
        {!collapsed && <span>Then</span>}
      </h2>
      <div className="helper-grid grid">
        <div className="then-value">
          <InputField
            label="Value"
            type="text"
            initialValue={value}
            setValue={changeThenValue}
            error={false}
            removeError={() => true}
          />
        </div>
      </div>
    </div>
  )
}

const RuleSet = ({
  ruleset,
  changeThenValue,
  fields,
  addRule,
  deleteRule,
  canDeleteRules,
  addRuleset,
  deleteRuleset,
  duplicateRuleset,
  dragHandle,
  isDragging,
  isSomethingDragged,
  changePattern,
  changeField,
  changeOperator,
  changeCondition,
  wordGroups,
  manageRulesErrors,
  hasCompetitorsRef,
}) => {
  const storage = useStorage()
  const [hovered, setHovered] = useState(false)
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(
    storage.isClusterColumnCollapsed(ruleset.id)
  )
  const optionsRef = useRef()
  const optionsButtonRef = useRef()

  const handleClickOutside = (event) => {
    if (
      optionsRef.current &&
      optionsButtonRef.current &&
      !optionsRef.current.contains(event.target) &&
      !optionsButtonRef.current.contains(event.target)
    ) {
      setOptionsOpen(false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }

  const handleOpenOptions = () => {
    setOptionsOpen(!optionsOpen)

    if (!optionsOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }
  }

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed)
    storage.toggleClusterColumnCollapse(ruleset.id)
  }

  return (
    <>
      <div
        ref={optionsRef}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="rule-set"
      >
        <div className="container-row">
          <div className="container-row container-center drag-handle-container">
            <span
              className={`material-icons-outlined drag-handle ${
                (hovered && !isSomethingDragged) || isDragging ? 'visible' : ''
              }`}
              {...dragHandle}
            >
              drag_indicator
            </span>
          </div>
          <div className="flex-grow">
            {!collapsed && (
              <>
                <div className="grid outer-grid">
                  <h2 className="when-then">When</h2>
                  {ruleset.rules[0] && (
                    <Rule
                      rule={ruleset.rules[0]}
                      column={ruleset.rules[0].field}
                      setColumn={(value) => changeField(0, value)}
                      relation={ruleset.rules[0].operator}
                      setRelation={(value) => changeOperator(0, value)}
                      pattern={ruleset.rules[0].value}
                      setPattern={(value) => changePattern(0, value)}
                      fields={fields}
                      canDeleteRules={canDeleteRules}
                      deleteRule={() => deleteRule(0)}
                      wordGroups={wordGroups}
                      manageRulesErrors={manageRulesErrors}
                    />
                  )}
                </div>
                {(() => {
                  let rulesetWithoutFirstRule = _.clone(ruleset.rules)
                  rulesetWithoutFirstRule.splice(0, 1)
                  return rulesetWithoutFirstRule.map((rule, ruleIndex) => (
                    <RuleWithOperator
                      key={ruleIndex + 1}
                      fields={fields}
                      rule={rule}
                      changePattern={(value) =>
                        changePattern(ruleIndex + 1, value)
                      }
                      changeField={(value) => changeField(ruleIndex + 1, value)}
                      changeOperator={(value) =>
                        changeOperator(ruleIndex + 1, value)
                      }
                      changeCondition={(value) =>
                        changeCondition(ruleIndex + 1, value)
                      }
                      canDeleteRules={canDeleteRules}
                      deleteRule={() => deleteRule(ruleIndex + 1)}
                      wordGroups={wordGroups}
                      manageRulesErrors={manageRulesErrors}
                    />
                  ))
                })()}
                <div className="grid">
                  <button onClick={addRule} className="link add-rule">
                    <span className="enlarge">+</span>Add Condition
                  </button>
                </div>
              </>
            )}
            <Then
              value={ruleset.name}
              changeThenValue={changeThenValue}
              collapsed={collapsed}
              handleToggleCollapse={handleToggleCollapse}
            />
            {(hovered || optionsOpen) && (
              <span
                ref={optionsButtonRef}
                onClick={handleOpenOptions}
                className={`options material-icons-outlined ${
                  optionsOpen ? 'highlighted' : ''
                }`}
              >
                pending
              </span>
            )}
            {hasCompetitorsRef(ruleset.name) && (
              <div className="competitors-warning">
                <span className="material-icons-outlined">warning</span>
                <span className="info-context">
                  This rule is referenced in competitors reports.
                </span>
              </div>
            )}
            {optionsOpen && (
              <ul ref={optionsRef} className="options">
                <li onClick={duplicateRuleset} className="copy">
                  <span className="material-icons-outlined">content_copy</span>
                  Duplicate
                </li>
                <li onClick={deleteRuleset} className="delete">
                  <span className="material-icons-outlined">
                    delete_outline
                  </span>
                  Delete
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <button onClick={addRuleset} className="add-ruleset link">
        <span className="enlarge">+</span>Add Rule
      </button>
    </>
  )
}

const Arrow = ({ header, order, onClick }) => {
  if (!order || order.column !== header.name) {
    return (
      <span
        className="material-icons-outlined arrow transparent"
        onClick={onClick}
      >
        arrow_drop_down
      </span>
    )
  }

  if (order.order === 'asc') {
    return (
      <span className="material-icons-outlined arrow" onClick={onClick}>
        arrow_drop_up
      </span>
    )
  }

  return (
    <span className="material-icons-outlined arrow" onClick={onClick}>
      arrow_drop_down
    </span>
  )
}

const PreviewTable = ({
  table,
  loadingTable,
  tableError,
  isPreview,
  setSelectedValue,
  getPreviewValues,
  order,
  setOrder,
}) => {
  const [tableContainerWidth, setTableContainerWidth] = useState(0)  

  const formatValue = ({ header, value }) => {
    const numberWithCommas = (number) => {
      let parts = number.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas to the thousands of a number
      return parts.join('.')
    }

    if (value === null) {
      return '-'
    }

    if (header.type === 'money') {
      const moneyValue = numberWithCommas(Math.round(parseFloat(value)))
      return `$${moneyValue}`
    }

    if (header.type === 'numeric') {
      const numberValue = numberWithCommas(value)
      return numberValue
    }
    return value
  }

  const handleClickCell = (headerIndex, value) => {
    if (isPreview && headerIndex === 0) {
      setSelectedValue(value)
      getPreviewValues(value)
    }
  }

  const clickHeader = (header) => {
    let draftPreviewOrder = _.clone(order)

    if (!order || order.column !== header.name) {
      draftPreviewOrder = {}
      draftPreviewOrder.column = header.name
      draftPreviewOrder.order = 'desc'
    } else {
      const isNumeric = header.type === 'numeric' || header.type === 'money'

      if (isNumeric || order.order === 'asc') {
        draftPreviewOrder.order = 'desc'
      } else {
        draftPreviewOrder.order = 'asc'
      }
    }
    setOrder(draftPreviewOrder)
  }

  if (tableError) {
    return (
      <div className="alert alert-danger" role="alert">
        Something went wrong: {tableError}
      </div>
    )
  }

  const TableContent = () => {
    const tableRef = useRef(null)

    const createResizableColumn = useCallback((col, resizer, last = false) => {
      // Track the current position of mouse
      let mouseX = 0
      let mouseWidth = 0

      const mouseDownHandler = (e) => {
        mouseX = e.clientX

        // Calculate the current width of column
        const styles = window.getComputedStyle(col)
        mouseWidth = parseInt(styles.width, 10)

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
        resizer.classList.add('resizing')
      }

      const mouseMoveHandler = (e) => {
        // Determine how far the mouse has been moved
        const dx = e.clientX - mouseX

        // Update the width of the column
        if (!last) {
          adjustLastColumnWidth(col)
        }
        col.style.width = `${mouseWidth + dx}px`
      }

      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)
        resizer.classList.remove('resizing')
      }

      resizer.addEventListener('mousedown', mouseDownHandler)
    }, [])

    const adjustLastColumnWidth = () => {
      if (tableRef.current === null) return
      const lastColIdx = tableRef.current.querySelectorAll('th').length - 1
      const lastCol = tableRef.current.querySelectorAll('th')[lastColIdx]
      const tableWidth = tableRef.current.parentElement.offsetWidth
      let totalColsWidth = 0
      tableRef.current.querySelectorAll('th').forEach((col) => {
        totalColsWidth += col.offsetWidth
      })

      if (tableWidth > totalColsWidth) {
        const colWhitespace = tableWidth - totalColsWidth
        lastCol.style.width = lastCol.offsetWidth + colWhitespace + 'px'
      }
    }

    // Resizing effect
    useEffect(() => {
      if (tableRef.current === null) {
        return
      }
      let containerWidth = 0
      tableRef.current.querySelectorAll('th').forEach((col, idx) => {
        // Create a resizer element
        const resizer = document.createElement('div')
        resizer.innerText = '↔'
        resizer.classList.add('resizer')
        if (idx === 0) {
          col.style.display = 'flex'
        }

        // Add the resizer element to the respective column
        col.appendChild(resizer)

        if (idx === 0) {
          col.style.width =
            tableRef.current.parentElement.offsetWidth * 0.4 + 'px'
        } else {
          col.style.width =
            tableRef.current.parentElement.offsetWidth /
              (tableRef.current.querySelectorAll('th').length * 2) +
            'px'
        }
        containerWidth += col.offsetWidth
        createResizableColumn(
          col,
          resizer,
          idx === tableRef.current.querySelectorAll('th').length - 1
          )
        })
        
      setTableContainerWidth(containerWidth)
    }, [createResizableColumn])

    useEffect(() => {
      if (tableRef?.current?.offsetWidth) {
        const columnNumber = tableRef.current.querySelectorAll('th').length
        const tableWidth = tableRef.current.parentElement.offsetWidth

        tableRef.current.querySelectorAll('th').forEach((col, idx) => {
          if (columnNumber <= 3) {
            col.style.width = tableWidth / columnNumber + 'px'
            return
          }
          if (idx === 0) {
            col.style.width = tableWidth * 0.4 + 'px'
          } else {
            col.style.width = tableWidth * .6 / (columnNumber - 1) + 'px'
          }
          })
      }
    }, [tableRef.current])

    if (loadingTable) {
      return <ScreenLoading />
    }

    if (!table || table.headers.length === 0) {
      return <span className="full-screen">No data for this table</span>
    }

    return (
      <table ref={tableRef} className="table">
        <thead>
          <tr>
            {table.headers.map((header, headerIndex) => (
              <th
                className={`${!isPreview && headerIndex === 0 ? 'larger' : ''}`}
              >
                <div className="table__header">
                  <div className="table__title name text-overflow-ellipsis">
                    {header.name}
                  </div>
                  <Arrow
                    header={header}
                    order={order}
                    onClick={() => clickHeader(header)}
                    key={header.name}
                  />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.data.length === 0 && (
            <tr>
              <td className="no-data">No data</td>
            </tr>
          )}
          {table.data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {table.headers.map((header, headerIndex) => (
                <td
                  className={`${
                    !isPreview && headerIndex === 0 ? 'larger' : ''
                  } ${
                    header.type === 'numeric' || header.type === 'money'
                      ? 'align-right'
                      : 'align-left'
                  }`}
                  key={`${rowIndex}-${header.name}`}
                  onClick={() => handleClickCell(headerIndex, row[header.name])}
                >
                  <span className="text-overflow-ellipsis">
                    {formatValue({ header, value: row[header.name] })}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    <div className="table__container" style={{ maxHeight: 'default'}}>
      <TableContent />
    </div>
  )
}

const SqlPreview = ({ sqlError, sql, loadingSql }) => {
  if (sqlError) {
    return (
      <div className="alert alert-danger" role="alert">
        {sqlError}
      </div>
    )
  }

  if (sql === '') {
    return (
      <div className="alert alert-info" role="alert">
        Not enough data for the sql preview.
      </div>
    )
  }

  return (
    <div className="preview-sql">
      {loadingSql ? (
        <ScreenLoading />
      ) : (
        <code
          dangerouslySetInnerHTML={{ __html: highlight(sql, { html: true }) }}
        />
      )}
    </div>
  )
}

const Preview = ({
  openPreview,
  isSqlOpen,
  toggleSqlOpen,
  sql,
  loadingSql,
  sqlError,
  preview,
  loadingPreview,
  previewError,
  previewValue,
  loadingPreviewValue,
  previewValueError,
  setSelectedValue,
  selectedValue,
  getPreviewValues,
  previewOrder,
  setPreviewOrder,
  previewValuesOrder,
  setPreviewValuesOrder,
  refreshPreview,
  isPreviewRefreshable,
  notEnoughPreviewData,
}) => {
  const handleChangeValue = (value) => {
    getPreviewValues(value)
    setSelectedValue(value)
  }

  const cleanConversionValues = () => {
    const requiredHeaders = ['conversion_name', 'source_service'];
    const headersExist = requiredHeaders.every(header =>
      previewValue?.headers?.some(h => h.name === header)
    );

    if (headersExist) {
      // Clean data to contain distinct values
      const distinctData = [];
      const seenValues = new Set();

      for (const item of previewValue.data) {
        const key = `${item.conversion_name}-${item.source_service}`;
        if (!seenValues.has(key)) {
          seenValues.add(key);
          distinctData.push(item);
        }
      }

      // Update the input object with cleaned data
      previewValue.data = distinctData;
    } else {
    }
  };

  useEffect(() => {
    cleanConversionValues();
  }, [previewValue])

  let values = []
  if (preview && preview.headers.length > 0) {
    const columnName = preview.headers[0].name
    values = preview.data.map((a) => a[columnName])
  }

  const handleRefreshPreview = async () => {
    TagManager.dataLayer({
      dataLayer: { event: 'clicked_clustering_refresh_preview', is_custom_track: true },
    })
    if (isPreviewRefreshable) {
      refreshPreview()
    }
  }

  return (
    <div className="show-preview">
      <div onClick={() => openPreview(false)} className="expand-square">
        <span className="material-icons-outlined">double_arrow</span>
      </div>
      <div className="helper-div">
        <div className="preview">
          <h2>
            Preview
            <button
              onClick={handleRefreshPreview}
              className={`submit-button refresh ${
                !isPreviewRefreshable ? 'deactivated' : ''
              }`}
            >
              <span className={`material-icons-outlined arrow`}>refresh</span>
              Refresh
            </button>
          </h2>
          {notEnoughPreviewData ? (
            <div className="alert alert-info" role="alert">
              Not enough data for the preview.
            </div>
          ) : (
            <>
              <PreviewTable
                table={preview}
                loadingTable={loadingPreview}
                tableError={previewError}
                isPreview={true}
                setSelectedValue={setSelectedValue}
                getPreviewValues={getPreviewValues}
                order={previewOrder}
                setOrder={setPreviewOrder}
              />
              {!(
                (!previewValue && !loadingPreviewValue) ||
                !preview ||
                preview.data.length === 0
              ) && (
                <>
                  <DropDownField
                    label=""
                    value={selectedValue}
                    options={values.map(makeOption)}
                    onChange={handleChangeValue}
                  />
                  <PreviewTable
                    table={previewValue}
                    loadingTable={loadingPreviewValue}
                    tableError={previewValueError}
                    isPreview={false}
                    order={previewValuesOrder}
                    setOrder={setPreviewValuesOrder}
                  />
                </>
              )}
              <div className="sql-container">
                <div
                  className={`sql-title ${
                    isSqlOpen ? 'sql-expanded' : 'sql-collapsed'
                  }`}
                  onClick={toggleSqlOpen}
                >
                  <span className="sql-title-text">SQL</span>
                  <span className="material-icons-outlined">
                    {!isSqlOpen
                      ? 'keyboard_arrow_right'
                      : 'keyboard_arrow_down'}
                  </span>
                </div>
                {isSqlOpen && (
                  <SqlPreview
                    sql={sql}
                    sqlError={sqlError}
                    loadingSql={loadingSql}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const ColumnRules = forwardRef(
  (
    {
      column,
      fetchColumnByCategory,
      isLoadingColumn,
      columnError,
      isLoadingOrganization,
      currentOrganization,
      clusterCategory,
      columnId,
      setNotEnoughPreviewData,
      getSqlByColumn,
      getPreviewByColumn,
      getPreviewValueByColumn,
      setSelectedValue,
      AVAILABLE_CLUSTERS,
      setClusterBreadcrumb,
      isPreviewOpen,
      setCanRefreshPreview,
      setPreviewOpen,
    },
    ref
  ) => {
    const {
      wordGroups,
      isLoadingWordGroups,
      wordGroupsError,
      fetchWordGroups,
    } = useGetWordGroups()
    const { saveColumnByCategory, saveError, isSaving, removeError } =
      useSaveColumnByCategory()
    const [columnState, setColumnState] = useState()
    const { columns, loadingColumns, columnsError, fetchColumnsByCategory } =
      useGetColumns()
    const { reports, getCompetitorsReports } = useGetCompetitorsReports()
    const [rulesWithErrors, setRulesWithErrors] = useState(new Set())
    const [checkErrors, setCheckErrors] = useState()
    const [hasBeenSaved, setHasBeenSaved] = useState(true)
    const [hasFetchedColumnState, setHasFetchedColumnState] = useState(false)

    const [duplicateColumnName, setDuplicateColumnName] = useState(false)
    const { confirm } = useConfirmation()
    let history = useHistory()

    const handleWindowClose = (e) => {
      e && e.preventDefault && e.preventDefault()
      if (hasBeenSaved) {
        return
      }
      // note: most browsers ignore the custom message and display their own if you return any nonempty string
      return 'You have unsaved changes. Are you sure you want to exit?'
    }

    useEffect(() => {
      window.addEventListener('beforeunload', handleWindowClose)
      return () => {
        window.removeEventListener('beforeunload', handleWindowClose)
      }
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      if (hasFetchedColumnState && hasBeenSaved) {
        setHasBeenSaved(false)
        setCanRefreshPreview(true)
      }
      // eslint-disable-next-line
    }, [columnState])

    useEffect(() => {
      getCompetitorsReports({
        organization: currentOrganization,
        filterColumn: columnId,
      })
      // eslint-disable-next-line
    }, [columnId])

    const getData = async () => {
      fetchWordGroups({ organization: currentOrganization })
      fetchColumnsByCategory({
        category: clusterCategory,
        organization: currentOrganization,
      })

      const tempColumn = await fetchColumnByCategory({
        category: clusterCategory,
        organization: currentOrganization,
        columnId: columnId,
      })
      if (tempColumn) {
        loadState(tempColumn)
      } else {
        return
      }

      setNotEnoughPreviewData(false)
      setHasFetchedColumnState(true)

      if (tempColumn.name === '') {
        setNotEnoughPreviewData(true)
        return
      }

      getSqlByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
      })

      const tempPreview = await getPreviewByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
      })

      if (!tempPreview || tempPreview.data.length === 0) {
        return
      }

      const columnName = tempPreview.headers[0].name
      const firstValue = tempPreview.data[0][columnName]

      getPreviewValueByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
        value: firstValue,
      })
      setSelectedValue(firstValue)
    }

    useEffect(() => {
      if (!AVAILABLE_CLUSTERS.includes(clusterCategory)) {
        return
      }

      if (!isLoadingOrganization) {
        getData()
      }
      // eslint-disable-next-line
    }, [currentOrganization, isLoadingOrganization])

    const handleBack = async () => {
      if (
        hasBeenSaved ||
        (await confirm(
          'You have unsaved changes. Are you sure you want to change tab?'
        ))
      ) {
        history.push(
          `/organization/${currentOrganization.slug}/clusters/${clusterCategory}`
        )
      }
    }

    useImperativeHandle(
      ref,
      () => ({
        getPreviewValues: getPreviewValues,
        refreshPreview: refreshPreview,
        getPreviewOrder: getPreviewOrder,
        getPreviewValuesOrder: getPreviewValuesOrder,
      }),
      // eslint-disable-next-line
      [column, columnState]
    )

    const getPreviewOrder = (previewOrder) => {
      const tempColumn = {
        ...columnState,
        fields: column.fields,
      }

      getPreviewByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
        orderBy: previewOrder.column,
        orderDirection: previewOrder.order,
      })
    }

    const getPreviewValuesOrder = (previewValuesOrder, value) => {
      const tempColumn = {
        ...columnState,
        fields: column.fields,
      }

      getPreviewValueByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
        value: value,
        orderBy: previewValuesOrder.column,
        orderDirection: previewValuesOrder.order,
      })
    }

    const getPreviewValues = (value) => {
      const tempColumn = {
        ...columnState,
        fields: column.fields,
      }

      getPreviewValueByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
        value,
      })
    }

    const refreshPreview = async () => {
      setCanRefreshPreview(false)
      const tempColumn = {
        ...columnState,
        fields: column.fields,
      }

      if (tempColumn.name === '') {
        setNotEnoughPreviewData(true)
        return
      }
      setNotEnoughPreviewData(false)

      getSqlByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
      })

      const tempPreview = await getPreviewByColumn({
        category: clusterCategory,
        columnId: columnId,
        organization: currentOrganization,
        column: tempColumn,
      })

      if (!tempPreview || tempPreview.data.length === 0) {
        return
      }

      const columnName = tempPreview.headers[0].name
      const values = tempPreview.data.map((a) => a[columnName])

      getPreviewValues(values[0])
      setSelectedValue(values[0])
    }

    const setColumnName = (name) => {
      setColumnState(
        produce(columnState, (draftColumnState) => {
          draftColumnState.name = name
        })
      )
    }

    if (columnError || wordGroupsError) {
      return <>Error</>
    }

    const defaultRule = () => {
      const rule = {
        condition: 'and',
        operator: column.fields[0].operators[0].value,
        field: column.fields[0].id,
        uuid: uuidv4(),
      }

      const op = column.fields[0].operators[0].value
      if (
        (op === 'matches_wordgroup' || op === 'not_matches_wordgroup') &&
        wordGroups.length > 0
      ) {
        rule.value = wordGroups[0].name
      } else {
        rule.value = ''
      }

      return rule
    }

    const manageRules = {
      addRule: (rulesetIndex) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values[rulesetIndex].rules.push(defaultRule())
          })
        )
      },
      deleteRule: (rulesetIndex, ruleIndex) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            if (ruleIndex === 0) {
              delete draftColumnState.values[rulesetIndex].rules[1].condition
            }

            draftColumnState.values[rulesetIndex].rules.splice(ruleIndex, 1)
          })
        )
      },
      changeOperator: (rulesetIndex, ruleIndex, value) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values[rulesetIndex].rules[ruleIndex].operator =
              value

            if (
              (value === 'matches_wordgroup' ||
                value === 'not_matches_wordgroup') &&
              wordGroups.length > 0
            ) {
              draftColumnState.values[rulesetIndex].rules[ruleIndex].value =
                wordGroups[0].name
            }
          })
        )
      },
      changeField: (rulesetIndex, ruleIndex, value) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values[rulesetIndex].rules[ruleIndex].field = value

            const oldOperatorValue =
              columnState.values[rulesetIndex].rules[ruleIndex].operator
            const newOperators = _.find(column.fields, ['id', value]).operators

            if (!_.find(newOperators, ['value', oldOperatorValue])) {
              draftColumnState.values[rulesetIndex].rules[ruleIndex].operator =
                newOperators[0].value

              const op =
                draftColumnState.values[rulesetIndex].rules[ruleIndex].operator
              if (
                (op === 'matches_wordgroup' ||
                  op === 'not_matches_wordgroup') &&
                wordGroups.length > 0
              ) {
                draftColumnState.values[rulesetIndex].rules[ruleIndex].value =
                  wordGroups[0].name
              }
            }
          })
        )
      },
      changePattern: (rulesetIndex, ruleIndex, value) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values[rulesetIndex].rules[ruleIndex].value = value
          })
        )
      },
      changeCondition: (rulesetIndex, ruleIndex, value) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values[rulesetIndex].rules[ruleIndex].condition =
              value
          })
        )
      },
    }

    const manageRulesets = {
      addRuleset: (rulesetIndex) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values.splice(rulesetIndex, 0, {
              id: uuidv4(),
              name: '',
              rules: [defaultRule()],
            })
          })
        )
      },
      deleteRuleset: (rulesetIndex) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values.splice(rulesetIndex, 1)
          })
        )
      },
      duplicateRuleset: (rulesetIndex) => {
        setColumnState(
          produce(columnState, (draftColumnState) => {
            draftColumnState.values.splice(
              rulesetIndex,
              0,
              _.cloneDeep({ ...columnState.values[rulesetIndex], id: uuidv4() })
            )
          })
        )
      },
    }

    const manageRulesErrors = {
      remove: (id) => {
        if (rulesWithErrors.has(id)) {
          setRulesWithErrors(
            produce(rulesWithErrors, (draft) => {
              draft.delete(id)
            })
          )
        }
      },
      add: (id) => {
        if (!rulesWithErrors.has(id)) {
          setRulesWithErrors(
            produce(rulesWithErrors, (draft) => {
              draft.add(id)
            })
          )
        }
      },
    }

    const changeThenValue = (rulesetIndex, value) => {
      setColumnState(
        produce(columnState, (draftColumnState) => {
          draftColumnState.values[rulesetIndex].name = value
        })
      )
    }

    const handleDragEnd = (result) => {
      if (!result || !result.destination) {
        return
      }

      setColumnState(
        produce(columnState, (draftColumnState) => {
          const draggedElement = _.find(columnState.values, [
            'id',
            result.draggableId,
          ])
          draftColumnState.values.splice(
            _.findIndex(columnState.values, ['id', result.draggableId]),
            1
          )
          draftColumnState.values.splice(
            result.destination.index,
            0,
            draggedElement
          )
        })
      )
    }

    const hasCompetitorsRef = (value) => {
      if (!reports) {
        return false
      }
      return _.some(reports, ['filter_value', value])
    }

    const loadState = (column) => {
      const tempState = {
        id: column.id,
        name: !column.is_placeholder ? column.name : '',
        values: column.values.map((value) => ({
          id: value.id,
          name: value.name,
          rules: value.rules.map((rule) => ({
            ...rule,
            uuid: uuidv4(),
          })),
        })),
        is_fixed: column.is_fixed,
      }
      setColumnState(tempState)
    }

    const checkDuplicateColumn = () => {
      const checkForDuplicate = (column) => {
        return column.name === columnState.name && column.id !== columnState.id
      }

      if (columns && _.find(columns.columns, checkForDuplicate)) {
        return true
      }

      return false
    }

    if (columnsError) {
      return <>{columnsError}</>
    }

    const handleSave = async () => {
      removeError()

      const isDuplicate = checkDuplicateColumn()

      if (isDuplicate) {
        setCheckErrors('Please fix the rule errors.')
        setDuplicateColumnName(true)
        return
      } else {
        setDuplicateColumnName(false)
      }

      setClusterBreadcrumb((prev) => ({ ...prev, name: columnState.name }))

      if (rulesWithErrors.size > 0) {
        setCheckErrors('Please fix the rule errors.')
        return
      }
      setCheckErrors(null)

      const request = {
        ...columnState,
        fields: column.fields,
      }

      const saveResponse = await saveColumnByCategory({
        category: clusterCategory,
        organization: currentOrganization,
        columnId: columnId,
        column: request,
      })
      if (saveResponse) {
        refreshPreview()
        TagManager.dataLayer({
          dataLayer: {
            event: 'saved_clustering',
            is_custom_track: true,
            event_params: {
              clusteringCategory: clusterCategory,
            }
          },
        })
        setHasBeenSaved(true)
      }
    }

    return (
      <div
        className={`cluster-settings container-column ${
          isPreviewOpen ? 'preview-open' : ''
        }`}
      >
        {!columnState ||
        loadingColumns ||
        isLoadingWordGroups ||
        isLoadingColumn ||
        isLoadingOrganization ? (
          <ScreenLoading />
        ) : (
          <div className="clusters-campaigns-column">
            {checkErrors && (
              <div className="alert alert-danger" role="alert">
                {checkErrors}
              </div>
            )}
            <span
              onClick={handleBack}
              className="material-icons-outlined back-arrow non-selectable"
            >
              arrow_back
            </span>
            <div className="grid header">
              <div className="column-name">
                {!columnState.is_fixed ? (
                  <InputField
                    label="Cluster Name"
                    type="text"
                    initialValue={columnState.name}
                    setValue={setColumnName}
                    error={
                      duplicateColumnName ? 'Duplicate column name' : false
                    }
                    removeError={() => true}
                  />
                ) : (
                  <div
                    style={{ fontWeight: 'bold', marginTop: 12, fontSize: 20 }}
                  >
                    {columnState.name}
                  </div>
                )}
              </div>
            </div>
            {!columnState.values.length && (
              <button
                onClick={() => manageRulesets.addRuleset(0)}
                className="add-ruleset link"
              >
                <span className="enlarge">+</span>Add Rule
              </button>
            )}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="ruleset" type="ruleset">
                {(provided, droppableSnapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {columnState.values.map((ruleset, rulesetIndex) => (
                      <Draggable
                        key={ruleset.id}
                        draggableId={ruleset.id}
                        index={rulesetIndex}
                      >
                        {(provided, draggableSnapshot) => (
                          <div
                            ref={provided.innerRef}
                            className="draggable-ruleset"
                            {...provided.draggableProps}
                          >
                            <RuleSet
                              ruleset={ruleset}
                              addRuleset={() =>
                                manageRulesets.addRuleset(rulesetIndex + 1)
                              }
                              changeCondition={(ruleIndex, value) =>
                                manageRules.changeCondition(
                                  rulesetIndex,
                                  ruleIndex,
                                  value
                                )
                              }
                              changePattern={(ruleIndex, value) =>
                                manageRules.changePattern(
                                  rulesetIndex,
                                  ruleIndex,
                                  value
                                )
                              }
                              changeField={(ruleIndex, value) =>
                                manageRules.changeField(
                                  rulesetIndex,
                                  ruleIndex,
                                  value
                                )
                              }
                              changeOperator={(ruleIndex, value) =>
                                manageRules.changeOperator(
                                  rulesetIndex,
                                  ruleIndex,
                                  value
                                )
                              }
                              changeThenValue={(value) =>
                                changeThenValue(rulesetIndex, value)
                              }
                              fields={column.fields}
                              addRule={() => manageRules.addRule(rulesetIndex)}
                              deleteRule={(ruleIndex) =>
                                manageRules.deleteRule(rulesetIndex, ruleIndex)
                              }
                              canDeleteRules={ruleset.rules.length > 1}
                              deleteRuleset={() =>
                                manageRulesets.deleteRuleset(rulesetIndex)
                              }
                              duplicateRuleset={() =>
                                manageRulesets.duplicateRuleset(rulesetIndex)
                              }
                              dragHandle={provided.dragHandleProps}
                              isDragging={draggableSnapshot.isDragging}
                              isSomethingDragged={
                                droppableSnapshot.isDraggingOver
                              }
                              wordGroups={wordGroups}
                              manageRulesErrors={manageRulesErrors}
                              hasCompetitorsRef={hasCompetitorsRef}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        {!isPreviewOpen && (
          <div onClick={() => setPreviewOpen(true)} className="expand-preview">
            <div className="expand-square">
              <span className="material-icons-outlined rotated">
                double_arrow
              </span>
            </div>
          </div>
        )}
        {saveError && (
          <PopUpMessage
            onClose={() => removeError()}
            type="error"
            message={`${
              saveError.message ? saveError.message : saveError.type
            }`}
            details={saveError.detailed_message}
          />
        )}
        <div className="save-bottom-bar">
          {!isSaving ? (
            <>
              <button
                onClick={handleSave}
                className={`submit-button ${hasBeenSaved ? 'deactivated' : ''}`}
              >
                {hasBeenSaved ? 'Saved' : 'Save'}
              </button>
            </>
          ) : (
            <ScreenLoading />
          )}
        </div>
      </div>
    )
  }
)

const ClusterView = ({
  columnId,
  clusterCategory,
  AVAILABLE_CLUSTERS,
  setClusterBreadcrumb,
}) => {
  const { column, isLoadingColumn, columnError, fetchColumnByCategory } =
    useGetColumnByCategory()
  const { sql, loadingSql, sqlError, getSqlByColumn } = useGetSqlByColumn()
  const { preview, loadingPreview, previewError, getPreviewByColumn } =
    useGetPreviewByColumn()
  const {
    previewValue,
    loadingPreviewValue,
    previewValueError,
    getPreviewValueByColumn,
  } = useGetPreviewValueByColumn()
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [isSqlOpen, setIsSqlOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState()
  const [canRefreshPreview, setCanRefreshPreview] = useState(false)
  const [notEnoughPreviewData, setNotEnoughPreviewData] = useState(false)
  const { collapseSidebar } = useSidebarCtx()

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)
  const [previewOrder, setPreviewOrder] = useState()
  const [previewValuesOrder, setPreviewValuesOrder] = useState()

  const columnRulesRef = useRef()

  useEffect(() => {
    if (!previewOrder) {
      return
    }

    columnRulesRef.current.getPreviewOrder(previewOrder)
    // eslint-disable-next-line
  }, [previewOrder])

  useEffect(() => {
    if (!previewValuesOrder) {
      return
    }

    columnRulesRef.current.getPreviewValuesOrder(
      previewValuesOrder,
      selectedValue
    )
    // eslint-disable-next-line
  }, [previewValuesOrder])

  const toggleSqlOpen = () => {
    setIsSqlOpen(!isSqlOpen)
  }

  useEffect(() => {
    setPreviewOpen(true)
    // eslint-disable-next-line
  }, [])

  if (!AVAILABLE_CLUSTERS.includes(clusterCategory)) {
    return <Page404 />
  }

  const setPreviewOpen = (openPreview) => {
    if (openPreview) {
      setIsPreviewOpen(true)
      collapseSidebar(true)
    } else {
      setIsPreviewOpen(false)
      const isSidebarCollapsed = JSON.parse(
        localStorage.getItem('isSideBarCollapsed')
      )
      if (isSidebarCollapsed) {
        collapseSidebar(true)
      } else {
        collapseSidebar(false)
      }
    }
  }

  const isPreviewRefreshable =
    canRefreshPreview || previewError || previewValueError || sqlError

  return (
    <div className="container-row">
      <ColumnRules
        ref={columnRulesRef}
        column={column}
        isLoadingColumn={isLoadingColumn}
        columnError={columnError}
        fetchColumnByCategory={fetchColumnByCategory}
        isLoadingOrganization={isLoadingOrganization}
        currentOrganization={currentOrganization}
        clusterCategory={clusterCategory}
        columnId={columnId}
        setNotEnoughPreviewData={setNotEnoughPreviewData}
        setCanRefreshPreview={setCanRefreshPreview}
        getSqlByColumn={getSqlByColumn}
        getPreviewByColumn={getPreviewByColumn}
        getPreviewValueByColumn={getPreviewValueByColumn}
        setSelectedValue={setSelectedValue}
        AVAILABLE_CLUSTERS={AVAILABLE_CLUSTERS}
        setClusterBreadcrumb={setClusterBreadcrumb}
        isPreviewOpen={isPreviewOpen}
        setPreviewOpen={setPreviewOpen}
      />
      {isPreviewOpen && (
        <Preview
          openPreview={setPreviewOpen}
          sql={sql}
          loadingSql={loadingSql}
          isSqlOpen={isSqlOpen}
          toggleSqlOpen={toggleSqlOpen}
          sqlError={sqlError}
          preview={preview}
          loadingPreview={loadingPreview}
          previewError={previewError}
          previewValue={previewValue}
          loadingPreviewValue={loadingPreviewValue}
          previewValueError={previewValueError}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          getPreviewValues={(value) =>
            columnRulesRef.current.getPreviewValues(value)
          }
          previewOrder={previewOrder}
          setPreviewOrder={setPreviewOrder}
          previewValuesOrder={previewValuesOrder}
          setPreviewValuesOrder={setPreviewValuesOrder}
          refreshPreview={() => columnRulesRef.current.refreshPreview()}
          isPreviewRefreshable={isPreviewRefreshable}
          notEnoughPreviewData={notEnoughPreviewData}
        />
      )}
    </div>
  )
}

export default React.memo(ClusterView)
