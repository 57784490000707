import React, { useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import ScreenLoading from 'components/ScreenLoading'
import Item from 'components/Item'
import PopUpMessage from 'components/PopUpMessage'

import useGetCompetitorsReports from 'containers/competitors/useGetCompetitorsReports'
import useDeleteCompetitorsReport from 'containers/competitors/useDeleteCompetitorsReport'
import useGetCountries from 'containers/countries/useGetCountries'
import useGetConnectedAccounts from 'containers/services/useGetConnectedAccounts'
import useGetColumns from 'containers/clustering/useGetColumnsByCategory'
import OrganizationsContext from 'containers/organizations/organizationsContext'

import './Competitors.scss'

const Competitors = () => {
  const {
    reports,
    removeReport,
    isLoadingReports,
    reportsError,
    getCompetitorsReports,
  } = useGetCompetitorsReports()
  const { countries, loadingCountries, countriesError, getCountries } =
    useGetCountries()
  const { deleteReport, deleteReportError } = useDeleteCompetitorsReport()
  const {
    serviceAccounts,
    serviceAccountsError,
    getServiceAccounts,
    loadingServicesAccounts,
  } = useGetConnectedAccounts()
  const { columns, loadingColumns, columnsError, fetchColumnsByCategory } =
    useGetColumns()

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  const getData = async () => {
    getCountries()
    if (!currentOrganization) {
      return
    }

    fetchColumnsByCategory({
      category: 'searchTerms',
      organization: currentOrganization,
    })
    getServiceAccounts(currentOrganization.id)
    getCompetitorsReports({ organization: currentOrganization })
  }

  useEffect(() => {
    if (!isLoadingOrganization) {
      getData()
    }
    // eslint-disable-next-line
  }, [currentOrganization, isLoadingOrganization])

  if (reportsError) {
    return <>error</>
  }

  const handleDeleteReport = async (reportId) => {
    if (await deleteReport({ organization: currentOrganization, reportId })) {
      TagManager.dataLayer({
        dataLayer: { event: 'deleted_competition_analysis_report' },
      })
      removeReport(reportId)
    }
  }

  let countriesByCode
  if (!loadingCountries) {
    countriesByCode = _.keyBy(countries, 'code')
  }

  const currentErrors = []
  const availableErrors = [countriesError, serviceAccountsError, columnsError]

  availableErrors.forEach((error, errorIndex) => {
    if (error) {
      currentErrors.push(
        <div key={errorIndex} className="alert alert-danger" role="alert">
          {error}
        </div>
      )
    }
  })

  return (
    <div className="clusters-campaigns container-column competitors">
      {deleteReportError && (
        <div className="alert alert-danger" role="alert">
          {deleteReportError}
        </div>
      )}
      <div className="head" style={{ marginBottom: '30px' }}>
        <div className="page-name">
          <h2>Competition Analysis Reports</h2>
          <span className="subtitle-text">
            Competition analysis reports display the ranking of competitors on a
            set of search terms and is calculated once per month.
          </span>
        </div>
      </div>
      {currentErrors.length > 0 ? (
        <>{currentErrors}</>
      ) : (
        <>
          {isLoadingOrganization ||
          isLoadingReports ||
          loadingCountries ||
          loadingServicesAccounts ||
          loadingColumns ? (
            <ScreenLoading />
          ) : (
            <>
              {reports.map((report, reportIndex) => {
                const account = _.find(serviceAccounts, [
                  'uuid',
                  report.account_uuid,
                ])

                const selectedColumn =
                  report.filter_column !== 'cluster_brand_auto'
                    ? _.find(columns.columns, ['id', report.filter_column])
                    : { name: 'Is Brand' }
                const selectedValue = report.filter_value
                const subtitle = (
                  <>
                    Top{' '}
                    <span className="black">{report.search_terms_number}</span>{' '}
                    search terms ordered by{' '}
                    <span className="black">{report.order_by}</span> included in
                    column:{' '}
                    <span className="black">{selectedColumn?.name}</span> and
                    value: <span className="black">{selectedValue}</span>{' '}
                    cluster.
                  </>
                )

                return (
                  <Item
                    key={report.uuid}
                    columns={[
                      {
                        label: 'Account',
                        value: account?.name,
                        size: 5,
                      },
                      {
                        label: 'Country',
                        value: countriesByCode[report.country_code]?.name,
                        size: 5,
                      },
                    ]}
                    linkText="Edit Report"
                    id={report.uuid}
                    isDraggable={false}
                    editUrl={`/organization/${currentOrganization.slug}/competitors/${report.uuid}`}
                    orderIndex={reportIndex}
                    handleDeleteItem={() => handleDeleteReport(report.uuid)}
                    subtitle={subtitle}
                  />
                )
              })}
              <NavLink
                to={`/organization/${
                  currentOrganization.slug
                }/competitors/${uuidv4()}`}
                className="link add-column"
              >
                <span className="enlarge">+</span>Add Report
              </NavLink>
            </>
          )}
        </>
      )}
      {localStorage.getItem('competitorsReportSaved') && (
        <PopUpMessage
          onClose={() => localStorage.removeItem('competitorsReportSaved')}
          message={`Report was saved succesfully.`}
          time={3000}
        />
      )}
    </div>
  )
}

export default React.memo(Competitors)
