import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import api from 'containers/api'
import ScreenLoading from 'components/ScreenLoading'
import { selectToken } from 'containers/authentication/authenticationSlice'
import { Redirect } from 'components/Redirect'

const LatestOrganizationRoute = () => {
  const [noOrganizations, setNoOrganizations] = useState(false)
  const [redirectSlug, setRedirectSlug] = useState('')
  const token = useSelector(selectToken)

  const redirectToFirstOrg = useCallback(async () => {
    const orgs = await api.getOrganizations({ token })
    if (orgs.length) {
      setRedirectSlug(orgs[0].slug)
    } else {
      setNoOrganizations(true)
    }
  }, [token])

  useEffect(() => {
    const latestOrg = localStorage.getItem('latest-organization')
    if (latestOrg) {
      setRedirectSlug(latestOrg)
    } else {
      redirectToFirstOrg()
    }
  }, [setRedirectSlug, redirectToFirstOrg])

  if (noOrganizations) {
    return <Redirect to="/" />
  }

  if (redirectSlug) {
    const urlParts = window.location.href.split('/')

    // 4 because two slashes from proto, domain, organizations
    urlParts[4] = redirectSlug

    return <Redirect to={'/' + urlParts.slice(3).join('/')} />
  }

  return <ScreenLoading />
}

export default LatestOrganizationRoute
