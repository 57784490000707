const useValidator = () => {
  const validateEmail = (email) => {
    const re = /^\S+@\S+$/

    return re.test(String(email).toLowerCase())
  }

  return { validateEmail }
}

export default useValidator
