import React from 'react'
import { useHistory } from 'react-router-dom'

import ScreenLoading from 'components/ScreenLoading'

const ReportList = ({
  embedReportsFetchStatus,
  embedReports,
  currentOrganization,
  currentReportSlug,
  onReportOpen,
}) => {
  const history = useHistory()

  const handleClickReport = (report) => {
    const destination = `/organization/${currentOrganization.slug}/dashboard/${report.slug}`
    if (currentReportSlug !== report.slug) {
      history.push(destination)
    }
    onReportOpen(report.name)
  }

  if (embedReportsFetchStatus === 'pending') {
    return <ScreenLoading />
  }
  return (
    <ul className="sub-navigation">
      {embedReports.map((report) => {
        return (
          <li
            key={report.id}
            className={currentReportSlug === report.slug ? 'active' : ''}
            onClick={() => handleClickReport(report)}
          >
            {currentReportSlug === report.slug &&
            !currentOrganization.is_demo &&
            report.access_level === 'Edit' ? (
              <>
                {report.name[0] === '-' ? report.name.slice(1) : report.name}{' '}
                {report.demo && <span className="demo-tag">DEMO</span>}
              </>
            ) : (
              <>
                {report.name[0] === '-' ? report.name.slice(1) : report.name}{' '}
                {report.demo && <span className="demo-tag">DEMO</span>}
              </>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default React.memo(ReportList)
