import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

interface IScreenLoadingProps {
  spinnerColor?: 'white' | 'maya-green'
  spinnerWidth?: 'sm' | 'lg'
}

const ScreenLoading: React.FC<IScreenLoadingProps> = ({
  spinnerColor,
  spinnerWidth = 'lg',
}) => {
  return (
    <div className="full-screen">
      <Spinner
        animation="border"
        role="status"
        className={spinnerColor}
        size={spinnerWidth}
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  )
}

export default React.memo(ScreenLoading)
