import React, { useState, useRef } from 'react'

import _ from 'lodash'
import stringSimilarity from 'string-similarity'

const DropDownOption = ({ option, selected, onClick, inputValue }) => {
  const containsSubString = option.value
    .toLowerCase()
    .includes(inputValue.toLowerCase())

  if (
    containsSubString ||
    stringSimilarity.compareTwoStrings(option.value, inputValue) > 0.4
  ) {
    return (
      <li className={`${selected ? 'selected' : ''}`} onClick={onClick}>
        <div className="info">
          <img alt="" width="33" src={option.image} />
          <div className="value">
            <span>{option.value}</span>
            {option.subtitle && (
              <span className="subtitle">{option.subtitle}</span>
            )}
          </div>
        </div>
      </li>
    )
  }

  return null
}

const DropDownWithImage = ({
  label,
  options,
  selectedId,
  setSelectedId,
  required = true,
  emptyLabel = '',
}) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const fieldRef = useRef()

  const handleClickOutside = (event) => {
    if (fieldRef.current && !fieldRef.current.contains(event.target)) {
      setOpen(false)
    }
    document.removeEventListener('mousedown', handleClickOutside)
  }

  const handleClickDropDown = () => {
    setInputValue('')
    setOpen(!open)
    if (!open) {
      document.addEventListener('mousedown', handleClickOutside)
    }
  }

  const selectedOption = _.find(options, ['id', selectedId])

  return (
    <div
      ref={fieldRef}
      onClick={handleClickDropDown}
      className={`drop-down-input-field-with-image ${open ? 'open' : ''} ${
        !selectedOption && (required || selectedId) ? 'error' : ''
      }`}
    >
      <div className="input-header">
        <label>{label}</label>
      </div>
      {open ? (
        <input
          autoFocus
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="value"
          type="text"
        />
      ) : (
        <div className="info">
          {selectedOption && (
            <>
              <img alt="" width="33" src={selectedOption.image} />
              <div className="value">
                <>
                  <span>{selectedOption.value}</span>
                  {selectedOption.subtitle && (
                    <span className="subtitle">{selectedOption.subtitle}</span>
                  )}
                </>
              </div>
            </>
          )}
          {!selectedOption && selectedId === null && emptyLabel && (
            <>
              <div className="value">
                <>
                  <span>{emptyLabel}</span>
                </>
              </div>
            </>
          )}
        </div>
      )}
      <span className="material-icons arrow non-selectable">
        keyboard_arrow_down
      </span>
      {open && (
        <ul className="drop-down-options">
          {emptyLabel && (
            <DropDownOption
              option={{ value: emptyLabel }}
              onClick={() => setSelectedId(null)}
              inputValue={inputValue}
              selected={selectedId === null}
              key="empty_option"
            />
          )}
          {selectedOption && (
            <DropDownOption
              option={selectedOption}
              selected={true}
              onClick={() => null}
              inputValue={inputValue}
            />
          )}
          {options.map((option, optionIndex) => {
            if (!selectedOption || option.value !== selectedOption.value) {
              return (
                <DropDownOption
                  key={optionIndex}
                  option={option}
                  selected={false}
                  onClick={() => setSelectedId(option.id)}
                  inputValue={inputValue}
                />
              )
            }
            return <React.Fragment key={optionIndex} />
          })}
        </ul>
      )}
    </div>
  )
}

export default React.memo(DropDownWithImage)
