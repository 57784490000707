import { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { selectToken } from 'containers/authentication/authenticationSlice'

import api, { isAPIError } from 'containers/api'

const useUpdateColumnsByCategory = () => {
  const [updateColumnsError, setUpdateColumnsError] = useState('')
  const token = useSelector(selectToken)

  const updateColumnsByCategory = async ({
    category,
    organization,
    columnIds,
    columns,
    updateType,
  }) => {
    setUpdateColumnsError('')
    try {
      await api.updateColumnsByCategory({
        category,
        organizationId: organization.id,
        token,
        columns: columnIds,
      })
      return true
    } catch (error) {
      if (isAPIError(error)) {
        const detail = error.response.data.detail
        if (detail.type === 'invalid_field_reference') {
          const above = _.find(columns, ['id', detail.ref_column_id])
          const below = _.find(columns, ['id', detail.column_id])
          if (above && below) {
            setUpdateColumnsError(
              `Error: Can't move column '${below.name}' above column '${above.name}'`
            )
          } else if (above) {
            setUpdateColumnsError(
              `Error: There was an issue reordering column '${above.name}'`
            )
          } else if (below) {
            setUpdateColumnsError(
              `Error: There was an issue reordering column '${below.name}'`
            )
          } else {
            setUpdateColumnsError('Error: Could not reorder columns')
          }
        } else {
          setUpdateColumnsError('There was an error, please contact support.')
        }
      } else {
        if (updateType === 'delete') {
          setUpdateColumnsError('Error: Could not delete column')
        }
        if (updateType === 'reorder') {
          setUpdateColumnsError('Error: Could not reorder columns')
        }
      }
      return false
    }
  }

  return { updateColumnsByCategory, updateColumnsError }
}

export default useUpdateColumnsByCategory
