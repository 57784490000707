import { useState } from 'react'
import produce from 'immer'

import useValidator from './useValidator'

import api, { isHTTPError } from '../api'

const useForgotPassword = () => {
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false)
  const [loadingResetPassword, setLoadingResetPassword] = useState(false)
  const [forgotPasswordError, setForgotPasswordError] = useState({
    email: '',
    server: '',
  })
  const [resetPasswordError, setResetPasswordError] = useState({
    server: '',
    email: '',
  })
  const { validateEmail } = useValidator()

  const removeForgotPasswordError = (errorName) => {
    setForgotPasswordError(
      produce(forgotPasswordError, (draft) => {
        draft[errorName] = ''
        draft.server = ''
      })
    )
  }

  const removeResetPasswordError = (errorName) => {
    setResetPasswordError(
      produce(resetPasswordError, (draft) => {
        draft[errorName] = ''
        draft.server = ''
      })
    )
  }

  const requestPasswordReset = async ({ email }) => {
    let shouldSubmit = true
    const error = {
      email: '',
      server: '',
    }

    if (!validateEmail(email)) {
      error.email = 'Please enter a valid email address'
      shouldSubmit = false
    }

    if (!shouldSubmit) {
      setForgotPasswordError(error)
      return
    }

    setLoadingForgotPassword(true)
    try {
      await api.requestPasswordReset({ email })
    } catch (error) {
      setForgotPasswordError(
        produce(forgotPasswordError, (draft) => {
          if (isHTTPError(error)) {
            switch (error.response.status) {
              case 422:
                draft.server = 'Validation Error'
                break
              default:
                draft.server = 'Something went wrong'
            }
          } else {
            draft.server = "Can't connect to the server"
          }
        })
      )
      setLoadingForgotPassword(false)
      return false
    }
    setLoadingForgotPassword(false)
    return true
  }

  const resetPassword = async ({ password, id, email }) => {
    let shouldSubmit = true
    const error = {
      email: '',
      server: '',
    }

    if (password === '') {
      error.email = 'Please enter a valid password'
      shouldSubmit = false
    }

    if (!shouldSubmit) {
      setResetPasswordError(error)
      return
    }

    setLoadingResetPassword(true)

    try {
      await api.passwordReset({ password, id, email })
    } catch (error) {
      setResetPasswordError(
        produce(resetPasswordError, (draft) => {
          if (isHTTPError(error)) {
            switch (error.response.status) {
              case 404:
                draft.server = 'Could not reset password'
                break
              case 422:
                draft.server = 'Validation Error'
                break
              default:
                draft.server = 'Something went wrong'
            }
          } else {
            draft.server = "Can't connect to the server"
          }
        })
      )
      setLoadingResetPassword(false)
      return false
    }
    setLoadingResetPassword(false)
    return true
  }

  return {
    forgotPasswordError,
    loadingForgotPassword,
    removeForgotPasswordError,
    requestPasswordReset,
    resetPasswordError,
    loadingResetPassword,
    removeResetPasswordError,
    resetPassword,
  }
}

export default useForgotPassword
