import { useSelector } from 'react-redux'
import { selectToken } from './authentication/authenticationSlice'
import api, { isHTTPError } from './api'
import { useState } from 'react'
import { ISubscriptionDetailsResponse } from 'types'

const useSubscriptions = () => {
  const token = useSelector(selectToken)
  const [error, setError] = useState<string | null>(null)

  const checkUserSubscriptions = async () => {
    try {
      return await api.checkUserSubscriptions({ token })
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setError('Something went wrong while checking user subscriptions')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setError(
          'Error while checking subscriptions: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const linkSubscription = async (subscriptionId: string, organizationId: string) => {
    try {
      return await api.linkSubscription({ token, subscriptionId, organizationId })
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setError('Something went wrong while linking subscription')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setError(
          'Error while linking subscription: ' + error.response.data.detail.type
        )
      }
    }
  }

  const checkSubscriptionExists = async (subscriptionId: string) => {
    try {
      return await api.checkSubscriptionExists({ token, subscriptionId })
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setError('Something went wrong while checking subscription')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setError(
          'Error while checking subscription: ' + error.response.data.detail.type
        )
      }
    }
  }

  const getSubscriptionDetails = async (subscriptionId: string): Promise<ISubscriptionDetailsResponse | undefined> => {
    try {
      return await api.getSubscriptionDetails({ token, subscriptionId })
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setError('Something went wrong while getting subscription details')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setError(
          'Error while getting subscription details: ' + error.response.data.detail.type
        )
      }
    }
  }

  return {
    checkUserSubscriptions,
    linkSubscription,
    checkSubscriptionExists,
    getSubscriptionDetails,
    error,
  }
}

export default useSubscriptions
