import api from '../api'
import useDataLoader from '../dataLoader'
import { ICurrency } from 'types'

const useGetCurrencies = () => {
  const fetchCurrencies = async (): Promise<ICurrency[]> => {
    return api.listCurrencies()
  }

  return useDataLoader(fetchCurrencies, [], [])
}

export default useGetCurrencies
