import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api, { isHTTPError } from '../api'

const useApiCreds = (serviceUUID: string) => {
  const [apiCreds, setApiCreds] = useState<any>([])
  const [loadingSaveApiCreds, setLoadingSaveApiCreds] = useState(false)
  const [saveCredsError, setSaveCredsError] = useState('')
  const token = useSelector(selectToken)
  const [serviceUuid, setServiceUuid] = useState(serviceUUID)

  const saveApiCreds = async (organizationId: number, apiCreds: any) => {
    try {
      setLoadingSaveApiCreds(true)
      setSaveCredsError('')
      await api.verifyApiCreds({
        token,
        serviceUuid,
        apiCreds,
        organizationId,
      })
      setLoadingSaveApiCreds(false)
    } catch (error) {
      if (isHTTPError(error) && 'detail' in error.response.data) {
        setSaveCredsError(
          `Error ${error.response.status}: ${error.response.data.detail}`
        )
      } else {
        setSaveCredsError('Failed saving API credentials')
      }
    }
  }

  return { apiCreds, loadingSaveApiCreds, saveApiCreds, saveCredsError }
}

export default useApiCreds
