import useSignIn from 'containers/authentication/useSignIn'
import useQuery from 'containers/useQuery'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const MicrosoftRedirect = () => {
    const code = useQuery().get('code')
    const history = useHistory()

    const {
        signInError,
        signInWithMicrosoft,
      } = useSignIn()
    
    useEffect(() => {
        if (signInError.server === "Merge Required") {
          history.push({
            pathname: `/merge-account`,
            search: `?email=${signInError.details.email}&provider=${signInError.details.provider}&is_google_signin=${signInError.details.is_google_signin}&is_microsoft_signin=${signInError.details.is_microsoft_signin}&is_password_signin=${signInError.details.is_password_signin}`,
            state: { microsoftAccessToken: signInError.details.microsoft_access_token },
          })
          return
        }
        (async () => {
        if (!(await signInWithMicrosoft({ microsoft_code: code! }))) {
            history.push({
              pathname: '/sign-up',
              state: {
                microsoftAccessToken: signInError.details.access_token,
              },
            })
          } else {
            history.push('/')
          }})()
    }, [signInError])
    
  return (
    <></>
  )
}

export default React.memo(MicrosoftRedirect)