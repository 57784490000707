import { useState } from 'react'

export type Validator<V, F> = (value: V, form: F) => string

export type ValidationErrors<A> = Partial<{ [P in keyof A]: string }>

export function composeValidator<V, F>(
  func: (value: V) => boolean,
  message: string
): Validator<V, F> {
  return (value, _form) => (!func(value) ? message : '')
}

export function conditionalRule<V, F>(
  condition: (form: F) => boolean,
  validator: Validator<V, F>
): Validator<V, F> {
  return (value, form) => (condition(form) ? validator(value, form) : '')
}

export const isRequired = (msg = 'Required') =>
  composeValidator((val) => !!val, msg)
export const isValidNumber = (msg = 'Invalid number') =>
  composeValidator((val: number) => !isNaN(val), msg)
export const maxLength = (maxLength: number, msg = 'Text too long') =>
  composeValidator((val: string) => val.length <= maxLength, msg)
export const matchesRegex = (
  pattern: RegExp | string,
  msg = 'Does not match expected pattern'
) => composeValidator((val: string) => !!val.match(pattern), msg)

export type Rules<F> = {
  [Prop in keyof Partial<F>]: Array<Validator<F[Prop], F>>
}

export function validate<F extends object>(
  form: F,
  rules: Rules<F>
): [boolean, ValidationErrors<F>] {
  let errors: ValidationErrors<F> = {}
  let hasErrors = false
  for (const prop in rules) {
    for (const rule of rules[prop]) {
      errors[prop] = rule(form[prop], form)
      if (errors[prop]) {
        hasErrors = true
        break
      }
    }
  }
  return [hasErrors, errors]
}

export function useValidator<A extends object>(
  rules: Rules<A>
): [
  (data: A) => boolean,
  (k: keyof A) => string,
  boolean,
  ValidationErrors<A>,
  (k: keyof A) => void
] {
  const [hasFormErrors, setHasFormErrors] = useState(false)
  const [formErrors, setFormErrors] = useState<ValidationErrors<A>>({})
  const validateForm = (data: A): boolean => {
    const [hasErrors, errors] = validate(data, rules)
    setHasFormErrors(hasErrors)
    setFormErrors(errors)
    return !hasErrors
  }
  const getFormFieldError = (k: keyof A) => {
    return k in formErrors ? (formErrors[k] as string) : ''
  }
  const removeFormError = (k: keyof A) => {
    formErrors[k] = ''
  }

  return [
    validateForm,
    getFormFieldError,
    hasFormErrors,
    formErrors,
    removeFormError,
  ]
}
