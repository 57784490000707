import usePageTitle from 'containers/pageTitle/usePageTitle'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useHistory } from 'react-router-dom'

import ScreenLoading from '../../components/ScreenLoading'

const CheckoutSuccess = () => {
  //Page title
  usePageTitle('Checkout Canceled - Maya')
  let history = useHistory()

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: 'checkout_failure', is_custom_track: true } })
    history.push(
      `/organization/${localStorage.getItem('currentOrganization')}/sources`
    )
  }, [history])

  return <ScreenLoading />
}

export default React.memo(CheckoutSuccess)
