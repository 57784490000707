import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { Column, IOrganization } from 'types'

const useUpdateCustomTableColumns = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const updateTable = async (columns: Column[], category: string) => {
    const res = await api.updateCustomTableColumns(
            token,
            organization.id,
            category,
            columns,
    )
    return res
  }

  return useDataLoader(updateTable)
}

export default useUpdateCustomTableColumns
