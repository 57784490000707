import React, { createContext, useReducer } from 'react'

export type IConfirmation = {
  message: string
  onConfirm: null | (() => void)
  onCancel: null | (() => void)
}

export type IConfirmationState = IConfirmation & {
  visible: boolean
}

const makeInitialState = (): IConfirmationState => {
  return {
    message: '',
    visible: false,
    onConfirm: null,
    onCancel: null,
  }
}

export const ConfirmationContext = createContext({} as any)

type ConfirmationAction =
  | { type: 'show'; payload: IConfirmation }
  | { type: 'closed' }

const reducer = (
  state: IConfirmationState,
  action: ConfirmationAction
): IConfirmationState => {
  switch (action.type) {
    case 'show':
      return {
        message: action.payload.message,
        visible: true,
        onConfirm: action.payload.onConfirm,
        onCancel: action.payload.onCancel,
      }
    case 'closed':
      return { ...state, visible: false }
  }
}

const ConfirmationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, makeInitialState())

  return (
    <ConfirmationContext.Provider value={[state, dispatch]}>
      {children}
    </ConfirmationContext.Provider>
  )
}

export default ConfirmationContextProvider
