import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api, { isAPIError } from 'containers/api'

const useSaveColumnByCategory = () => {
  const token = useSelector(selectToken)

  const [saveError, setSaveError] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const removeError = () => {
    setSaveError('')
  }

  const saveColumnByCategory = async ({
    category,
    columnId,
    organization,
    column,
  }) => {
    setIsSaving(true)
    try {
      await api.saveColumnByCategory({
        category,
        organizationId: organization.id,
        columnId,
        column,
        token,
      })

      setIsSaving(false)
      return true
    } catch (error) {
      if (isAPIError(error)) {
        // localStorage.setItem('clusteringSaveError', JSON.stringify({ ...error.response.data }))
        setSaveError(error.response.data.detail)
        if (error.response.data.detail.saved) {
          setIsSaving(false)
          return true
        }
      } else {
        setSaveError('Failed saving clustering column')
      }

      setIsSaving(false)
      return false
    }
  }

  return { saveColumnByCategory, saveError, isSaving, removeError }
}

export default useSaveColumnByCategory
