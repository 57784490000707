import { useEffect } from 'react'
import './WaveLoading.scss'

const WaveLoading = ({ barColor }) => {
  useEffect(() => {
    document.documentElement.style.setProperty('--bar-color', barColor)
    document.documentElement.style.setProperty(
      '--bar-color-transparent',
      barColor === 'black' ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)'
    )
  }, [barColor])

  return (
    <div class="middle">
      <div class="bar bar1"></div>
      <div class="bar bar2"></div>
      <div class="bar bar3"></div>
      <div class="bar bar4"></div>
    </div>
  )
}

export default WaveLoading
