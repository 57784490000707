import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetAuthUrl from 'containers/services/useGetAuthUrl'
import useQuery from 'containers/useQuery'

import WaveLoading from 'components/WaveLoading'
import Divider from '../Divider'
import InputField from '../InputField'

import AuthorizeButton from './AuthorizeButton'
import './GooglePlayBucket.scss'
import AddAccounts from 'pages/Sources/AddAccounts'

const Guide = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Setup Guide</h1>
      <Divider spaceY={10} />
      <div className="step-heading">
        <p>STEP 1</p>
        <h2>Find Cloud Storage URI</h2>
      </div>
      <ol>
        <li>
          Log in to your{' '}
          <a href="https://play.google.com/console/" target="_blank" rel="noreferrer">
            Google Play Console
            <span className="material-icons-outlined non-selectable">
              launch
            </span>
          </a>
        </li>
        <li>
          <div className="img-item">
            <span>
              Click <b>Copy Cloud Storage URI</b>. For example, the URI is{' '}
              <code>gs://pubsite_prod_rev_01234567890987654321/reviews/</code>
            </span>
            <img
              src="https://www.mayainsights.com/wp-content/uploads/2022/07/google-play-bucket-uri.dd427c92.png"
              width="500"
              alt="google-play-bucket-instructions"
            />
          </div>
        </li>
      </ol>
      <div className="step-heading">
        <p>STEP 2</p>
        <h2>Complete configuration</h2>
      </div>
      <ol>
        <li>
          Enter your Cloud Storage URI in the form field{' '}
          <code>Cloud Storage URI.</code>
        </li>
        <li>
          Click <button className="button-tip">Authorize</button> to let us
          configure your Google Play account.
        </li>
      </ol>
    </div>
  )
}

const GooglePlayBucket = () => {
  const [bucket, setBucket] = useState('')
  const [bucketError, setBucketError] = useState('')
  const [credentials, setCredentials] = useState(null)
  const [showGuide, setShowGuide] = useState(false)
  const [loadingRedirect, setLoadingRedirect] = useState(false)

  const { currentOrganization } = useContext(OrganizationsContext)
  const history = useHistory()
  const location = useLocation()
  const serviceUUID = useParams().id
  const query = useQuery()
  const SERVICE_ID = 'google_play'

  const { getAuthUrl } = useGetAuthUrl()

  const handleAuthorize = async () => {
    // authorize google account by starting a new oauth window and on completion authorize with the bucket id
    // once it's authorized, set googleAcc to the google account
    // NOTE: create custom hook to handle this case

    if (bucket.length <= 0 || serviceUUID === 'oauth') return

    const pattern = new RegExp(/gs:[/]{2}\S+/, 'g')

    if (pattern.test(bucket) === false) {
      setBucketError('Invalid bucket id')
      return
    }

    setBucketError('')
    setLoadingRedirect(true)

    localStorage.setItem('google_play_bucket', JSON.stringify({ bucket }))
    localStorage.setItem('redirectServiceUuid', serviceUUID)
    localStorage.setItem('redirectServiceId', SERVICE_ID)

    const authUrl = await getAuthUrl(serviceUUID, currentOrganization.id)
    window.open(authUrl, '_self')
  }

  useEffect(() => {
    const bucket = JSON.parse(
      localStorage.getItem('google_play_bucket')
    )?.bucket

    if (bucket && serviceUUID === 'oauth') {
      setBucket(bucket)
    }
    if (serviceUUID === 'oauth') {
      setCredentials({
        state: query.get('state'),
        code: query.get('code'),
        scope: query.get('scope').bucket,
      })
    }
  }, [query, serviceUUID])

  useEffect(() => {
    if (credentials?.code !== undefined && credentials?.state !== undefined) {
      localStorage.setItem('redirectState', credentials.state)
      localStorage.setItem('redirectCode', credentials.code)
    }
  }, [credentials])

  if (serviceUUID === 'oauth' && location.search.length <= 0) {
    history.goBack()
    return <></>
  }

  return credentials?.code === undefined && credentials?.state === undefined ? (
    <div className="guide-container">
      <Guide onHide={() => setShowGuide(false)} show={showGuide} />

      <img
        src="https://www.mayainsights.com/wp-content/uploads/2022/07/Google-Play-logo.png"
        width="200"
        alt=""
      />
      <p>
        Press the{' '}
        <button className="button-tip" onClick={() => setShowGuide(true)}>
          Guide
        </button>{' '}
        button to help you through the authorization proccess.
      </p>

      <InputField
        label="Cloud Storage URI"
        error={bucketError}
        value={bucket}
        setValue={setBucket}
        style={{
          maxWidth: '400px',
        }}
      />
      <div className="authorize-container">
        <AuthorizeButton
          disabled={
            bucket.length <= 0 || serviceUUID === 'oauth' ? true : false
          }
          onClick={handleAuthorize}
        >
          {loadingRedirect ? <WaveLoading barColor="black" /> : 'Google'}
        </AuthorizeButton>

        {serviceUUID === 'oauth' &&
        credentials?.code !== undefined &&
        credentials?.state !== undefined ? (
          <p className="authorized">
            Authorized
            <span className="material-icons-outlined saved-icon non-selectable">
              task_alt
            </span>
          </p>
        ) : (
          <p>Click to grant access to your Google Cloud Storage bucket.</p>
        )}
      </div>

      <div className="guide-bar">
        <button
          className="submit-button guide-button"
          onClick={() => setShowGuide(true)}
        >
          <span>Guide</span>
          <span className="material-icons-outlined saved-icon non-selectable">
            menu_book
          </span>
        </button>
      </div>
    </div>
  ) : (
    <AddAccounts />
  )
}

export default GooglePlayBucket
