import { INotification, INotificationFilter, NotificationStatus, NotificationType } from "types";

interface IFindLatestNotificationParams {
  status: NotificationStatus
}
export const findLatestNotification = (notifications: INotification[], params:  IFindLatestNotificationParams| null = null): INotification | null => {
    if (notifications.length === 0) {
      return null;
    }
  
    let latestNotification: INotification = notifications[0];
  
    for (let i = 1; i < notifications.length; i++) {
      if (params && notifications[i].status !== params.status) {
        continue
      }
      const currentNotification = notifications[i];
      const currentCreatedAt = new Date(currentNotification.created_at);
      const latestCreatedAt = new Date(latestNotification.created_at);
  
      if (currentCreatedAt > latestCreatedAt) {
        latestNotification = currentNotification;
      }
    }
  
    return latestNotification;
  };

  export const timeElapsedSinceReceived = (receivedTimeStr) => {
    const receivedTime = new Date(receivedTimeStr);
    const currentTime = new Date();

    // the time difference in millis
    const timeDifference = currentTime - receivedTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 365) {
        return (days < 365 * 2) ? `more than 1 year ago` : `${days} years ago`;
    } else if (days > 0 && days < 365) { 
        return (days === 1) ? `${days} day ago` : `${days} days ago`;
    } else if (hours > 0) {
        return (hours === 1) ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (minutes > 0) {
        return (minutes === 1) ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
        return 'Just now';
    }
}

export const beautifyNotificationDate = (dateString: string) => {
  const options = {  weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false  };
  const prnDt = new Date(dateString).toLocaleTimeString('en-us', options);
  return prnDt 
}
export const placeNotificationTypeIcon = (notificationType: NotificationType) => {
  switch (notificationType) {
    case NotificationType.Error:
      return "error"
    case NotificationType.Info:
      return "info"
    case NotificationType.Success:
      return "check_circle"
    case NotificationType.Warning:
      return "warning"
      case NotificationType.Persistent:
        return "info"
  }
}