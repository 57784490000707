import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { IToken, Conversation } from 'types'

const useConversation = (organizationId: number) => {
  const [conversations, setConversations] = useState({})
  const [loadingCreatingConversation, setLoadingCreatingConversation] =
    useState(false)
  const [errorCreatingConversation, setErrorCreatingConversation] =
    useState(false)
  const [errorMessages, setErrorMessages] = useState('')
  const token: IToken = useSelector(selectToken)

  const createConversation = async (conversation: Conversation) => {
    try {
      setLoadingCreatingConversation(true)
      const conv = await api.createConversation({
        organizationId,
        token,
        conversation,
      })
      setLoadingCreatingConversation(false)
      setConversations(conv)
      return conv
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessages(e.message)
      }
      setErrorCreatingConversation(true)
    }
  }

  return {
    createConversation,
    conversations,
    loadingCreatingConversation,
    errorCreatingConversation,
    errorMessages,
  }
}

export default useConversation
