import React from 'react'
import { useParams } from 'react-router'
import isUUID from 'is-uuid'

import Page404 from 'pages/ErrorPages/404'
import ClusterView from 'pages/Clusters/Campaigns/ClustersView'

const AVAILABLE_CLUSTERS = {
  standard: [
    'adgroups',
    'conversions',
    'urls',
    'budget',
    'keywords',
    'searchTerms',
  ],
}

const ClustersChooseTab = ({ setClusterBreadcrumb }) => {
  const { columnId, clusterCategory } = useParams()

  if (!isUUID.v4(columnId)) {
    return <Page404 />
  }

  if (AVAILABLE_CLUSTERS.standard.includes(clusterCategory)) {
    return (
      <ClusterView
        columnId={columnId}
        clusterCategory={clusterCategory}
        AVAILABLE_CLUSTERS={AVAILABLE_CLUSTERS.standard}
        setClusterBreadcrumb={setClusterBreadcrumb}
      />
    )
  }

  return <Page404 />
}

export default React.memo(ClustersChooseTab)
