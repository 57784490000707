import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { IOrganization } from 'types'

const useGetCategoryColumns = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const getCategoryColumns = (category: string) => {
    return api.getCategoryColumns(category, token, organization.id)
  }

  return useDataLoader(getCategoryColumns)
}

export default useGetCategoryColumns
