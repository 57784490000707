import { useContext, useState } from 'react'
import { ConfirmationContext } from 'containers/confirmation/confirmationContextProvider'

const useConfirmation = () => {
  const [confirmation, dispatch] = useContext(ConfirmationContext)
  const [promise, setPromise] = useState<Promise<boolean> | null>(null)

  const confirm = async (message: string) => {
    if (promise instanceof Promise) {
      await promise // wait for previous dialog before starting a new one
    }
    const prom = new Promise<boolean>((resolve) => {
      dispatch({
        type: 'show',
        payload: {
          message,
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false),
        },
      })
    }).finally(() => {
      dispatch({ type: 'closed' })
    })
    setPromise(prom)
    return prom
  }

  window['myconfirm'] = confirm

  return { ...confirmation, confirm }
}

export default useConfirmation
