import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import { ISubscription } from 'types'
import api from '../api'

const useGetSubscription = () => {
  const token = useSelector(selectToken)
  const [subscription, setSubscription] = useState<ISubscription | null>(null)
  const [loadingSubscription, setLoadingSubscription] = useState(false)
  const [subscriptionError, setSubscriptionError] = useState('')

  const getSubscription = async (organizationId: number) => {
    setLoadingSubscription(true)
    setSubscription(null)
    try {
      const sub = await api.getSubscription({ token, organizationId })
      setSubscription(sub)
    } catch (e) {
      if (e instanceof Error) {
        setSubscriptionError(e.message)
      }
    }
    setLoadingSubscription(false)
  }

  return {
    subscription,
    loadingSubscription,
    getSubscription,
    subscriptionError,
  }
}

export default useGetSubscription
