import ScreenLoading from 'components/ScreenLoading'
import useChangePassword from 'containers/user/useChangePassword'
import React, { useEffect, useRef, useState } from 'react'
import Divider from '../Divider'
import InputField from '../InputField'

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const formRef = useRef(null)

  const [changePassword, status, error, response] = useChangePassword()

  const [pendingRequest, setPendingRequest] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState()

  const [passwordError, setPasswordError] = useState(null)
  const [dontMatchError, setDontMatchError] = useState(null)
  const [otherError, setOtherError] = useState(null)

  useEffect(() => {
    if (status === 'fulfilled') {
      setSaveSuccess('Password changed successfully')
      setCurrentPassword('')
      return formRef.current.reset()
    }
    if (
      status === 'rejected' &&
      response?.data?.detail?.type === 'invalid_credentials'
    ) {
      const errorMsg = formatErrorMessage(response.data.detail.type)
      setPasswordError(errorMsg)
      return
    }
    if (status === 'rejected') {
      setOtherError(error)
      return
    }
  }, [response, status, error])

  async function handlePasswordChange(e) {
    e.preventDefault()
    const newPassword = e.target.new__password.value
    const confirmPassword = e.target.confirm__password.value

    if (newPassword !== confirmPassword) {
      // handle error not matching passwords
      setDontMatchError('Passwords do not match')
      return
    }

    setDontMatchError(null)
    setPasswordError(null)
    setOtherError(null)
    setPendingRequest(true)
    setSaveSuccess('')

    await changePassword({ currentPassword, newPassword })

    setPendingRequest(false)
  }

  return (
    <form
      onSubmit={handlePasswordChange}
      ref={formRef}
      style={{ marginTop: '1rem', width: '50%', minWidth: '300px' }}
    >
      <InputField
        label="Current password"
        type="password"
        required
        setCurrentValue={setCurrentPassword}
        error={passwordError}
      />
      <Divider spaceY={10} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2rem' }}>
        <InputField
          label="New password"
          type="password"
          name="new__password"
          required
          error={dontMatchError}
          style={{ margin: '0' }}
        />
        <InputField
          label="Repeat new password"
          type="password"
          name="confirm__password"
          required
          error={dontMatchError}
          style={{ margin: '0' }}
        />
      </div>

      {otherError ? <p className="form-error"> {otherError}</p> : ''}
      {saveSuccess ? <p className="form-success"> {saveSuccess}</p> : ''}

      <button
        type="submit"
        className={`submit-button ${!currentPassword ? 'disabled' : ''}`}
        disabled={!currentPassword ? true : false}
        style={{ marginTop: '1.8rem' }}
      >
        {pendingRequest ? <ScreenLoading spinnerColor="white" /> : 'Save'}
      </button>
    </form>
  )
}

export default ChangePassword

function formatErrorMessage(message) {
  const pattern = new RegExp('[^a-z0-9]', 'gi')

  const newMessage = (message[0].toUpperCase() + message.slice(1)).replace(
    pattern,
    ' '
  )
  return newMessage
}
