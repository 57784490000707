import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import ScreenLoading from 'components/ScreenLoading'

import OrganizationsContext from 'containers/organizations/organizationsContext'

const Clusters = ({ organizationSlug }) => {
  let history = useHistory()
  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  useEffect(() => {
    if (!isLoadingOrganization) {
      history.push(`/organization/${organizationSlug}/clusters/adgroups`)
    }
    // eslint-disable-next-line
  }, [currentOrganization.id, isLoadingOrganization])

  return <ScreenLoading />
}

export default React.memo(Clusters)
