import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api, { isHTTPError } from '../api'
import useDataLoader from '../dataLoader'

const useGetManualExpense = (organizationId: number | null, uuid: string) => {
  const token = useSelector(selectToken)
  const fetchManualExpense = async () => {
    if (organizationId && uuid) {
      try {
        return await api.getManualExpense({ token, organizationId, uuid })
      } catch (error) {
        if (isHTTPError(error) && error.response.status === 404) {
          return null
        } else if (isHTTPError(error) && 'detail' in error.response.data) {
          throw new Error(
            'Error loading expense: ' + error.response.data.detail.type
          )
        } else {
          throw error
        }
      }
    }
  }

  return useDataLoader(fetchManualExpense, [], [])
}

export default useGetManualExpense
