import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from 'containers/authentication/authenticationSlice'

import api from 'containers/api'

const useGetPreviewValueByColumn = () => {
  const token = useSelector(selectToken)
  const [previewValue, setPreviewValue] = useState()
  const [loadingPreviewValue, setloadingPreviewValue] = useState(true)
  const [previewValueError, setPreviewValueError] = useState('')

  const getPreviewValueByColumn = async ({
    category,
    columnId,
    organization,
    column,
    value,
    orderBy,
    orderDirection,
  }) => {
    setloadingPreviewValue(true)
    setPreviewValueError('')
    try {
      const responsePreviewValue = await api.getPreviewValueByColumn({
        category,
        columnId,
        organizationId: organization.id,
        token,
        column,
        value,
        orderBy,
        orderDirection,
      })
      setPreviewValue(responsePreviewValue)
    } catch (error) {
      setPreviewValueError('Could not get the preview value for this column')
    }
    setloadingPreviewValue(false)
  }

  return {
    previewValue,
    loadingPreviewValue,
    previewValueError,
    getPreviewValueByColumn,
  }
}

export default useGetPreviewValueByColumn
