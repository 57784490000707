import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectServices, setServices } from './servicesSlice'

import { IService } from 'types'
import api, { isHTTPError } from '../api'

const useGetServices = () => {
  const dispatch = useDispatch()
  const [services, setNewServices] = useState<IService[]>([])
  const [loadingServices, setLoadingServices] = useState(true)
  const [servicesError, setServicesError] = useState('')
  const currentServices = useSelector(selectServices)

  const fetchServices = useCallback(async () => {
    try {
      const responseServices = await api.getServices()

      dispatch(setServices(responseServices))
      setNewServices(responseServices)
      setLoadingServices(false)
      return responseServices
    } catch (error) {
      if (isHTTPError(error) && 'detail' in error.response.data) {
        setServicesError(
          `Error ${error.response.status}: ${error.response.data.detail}`
        )
      } else {
        setServicesError('Failed loading services')
      }
    }
  }, [setLoadingServices, setNewServices, setServicesError, dispatch])

  const getServices = useCallback(async () => {
    if (currentServices) {
      setNewServices(currentServices)
      setLoadingServices(false)
      return currentServices
    } else {
      return await fetchServices()
    }
  }, [setNewServices, setLoadingServices, currentServices, fetchServices])

  return {
    getServices,
    fetchServices,
    services,
    servicesError,
    loadingServices,
  }
}

export default useGetServices
