import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { IOrganization } from 'types'

const useManualUpload = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const uploadFile = async (file: File, category: string) => {
    const formData = new FormData()
    formData.append('file', file)
    const res = await api.manualUpload(
      token,
      organization.id,
      formData,
      category
    )
    return res
  }

  return useDataLoader(uploadFile)
}

export default useManualUpload
