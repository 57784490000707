import { createSlice } from '@reduxjs/toolkit'

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    organizations: null,
  },
  reducers: {
    setOrganizations: (state, action) => {
      state.organizations = action.payload
    },
  },
})

export const { setOrganizations } = organizationsSlice.actions

export default organizationsSlice.reducer

export const selectOrganizations = (state) => state.organizations.organizations
