import { useContext, useEffect, useState } from 'react'

import useProductDetails from 'containers/products/useProductDetails'

import ScreenLoading from 'components/ScreenLoading'
import Switch from 'components/Switch'

import ProductCard from './ProductCard'
import './Products.scss'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import { Product, ProductMode } from 'types'

const Products = () => {
  const { currentOrganization } = useContext(OrganizationsContext)
  const { products, getProducts, loadingProducts, productError } =
    useProductDetails(currentOrganization)
  const [annualToggle, setAnnualToggle] = useState(true)
  const [subscriptionProducts, setSubscriptionProducts] = useState<Product[]>([])
  const [paymentProducts, setPaymentProducts] = useState<Product[]>([])

  useEffect(() => {
    getProducts()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const subscriptions: Product[] = []
    const payments: Product[] = []
    products.map(product => {
      if (product.mode === ProductMode.SUBSCRIPTION) subscriptions.push(product)
      else payments.push(product)
    })
    setSubscriptionProducts(subscriptions)
    setPaymentProducts(payments)
  }, [products])

  return (
    <div className="marketplace-wrapper">
      <MarketplaceCategory
        title="One-time payments"
        subheading="Select a product."
        products={paymentProducts}
        loadingProducts={loadingProducts}
        productError={productError}
      />
      <MarketplaceCategory
        title="Subscription plans"
        subheading="Select a Subscription plan."
        products={subscriptionProducts}
        loadingProducts={loadingProducts}
        productError={productError}
        annualToggle={annualToggle}
        setAnnualToggle={setAnnualToggle}
      />
    </div>
  )
}

const MarketplaceCategory = ({
  title,
  subheading,
  products,
  loadingProducts,
  productError,
  annualToggle = null,
  setAnnualToggle = (toggle: boolean) => {},
}) => {
  return (
    <div className="products-wrapper">
      <div className="products-header">
        <div className="products-top-header">
          <h1>{title}</h1>
          <span className="subtitle-text">{subheading}</span>
          {productError.length > 0 ? (
            <p style={{ color: 'red' }}>{productError}</p>
          ) : (
            <></>
          )}
        </div>
        {annualToggle !== null && (
          <Switch
            onChange={() => setAnnualToggle(!annualToggle)}
            checked={annualToggle}
          />
        )}
      </div>

      <div className="products-container">
        {products.length > 0 ? (
          products.map((product) => {
            return <ProductCard product={product} toggle={annualToggle} />
          })
        ) : loadingProducts ? (
          <ScreenLoading />
        ) : (
          products.length <= 0 && <p>No products found.</p>
        )}
      </div>
    </div>
  )
}

export default Products
