import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as rudderanalytics from 'rudder-sdk-js'
import ScreenLoading from 'components/ScreenLoading'
import { IOrganization } from 'types'
import TagManager from 'react-gtm-module'

interface IOrganizationPickerProps {
  organizations: IOrganization[]
  isLoadingOrganization: boolean
  organizationSlug: string
  currentOrganization: IOrganization | null
  page: string
  onPick?: (organization: IOrganization) => void
  style?: React.CSSProperties
  showLabel?: boolean
}

const OrganizationPicker: React.FC<IOrganizationPickerProps> = ({
  organizations,
  isLoadingOrganization,
  organizationSlug,
  currentOrganization,
  page,
  onPick = null,
  style = {},
  showLabel = false,
}) => {
  const [pickOrganizationOpen, setPickOrganizationOpen] = useState(false)
  const history = useHistory()

  const handleClickInside = (e) => {
    e.stopPropagation()
  }

  const handleClickOrganizationMenu = (e) => {
    e.stopPropagation()
    if (!pickOrganizationOpen) {
      document.addEventListener('click', handleCloseAll)
    }
    setPickOrganizationOpen(!pickOrganizationOpen)
  }

  const handleAddOrganization = () => {
    // rudderanalytics.track('new_organization_button_clicked')
    TagManager.dataLayer({ dataLayer: { event: `new_organization_button_clicked`, is_custom_track: true } })

    history.push('/create-organization')
  }

  const handleCloseAll = () => {
    document.removeEventListener('click', handleCloseAll)
    setPickOrganizationOpen(false)
  }

  interface IRoutes {
    [key: string]: string[]
  }

  const reverseRoutes = (routes: IRoutes) => {
    let reverseRoutes: IRoutes = {}
    for (let key in routes) {
      routes[key].forEach((route) => {
        reverseRoutes[route] = [key]
      })
    }
    return reverseRoutes
  }

  const handleChangeOrganization = (organization) => {
    if (onPick) {
      onPick(organization)
      setPickOrganizationOpen(false) 
      return
    }
    
    setPickOrganizationOpen(false)
    let route = `/organization/${organization.slug}/`

    if (organization.is_demo) {
      history.push(route)
      return
    }

    const routes = {
      add: ['add-source'],
      sources: ['sources'],
      clusters: ['clusters'],
      'account-settings': ['account-settings'],
      settings: ['settings'],
      dashboard: ['dashboard'],
      demo: ['demo'],
      competitors: ['competitors'],
      pagespeed: ['pagespeed'],
      dataload: ['dataload'],
      notifications: ['notifications'],
      'billing/details': ['billing', 'billingDetails', 'billingCheckout'],
    }

    const reversedRoutes = reverseRoutes(routes);

    history.push(route + reversedRoutes[page][0])
  }

  return (
    <>
      <div onClick={handleClickInside} className="organizations-menu" style={style}>
        {currentOrganization && currentOrganization.name !== null ? (
          <div
            onClick={handleClickOrganizationMenu}
            className={`pick-organization ${
              pickOrganizationOpen ? 'open' : ''
            }`}
            style={showLabel ? {height: "2.7rem"} : {}}
          >
            {showLabel && (<div className="input-header"><label>Organization</label></div>)}
            <div className="picked" style={showLabel ? {paddingTop: "1.4rem"} : {}}>
              <span>{currentOrganization.name}</span>
            </div>
            <span
              style={showLabel ? {top: "8px"} : {}} 
              className={`material-icons arrow non-selectable ${
                pickOrganizationOpen && 'rotate'
              }`}
            >
              keyboard_arrow_up
            </span>
          </div>
        ) : (
          <ScreenLoading />
        )}

        {pickOrganizationOpen && (
          <div className="drop-down-organizations" style={showLabel ? {top: "calc(3.7rem - 15px"} : {}}>
            {!onPick && (
              <button
                onClick={handleAddOrganization}
                className="link add-organization"
              >
                <span className="enlarge">+</span>Add new organization
              </button>
            )}
            <span className="subtitle-text">Select organization</span>
            {!isLoadingOrganization ? (
              <ul>
                {organizations?.map((organization) => (
                  <li
                    onClick={() => handleChangeOrganization(organization)}
                    key={organization.slug}
                    className={`${
                      organization.slug === organizationSlug ? 'selected' : ''
                    }`}
                  >
                    {organization.slug === organizationSlug ? (
                      <span className="material-icons-outlined check-circle checked">
                        radio_button_checked
                      </span>
                    ) : (
                      <span className="material-icons-outlined check-circle unchecked">
                        radio_button_unchecked
                      </span>
                    )}
                    <span className="organization-name">
                      {organization.name}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <ScreenLoading />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default OrganizationPicker
