import api from 'containers/api'
import useDataLoader from 'containers/dataLoader'

import { selectToken } from 'containers/authentication/authenticationSlice'
import { useSelector } from 'react-redux'

import { IOrganization } from 'types'

const useGetPublicKey = (org: IOrganization) => {
  const token = useSelector(selectToken)

  const getPublicKey = async (serviceUUID) => {
    const res = await api.getPublicKey({token, serviceUUID, organizationId: org.id})
    return res
  }

  return useDataLoader(getPublicKey)
}

export default useGetPublicKey
