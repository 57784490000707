import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

const useGetAuthUrl = () => {
  const token = useSelector(selectToken)
  const [loadingAuthUrlServiceId, setLoadingAuthUrlServiceId] = useState('')
  const [authUrlError, setAuthUrlError] = useState('')
  const latestServiceId = useRef('')

  const getAuthUrl = async (serviceUuid: string, organizationId: number) => {
    if (latestServiceId.current === serviceUuid) {
      return
    }

    latestServiceId.current = serviceUuid
    setLoadingAuthUrlServiceId(serviceUuid)
    try {
      const url = (
        await api.getServiceAuthUrl({ token, serviceUuid, organizationId })
      ).authorization_url

      if (latestServiceId.current !== serviceUuid) {
        return
      }
      setLoadingAuthUrlServiceId('')
      return url
    } catch (error) {
      setAuthUrlError('Could not start the authentication process.')
      setLoadingAuthUrlServiceId('')
    }
  }

  return { loadingAuthUrlServiceId, authUrlError, getAuthUrl }
}

export default useGetAuthUrl
