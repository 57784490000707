import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api from 'containers/api'

const useSaveCompetitorsReport = () => {
  const token = useSelector(selectToken)

  const [isSavingReport, setIsSavingReport] = useState(false)
  const [saveReportError, setSaveReportError] = useState(false)

  const saveReport = async ({
    organization,
    reportId,
    countryCode,
    accountUUID,
    uuid,
    columnId,
    value,
    orderBy,
  }) => {
    setIsSavingReport(true)
    try {
      await api.saveReport({
        organizationId: organization.id,
        token,
        reportId,
        countryCode,
        accountUUID,
        uuid,
        columnId,
        value,
        orderBy,
      })

      setIsSavingReport(false)
      return true
    } catch (error) {
      setSaveReportError(true)
      setIsSavingReport(false)
      return false
    }
  }

  return { isSavingReport, saveReportError, saveReport }
}

export default useSaveCompetitorsReport
