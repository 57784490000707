import { useState } from 'react'
import { useDispatch } from 'react-redux'
import produce from 'immer'
import { useCookies } from 'react-cookie'
import TagManager from 'react-gtm-module'

import { setToken } from './authenticationSlice'
import { setOrganizations } from '../organizations/organizationsSlice'
import useStorage from 'containers/useStorage'

import useValidator from './useValidator'

import api, { isHTTPError } from '../api'
import { IGoogleLogin, ILoginCredentials, IMicrosoftLogin } from 'types'

interface ISignInError {
  email?: string
  password?: string
  server?: string
  details?: ISignInErrorDetails
}

interface ISignInErrorDetails {
  type?: string
  message?: string
  code?: string

  user?: string
  email?: string
  provider?: string
  microsoft_access_token?: string
  is_google_signin?: boolean
  is_microsoft_signin?: boolean
  is_password_signin?: boolean
}

const useSignIn = () => {
  const storage = useStorage()
  const dispatch = useDispatch()
  const [, setCookie] = useCookies(['user_id'])

  const [signInError, setSignInError] = useState<ISignInError>({
    email: '',
    password: '',
    server: '',
    details: {},
  })
  const { validateEmail } = useValidator()

  const removeError = (errorName: string) => {
    setSignInError(
      produce(signInError, (draftTempSignInError) => {
        draftTempSignInError[errorName] = ''
        draftTempSignInError.server = ''
      })
    )
  }

  const signInWithEmailPassword = async ({ email, password }) => {
    let shouldSubmit = true
    const error = {
      email: '',
      password: '',
      server: '',
    }

    if (!validateEmail(email)) {
      error.email = 'Please enter a valid email address'
      shouldSubmit = false
    }

    if (password === '') {
      error.password = 'Please enter a valid password'
      shouldSubmit = false
    }

    if (!shouldSubmit) {
      setSignInError(error)
      return
    }

    return signInWithCredentials({
      email: email,
      password: password,
    })
  }

  const signInWithGoogle = async (credentials: IGoogleLogin) => {
    return signInWithCredentials(credentials)
  }

  const signInWithMicrosoft = async (credentials: IMicrosoftLogin) => {
    return signInWithCredentials(credentials)
  }

  const signInWithCredentials = async (credentials: ILoginCredentials) => {
    try {
      const response = await api.signIn(credentials)

      storage.clearNewSession()

      setCookie('user_id', response.id, { path: '/' })
      dispatch(setOrganizations(null))
      const user = {
        id: response.id,
        email: response.email,
        profile: response.profile,
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'sign_in',
          is_custom_track: true,
          event_params: {
            userId: user.id,
            userEmail: user.email,
            userFirstName: user.profile.first_name,
            userLastName: user.profile.last_name,
          }
        },
      })
      dispatch(
        setToken({
          accessToken: response.access_token,
          accessTokenExpiresAt: response.access_token_expires_at,
          userId: response.id,
        })
      )
      return user
    } catch (error) {
      setSignInError(
        produce(signInError, (draftTempSignInError) => {
          if (isHTTPError(error)) {
            switch (error.response.status) {
              case 400:
                switch (error.response.data.detail.type) {
                  case 'merge_required':
                    draftTempSignInError.server = 'Merge Required';
                    break;
                  case 'invalid_credentials':
                    draftTempSignInError.server = 'Incorrect credentials'
                    break
                  case 'no_password_set':
                    draftTempSignInError.server = 'No password set'
                    break
                  case 'no_such_user':
                    draftTempSignInError.server = 'No such user'
                    break
                  default:
                    draftTempSignInError.server = 'Something went wrong'
                }
                draftTempSignInError.details = error.response.data.detail
                break
              case 422:
                draftTempSignInError.server = 'Validation Error'
                break
              default:
                draftTempSignInError.server = 'Something went wrong'
            }
          } else {
            draftTempSignInError.server = 'Something went wrong'
          }
        })
      )
      if (isHTTPError(error) && error.response.status === 400) {
        return null
      }
    }
    return null
  }

  return {
    signInError,
    removeError,
    signInWithGoogle,
    signInWithMicrosoft,
    signInWithEmailPassword,
  }
}

export default useSignIn
