import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'
import useDataLoader from '../dataLoader'

const useETLProgress = (organizationId: number | null) => {
  const token = useSelector(selectToken)
  const fetchETLProgress = async () => {
    if (organizationId) {
      return await api.getETLProgress({ token, organizationId })
    }
  }

  return useDataLoader(fetchETLProgress, [], {
    running: false,
    description: '',
    points: 0,
  })
}

export default useETLProgress
