import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api from 'containers/api'

const useGetWordGroups = () => {
  const token = useSelector(selectToken)

  const [wordGroups, setWordGroups] = useState()
  const [isLoadingWordGroups, setIsLoadingWordGroups] = useState(true)
  const [wordGroupsError, setWordGroupsError] = useState(false)

  const fetchWordGroups = async ({ organization }) => {
    try {
      const responseWordGroups = await api.getWordGroups({
        organizationId: organization.id,
        token,
      })
      setWordGroups(responseWordGroups)
      setIsLoadingWordGroups(false)
      return responseWordGroups
    } catch (error) {
      setWordGroupsError(true)
    }
    setIsLoadingWordGroups(false)
  }

  return { wordGroups, isLoadingWordGroups, wordGroupsError, fetchWordGroups }
}

export default useGetWordGroups
