import api, { isHTTPError } from 'containers/api'
import { useState } from 'react'
import { selectToken } from 'containers/authentication/authenticationSlice'
import { useSelector } from 'react-redux'
import {
  INotification,
  INotificationFilter,
  INotificationUpdateStatus,
  IRefreshParams,
  NotificationCountParams,
  NotificationStatus,
  NotificationType,
  PaginatedNotificationsParams,
} from 'types'

type SortFilter = {
  createdAt?: boolean
  type?: boolean
  status?: boolean
  pinned?: boolean
}

const DEFAULT_SORT_FILTER: SortFilter = {
  createdAt: true,
  pinned: true,
  status: false,
  type: false,
}

const useNotifications = (organizationId) => {
  const token = useSelector(selectToken)
  const [authURIError, setAuthURIError] = useState<string | null>(null)
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [notificationCursor, setNotificationCursor] = useState<number | null>(1)

  // default sort by created_at and pinned
  const sortNotifications = (
    notifications: INotification[],
    sortFilter: SortFilter = DEFAULT_SORT_FILTER
  ) => {
    let notificationsSort = [...notifications]

    if (sortFilter.createdAt) {
      // sort by created_at
      notificationsSort = notifications.sort((a, b) => {
        if (a.created_at < b.created_at) return 1
        if (a.created_at > b.created_at) return -1
        return 0
      })
    }

    if (sortFilter.status) {
      // sort by unread/read
      notificationsSort = notificationsSort.sort((a, b) => {
        if (
          a.status === NotificationStatus.Unread &&
          b.status === NotificationStatus.Read
        )
          return -1
        if (
          a.status === NotificationStatus.Read &&
          b.status === NotificationStatus.Unread
        )
          return 1
        return 0
      })
    }

    if (sortFilter.type) {
      // sort by type
      notificationsSort = notificationsSort.sort((a, b) => {
        if (
          a.type === NotificationType.Persistent &&
          b.type !== NotificationType.Persistent
        )
          return -1
        if (
          a.type !== NotificationType.Persistent &&
          b.type === NotificationType.Persistent
        )
          return 1
        return 0
      })
    }

    if (sortFilter.pinned) {
      // sort by pinned
      notificationsSort = notificationsSort.sort((a, b) => {
        if (a.pinned && !b.pinned) return -1
        if (!a.pinned && b.pinned) return 1
        return 0
      })
    }

    return notificationsSort
  }

  const getAllNotifications = async (): Promise<INotification[] | null> => {
    try {
      const tempNotifications = await api.getAllNotifications({
        organizationId,
        token,
      })
      const sortedNotifications = sortNotifications(tempNotifications)
      setNotifications(sortedNotifications)
      return sortedNotifications
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting notifications for organization: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const updateNotification = async (
    params: INotificationFilter
  ): Promise<INotificationUpdateStatus | null> => {
    try {
      const notificationUpdateStatus = await api.updateNotification({
        organizationId,
        params,
        token,
      })
      setNotifications((prev) => {
        if (
          prev !== null &&
          notificationUpdateStatus?.success &&
          notificationUpdateStatus?.updated_notification
        ) {
          const updatedNotifications = prev.map((notification) =>
            notification.id ===
            notificationUpdateStatus?.updated_notification!.id
              ? notificationUpdateStatus?.updated_notification!
              : notification
          )
          return sortNotifications(updatedNotifications)
        }
        return sortNotifications(prev)
      })
      return notificationUpdateStatus
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid notification')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error updating notification for organization: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const refreshNotifications = async (
    params: IRefreshParams
  ): Promise<INotification[] | null> => {
    try {
      const tempNotifications = await api.refreshNotifications({
        organizationId,
        params,
        token,
      })
      setNotifications((prev) => {
        if (prev === null) {
          return sortNotifications(tempNotifications)
        }
        return sortNotifications([...tempNotifications, ...prev])
      })
      return sortNotifications(tempNotifications)
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error refreshing notifications for organization: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const getFilteredNotifications = async (
    params: PaginatedNotificationsParams = {
      cursor: notificationCursor,
      limit: 10,
    }
  ): Promise<INotification[] | null> => {
    try {
      const filterNotificationResponse = await api.getFilteredNotifications({
        organizationId,
        params,
        token,
      })
      const sortedNotifications = sortNotifications([
        ...notifications,
        ...filterNotificationResponse.notifications,
      ])
      setNotifications(sortedNotifications)
      setNotificationCursor(filterNotificationResponse.next_page)
      return sortedNotifications
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting notifications for organization: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const getNotificationCount = async (
    params: NotificationCountParams = {
      status: [NotificationStatus.Unread],
    }
  ): Promise<number | null> => {
    try {
      const { count } = await api.getNotificationCount({
        organizationId,
        params,
        token,
      })
      return count
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting notifications for organization: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const resetNotifications = () => {
    setNotifications([])
    setNotificationCursor(1)
  }

  return {
    authURIError,
    notifications,
    notificationCursor,
    getAllNotifications,
    getFilteredNotifications,
    updateNotification,
    refreshNotifications,
    getNotificationCount,
    resetNotifications,
  }
}

export default useNotifications
