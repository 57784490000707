import { Route } from 'react-router-dom'
import { Redirect } from 'components/Redirect'

import useStorage from 'containers/useStorage'

type IProtectedRoute = {
  component: any
  path: string
  exact?: boolean
  userId?: number | null
  token?: { userId: number }
  redirectTo?: string
}

const ProtectedRoute = ({
  component: Component,
  path,
  userId,
  redirectTo,
  token,
  exact,
}: IProtectedRoute) => {
  const storage = useStorage()

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (token && userId && token.userId === userId) {
          return <Component {...props} />
        } else {
          storage.setUrlWhenLoggedIn({ url: props.location.pathname })

          let path = '/sign-in'
          if (redirectTo) {
            path = redirectTo
          }

          return (
            <Redirect
              to={{
                pathname: path,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      }}
    />
  )
}

export default ProtectedRoute
