// SubscriptionPopUp.tsx
import React, { useEffect } from 'react'
import './NotificationPopup.scss'
import {
  placeNotificationTypeIcon,
  timeElapsedSinceReceived,
} from 'utils/notificationUtils'
import { useNotificationContext } from 'containers/notifications/NotificationContext'
import { useHistory } from 'react-router-dom'
import { ActionStatus, IOrganization, NotificationType } from 'types'

type NotificationPopupProps = {
  onClick: () => void
  show: boolean
  onTrigger: (trigger: boolean) => void
  currentOrganization: IOrganization
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  onClick,
  show,
  onTrigger,
  currentOrganization,
}) => {
  const { latestNotification } = useNotificationContext()
  const history = useHistory()

  useEffect(() => {
    onTrigger(true)
  }, [latestNotification])

  const handleClickPopup = () => {
    if (latestNotification === null) return
    history.push({
      pathname: `/organization/${currentOrganization.slug}/notifications`,
      state: {
        selectedPreview: latestNotification,
      },
    })
    if (
      latestNotification.type === NotificationType.Persistent &&
      latestNotification.action?.status !== ActionStatus.FULFILLED
    ) {
      return
    }

    // TODO: use this when actions are implemented
    // onClick()
  }

  if (!latestNotification || !show) {
    return <></>
  }

  const dismissNotification = (e) => {
    e.stopPropagation()
    onTrigger(false)
  }

  return (
    <div className="notification-popup" onClick={handleClickPopup}>
      <div className="notification-popup-top">
        <p className="notification-popup-announcement">New notification</p>
        <span
          className="material-icons dismiss-icon"
          onClick={dismissNotification}
        >
          close
        </span>
      </div>
      <div className="notification-popup-main">
        <div className="notification-popup-header">
          <h2 className="notification-popup__title">
            {latestNotification.title}
          </h2>
          <div className="notification-popup-header__right">
            <p className="notification-popup__date">
              {timeElapsedSinceReceived(latestNotification.created_at)}
            </p>
            <span
              className="material-icons status-icon"
              data-status={latestNotification.type}
            >
              {placeNotificationTypeIcon(latestNotification.type)}
            </span>
          </div>
        </div>
        <p className="notification-popup__body">{latestNotification.body}</p>
      </div>
    </div>
  )
}

export default NotificationPopup
