import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'

import api, { isAPIError } from 'containers/api'
import { IPageSpeedLink } from 'types'
import { selectToken } from 'containers/authentication/authenticationSlice'

const usePageSpeedLinks = (organizationId: number) => {
  const [isSaved, setIsSaved] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [urls, setUrls] = useState<IPageSpeedLink[]>([])
  const [loadingUrls, setLoadingUrls] = useState(false)
  const [topUrls, setTopUrls] = useState<string[]>([])
  const [loadingTopUrls, setLoadingTopUrls] = useState(false)
  const [loadingUrlsError, setLoadingUrlsError] = useState('')
  const [savingError, setSavingError] = useState('')
  const token = useSelector(selectToken)

  const loadUrls = useCallback(async () => {
    setLoadingUrls(true)
    try {
      const urls = await api.getPageSpeedUrls({ organizationId, token })
      setUrls(urls)
      setLoadingUrlsError('')
    } catch (e) {
      if (isAPIError(e)) {
        setSavingError(e.response.data.detail.type)
      } else {
        setSavingError('unexpected error')
      }
    }
    setLoadingUrls(false)
  }, [organizationId, token, setUrls, setLoadingUrls])

  const loadTopUrls = useCallback(async () => {
    setLoadingTopUrls(true)
    try {
      const topUrls = await api.getPageSpeedTopUrls({
        organizationId: organizationId,
        token,
      })
      setTopUrls(topUrls)
    } catch (e) {
      console.log('failed loading top urls', e)
    }
    setLoadingTopUrls(false)
  }, [organizationId, token, setTopUrls, setLoadingTopUrls])

  const changeUrl = (index: number, url: string) => {
    setUrls(
      produce(urls, (draftUrls) => {
        draftUrls[index].urlLink = url
      })
    )
    setIsSaved(false)
  }

  const deleteUrl = (index: number) => {
    setUrls(
      produce(urls, (draftUrls) => {
        draftUrls.splice(index, 1)
      })
    )
    setIsSaved(false)
  }

  const addEmptyUrl = (index: number) => {
    setUrls(
      produce(urls, (draftUrls) => {
        draftUrls.splice(index, 0, { uuid: uuidv4(), urlLink: '' })
      })
    )
    setIsSaved(false)
  }

  const saveUrls = async () => {
    setIsSaving(true)
    try {
      await api.updatePageSpeedUrls({
        organizationId: organizationId,
        token,
        urls,
      })
      setSavingError('')
      setIsSaved(true)
    } catch (e) {
      if (isAPIError(e)) {
        setSavingError(e.response.data.detail.type)
      } else {
        setSavingError('unexpected error')
      }
    }
    setIsSaving(false)
  }

  return {
    urls,
    loadingUrls,
    topUrls,
    loadingTopUrls,
    changeUrl,
    deleteUrl,
    addEmptyUrl,
    loadUrls,
    loadTopUrls,
    saveUrls,
    isSaved,
    loadingUrlsError,
    savingError,
    isSaving,
  }
}

export default usePageSpeedLinks
