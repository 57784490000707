import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { IOrganization } from 'types'

const useGetFilterOptions = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const getFilterOptions = async (
    datasetId: string,
    table: string,
    column: string,
    cursor: string = '0'
  ) => {
    const res = await api.getFilterOptions(
      token,
      organization.id,
      datasetId,
      table,
      column,
      cursor
    )
    return res
  }

  return useDataLoader(getFilterOptions)
}

export default useGetFilterOptions
