import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api, { isAPIError } from 'containers/api'

const useDeleteColumnByCategory = () => {
  const token = useSelector(selectToken)

  const [deleteError, setDeleteError] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)

  const removeDeletionError = () => {
    setDeleteError('')
  }

  const deleteColumnByCategory = async ({
    category,
    columnId,
    organization,
  }) => {
    setIsDeleting(true)
    try {
      await api.deleteColumnByCategory({
        category,
        organizationId: organization.id,
        columnId,
        token,
      })

      setIsDeleting(false)
      return true
    } catch (error) {
      if (isAPIError(error)) {
        // localStorage.setItem('clusteringSaveError', JSON.stringify({ ...error.response.data }))
        setDeleteError(error.response.data.detail.type)
      } else {
        setDeleteError('Failed saving clustering column')
      }

      setIsDeleting(false)
      return false
    }
  }

  return {
    deleteColumnByCategory,
    deleteError,
    isDeleting,
    removeDeletionError,
  }
}

export default useDeleteColumnByCategory
