import React from "react"
import ReactDOM from "react-dom"
import "./Modal.scss"

const Modal = ({
    show,
    onHide,
    children,
    className,
    styles = {},
  }: {
    show: boolean
    onHide: () => void
    children: React.ReactNode,
    className: string,
    styles?: React.CSSProperties
  }) => {
  
    return ReactDOM.createPortal(
      <>
        <div className={`overlay ${show ? 'show' : ''}`} onClick={onHide}></div>
        <div className={`modal ${show ? 'pop' : ''} ${className || ""}`} style={styles}>
          <button onClick={onHide} className="close-button">
            &times;
          </button>
          {children}
        </div>
      </>,
      document.getElementById('modal-root')!
    )
  }
  
  export default React.memo(Modal)