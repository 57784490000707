import { useReportsCtx } from 'containers/reports/ReportsContext'
import { useSidebarCtx } from 'containers/SidebarContext'
import 'css/Topbar.scss'
import mayaLogo from 'images/maya-logo.png'
import { useState } from 'react'

import { useHistory } from 'react-router-dom'
import AccountMenu from './AccountMenu'
import Breadcrumb from './Breadcrumb'
import OrganizationPicker from './OrganizationPicker'
import NotificationBell from 'components/Notifications/NotificationBell'

const Topbar = ({
  organizations,
  isLoadingOrganization,
  organizationSlug,
  currentOrganization,
  user,
  page,
  clusterBreadcrumb,
}) => {
  const [accountMenuOpen, setAccountMenuOpen] = useState(false)
  const { isSidebarCollapsed, collapseSidebar } = useSidebarCtx()
  const { reportPages, report } = useReportsCtx()

  const history = useHistory()

  const handleClickLogo = () => {
    history.push('/')
  }

  const handleCollapseSidebar = () => {
    collapseSidebar((prev) => !prev)

    if (isSidebarCollapsed) {
      localStorage.setItem('isSideBarCollapsed', 'false')
    } else {
      localStorage.setItem('isSideBarCollapsed', 'true')
    }
  }

  const handleOpenAccountMenu = (e) => {
    e.stopPropagation()
    if (!accountMenuOpen) {
      document.addEventListener('click', handleCloseAll)
    }
    setAccountMenuOpen((prev) => !prev)
  }

  const handleCloseAll = () => {
    document.removeEventListener('click', handleCloseAll)
    setAccountMenuOpen(false)
  }

  return (
    <div className="top-bar">
      <div className="left-side">
        <div
          className={`menu ${!isSidebarCollapsed ? 'open' : ''}`}
          onClick={handleCollapseSidebar}
        >
          <span className="material-icons-outlined">menu</span>
        </div>
        <div className="header">
          <div onClick={handleClickLogo} className="logo">
            <img src={mayaLogo} alt="Maya" />
          </div>
        </div>
      </div>
      <div className="middle">
        <Breadcrumb
          reportPages={reportPages}
          clusterBreadcrumb={clusterBreadcrumb}
          page={page}
          report={report}
        />
      </div>
      <div className="right-side">
        {/* TODO: remove this once ready for production */}
        { user && user.is_staff && <NotificationBell currentOrganization={currentOrganization}/>}
        <OrganizationPicker
          organizations={organizations}
          isLoadingOrganization={isLoadingOrganization}
          organizationSlug={organizationSlug}
          currentOrganization={currentOrganization}
          page={page}
        />
        <div
          className={`account-menu ${accountMenuOpen ? 'open' : ''}`}
          onClick={handleOpenAccountMenu}
        >
          <span className="material-icons-outlined">account_circle</span>
          {accountMenuOpen ? (
            <AccountMenu currentOrganization={currentOrganization} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Topbar
