import { useSelector } from 'react-redux'

import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useMutator from 'containers/mutator'

const useDeleteManualExpense = (organizationId: number | null) => {
  const token = useSelector(selectToken)
  const saveExpense = async (uuid: string) => {
    if (organizationId) {
      return api.deleteManualExpense({
        organizationId: organizationId,
        token,
        uuid,
      })
    } else {
      throw new Error('organization not loaded yet')
    }
  }
  return useMutator(saveExpense)
}

export default useDeleteManualExpense
