import React, { useEffect, useState } from 'react'
import '../pages/Dashboard/Dashboard.scss'

const DEMOS = {
    'Spend': "https://app.powerbi.com/view?r=eyJrIjoiNTE5ZDA5OGQtZmVjNy00NGI5LWJhNDYtNDI0Y2Y2ZDQxOWRjIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    'SEO': "https://app.powerbi.com/view?r=eyJrIjoiY2YzODQ5M2UtZThiOC00NDc3LWIzNWEtNTdlNjhlMjFiNTU0IiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    'SEM': "https://app.powerbi.com/view?r=eyJrIjoiY2I0OTVkN2EtODgxNy00YzM5LThkNDQtZjI2YzczNzQzMTI5IiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    "Conversions": "https://app.powerbi.com/view?r=eyJrIjoiMzEzMmZjMzgtYjJjMC00OTc2LTkwM2YtNDQ0YjU2NTIxNjliIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    'Competition': "https://app.powerbi.com/view?r=eyJrIjoiMzIxY2FmMTQtNmUwYi00N2UwLTk4ZmMtZTQ0OWZmMjVlZDBiIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9"
}

const Demo = ({reportSlug}) => {
    // check orgpicker to default to seo
  return (
    <div className="demo">
      <iframe src={DEMOS[reportSlug  || 'Spend']} frameborder="0" scrolling="no" allowfullscreen="true" width="100%" height="100%"></iframe>
    </div>
  )
}

export default React.memo(Demo)
