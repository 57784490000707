import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api, { isAPIError } from '../api'
import useDataLoader from '../dataLoader'

const useGetEmbedStatus = (organizationId: number) => {
  const token = useSelector(selectToken)
  const getEmbedStatus = async () => {
    try {
      const data = (await api.getEmbedStatus({ token, organizationId }))
      return data
    } catch (error) {
      if (
        isAPIError(error) &&
        error.response.data.detail.type === 'workspace_not_setup'
      )
        console.error('error loading embed status', error)
    }
  }

  return useDataLoader(getEmbedStatus)
}

export default useGetEmbedStatus
