import React, {
  ChangeEvent,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'

import ScreenLoading from 'components/ScreenLoading'

import Divider from 'components/Divider'
import useManualUpload from 'containers/manualUpload/useManualUpload'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import './FileUpload.scss'
import {
  ProcessResult,
  ReducerAction,
  ReducerState,
} from './FileUploadTypes'
import Modal from 'components/Modal'
import { getCSVColumns } from './utils'
import UploadZone from 'components/UploadZone'

const ACTIONS = {
  ADD_FILE: 'add-file',
  CHECK_FILE_IS_CSV: 'check-file-is-csv',
  FIND_COLUMN_ON_FILE: 'find-column-on-file',
  PROCESS_FILE: 'processing-file',
  UPLOAD_FILE: 'upload-file',
  FAILED: 'failed',
  DONE: 'done',
}

const reducer: Reducer<ReducerState, ReducerAction> = (
  state,
  action
): ReducerState => {
  switch (action.type) {
    case ACTIONS.ADD_FILE:
      state.file = action.payload.files[0]
      return {
        ...state,
        fileTypeError: false,
        processingFile: null,
        uploadDone: null,
      }
    case ACTIONS.CHECK_FILE_IS_CSV:
      if (state.file instanceof File)
        return {
          ...state,
          fileTypeError:
            state.file.type !== 'text/csv' &&
            state.file.type !== 'application/vnd.ms-excel',
        }
      return state
    case ACTIONS.PROCESS_FILE:
      return { ...state, processingFile: action.payload.processing }
    case ACTIONS.UPLOAD_FILE:
      return { ...state, uploadingFile: action.payload.uploading }
    case ACTIONS.FAILED:
      return {
        file: null,
        processingFile: null,
        fileTypeError: false,
        uploadingFile: false,
        uploadDone: false,
      }
    case ACTIONS.DONE:
      return {
        file: null,
        processingFile: null,
        fileTypeError: false,
        uploadingFile: false,
        uploadDone: true,
      }
    default:
      return state
  }
}

const UploadFile = ({ columnNames, category }: {columnNames: string[], category: string}) => {
  const { currentOrganization } = useContext(OrganizationsContext)
  const [res, upload, , uploadError] = useManualUpload(currentOrganization)
  const [processResult, setProcessResult] = useState<ProcessResult | null>(null)
  const [state, dispatch] = useReducer(reducer, {
    file: null,
    processingFile: null,
    fileTypeError: false,
    uploadingFile: false,
    uploadDone: null,
  })

  const processData = async () => {
    const res = await getCSVColumns(state.file as File)
    const data = res.columns;

    if (data === undefined) {
      dispatch({ type: ACTIONS.FAILED })
      const result = {
        success: false,
        type: 'File did not contain any columns',
        columns: columnNames
      }
      setProcessResult(result)
      return
    }

    let matches = 0
    columnNames.forEach((column) => {
      const pattern = new RegExp(column, 'gi')
      const match = data.join(',').match(pattern)

      if (match !== null) {
        matches++
      }
    })

    if (matches >= columnNames.length) {
      const result = {
        success: true,
        type: `Successfuly found ${columnNames.join(', ')} column`,
        columns: columnNames
      }
      setProcessResult(result)
      dispatch({ type: ACTIONS.UPLOAD_FILE, payload: { uploading: true } })
    } else {
      const result = {
        success: false,
        type: `Could not find ${columnNames.join(', ')} column`,
        columns: columnNames
      }
      setProcessResult(result)
      dispatch({ type: ACTIONS.FAILED })
    }
  }

  const uploadFile = async () => {
    await upload(state.file, category)

    dispatch({ type: ACTIONS.DONE })
    setProcessResult(null)
  }

  const handlePrepareFile = (e: ChangeEvent<HTMLInputElement>) => {
    setProcessResult(null)
    dispatch({ type: ACTIONS.ADD_FILE, payload: { files: e.target.files } })

    dispatch({ type: ACTIONS.CHECK_FILE_IS_CSV })

    dispatch({ type: ACTIONS.PROCESS_FILE, payload: { processing: true } })
  }

  useEffect(() => {
    if (
      state.file instanceof File &&
      state.processingFile === true &&
      state.fileTypeError === false
    ) {
      processData()
    }

    return () => {
      dispatch({ type: ACTIONS.PROCESS_FILE, payload: { processing: false } })
    }
  }, [state.processingFile, state.file])

  useEffect(() => {
    if (
      state.uploadingFile &&
      processResult !== null &&
      processResult.success
    ) {
      uploadFile()
    }
  }, [state.uploadingFile, processResult])

  return (
    <div className="upload-zone">
      <UploadZone
        fileType='csv'
        uploadFile={handlePrepareFile}
        uploadingFile={state.uploadingFile}
        clear={false}
      />
      {state.processingFile === true ? <ScreenLoading /> : <></>}
      {processResult && state.fileTypeError === false ? (
        <p style={{ color: processResult.success ? 'green' : 'red' }}>
          {' '}
          {processResult.type}{' '}
        </p>
      ) : (
        ''
      )}
      {state.fileTypeError ? (
        <p> Not a valid .csv file. Please select a valid .csv file</p>
      ) : (
        ''
      )}
      {state.uploadDone ? (
        res !== null && res.ok && uploadError.length <= 0 ? (
          <p style={{ color: 'green' }}>File uploaded succesfully</p>
        ) : (
          <p style={{ color: 'red' }}>Error: {uploadError}</p>
        )
      ) : (
        <></>
      )}
    </div>
  )
}

const Guide = ({ columnNames, exampleName }) => {
  return (
    <div className={`upload-guide`}>
      <h1>Upload guide</h1>
      <ol>
        <li>
          The file should have a <code>.csv</code> file extension
        </li>
        <li>
          The file should contain {columnNames.length} column
          {columnNames.length === 1 ? '' : 's'}:{' '}
          {columnNames.map((column) => (
            <code key={column}>{column}</code>
          ))}
        </li>
        <li>
          Download this{' '}
          <a
            href={`https://old.mayainsights.com/static/files/${exampleName}.csv`}
            download
          >
            example csv file
            <span className="material-icons-outlined non-selectable">
              download
            </span>
          </a>{' '}
          for reference
        </li>
      </ol>
    </div>
  )
}

const FileModal = ({
  show,
  onHide,
  category,
}: {
  show: boolean
  onHide: () => void
  category: string
}) => {
  const COLUMN_REQUIREMENTS = {
    manual_search_term: ['query', 'category'],
    manual_users: ['user_id', 'created_at'],
    manual_orders: ['order_id', 'user_id', 'created_at'],
    manual_ads_performance: ['date', 'account_id', 'campaign_id'],
    manual_ads_conversions: [
      'date',
      'account_id',
      'campaign_id',
      'conversion_name',
    ],
  }

  return (
      <Modal
        show={show}
        onHide={onHide}
         styles={
          {
            display: "flex",
            flexDirection: "column"
          }
        }
      >
        <Guide
          columnNames={COLUMN_REQUIREMENTS[category] || []}
          exampleName={category}
        />
        <Divider spaceY={20} />
        <UploadFile
          columnNames={COLUMN_REQUIREMENTS[category] || []}
          category={category}
        />

      </Modal>
  )
}

export default React.memo(FileModal)
