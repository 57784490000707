import useMicrosoft from 'containers/authentication/useMicrosoft'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const MonaStart = () => {
    const subscriptionUri = useLocation()
    .search
    .replace('?_sub=', '')
    const {getAuthUri} = useMicrosoft()

    const handleSignInWithMicrosoft = async () => {
      const response = await getAuthUri({isMona: true, monaSubscription: subscriptionUri!})
      if (response.auth_uri) {
        window.location.href = response.auth_uri
      } else {
        // TODO: handle error
      }
    }

    useEffect(() => {
      handleSignInWithMicrosoft()
    }, [])
  
  return (
    <></>
  )
}

export default React.memo(MonaStart)

