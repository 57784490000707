import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: null,
    token: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      Sentry.setUser({ id: action.payload.id, email: action.payload.email })
    },
    setToken: (state, action) => {
      state.token = action.payload
      if (action.payload) {
        Sentry.setUser({ id: action.payload.id })
      }
    },
  },
})

export const { setUser, setToken } = authenticationSlice.actions

export default authenticationSlice.reducer

export const selectUser = (state) => state.authentication.user
export const selectToken = (state) => state.authentication.token
