import api from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import useDataLoader from 'containers/dataLoader'
import { useSelector } from 'react-redux'
import { IOrganization } from 'types'

const useGetSharedUsers = (organization: IOrganization) => {
  const token = useSelector(selectToken)
  const getSharedUsers = () => {
    return api.getSharedUsers(token, organization.id)
  }

  return useDataLoader(getSharedUsers)
}

export default useGetSharedUsers
