import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function useRedirect(organizationSlug, query, persistParams = true) {
  const history = useHistory()

  useEffect(() => {
    const redirectTo = query.get('redirect_to')

    if (redirectTo) {
      let redirectUrl = `/organization/${organizationSlug}/${redirectTo}`

      if (persistParams) {
        const existingParams = new URLSearchParams(window.location.search)
        // remove redirect_to param as its use has been fulfilled
        existingParams.delete('redirect_to')
        existingParams.forEach((value, key) => {
          existingParams.set(key, value)
        })
        redirectUrl += '?' + existingParams.toString()
      }

      history.push(redirectUrl)
    }
  }, [query, history])
}

export default useRedirect
