import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

const useDeleteInvite = () => {
  const token = useSelector(selectToken)

  const deleteInvite = async ({ organization, invite }) => {
    const organizationId = organization.id
    const invitationId = invite.uuid

    try {
      await api.deleteInvite({ organizationId, invitationId, token })
      return true
    } catch {
      return false
    }
  }

  return { deleteInvite }
}

export default useDeleteInvite
