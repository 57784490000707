import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import $ from 'jquery'

import 'css/PopUp.scss'

interface IPopUpMessage {
  message: string
  onClose?: () => void
  time?: number
  type?: string
  details?: string
  styles?: any
}

const PopUpMessage = ({
  message,
  onClose,
  time,
  type,
  details,
  styles
}: IPopUpMessage) => {
  const [showPopUp, setShowPopUp] = useState(true)
  const [detailsOpen, setDetailsOpen] = useState(false)

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setShowPopUp(false)
  }

  const outsideClickListener = (event) => {
    const target = $(event.target)
    if (!target.closest('#pop-up').length) {
      handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('click', outsideClickListener)

    if (time) {
      setTimeout(() => {
        handleClose()
      }, time)
    }
    return () => {
      document.removeEventListener('click', outsideClickListener)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <AnimatePresence>
      {showPopUp && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          exit={{ opacity: 0 }}
          className={`pop-up ${type === 'error' ? 'error' : ''}`}
          style={{...styles}}
          id="pop-up"
        >
          <span onClick={handleClose} className="material-icons-outlined x">
            close
          </span>
          {type === 'error' ? (
            <>
              <h2>
                <span className="material-icons-outlined">error_outline</span>
                Something went wrong
              </h2>
              <span className="text">{message}</span>
              {details && (
                <>
                  <button
                    onClick={() => setDetailsOpen(!detailsOpen)}
                    className="link"
                  >
                    {detailsOpen ? 'Hide' : 'More'} details
                  </button>
                  {detailsOpen && (
                    <span className="details">
                      <pre>{details}</pre>
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <span className="material-icons check">check_circle</span>
              <span className="text">{message}</span>
            </>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default React.memo(PopUpMessage)
