import React, { useState } from 'react'
import './Options.scss'

const Option = ({ title, icon, onClick }) => {
  return (
    <div
      className="option"
      onClick={(e) => {
        onClick()
      }}
    >
      <span className={`viewmode-switch material-icons-outlined ${icon}`}>
        {icon}
      </span>{' '}
      {title}
    </div>
  )
}

interface IOptionProps {
  options: IOption[]
  open?: boolean
  disabled?: boolean
}

interface IOption {
  uuid: string
  title: string
  icon: string
  onClick: () => void
}

const Options: React.FC<IOptionProps> = ({
  options,
  open,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(open || false)

  const handleOpenOptions = (e) => {
    e.stopPropagation()
    if (e.target === e.currentTarget) {
      if (!isOpen) {
        document.addEventListener('click', handleCloseAll)
      }
    }
    setIsOpen((prev) => !prev)
  }

  const handleCloseAll = () => {
    document.removeEventListener('click', handleCloseAll)
    setIsOpen(false)
  }

  return (
    <div className={`options-container ${disabled ? 'disabled' : ''}`}>
      <div className="options-controls">
        <span
          className="material-icons-outlined"
          onClick={(e) => disabled || handleOpenOptions(e)}
        >
          more_vert
        </span>
        {isOpen && (
          <div className="controls-menu" onClick={handleCloseAll}>
            {options &&
              options.map((option) => <Option key={option.uuid} {...option} />)}
          </div>
        )}
      </div>
    </div>
  )
}

export default Options
