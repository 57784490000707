import { useCallback, useContext, useEffect } from 'react'

import OrganizationsContext from 'containers/organizations/organizationsContext'
import InputField from 'components/InputField'
import useGetGA4Email from 'containers/GA4/useGetGA4Email'

const GA4Integration = () => {
  const { currentOrganization } = useContext(OrganizationsContext)
  const [ga4Email, fetchGA4Email] = useGetGA4Email(currentOrganization.id)

  const getData = useCallback(async () => {
    if (currentOrganization.id) {
        fetchGA4Email()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization.id])

  const handleCopyAPIKey = (e) => {
    e.preventDefault()
    if (ga4Email !== null && ga4Email !== undefined && ga4Email[0] !== undefined) {
      navigator.clipboard.writeText(ga4Email[0])
    }
  }

  useEffect(() => {
    getData()
  }, [currentOrganization.id, getData])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <InputField
          label="GA4 Email"
          value={ga4Email && ga4Email[0] ? ga4Email[0] : 'ga4events@bi-analytics-253109.iam.gserviceaccount.com'}
          style={{
            width:
            ga4Email && ga4Email[0]
                ? ga4Email[0].length * 9 + 'px'
                : '400px ',
            maxWidth: '400px',
          }}
          disabled={true}
        />
        <button
          onClick={handleCopyAPIKey}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <span className="material-icons-outlined saved-icon non-selectable">
            copy
          </span>
        </button>
      </div>
    </div>
  )
}

export default GA4Integration
