import Feature from 'components/Feature'
import UpgradeButton from 'components/UpgradeButton'
import { useEffect, useState } from 'react'
import './DevelopersTab.scss'
import ShareDatasetForm from './ShareDataset/ShareDatasetForm'
import ApiKeyView from './DeveloperKeys/ApiKeyView'
import NotificationTokenView from './DeveloperKeys/NotificationTokenView'
import { useHistory, useParams } from 'react-router-dom'
import GA4Integration from './GA4/GA4Integration'

const DevelopersTab = ({ organizationSlug }) => {
  const [selectedOption, setSelectedOption] = useState("shared-dataset")
  const category = useParams<{categorySlug: string}>().categorySlug
  const history = useHistory()

  useEffect(() => {
    setSelectedOption(category || 'shared-dataset')
  }, [category])

  const OPTIONS = {
    'shared-dataset': {
      name: 'Shared Dataset',
      slug: 'shared-dataset',
      component: (
        <Feature
          feature={'has_database_access'}
          switchComponent={
            <UpgradeButton>Upgrade plan for dataset access</UpgradeButton>
          }
        >
          <ShareDatasetForm />
        </Feature>
      ),
    },
    'api-key': {
      name: 'API Key',
      slug: 'api-key',
      component: <ApiKeyView />,
    },
    'notification-token': {
      name: 'Notification Token',
      slug: 'notification-token',
      component: <NotificationTokenView />,
    },
    "ga4": {
      name: "Google Analytics 4",
      slug: "ga4",
      component: <GA4Integration/>
    }
  }

  const handleChangeTab = (_,  slug) => {
    setSelectedOption(slug)
    history.push(
      `/organization/${organizationSlug}/settings/developers/${slug}`
    )
  }

  return (
    <>
      <div
        className="pane-containers"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div className="options-pane">
          <h2>Developer settings</h2>
          <ul className="options">
            {Object.keys(OPTIONS).map((option, idx) => {
              return (
                <li
                  key={OPTIONS[option].name}
                onClick={() => handleChangeTab(idx, OPTIONS[option].slug)}
                  className={
                    OPTIONS[option].slug === selectedOption ? 'selected' : ''
                  }
                >
                  {OPTIONS[option].name}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="pane-body">
          {OPTIONS[selectedOption] !== null &&
            OPTIONS[selectedOption]?.component}
        </div>
      </div>
    </>
  )
}

export default DevelopersTab
