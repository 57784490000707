import api from '../api'
import useMutator from '../mutator'

import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import { IToken } from 'types'
import { AxiosResponse } from 'axios'

type ChangePassword = ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string
  newPassword: string
}) => Promise<AxiosResponse<any>>

const useChangePassword = () => {
  const token: IToken = useSelector(selectToken)

  const changePassword: ChangePassword = async ({
    currentPassword,
    newPassword,
  }) => {
    return await api.changePassword(token, currentPassword, newPassword)
  }

  return useMutator(changePassword)
}

export default useChangePassword
