import api from 'containers/api'
import useMutator from 'containers/mutator'
import { EmailDomainExists } from 'types'

const useCheckEmailDomain = () => {
  const checkEmailDomain = async (
    payload: string
  ): Promise<EmailDomainExists | null> => {
    const emailDomain = payload.split('.')[0].split('@')[1]
    if (emailDomain === undefined) {
      return null
    }
    return await api.checkEmailDomain(emailDomain)
  }
  return useMutator<string, EmailDomainExists>(checkEmailDomain)
}

export default useCheckEmailDomain
