import useQuery from 'containers/useQuery'
import { useEffect, useState } from 'react'
import mayaLogo from 'images/maya-logo.png'
import useLogout from 'containers/authentication/useLogout'
import { useHistory } from 'react-router-dom'
import ScreenLoading from 'components/ScreenLoading'
import useVerifyEmail from 'containers/authentication/useVerifyEmail'
import useRequestVerificationEmail from 'containers/authentication/useRequestVerificationEmail'
import './NotificationWindow.scss'

const VerifyEmail = () => {
  const logout = useLogout()
  const [, verifyEmail] = useVerifyEmail()
  const [, requestVerificationEmail] = useRequestVerificationEmail()
  const history = useHistory()
  const requestId = useQuery().get('request_id')
  const email = useQuery().get('email')?.replace(' ', '+')
  const [pendingSendVerification, setPendingSendVerification] = useState(false)
  const [emailStatus, setEmailStatus] = useState<string | null>(null)
  const [verificationError, setVerificationError] = useState<string | null>(
    null
  )

  const goToSignIn = () => {
    logout()
  }

  // send user to home and make him redirect to destinations
  const handleGoHome = () => {
    history.push({
      pathname: '/',
      state: {
        destinations: true,
      },
    })
  }

  const resendVerificationEmail = async () => {
    setPendingSendVerification(true)
    const response = await requestVerificationEmail(email!)
    if (response.success === true) {
      setEmailStatus('Verification email sent!')
      setTimeout(() => {
        setEmailStatus(null)
      }, 5000)
    }
    setPendingSendVerification(false)
  }

  useEffect(() => {
    if (requestId && email) {
      ;(async () => {
        const response = await verifyEmail(requestId, email)
        if (response?.success === true) {
          history.push({
            pathname: '/',
            state: {
              destinations: true,
            },
          })
        } else {
          const error_detail = response
          switch (error_detail) {
            case 'already_used':
              setVerificationError(
                'The verification has already been used. Please request a new verification email.'
              )
              break
            case 'does_not_exist':
              setVerificationError(
                'The verification link is invalid. Please sign in again.'
              )
              break
            case 'already_verified':
              setVerificationError('Your email has already been verified.')
              break
            default:
              setVerificationError(
                'An error occurred while verifying your email. Please try again later.'
              )
              break
          }
        }
      })()
    }
  }, [])

  if (requestId && email) {
    return (
      <>
        {verificationError !== null && (
          <p style={{ color: 'red' }}>{verificationError}</p>
        )}{' '}
        <button
          onClick={() => history.push(`/verify-email?email=${email}`)}
          className="submit-button"
        >
          Back
        </button>
      </>
    )
  }
  return (
    <>
      <header>
        <div className="logo">
          <img src={mayaLogo} alt="Maya" />
        </div>
      </header>
      <div className="alert-container">
        <div className="alert-body">
          <img src={mayaLogo} alt="Maya" />
          <h3>Please verify your email</h3>
          <h4>
            You're almost there! Follow the instructions on the verification
            email we have sent to <span className="email">{email}</span>!
          </h4>
          <p>
            The verification email expires after 24 hours. If the link doesn't
            work or you haven't received an email from us you can click the
            resend button below to have another email sent to you.
          </p>
          <a className="resend-link" onClick={resendVerificationEmail}>
            {emailStatus || "I haven't received a verification email. Resend"}
          </a>
          {pendingSendVerification && <ScreenLoading />}
          <div className="button-container">
            <button className="submit-button" onClick={goToSignIn}>
              Back
            </button>
            <button className="submit-button" onClick={handleGoHome}>
              I verified my E-mail
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyEmail
