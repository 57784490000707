import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api, { isHTTPError } from '../api'
import useDataLoader from '../dataLoader'

const useGetApiKeys = (organizationId: number | null) => {
  const token = useSelector(selectToken)
  const fetchApiKeys = async () => {
    if (organizationId) {
      try {
        return await api.getApiKeys({ token, organizationId })
      } catch (error) {
        if (isHTTPError(error) && error.response.status === 404) {
          return null
        } else if (isHTTPError(error) && 'detail' in error.response.data) {
          throw new Error(
            'Error loading api key: ' + error.response.data.detail.type
          )
        } else {
          throw error
        }
      }
    }
  }

  return useDataLoader(fetchApiKeys, [], [])
}

export default useGetApiKeys
