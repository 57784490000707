import { createContext, useContext, useEffect, useState } from 'react'
import { INotification, INotificationContext, NotificationStatus } from 'types'
import useNotifications from './useNotifications'
import { findLatestNotification } from 'utils/notificationUtils'

const NotificationCtx = createContext<INotificationContext>({
  notifications: null,
  latestNotification: null,
  notificationCursor: null,
})

export function NotificationProvider({ currentOrganization, children }) {
  const {
    notifications,
    notificationCursor,
    getFilteredNotifications,
    updateNotification,
    refreshNotifications,
    getNotificationCount,
    resetNotifications,
  } = useNotifications(currentOrganization.id)
  const [latestNotification, setLatestNotification] =
    useState<INotification | null>(null)
  const [resetTimeout, setResetTimeout] = useState(false)

  // this useEffect is used to create a setTimeout on mount that calls a function called checkForNewNotifications every 20 seconds
  useEffect(() => {
    if (notifications !== null && !latestNotification) {
      setLatestNotification(findLatestNotification(notifications))
    }

    const timerId = setTimeout(() => {
      checkForNewNotifications()
    }, 12000)

    return () => {
      clearTimeout(timerId) // clean up the timer on component unmount
    }
  }, [notifications, resetTimeout, currentOrganization])

  const checkForNewNotifications = async () => {
    console.log('Checking for new notifications...')
    if (notifications === null) {
      setResetTimeout(prev => !prev)
      return
    }

    if (latestNotification) {
      const newNotifications = await refreshNotifications({
        date: latestNotification.created_at,
      })
      if (newNotifications === null || newNotifications.length <= 0) {
        return
      }

      // set the latest notification
      const latest = findLatestNotification([...newNotifications], {
        status: NotificationStatus.Unread,
      })!
      setLatestNotification(latest)
    }
  }

  const nextNotifications = async () => {
    // only continue fetching if notificationCursor is != null
    if (notificationCursor === null) {
      return
    }

    getFilteredNotifications()
  }

  useEffect(() => {
    resetNotifications()
    nextNotifications()
  }, [currentOrganization])

  return (
    <NotificationCtx.Provider
      value={{
        notifications,
        notificationCursor: notificationCursor,
        latestNotification: latestNotification,
        updateNotification: updateNotification,
        refreshNotifications: refreshNotifications,
        nextNotifications: nextNotifications,
        getNotificationCount: getNotificationCount,
      }}
    >
      {children}
    </NotificationCtx.Provider>
  )
}

export const useNotificationContext = () => {
  return { ...useContext(NotificationCtx) }
}
