import React, { useState, useEffect, useRef } from 'react'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'
import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'
import { AnimatePresence, motion } from 'framer-motion'

import ScreenLoading from 'components/ScreenLoading'

import useGetWordGroups from 'containers/clustering/useGetWordGroups'
import useSaveWordGroups from 'containers/clustering/useSaveWordGroups'
import useConfirmation from 'containers/confirmation/useConfirmation'

import 'pages/Clusters/Campaigns/ClustersWordGroups.scss'

const components = {
  DropdownIndicator: null,
}

const Tag = ({ label, values, setValues }) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (value) => {
    setInputValue(value)
  }

  const handleChange = (value) => {
    setValues(value)
  }

  const createOption = (label) => ({
    label,
    value: uuidv4(),
  })

  const handleKeyDown = (event) => {
    if (!inputValue) {
      return
    }

    switch (event.key) {
      case 'Enter':
        if (_.some(values, ['label', inputValue])) {
          setInputValue('')
          break
        }

        setValues([...values, createOption(inputValue)])
        setInputValue('')
        event.preventDefault()
        break
      default:
        break
    }
  }

  const createOptions = (text) => {
    let pastedTags = []

    text.forEach((tag) => {
      if (!tag) {
        return
      }
      if (!/\S/.test(tag)) {
        return
      }
      pastedTags.push(createOption(tag))
    })

    return pastedTags
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const clipboardText = e.clipboardData.getData('text/plain').split(/\n|\t/)
    const uniqueTags = _.uniq(clipboardText)
    const newTags = uniqueTags.filter(
      (value) => !values.map((v) => v.label).includes(value)
    )

    setValues([...values, ...createOptions(newTags)])
  }

  const handleCopyTerms = (e) => {
    e.preventDefault()
    const terms = values.map((v) => v.label).join('\n')
    navigator.clipboard.writeText(terms)
  }

  return (
    <div onPaste={handlePaste}>
      <div className="header_container">
        <span>{label}</span>
        <button onClick={handleCopyTerms}>
          <span className="material-icons-outlined edit">copy</span>
        </button>
      </div>
      <CreatableSelect
        isMulti
        isClearable={false}
        components={components}
        menuIsOpen={false}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        value={values}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder="Add keywords..."
        styles={{
          control: (base) => ({
            ...base,
            border: '1px solid #dddcd7',
            boxShadow: 'none',
            '&:hover': {
              border: '1px solid #bababa',
            },
          }),
        }}
      />
    </div>
  )
}

const WordGroupOption = ({
  wordGroupsState,
  wordGroup,
  selectedWordGroupId,
  setSelectedWordGroupId,
  setEditing,
  setName,
  deleteWordGroup,
  hasError,
  setHasError,
}) => {
  const [hovered, setHovered] = useState(false)
  const [draftWordGroupName, setDraftWordGroupName] = useState(wordGroup.name)
  const [hasEditingError, setHasEditingError] = useState(false)

  const isWordGroupNameValid = (value) => {
    const wgLower = [
      ...wordGroupsState.map(
        (v) => v.id !== wordGroup.id && v.name.toLowerCase()
      ),
    ]
    if (wgLower.includes(value.toLowerCase())) {
      return false
    }
    return true
  }

  const handleEditingState = (e) => {
    updateWordGroupName(draftWordGroupName)
    if (isWordGroupNameValid(draftWordGroupName)) {
      setHasEditingError(false)
      e.preventDefault()
    } else {
      setHasEditingError(true)
    }
  }

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        handleEditingState(event)
        break
      default:
        break
    }
  }

  const handleEditWordGroupName = (e) => {
    if (!isWordGroupNameValid(e.target.value) && !hasEditingError) {
      setHasEditingError(true)
      if (!hasError) {
        setHasError(true)
      }
    }
    if (isWordGroupNameValid(e.target.value) && hasEditingError) {
      setHasEditingError(false)
      if (hasError) {
        setHasError(false)
      }
    }
    setDraftWordGroupName(e.target.value)
  }

  const updateWordGroupName = (wordGroupName) => {
    if (wordGroupName.length === 0) {
      const tempName = `Word group #${wordGroupsState.indexOf(wordGroup) - 1}`
      setName(tempName)
    } else {
      setName(wordGroupName)
    }
  }

  useEffect(() => {
    if (!hasEditingError) {
      setHasError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEditingError])

  useEffect(() => {
    if (hasEditingError) {
      isWordGroupNameValid(draftWordGroupName) && setHasEditingError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordGroupsState])

  return (
    <li
      className={`${wordGroup.id === selectedWordGroupId ? 'selected' : ''}`}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {wordGroup.editing ? (
        <input
          autoFocus
          onBlur={(e) => handleEditingState(e)}
          value={draftWordGroupName}
          onChange={handleEditWordGroupName}
          onKeyDown={handleKeyDown}
          className={hasEditingError ? 'error' : ''}
        />
      ) : (
        <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
          <span
            onClick={() => setSelectedWordGroupId(wordGroup.id)}
            className="name"
          >
            {draftWordGroupName || wordGroup.name}
          </span>
          {hasEditingError && (
            <span style={{ fontSize: '8px', color: 'red' }}>
              Duplicate Name
            </span>
          )}
        </div>
      )}
      {hovered && !wordGroup.deletion_protection && !wordGroup.editing && (
        <>
          <span
            onClick={() => setEditing(true)}
            className="material-icons-outlined edit"
          >
            edit
          </span>
          <span
            onClick={deleteWordGroup}
            className="material-icons-outlined delete"
          >
            delete
          </span>
        </>
      )}
    </li>
  )
}

const ClustersWordGroups = ({ organization, onChange, onSave }) => {
  const [wordGroupsState, setWordGroupsState] = useState()
  const [selectedWordGroupId, setSelectedWordGroupId] = useState()
  const [hasError, setHasError] = useState(false)
  const { isLoadingWordGroups, wordGroupsError, fetchWordGroups } =
    useGetWordGroups()
  const { isSavingWordGroups, saveWordGroups } = useSaveWordGroups()
  const { confirm } = useConfirmation()

  const [hasBeenSaved, setHasBeenSaved] = useState(true)

  const firstStateChange = useRef(true)

  const alphabeticallySort = (tags) => {
    return tags.sort((a, b) => {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
  }

  const getData = async () => {
    const tempWordGroups = await fetchWordGroups({ organization })
    setWordGroupsState(
      tempWordGroups.map((wordGroup) => ({
        id: wordGroup.id,
        name: wordGroup.name,
        deletion_protection: wordGroup.deletion_protection,
        has_references: wordGroup.has_references,
        terms: alphabeticallySort(
          wordGroup.terms.map((term) => ({ label: term, value: term }))
        ),
        excludeTerms: alphabeticallySort(
          wordGroup.excludeTerms.map((term) => ({ label: term, value: term }))
        ),
        editing: false,
      }))
    )
    setSelectedWordGroupId(tempWordGroups[0].id)
  }

  const updateWordGroupsState = (state) => {
    setWordGroupsState(state)
    onChange(state)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!wordGroupsState) {
      return
    }
    if (firstStateChange.current) {
      firstStateChange.current = false
      return
    }

    setHasBeenSaved(false)
  }, [wordGroupsState])

  if (wordGroupsError) {
    return <>error</>
  }
  if (isLoadingWordGroups) {
    return <ScreenLoading />
  }

  const selectedWordGroup = _.find(wordGroupsState, ['id', selectedWordGroupId])

  if (!wordGroupsState || !selectedWordGroup) {
    return <ScreenLoading />
  }

  const sortWordGroups = (wordGroups) => {
    const defaultGroups = wordGroups.filter(
      (group) => group.deletion_protection
    )
    let sortedGroups = wordGroups
      .filter((group) => !group.deletion_protection)
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    return [...defaultGroups, ...sortedGroups]
  }

  const setValues = (termsKey, newTerms) => {
    updateWordGroupsState(
      produce(wordGroupsState, (draftWordGroupsState) => {
        const index = _.findIndex(wordGroupsState, ['id', selectedWordGroupId])
        draftWordGroupsState[index][termsKey] = newTerms
      })
    )
  }

  const handleSave = async () => {
    if (hasError) return
    const formattedState = wordGroupsState.map((wordGroup, _index) => ({
      id: wordGroup.id,
      name: wordGroup.name,
      deletion_protection: wordGroup.deletion_protection,
      terms: wordGroup.terms.map((term) => term.label),
      excludeTerms: wordGroup.excludeTerms.map((term) => term.label),
    }))
    const saveResponse = await saveWordGroups({
      organization,
      state: formattedState,
    })

    if (saveResponse) {
      setHasBeenSaved(true)
      onSave()
    }
  }

  const handleAddWordGroup = () => {
    const newId = uuidv4()

    updateWordGroupsState(
      produce(wordGroupsState, (draftWordGroupsState) => {
        draftWordGroupsState.push({
          id: newId,
          name: '',
          deletion_protection: false,
          terms: [],
          excludeTerms: [],
          editing: true,
          has_references: false,
        })
      })
    )

    setSelectedWordGroupId(newId)
  }

  const setEditing = (id, value) => {
    const index = _.findIndex(wordGroupsState, ['id', id])
    setWordGroupsState(
      produce(wordGroupsState, (draftWordGroupsState) => {
        draftWordGroupsState[index].editing = value
      })
    )
  }

  const setWordGroupName = (id, value) => {
    const index = _.findIndex(wordGroupsState, ['id', id])
    updateWordGroupsState(
      produce(wordGroupsState, (draftWordGroupsState) => {
        draftWordGroupsState[index].name = value
        draftWordGroupsState[index].editing = false
      })
    )
  }

  const handleDeleteWordGroup = async (id) => {
    const deleteIndex = _.findIndex(wordGroupsState, ['id', id])

    const wg = _.find(wordGroupsState, ['id', id])
    if (
      !wg.has_references ||
      (await confirm(
        `References to this wordgroup will be deleted. Are you sure you want to delete it?`
      ))
    ) {
      setSelectedWordGroupId(wordGroupsState[deleteIndex - 1].id)
      updateWordGroupsState(
        produce(wordGroupsState, (draftWordGroupsState) => {
          _.remove(draftWordGroupsState, ['id', id])
        })
      )
    }
  }

  return (
    <>
      <div className="word-groups">
        <div className="names">
          <div className="header">
            <span className="group-name">Group Name</span>
            <button onClick={handleAddWordGroup} className="submit-button">
              <span className="enlarge">+</span> Add
            </button>
          </div>
          <ul>
            {sortWordGroups([...wordGroupsState]).map((wordGroup) => (
              <WordGroupOption
                key={wordGroup.id}
                wordGroup={wordGroup}
                wordGroupsState={wordGroupsState}
                selectedWordGroupId={selectedWordGroupId}
                setSelectedWordGroupId={setSelectedWordGroupId}
                setEditing={(value) => setEditing(wordGroup.id, value)}
                setName={(value) => setWordGroupName(wordGroup.id, value)}
                deleteWordGroup={() => handleDeleteWordGroup(wordGroup.id)}
                hasError={hasError}
                setHasError={setHasError}
              />
            ))}
          </ul>
        </div>
        <div className="keywords">
          <div className="included">
            <Tag
              label="Included"
              values={selectedWordGroup.terms}
              setValues={(newTerms) => setValues('terms', newTerms)}
            />
          </div>
          <div className="excluded">
            <Tag
              label="Excluded"
              values={selectedWordGroup.excludeTerms}
              setValues={(newTerms) => setValues('excludeTerms', newTerms)}
            />
          </div>
        </div>
      </div>
      <div className="save-bar">
        <div className="save-button">
          {isSavingWordGroups ? (
            <ScreenLoading />
          ) : (
            <>
              <AnimatePresence>
                {hasBeenSaved && (
                  <motion.span
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="material-icons-outlined saved-icon non-selectable"
                  >
                    task_alt
                  </motion.span>
                )}
              </AnimatePresence>
              <button
                onClick={handleSave}
                className={`submit-button ${hasError ? 'disabled' : ''}`}
                disabled={hasError}
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default React.memo(ClustersWordGroups)
