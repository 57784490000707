import { configureStore } from '@reduxjs/toolkit'
import authenticationReducer from './authentication/authenticationSlice'
import organizationsReducer from './organizations/organizationsSlice'
import servicesReducer from './services/servicesSlice'

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    organizations: organizationsReducer,
    services: servicesReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
