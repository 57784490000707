export default function Divider({ spaceY }) {
  const marginY = spaceY * 0.1
  return (
    <div
      className="divider"
      style={{
        background: '#ccc',
        width: '100%',
        height: '1px',
        borderRadius: '2px',
        margin: `${marginY}rem 0`,
      }}
    ></div>
  )
}
