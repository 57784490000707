import React from 'react'
import { motion } from 'framer-motion'

import './StepsProgress.scss'

const steps = ['Account info', 'Company info', 'Legal Terms & Conditions']

const StepsProgress = ({ currentStep, handleClickStep }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="steps-progress non-selectable"
    >
      {steps.map((step, stepIndex) => (
        <React.Fragment key={stepIndex}>
          <div
            key={stepIndex}
            className="step"
            onClick={() => handleClickStep(stepIndex + 1)}
          >
            <span className="material-icons gray steps-progress">
              check_circle
            </span>
            <div
              className={`step-title ${
                stepIndex + 1 > currentStep ? 'gray' : ''
              }`}
            >
              {step}
            </div>
          </div>
          {stepIndex < steps.length - 1 ? (
            <span className="line"></span>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
      <motion.div
        initial={{ width: 0 }}
        animate={{
          width: `${currentStep === 1 ? 8 : currentStep === 2 ? 54 : 100}%`,
        }}
        transition={{ type: 'tween', duration: 1, ease: 'easeInOut' }}
        className="steps-progress overlay"
      >
        {steps.map((step, stepIndex) => (
          <React.Fragment key={stepIndex}>
            <div
              key={stepIndex}
              className="step"
              onClick={() => handleClickStep(stepIndex + 1)}
            >
              <span className="material-icons green steps-progress">
                check_circle
              </span>
            </div>
            {stepIndex < steps.length - 1 ? (
              <span className="line"></span>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}
      </motion.div>
    </motion.div>
  )
}

export default React.memo(StepsProgress)
