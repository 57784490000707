import React, { useContext, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'

import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetCountries from 'containers/countries/useGetCountries'
import useBillingDetails from 'containers/billing/useBillingDetails'
import InputField from 'components/InputField'
import DropDownField from 'components/DropDownField'
import { IBillingDetails } from 'types'
import {
  useValidator,
  maxLength,
  matchesRegex,
  isRequired,
  conditionalRule,
} from 'containers/formValidator'
import useQuery from 'containers/useQuery'

import './Details.scss'
import {Redirect} from 'components/Redirect'
import useRedirect from 'containers/useRedirect'

const BillingDetails = () => {
  const history = useHistory()
  const query = useQuery()
  const [autoCheckout, setAutoCheckout] = useState(false)
  const [hideBillingForm, setHideBillingForm] = useState(true)

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  const {
    billingDetails,
    dispatch,
    loadingBillingDetails,
    getBillingDetails,
    saveBillingDetails,
    isSaved,
    savingBillingDetails,
  } = useBillingDetails(currentOrganization.id)
  const { countries, getCountries, loadingCountries } = useGetCountries()

  const [formError, setFormError] = useState('')

  const [
    validateForm,
    getFormFieldError,
    _hasFormErrors,
    formErrors,
    removeFormError,
  ] = useValidator<IBillingDetails>({
    taxId: [
      maxLength(15, 'VAT number too long'),
      matchesRegex(
        /^([a-zA-Z]{2}\d+)?$/,
        'Invalid VAT number: Must be two characters followed by numbers'
      ),
    ],
    addressLine1: [isRequired()],
    city: [isRequired()],
    postalCode: [isRequired()],
    taxOffice: [
      conditionalRule(
        (b: IBillingDetails) => b.countryCode === 'gr',
        isRequired()
      ),
    ],
  })

  const getData = async () => {
    await Promise.all([getBillingDetails(), getCountries()])
  }

  useEffect(() => {
    if (!isLoadingOrganization) {
      getData()
    }
    if (query.get('auto_checkout') === '1') {
      setAutoCheckout(true)
    }
    // eslint-disable-next-line
  }, [isLoadingOrganization, currentOrganization.id])

  const queryCheckoutError = query.get('checkout_error')

  useEffect(() => {
    if (queryCheckoutError === 'unrecognized_location') {
      setFormError(
        'Unrecognized location. Please update your location or contact support.'
      )
    } else if (queryCheckoutError === 'billing_details_missing') {
      setFormError(
        'Please specify billing details before proceeding to checkout.'
      )
    } else if (queryCheckoutError === 'redirect_contact') {
      window.location.href = 'https://www.mayainsights.com/get-in-touch/'
    } else if (queryCheckoutError === 'customer_deleted') {
      setFormError('Your account has been deleted. Please contact support.')
    } else if (queryCheckoutError) {
      setFormError(
        'There was a problem processing your request. Please try again or contact support.'
      )
    } else {
      setFormError('')
    }
  }, [queryCheckoutError])

  useEffect(() => {
    if (query.get('auto_checkout') === '1') {
      query.set('redirect_to', 'billing/checkout')
    } else if (hideBillingForm) {
      query.set('redirect_to', 'billing/products')
    }
  }, [autoCheckout, hideBillingForm])

  useRedirect(currentOrganization.slug, query, true)

  const handleSave = async () => {
    setFormError('')
    if (!validateForm(billingDetails)) {
      return false
    }
    await saveBillingDetails()
    if (query.get('auto_checkout') === '1') {
      setAutoCheckout(true)
    } else {
      history.push(`/organization/${currentOrganization.slug}/billing/details?checkout=1`)
    }
    return true
  }

  const handleCheckout = async () => {
    const valid = await handleSave()
    if (valid) {
      history.push(`/organization/${currentOrganization.slug}/billing/products`)
    }
  }

  function hasValidBillingDetails(obj) {
    if (obj.addressLine1.trim() !== "" &&
        obj.city.trim() !== "" &&
        obj.companyName.trim() !== "" &&
        obj.countryCode.trim() !== "" &&
        obj.postalCode.trim() !== "") {
      return true;
    } else {
      return false;
    }
  }

  if (query.get('auto_checkout') === '1' && hasValidBillingDetails(billingDetails)) {
    return <Redirect to={`/organization/${currentOrganization.slug}/billing/checkout`}/>
  }

  return (
    !hideBillingForm &&<div className="container-column clusters-campaigns billing-details-page">
      {query.get('checkout') === '1' ? (
        <>
          <h1>Subscription Order</h1>
          <h2>Billing Information</h2>
        </>
      ) : (
        <>
          <h1>Billing</h1>
          <h2 style={{ marginBottom: '20px' }}>Subscription</h2>
          <button
            className="submit-button"
            onClick={() =>
              history.push(
                `/organization/${currentOrganization.slug}/billing/portal`
              )
            }
          >
            Manage Subscription
          </button>
          <h2>Billing Information</h2>
        </>
      )}
      <span className="subtitle-text">
        Please fill in the following details about your company. These details
        will be present in the subscription invoices.
      </span>
      <div
        className={`billing-details-form ${
          loadingBillingDetails ? 'loading-details' : ''
        }`}
      >
        <InputField
          label="Company Name"
          type="text"
          setValue={(v) => dispatch({ type: 'companyNameChanged', payload: v })}
          value={billingDetails.companyName}
          required={true}
        />
        <DropDownField
          label="Country"
          value={billingDetails.countryCode}
          options={countries.map((country) => ({
            value: country.code,
            label: country.name,
          }))}
          onChange={(v) => dispatch({ type: 'countryCodeChanged', payload: v })}
          loadingOptions={loadingCountries}
        />
        <InputField
          label="Address Line 1"
          type="text"
          setValue={(v) =>
            dispatch({ type: 'addressLine1Changed', payload: v })
          }
          value={billingDetails.addressLine1}
          required={true}
          removeError={() => removeFormError('addressLine1')}
          error={getFormFieldError('addressLine1')}
        />
        <InputField
          label="Address Line 2"
          type="text"
          setValue={(v) =>
            dispatch({ type: 'addressLine2Changed', payload: v })
          }
          value={billingDetails.addressLine2}
        />
        <InputField
          label="City"
          type="text"
          setValue={(v) => dispatch({ type: 'cityChanged', payload: v })}
          value={billingDetails.city}
          required={true}
          removeError={() => removeFormError('city')}
          error={getFormFieldError('city')}
        />
        <InputField
          label="State / Region"
          type="text"
          setValue={(v) => dispatch({ type: 'stateChanged', payload: v })}
          value={billingDetails.state}
        />
        <InputField
          label="Zip / Postal Code"
          type="text"
          setValue={(v) => dispatch({ type: 'postalCodeChanged', payload: v })}
          value={billingDetails.postalCode}
          required={true}
          removeError={() => removeFormError('postalCode')}
          error={getFormFieldError('postalCode')}
        />
        <InputField
          label="VAT number (EU)"
          type="text"
          setValue={(v) => dispatch({ type: 'taxIdChanged', payload: v })}
          value={billingDetails.taxId}
          removeError={() => removeFormError('taxId')}
          error={getFormFieldError('taxId')}
        />
        <InputField
          label="Tax Office (ΔΟΥ)"
          type="text"
          setValue={(v) => dispatch({ type: 'taxOfficeChanged', payload: v })}
          value={billingDetails.taxOffice}
          error={getFormFieldError('taxOffice')}
          style={{
            display: billingDetails.countryCode === 'gr' ? 'flex' : 'none',
          }}
          removeError={() => removeFormError('taxOffice')}
        />
        <div className="actions">
          <button
            className={`submit-button save-button ${
              savingBillingDetails ? 'disabled-button' : ''
            }`}
            onClick={handleSave}
            disabled={savingBillingDetails}
          >
            Save
          </button>
          {query.get('checkout') === '1' && (
            <button
              className={`submit-button checkout-button ${
                savingBillingDetails ? 'disabled-button' : ''
              }`}
              onClick={handleCheckout}
              disabled={savingBillingDetails}
            >
              Proceed to payment
            </button>
          )}
          {isSaved && (
            <div className="saved-icon">
              <AnimatePresence>
                <motion.span
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="material-icons-outlined saved-icon non-selectable"
                >
                  task_alt
                </motion.span>
              </AnimatePresence>
            </div>
          )}
          {formError && <span className="form-error">{formError}</span>}
        </div>
      </div>
    </div>
  )
}

export default React.memo(BillingDetails)
