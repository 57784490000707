import { useSelector } from 'react-redux'
import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'
import useDataLoader from '../dataLoader'

const useNextETLTime = (organizationId: number | null) => {
  const token = useSelector(selectToken)
  const fetchNextTime = async () => {
    if (organizationId) {
      return await api.getNextETLTime({ token, organizationId })
    }
  }

  return useDataLoader(fetchNextTime)
}

export default useNextETLTime
