import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

const useDeleteCompetitorsReport = () => {
  const token = useSelector(selectToken)
  const [deleteReportError, setDeleteReportError] = useState('')

  const deleteReport = async ({ organization, reportId }) => {
    const organizationId = organization.id

    setDeleteReportError('')
    try {
      await api.deleteReport({ organizationId, reportId, token })
      return true
    } catch {
      setDeleteReportError('Could not delete the report.')
      return false
    }
  }

  return { deleteReport, deleteReportError }
}

export default useDeleteCompetitorsReport
