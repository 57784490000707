import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api, { isAPIError, ErrorDetail, APIError } from '../api'

const useAcceptInvite = () => {
  const token = useSelector(selectToken)
  const [inviteError, setInviteError] = useState<ErrorDetail | null>(null)
  const [inviteFailed, setInviteFailed] = useState(false)

  const acceptInvite = async ({ organizationSlug, inviteId }) => {
    setInviteError(null)
    setInviteFailed(false)
    try {
      await api.acceptInvite({ organizationSlug, inviteId, token })
      return true
    } catch (e) {
      if (isAPIError(e)) {
        if (e.response.data.detail.type === 'invitation_already_accepted') {
          return true
        }
      }
      setInviteError((e as APIError).response.data.detail)
      setInviteFailed(true)
      return false
    }
  }

  return { acceptInvite, inviteError, inviteFailed }
}

export default useAcceptInvite
