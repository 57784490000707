import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from 'containers/authentication/authenticationSlice'

import api from 'containers/api'

const useGetSqlByColumn = () => {
  const token = useSelector(selectToken)
  const [sql, setSql] = useState()
  const [loadingSql, setloadingSql] = useState(true)
  const [sqlError, setSqlError] = useState('')

  const getSqlByColumn = async ({
    category,
    columnId,
    organization,
    column,
  }) => {
    setloadingSql(true)
    setSqlError('')
    try {
      const responseSql = await api.getSqlByColumn({
        category,
        columnId,
        organizationId: organization.id,
        token,
        column,
      })
      setSql(responseSql.sql)
      setloadingSql(false)
    } catch (error) {
      setSqlError('Could not get the SQL preview')
      setloadingSql(false)
    }
  }

  return { sql, loadingSql, sqlError, getSqlByColumn }
}

export default useGetSqlByColumn
