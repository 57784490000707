import ScreenLoading from 'components/ScreenLoading'
import React, {
  Reducer,
  ReducerAction,
  ReducerState,
  useEffect,
  useReducer,
  useState,
} from 'react'
import './Destinations.scss'
import useDestinations from 'containers/destinations/useDestinations'
import {
  IDestination,
  IOrganization,
  DestinationStatus,
  DestinationLocation,
} from 'types'
import DestinationsGuide from './DestinationsGuide'
import UploadZone from 'components/UploadZone'
import { useHistory } from 'react-router-dom'
import { beautifyNotificationDate } from 'utils/notificationUtils'
import DropDownField from 'components/DropDownField'
import InputField from 'components/InputField'

type Props = {
  currentOrganization: IOrganization
}

const Destinations: React.FC<Props> = ({ currentOrganization }) => {
  const {
    destinations,
    loadingDestinations,
    destinationsError,
    organizationDestination,
    hasConfiguredDestination,
    canConfigureDestination,
    getAllDestinations,
    getDestination,
    getOrganizationDestination,
    checkOrganizationDestinationStatus,
    removeOrganizationDestination,
    createOrUpdateOrganizationDestination,
  } = useDestinations(currentOrganization.id)
  // state
  const [showGuide, setShowGuide] = useState(true)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [destinationPreview, setDestinationPreview] =
    useState<IDestination | null>(null)

  // Main state
  const [selectedDestination, setSelectedDestination] =
    useState<IDestination | null>(null)
  const [selectedAccount, setSelectedAccount] = useState(1)
  const [selectedLocation, setSelectedLocation] = useState('eu')
  const [credentials, setCredentials] = useState<any | null>(null)
  const [unmanagedDestinations, setUnmanagedDestinations] = useState<
    IDestination[]
  >([])
  const [managedDestinations, setManagedDestinations] = useState<
    IDestination[]
  >([])

  const history = useHistory()
  const handleClickContinue = () => {
    if (
      currentStep === 1 &&
      selectedDestination !== null &&
      managedDestinations
        .map((dest) => dest.id)
        .includes(selectedDestination?.id || 1)
    ) {
      // if the user chose a managed destination then we need to remove any existing unmanaged destinations if any
      removeOrganizationDestination().then(() => {
        history.push(`/organization/${currentOrganization.slug}/sources`)
      })
      return
    } else if (currentStep === 3) {
      createOrUpdateOrganizationDestination(
        selectedDestination!.id,
        destinations!.find((dest) => dest.id === selectedDestination!.id)!.name,
        credentials,
        selectedLocation
      )
        .then(() => {
          history.push(`/organization/${currentOrganization.slug}/sources`)
        })
        .catch((err) => alert(err))
      return
    }

    setCurrentStep((prev) => prev + 1)
  }

  const handleClickBack = () => {
    if (currentStep !== 1) setCurrentStep((prev) => prev - 1)
    else {
      setShowConfiguration(false)
      setShowGuide(false)
    }
  }

  const handleIsDisabled = () => {
    if (selectedDestination === null) {
      return true
    }

    if (currentStep > 1 && !credentials) {
      return true
    }

    if (currentStep > 2 && selectedLocation === null) {
      return true
    }

    return false
  }

  // fetch resources
  useEffect(() => {
    if (
      destinations?.length <= 0 ||
      destinations === null ||
      destinations === undefined
    ) {
      getAllDestinations()
      getOrganizationDestination()
      checkOrganizationDestinationStatus()
    }
  }, [currentOrganization.id, destinations])

  useEffect(() => {
    const getDestinationData = async () => {
      if (organizationDestination !== null) {
        const data = await getDestination(
          organizationDestination.destination_id
        )
        setDestinationPreview(data)
      }
    }

    getDestinationData()
  }, [organizationDestination])

  // sort destinations
  useEffect(() => {
    if (
      (destinations?.length > 0 && managedDestinations?.length === 0) ||
      unmanagedDestinations?.length === 0
    ) {
      const managed: IDestination[] = []
      const unamanaged: IDestination[] = []
      destinations.map((destination) => {
        if (destination.managed) {
          managed.push(destination)
          if (selectedDestination === null) {
            setSelectedDestination(destination)
          }
        } else {
          unamanaged.push(destination)
        }
      })
      setManagedDestinations(managed)
      setUnmanagedDestinations(unamanaged)
    }
  }, [destinations])

  useEffect(() => {
    setShowConfiguration(!hasConfiguredDestination)
  }, [hasConfiguredDestination])

  // if (!canConfigureDestination && !hasConfiguredDestination) {
  //   return <p>You cannot configure a destination</p>
  // }

  if (!currentOrganization || !currentOrganization?.id) {
    window.location.reload()
  }

  return (
    <div className="destination-wrapper">
      <DestinationsGuide
        show={showGuide}
        onHide={() => setShowGuide(false)}
        currentStep={currentStep}
        destination={selectedDestination || null}
        organizationId={currentOrganization.id}
      />
      <h2>Configure a Destination for your organization</h2>
      {destinations?.length > 0 ? (
        showConfiguration ? (
          <>
            <div className="navigation-header" onClick={handleClickBack}>
              <span className="material-icons back-arrow non-selectable">
                arrow_back
              </span>
              <p>Back</p>
            </div>
            {destinationsError && <p className="error">{destinationsError}</p>}
            <Steps
              selectedDestination={selectedDestination}
              setSelectedDestination={setSelectedDestination}
              managedDestinations={managedDestinations}
              unmanagedDestinations={unmanagedDestinations}
              loadingDestinations={loadingDestinations}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              setCredentials={setCredentials}
              currentStep={currentStep}
              setShowGuide={setShowGuide}
              showGuide={showGuide}
            />
            <button
              className={`submit-button ${
                handleIsDisabled() ? 'disabled' : ''
              }`}
              disabled={handleIsDisabled()}
              // className="submit-button"
              onClick={handleClickContinue}
            >
              {currentStep < 3 ? 'Continue' : 'Finish'}
            </button>
          </>
        ) : (
          <DestinationPreview
            canConfigureDestination={canConfigureDestination}
            destinationPreview={destinationPreview}
            organizationDestination={organizationDestination}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
            setShowConfiguration={setShowConfiguration}
          />
        )
      ) : (
        <>No destinations</>
      )}
    </div>
  )
}

const DestinationList = ({
  destinationCategory,
  destinations,
  loadingDestinations,
  selectedDestination,
  setSelectedDestination,
  showGuide,
  setShowGuide,
}) => {
  const handleOpenGuide = () => {
    if (!showGuide) {
      setShowGuide(true)
    }
  }

  return (
    <div className="destination-category">
      <div className="destination-category-info">
        <h2>{destinationCategory}</h2>
      </div>
      <div className="destinations">
        {destinations?.length > 0 ? (
          destinations.map((destination) => (
            <DestinationButton
              key={destination.id}
              onClick={handleOpenGuide}
              destination={destination}
              selectedDestination={selectedDestination}
              setSelectedDestination={setSelectedDestination}
            />
          ))
        ) : loadingDestinations ? (
          <ScreenLoading />
        ) : (
          <span className='subtitle-text'>0 <span className='success underline'>{destinationCategory}</span> destinations.</span>
        )}
      </div>
    </div>
  )
}

const DestinationButton = ({
  onClick,
  destination,
  selectedDestination,
  setSelectedDestination,
}) => {
  const handleClick = () => {
    onClick()
    setSelectedDestination(destination)
  }

  return (
    <div
      className={`destination-button ${
        selectedDestination?.id === destination.id ? 'selected' : ''
      }`}
      onClick={handleClick}
    >
      <div className="info">
        <img alt={destination.id} width="90" src={destination.logo}></img>
        <span className="name">{destination.name}</span>
        {destination.managed && <span className="name default">(Default)</span>}
      </div>
    </div>
  )
}

export default Destinations

const ACTIONS = {
  ADD_FIELD: 'add-field',
  UPDATE_FIELD: 'update-field',
  CHECK_FIELD_VALIDITY: 'check-field-validity',
  CLEAR_FIELDS: 'clear-fields',
}

const reducer: Reducer<ReducerState, ReducerAction> = (
  state,
  action
): ReducerState => {
  switch (action.type) {
    case ACTIONS.ADD_FIELD:
      state.fields[dashatize(action.payload.label, "_")] = {
        value: action.payload?.default || "",
        pattern: action.payload.pattern,
        help: action.payload.help,
      }
      return {
        ...state,
      }
    case ACTIONS.UPDATE_FIELD:
      if (!(action.payload.field in state.fields)) {
        return {
          ...state,
        }
      }
      state.fields[action.payload.field].value = action.payload.value
      return {
        ...state,
      }
    case ACTIONS.CLEAR_FIELDS:
      state = {
        fields: {},
        validatedCredentials: null,
        validityError: false,
      }
      return state
    case ACTIONS.CHECK_FIELD_VALIDITY:
      const validatedCredentials = {}
      const stateEntries = Object.entries(state.fields)
      for (const [key, value] of stateEntries) {
        const field = value as { [key: string]: string }
        if (field.value.match(field.pattern)) {
          validatedCredentials[key] = field.value
        }
      }
      if (stateEntries.length === Object.entries(validatedCredentials).length) {
        state.validatedCredentials = validatedCredentials
        state.validityError = false
      } else {
        state.validityError = true
      }
      return state
    default:
      return state
  }
}

const Steps = ({
  managedDestinations,
  unmanagedDestinations,
  loadingDestinations,
  selectedDestination,
  setSelectedDestination,
  selectedAccount,
  setSelectedAccount,
  selectedLocation,
  setSelectedLocation,
  setCredentials,
  currentStep,
  showGuide,
  setShowGuide,
}) => {
  const [clearFile, setClearFile] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [destinationLocations, setDestinationLocations] = useState<
    { value: string; label: string }[]
  >([])

  const enumToList = (enumObj) => {
    const list: { label: string; value: string }[] = []
    for (const key in enumObj) {
      if (isNaN(Number(enumObj[key]))) {
        const words = key.split('_')
        // .map(
        //   (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        // )
        const label = words.join(' ')
        list.push({ label, value: enumObj[key] })
      }
    }
    return list
  }

  useEffect(() => {
    setDestinationLocations(enumToList(DestinationLocation))
  }, [])

  useEffect(() => {
    if (!showGuide) {
      setShowGuide(true)
    }
  }, [currentStep])

  return (
    <>
      <Step1
        show={currentStep === 1}
        managedDestinations={managedDestinations}
        unmanagedDestinations={unmanagedDestinations}
        loadingDestinations={loadingDestinations}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
        showGuide={showGuide}
        setShowGuide={setShowGuide}
      />
      <Step2
        show={currentStep === 2}
        // credentialDispatch={credentialDispatch}
        // credentialState={credentialState}
        selectedDestination={selectedDestination}
        selectedAccount={selectedAccount}
        selectedFile={selectedFile}
        setSelectedAccount={setSelectedAccount}
        setSelectedFile={setSelectedFile}
        setCredentials={setCredentials}
        setShowGuide={setShowGuide}
        showGuide={showGuide}
        setClearFile={setClearFile}
        clearFile={clearFile}
      />
      <Step3
        show={currentStep === 3}
        destinationLocations={destinationLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
      />
    </>
  )
}

const Step1 = ({
  show,
  managedDestinations,
  unmanagedDestinations,
  loadingDestinations,
  selectedDestination,
  setSelectedDestination,
  showGuide,
  setShowGuide,
}) => {
  if (!show) return <></>
  return (
    <>
      <DestinationList
        destinations={managedDestinations}
        loadingDestinations={loadingDestinations}
        destinationCategory={'Managed by Maya'}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
        showGuide={showGuide}
        setShowGuide={setShowGuide}
      />
      <DestinationList
        destinations={unmanagedDestinations}
        loadingDestinations={loadingDestinations}
        destinationCategory={'On your premise'}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
        showGuide={showGuide}
        setShowGuide={setShowGuide}
      />
    </>
  )
}
const Step2 = ({
  show,
  selectedDestination,
  selectedAccount,
  selectedFile,
  setSelectedAccount,
  setSelectedFile,
  setCredentials,
  setShowGuide,
  showGuide,
  setClearFile,
  clearFile,
}) => {
  const [credentialState, credentialDispatch] = useReducer(reducer, {
    fields: {},
    validatedCredentials: null,
    validityError: false,
  })
  const [checkError, setCheckError] = useState(false)

  useEffect(() => {
    if (!credentialState.validatedCredentials) {
      setCredentials(null)
    }
  }, [credentialState.validatedCredentials])

  useEffect(() => {
    if (credentialState.validatedCredentials) {
      setCredentials(credentialState.validatedCredentials)
    }
  }, [credentialState.validatedCredentials])

  useEffect(() => {
    credentialDispatch({ type: ACTIONS.CLEAR_FIELDS })
    const form = selectedDestination?.form || []
    for (const field of form) {
      credentialDispatch({
        type: ACTIONS.ADD_FIELD,
        payload: {
          pattern: field.pattern,
          label: field.label,
          help: field.help,
          default: field?.default || null
        },
      })
    }
  }, [selectedDestination])

  const processUploadedFile = (e) => {
    if (e?.target?.files?.length > 0) {
      setSelectedFile(e.target.files[0])
    } else {
      return
    }

    const reader = new FileReader()
    reader.readAsText(e.target.files[0])
    reader.onload = () => {
      const contents = reader.result
      try {
        const jsonData = JSON.parse(contents as string)
        for (const [label, value] of Object.entries(jsonData)) {
          credentialDispatch({
            type: ACTIONS.UPDATE_FIELD,
            payload: { field: dashatize(label, "_"), value: value },
          })
          setCheckError((prev) => !prev)
        }
        credentialDispatch({ type: ACTIONS.CHECK_FIELD_VALIDITY })
      } catch (error) {
        console.error('Error parsing JSON:', error)
      }
    }
  }

  const handleOpenGuide = () => {
    if (!showGuide) {
      setShowGuide(true)
    }
  }

  const handleClearFile = (e) => {
    e.stopPropagation()
    setClearFile(true)
    setSelectedFile(null)
    setCredentials(null)
  }

  const handleSetValue = (field, value) => {
    credentialDispatch({
      type: ACTIONS.UPDATE_FIELD,
      payload: { field: dashatize(field.label, "_"), value: value },
    })
    credentialDispatch({ type: ACTIONS.CHECK_FIELD_VALIDITY })
  }

  if (!show) return <></>

  if (selectedDestination.is_upload) {
    return (
      <div className="account-selection-wrapper">
        <div className="option-header">
          <h2>Choose one of the two options</h2>
          <span onClick={handleOpenGuide} className="material-icons">
            help
          </span>
        </div>
        <div className="account-selection-body">
          <div
            className={`option ${selectedAccount === 1 ? 'selected' : ''}`}
            onClick={() => setSelectedAccount(1)}
          >
            <div className="option-header">
              <span className="material-icons-outlined">person_search</span>
              <p>Your service principal</p>
            </div>
            {selectedAccount === 1 && (
              <div className="option-body">
                <p>
                  Upload a '.json' file containing the credentials of the
                  service principal
                </p>
                {credentialState.validityError && (
                  <p className="error">The file has invalid format</p>
                )}
                {!selectedFile ? (
                  <UploadZone
                    clear={clearFile}
                    fileType="json"
                    uploadFile={processUploadedFile}
                    uploadingFile={false}
                  ></UploadZone>
                ) : (
                  <p onClick={handleClearFile} className="selected-file">
                    &times; {selectedFile.name}
                  </p>
                )}
              </div>
            )}
          </div>
          <div
            className={`option disabled ${
              selectedAccount === 2 ? 'selected' : ''
            }`}
            onClick={() => {
              // TODO: it's disabled for now
              // setSelectedAccount(2)
            }}
          >
            <div className="option-header">
              <span className="material-icons-outlined">people</span>
              <p>Our service principal</p>
            </div>
            {selectedAccount === 2 && <div className="option-body"></div>}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="credential-form-wrapper">
        <div className="option-header">
          <h2>Please fill in the following fields</h2>
          <span onClick={handleOpenGuide} className="material-icons">
            help
          </span>
        </div>
        <p>Check the guide on where to find the required credentials</p>
        {selectedDestination.form !== null &&
          selectedDestination.form.map((field) => (
            <InputField
              key={field.label}
              label={field.label}
              placeholder={field.label}
              pattern={field.pattern}
              type="text"
              value={credentialState.fields[dashatize(field.label, "_")]?.value}
              setValue={(value) => handleSetValue(field, value)}
              success={credentialState.fields[
                dashatize(field.label, "_")
              ]?.value?.match(field.pattern)}
              info={
                !credentialState.fields[dashatize(field.label, "_")]?.value?.match(
                  field.pattern
                )
                  ? 'Match the following format: ' + field.help
                  : undefined
              }
            />
          ))}
      </div>
    )
  }
}

const Step3 = ({
  show,
  destinationLocations,
  selectedLocation,
  setSelectedLocation,
}) => {
  if (!show) return <></>

  return (
    <div className="dropdown-wrapper">
      <h2>Choose a location closer to your data</h2>
      <DropDownField
        label="Choose a location"
        onChange={(v) => setSelectedLocation(v)}
        options={destinationLocations}
        value={selectedLocation}
        filterWithSimilarity
        style={{
          width: '30vw',
        }}
      />
    </div>
  )
}

const DestinationPreview = ({
  destinationPreview,
  organizationDestination,
  previewOpen,
  setPreviewOpen,
  canConfigureDestination,
  setShowConfiguration,
}) => {
  return (
    <>
      <div className="destination-preview-wrapper">
        {destinationPreview !== null ? (
          <div className="destination-preview-container">
            <div
              className="preview-header"
              onClick={() => setPreviewOpen((prev) => !prev)}
            >
              <div className="preview-info">
                <img src={destinationPreview.logo} alt="" />
                <p>{destinationPreview.name}</p>
              </div>
              <span className="material-icons">
                keyboard_arrow_{!previewOpen ? 'down' : 'up'}
              </span>
            </div>
            {previewOpen && (
              <div className="preview-body">
                <div className="preview-body__item">
                  <span className="material-icons">manage_accounts</span>
                  <p>
                    Destination managed by Maya:{' '}
                    {destinationPreview.managed ? (
                      <span className="true">True</span>
                    ) : (
                      <span className="false">False</span>
                    )}
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons">map</span>
                  <p>
                    Location:{' '}
                    <span className="true">
                      {organizationDestination.location}
                    </span>
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons">settings_applications</span>
                  <p>
                    Can modify destination:{' '}
                    {canConfigureDestination ? (
                      <span className="true">True</span>
                    ) : (
                      <span className="false">False</span>
                    )}
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons">event_available</span>
                  <p>
                    Date created:{' '}
                    <span className="date">
                      {beautifyNotificationDate(
                        organizationDestination.created_at
                      )}
                    </span>
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons-outlined">today</span>
                  <p>
                    Date updated:{' '}
                    <span className="date">
                      {beautifyNotificationDate(
                        organizationDestination.updated_at
                      )}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          'User has configured organization'
        )}
        {/* {canConfigureDestination ( */}
        <button
          className="submit-button"
          onClick={() => setShowConfiguration(true)}
        >
          Modify
        </button>
        {/* )} */}
      </div>
    </>
  )
}

export const dashatize = (word: string, symbol: string = "-"): string => {
  return word?.toLocaleLowerCase().replaceAll(' ', symbol) || ''
}
