import React, { useContext, useEffect } from 'react'
import LogTable from './LogTable'

import ScreenLoading from 'components/ScreenLoading'
import OrganizationsContext from 'containers/organizations/organizationsContext'

import useETLProgress from 'containers/etl/useETLProgress'
import useNextETLTime from 'containers/etl/useNextETLTime'
import useTriggerETL from 'containers/etl/useTriggerETL'

import * as rudderanalytics from 'rudder-sdk-js'

import Moment from 'react-moment'
import './DataLoad.scss'
import useGetETLlogs from 'containers/etl/useGetETLlogs'
import TagManager from 'react-gtm-module'

const DataLoad = () => {
  const { currentOrganization } = useContext(OrganizationsContext)
  const [nextETLTime, fetchNextETLTime, fetchNextETLTimeStatus] =
    useNextETLTime(currentOrganization.id)
  const [etlProgress, fetchETLProgress, fetchETLProgressStatus] =
    useETLProgress(currentOrganization.id)
  const [triggerETL] = useTriggerETL()
  const { getETLlogs, etlLogs, latestETLDate, loadingLogs, errorGettingLogs } =
    useGetETLlogs(currentOrganization)

  const handleTriggerETLButton = async () => {
    TagManager.dataLayer({ dataLayer: { event: 'data_load_button_clicked', is_custom_track: true }})
    // rudderanalytics.track('data_load_button_clicked')
    await triggerETL(currentOrganization.id)
    await Promise.all([fetchNextETLTime(), fetchETLProgress()])
  }

  useEffect(() => {
    fetchNextETLTime()
    fetchETLProgress()
    getETLlogs()
    // eslint-disable-next-line
  }, [currentOrganization.id])

  const buttonDisabled = etlProgress && etlProgress.running

  return (
    <div className="data-load-container">
      <div className="clusters-campaigns container-column data-load">
        <div className="head">
          <div className="page-name">
            <h2>Data Load</h2>
            <span className="subtitle-text">
              Monitor and control next time your data will be loaded.
            </span>
          </div>
        </div>
        <div className="next-etl-time">
          {!currentOrganization.id ||
          fetchNextETLTimeStatus === 'pending' ||
          fetchETLProgressStatus === 'pending' ? (
            <ScreenLoading />
          ) : (
            <>
              {etlProgress.running ? (
                <>
                  {' '}
                  Loading data: {etlProgress.description} {etlProgress.points}%
                </>
              ) : !nextETLTime ? (
                <> Next Data Load will start at: N/A</>
              ) : (
                <>
                  {' '}
                  Next Data Load will start at{' '}
                  <Moment local format="HH:mm a">
                    {nextETLTime}
                  </Moment>
                </>
              )}
              <p className="started-at">
                Last Data Load started at:{' '}
                {latestETLDate === undefined ? (
                  'N/A'
                ) : (
                  <Moment local format="YYYY-MM-DD HH:mm:ss">
                    {latestETLDate}
                  </Moment>
                )}
              </p>
              <button
                onClick={handleTriggerETLButton}
                disabled={buttonDisabled}
                className={`run-etl-button ${buttonDisabled ? 'disabled' : ''}`}
                style={{ marginTop: '10px' }}
              >
                Load data now
              </button>
            </>
          )}
        </div>
      </div>
      <div className="logs-container">
        <h2>History</h2>
        {loadingLogs ? (
          <ScreenLoading />
        ) : errorGettingLogs.length > 0 ? (
          <p style={{ marginLeft: '3rem' }}>{errorGettingLogs}</p>
        ) : (
          <LogTable allLogs={etlLogs}></LogTable>
        )}
      </div>
    </div>
  )
}
export default React.memo(DataLoad)
