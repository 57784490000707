import ScreenLoading from 'components/ScreenLoading'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const GooglePlayOauth = () => {
  const history = useHistory()
  const params = useLocation().search

  useEffect(() => {
    const org = localStorage.getItem('currentOrganization')

    const baseURL = `/organization/${org}/sources/setup/google_play/oauth${params}`
    history.push(baseURL)
    return
  }, [history, params])

  return (
    <div>
      <ScreenLoading />
    </div>
  )
}

export default GooglePlayOauth
