import api, { isAPIError } from 'containers/api'
import { selectToken } from 'containers/authentication/authenticationSlice'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { IETLlogs, IOrganization } from 'types'

const useGetETLlogs = (organization: IOrganization) => {
  const token = useSelector(selectToken)

  const [etlLogs, setEtlLogs] = useState<IETLlogs>()
  const [latestETLDate, setLatestETLDate] = useState<Date>()
  const [errorGettingLogs, setErrorGettingLogs] = useState<string>('')
  const [loadingLogs, setLoadingLogs] = useState<boolean>(false)

  const getETLlogs = async () => {
    try {
      setLoadingLogs(true)
      const rawLogs = await api.getETLlogs({
        organizationId: organization.id,
        token,
      })
      const logs = rawLogs.all_logs
      setEtlLogs(logs)
      setLatestETLDate(rawLogs.latest_etl_date)
      setLoadingLogs(false)
    } catch (err) {
      setLoadingLogs(false)
      if (isAPIError(err) && typeof err.response.data.detail == 'string') {
        setErrorGettingLogs(err.response.data.detail)
      }
    }
  }

  return { getETLlogs, etlLogs, latestETLDate, loadingLogs, errorGettingLogs }
}

export default useGetETLlogs
