import React, { useEffect, useContext, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import ScreenLoading from 'components/ScreenLoading'
import ClustersWordGroups from './ClustersWordGroups'
import Item from 'components/Item'

import Page404 from 'pages/ErrorPages/404'

import useGetColumns from 'containers/clustering/useGetColumnsByCategory'
import useUpdateColumnsByCategory from 'containers/clustering/useUpdateColumnsByCategory'
import useDeleteColumnByCategory from 'containers/clustering/useDeleteColumnByCategory'
import useConfirmation from 'containers/confirmation/useConfirmation'
import useListClusteringCategories from 'containers/clustering/useListClustingCategories'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import { FetchStatus } from 'containers/dataLoader'

import './ClustersOverview.scss'

const ClustersOverview = ({ setClusterBreadcrumb }) => {
  const { clusterCategory } = useParams()
  const {
    columns,
    loadingColumns,
    columnsError,
    fetchColumnsByCategory,
    deleteColumn,
    reorderColumns,
  } = useGetColumns()
  const { updateColumnsByCategory, updateColumnsError } =
    useUpdateColumnsByCategory()
  const { deleteColumnByCategory } = useDeleteColumnByCategory()
  const { confirm } = useConfirmation()
  const [wordGroupsUnsaved, setWordGroupsUnsaved] = useState(false)
  let history = useHistory()

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)
  const [, getClusteringCaterogies, hasFetchedCategories] =
    useListClusteringCategories(currentOrganization)
  const [clusteringCategories, setClusteringCategories] = useState([])
  const [is404, setIs404] = useState(false)

  const getClusteringCategoryData = async () => {
    const res = await getClusteringCaterogies()
    setClusteringCategories([
      ...res.categories,
      { label: 'Word Groups', slug: 'word-groups' },
    ])
  }

  useEffect(() => {
    getClusteringCategoryData()
    // eslint-disable-next-line
  }, [])

  const setBreadCrumb = useCallback(() => {
    if (setClusterBreadcrumb && clusteringCategories?.length > 0) {
      const { slug: destination, label: category } = clusteringCategories.find(
        (item) => item.slug === clusterCategory
      )
      setClusterBreadcrumb({
        category: category,
        name: null,
        url: `/organization/${currentOrganization.slug}/clusters/${destination}`,
      })
    }
  }, [clusterCategory])

  useEffect(() => {
    if (!isLoadingOrganization && currentOrganization) {
      // fetchColumnsByCategory(clusterCategory)
      setBreadCrumb()
    }
  }, [clusterCategory])

  const getData = async () => {
    if (!isLoadingOrganization && currentOrganization) {
      await fetchColumnsByCategory({
        category: clusterCategory,
        organization: currentOrganization,
      })
    }
  }

  useEffect(() => {
    if (clusteringCategories?.length <= 0) return
    if (!_.find(clusteringCategories, ['slug', clusterCategory])) {
      return
    }

    getData()
    // eslint-disable-next-line
  }, [
    currentOrganization.slug,
    isLoadingOrganization,
    clusterCategory,
    clusteringCategories,
  ])

  const handleWindowClose = (e) => {
    e && e.preventDefault && e.preventDefault()
    if (!wordGroupsUnsaved) {
      return
    }
    // note: most browsers ignore the custom message and display their own if you return any nonempty string
    return 'You have unsaved changes. Are you sure you want to exit?'
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose)
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      clusteringCategories?.length > 0 &&
      hasFetchedCategories === FetchStatus.Fulfilled &&
      !_.find(clusteringCategories, ['slug', clusterCategory])
    ) {
      setIs404(true)
    }
  }, [clusteringCategories])

  const tabInfo = _.find(clusteringCategories, ['slug', clusterCategory])

  if (columnsError) {
    return <>Error: {columnsError}</>
  }

  const handleAddColumn = () => {
    history.push(
      `/organization/${
        currentOrganization.slug
      }/clusters/${clusterCategory}/${uuidv4()}`
    )
  }

  const handleDragEnd = async (result) => {
    if (!result || !result.destination) {
      return
    }

    const { newColumns, reorder, undoReorder } = reorderColumns({
      columnId: result.draggableId,
      endIndex: result.destination.index,
    })

    const columnIds = newColumns.map((c) => c.id)

    reorder()

    if (
      await updateColumnsByCategory({
        category: clusterCategory,
        organization: currentOrganization,
        columnIds,
        columns: columns.columns,
        updateType: 'reorder',
      })
    ) {
      await fetchColumnsByCategory({
        category: clusterCategory,
        organization: currentOrganization,
      })
    } else {
      undoReorder()
    }
  }

  const handleChangeTab = async (destination) => {
    if (
      !wordGroupsUnsaved ||
      (await confirm(
        'You have unsaved changes. Are you sure you want to change tab?'
      ))
    ) {
      setWordGroupsUnsaved(false)
      history.push(
        `/organization/${currentOrganization.slug}/clusters/${destination}`
      )
    }
  }

  const handleWordGroupsChanges = (_state) => {
    setWordGroupsUnsaved(true)
  }

  const handleWordGroupsSave = () => {
    setWordGroupsUnsaved(false)
  }

  const handleDeleteItem = async (columnId) => {
    const { remove } = deleteColumn(columnId)

    if (
      await deleteColumnByCategory({
        category: clusterCategory,
        organization: currentOrganization,
        columnId,
      })
    ) {
      remove()
      await fetchColumnsByCategory({
        category: clusterCategory,
        organization: currentOrganization,
      })
    }
  }

  return (is404 ? (
    <Page404 />
  ) : (
    <>
      <div className="clusters-campaigns clusters-overview-campaigns container-column">
        {updateColumnsError && (
          <div className="alert alert-danger" role="alert">
            {updateColumnsError}
          </div>
        )}
        <div className="head">
          <div className="page-name">
            <h2>Clusters</h2>
            <span className="subtitle-text">
              Organize your data into meaningful clusters.
            </span>
          </div>
        </div>
        <nav className="page-navigation-menu">
          <ul>
            {clusteringCategories ? (
              clusteringCategories.map((category, tabIndex) => (
                <li
                  key={tabIndex}
                  onClick={() => handleChangeTab(category.slug)}
                  className={
                    category.slug === clusterCategory ? 'selected' : ''
                  }
                >
                  {category.label}
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </nav>
        {clusterCategory === 'word-groups' ? (
          <ClustersWordGroups
            organization={currentOrganization}
            onChange={handleWordGroupsChanges}
            onSave={handleWordGroupsSave}
          />
        ) : (
          <>
            {isLoadingOrganization || loadingColumns ? (
              <ScreenLoading />
            ) : (
              <>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="columns" type="columns">
                    {(provided, _droppableSnapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {columns.columns.map((column, columnIndex) => (
                          <Item
                            key={column.id}
                            columns={[
                              {
                                label: column.db_name,
                                value: column.name,
                                size: 10,
                              },
                            ]}
                            linkText="Edit"
                            id={column.id}
                            isDraggable={!column.is_fixed}
                            editUrl={`/organization/${currentOrganization.slug}/clusters/${clusterCategory}/${column.id}`}
                            deletable={
                              !column.is_placeholder && !column.is_fixed
                            }
                            orderIndex={columnIndex}
                            handleDeleteItem={() => handleDeleteItem(column.id)}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {columns.columns.length < tabInfo.maxColumns && (
                  <button onClick={handleAddColumn} className="link  add-column">
                    <span className="enlarge">+</span>Add Cluster
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  ))
}

export default React.memo(ClustersOverview)
