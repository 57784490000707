import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import { Provider } from 'react-redux'
import { enableMapSet } from 'immer'
import { CookiesProvider } from 'react-cookie'

import 'material-icons/iconfont/material-icons.css'

import store from './containers/store'

enableMapSet()

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
)
