import { useSelector } from 'react-redux'
import { selectToken } from 'containers/authentication/authenticationSlice'

import api from 'containers/api'
import useMutator from 'containers/mutator'

const useDeleteReport = () => {
  const token = useSelector(selectToken)
  const deleteReport = async ({
    organizationId,
    reportId,
  }: {
    organizationId: number
    reportId: string
  }) => {
    return await api.deleteEmbedReport({ organizationId, token, reportId })
  }
  return useMutator(deleteReport)
}

export default useDeleteReport
