import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

const useCreateInvite = () => {
  const token = useSelector(selectToken)

  const createInvite = async ({ organization, email }) => {
    const organizationId = organization.id

    try {
      const responseCreateInvite = await api.createInvite({
        organizationId,
        email,
        token,
      })
      return responseCreateInvite
    } catch {
      return false
    }
  }

  return { createInvite }
}

export default useCreateInvite
