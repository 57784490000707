import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import api from '../api'

const useDeleteServiceAccount = () => {
  const [deleteServiceAccountError, setDeleteServiceAccountError] = useState('')
  const token = useSelector(selectToken)

  const deleteServiceAccount = async (uuid: string, organizationId: number) => {
    if (!token) {
      return
    }

    try {
      const deletedServiceAccount = await api.deleteServiceAccount({
        uuid,
        organizationId,
        token,
      })
      return deletedServiceAccount
    } catch (error) {
      setDeleteServiceAccountError(
        error instanceof Error
          ? error.message
          : 'failed deleting service account'
      )
    }
    return false
  }

  return { deleteServiceAccount, deleteServiceAccountError }
}

export default useDeleteServiceAccount
